import Swal from "sweetalert2";

export default class AuthHelper {
    static alertReadOnly(redirectToHome) {
        Swal.fire({
            icon: "warning",
            title: "Atenção",
            text: "Não é possível acessar este benefício em modo de leitura!",
        }).then(() => {
            if (redirectToHome) {
                // Redirect para home caso seja necessário, do contrário, somente exibe popout.
                window.location.replace('/');
            }
        });
    }

    static alertInadiplente(redirectToHome) {
        Swal.fire({
            icon: "warning",
            title: "Essa operação não pode ser realizada",
            text: 'Por favor verifique o pagamento de sua mensalidade!',
        }).then(() => {
            if (redirectToHome) {
                // Redirect para home caso seja necessário, do contrário, somente exibe popout.
                window.location.replace('/');
            }
        });
    }
}

