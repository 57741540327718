export const MigrationOfPointsTypes = {
    MIGRATION_STATE : "@migrationOfPoints/MIGRATION_STATE",
    MIGRATION_POINTS_SUBSIDIARY_REQUEST : "@migrationOfPoints/MIGRATION_POINTS_SUBSIDIARY_REQUEST",
    MIGRATION_POINTS_SUBSIDIARY_SUCCESS : "@migrationOfPoints/MIGRATION_POINTS_SUBSIDIARY_SUCCESS",
    MIGRATION_POINTS_SUBSIDIARY_FAILURE : "@migrationOfPoints/MIGRATION_POINTS_SUBSIDIARY_FAILURE",
    MIGRATION_POINTS_REQUEST : "@migrationOfPoints/MIGRATION_POINTS_REQUEST",
    MIGRATION_POINTS_SUCCESS : "@migrationOfPoints/MIGRATION_POINTS_SUCCESS",
    MIGRATION_POINTS_FAILURE : "@migrationOfPoints/MIGRATION_POINTS_FAILURE",
    MIGRATION_POINTS_HISTORY_REQUEST : "@migrationOfPoints/MIGRATION_POINTS_HISTORY_REQUEST",
    MIGRATION_POINTS_HISTORY_SUCCESS : "@migrationOfPoints/MIGRATION_POINTS_HISTORY_SUCCESS",
    MIGRATION_POINTS_HISTORY_FAILURE : "@migrationOfPoints/MIGRATION_POINTS_HISTORY_FAILURE"
}