import React from "react";
import './src/quizPrime.min.css';

const QuizPrime = () => {
  return (
    <section className="page-quiz-prime">
      <div>
        <iframe id='myFrame' title="quiz" src="https://docs.google.com/forms/d/e/1FAIpQLSeE5YR4_U2U_8a1QEQUQjOWtr1aZwEbEZ2x4b7ZnTTswrWrLw/viewform?embedded=true" width="640" frameBorder="0" marginHeight="0" marginWidth="0">Carregando…</iframe>
      </div>
    </section>
  );
};
export default QuizPrime;
