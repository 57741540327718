
import React, { Fragment } from 'react';
import './src/fields.min.css';

// COMPONENTES DE INPUTS PADRAO (TEXT, PASSWORD, EMAIL) 
const Textarea = ({ error, label, inputProps }) => {

    return (
        <div className={`fm-field fm-textarea ${error ? 'filled-error' : ''}`}>
            <label htmlFor={inputProps.id}>
                {label}
            </label>
            {React.createElement('textarea', { ...inputProps })}
            {error && <span className='error-label'>{error.message}</span>}
        </div>
    )
};

export default Textarea;
