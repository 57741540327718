import { ProductCard } from "@/components/cards";
import { DefaultContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { default as Carousel } from "react-slick";
import * as ShowCaseActions from "store/showCase/actions";
import AuthHelper from "./../../Utils/AlertHelper";
import "./src/showcase.min.css";

// VITRIDE DE PRODUTOS, USANDO CARROSSEL
const ProductsShowcase = ({
  products,
  loading,
  loadShowCase,
  inadimplente,
  readOnly,
  inadimplenteVencimentos,
  matriz,
  cobrancaCentralizada,
  reativacaoAtual,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 548,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          dots: true,
          slidesToShow: 4,
        },
      },
    ],
  };

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    loadShowCase();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  const onClick = (event) => {
    event.preventDefault();

    if (readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }
    if (
      reativacaoAtual &&
      AuthHelper.validateUpdateDateReentry(reativacaoAtual.dataConclusao) &&
      !reativacaoAtual.negociaDebito
    ) {
      AuthHelper.alertReentry(false);
      return;
    } else if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }

    window.open(
      `${process.env.REACT_APP_URL_CATALOG}/vendor?vendor=61906`,
      "_blank"
    );
  };

  return (
    productList &&
    productList.length > 0 && (
      <section className="showcase product-list">
        <DefaultContainer>
          <SectionTitle title="Você também pode trocar seus pontos acumulados por descontos:">
            <a
              className="btn ic-go f-right"
              onClick={(e) => {
                onClick(e);
              }}
            >
              veja tudo
            </a>
          </SectionTitle>

          <Carousel {...settings}>
            {productList &&
              productList.map((product, i) => {
                return (
                  <ProductCard
                    key={`product-${i}`}
                    originalId={product.originalId}
                    cardType="product-card"
                    image={product.imageUrl}
                    description={product.name}
                    url={`${process.env.REACT_APP_URL_CATALOG}/products/${product.sku}`}
                    button={true}
                    sku={product.sku}
                    blank={true}
                    position={i}
                    inadimplente={inadimplente}
                    inadimplenteVencimentos={inadimplenteVencimentos}
                    readOnly={readOnly}
                    matriz={matriz}
                    cobrancaCentralizada={cobrancaCentralizada}
                    reativacaoAtual={reativacaoAtual}
                  />
                );
              })}
          </Carousel>
        </DefaultContainer>
      </section>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.showcase.products,
    loading: state.showcase.loading,
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    readOnly: state.auth.userInfo.readOnly,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
    reativacaoAtual: state.auth.userInfo.reativacaoAtual,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadShowCase: () => {
      dispatch(ShowCaseActions.loadShowCase());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsShowcase);
