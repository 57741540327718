import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Validation from "@/components/utils/validationFields";
import { BoxOutline } from "@/components/boxOutline";
import { RadioButton } from "@/components/formFields";
import { IconCheck } from "../icons";

import "./src/myData.min.css";
import { Label } from "reactstrap";

const MyData = ({ getMyDataRequest, myData }) => {
  useEffect(() => {
    // getMyDataRequest()
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const visitTypes = [
    {
      value: "Horário Comercial",
      id: 1,
    },
    {
      value: "Horário Comercial (Segunda a Sábado)",
      id: 2,
    },
    {
      value: "Horário Comercial 7 dias",
      id: 3,
    },
    {
      value: "24 horas",
      id: 4,
    },
    {
      value: "Horário Noturno (Segunda a Sexta)",
      id: 5,
    },
    {
      value: "Final de Semana",
      id: 6,
    },
    {
      value: "Outros",
      id: 7,
    }
  ];

  const { empresa } = myData;
  const {
    codigoSAP,
    razaoSocial,
    cnpj,
    telefone,
    endereco,
    numero,
    bairro,
    cidade,
    uf,
    email,
    cep,
    pontoReferencia,
    periodoVisita,
  } = empresa;

  return (
    <form>
      <section>
        <div className="fields">
          <BoxOutline
            label={"Código SAP"}
            inputProps={{
              id: "codigo-sap",
              disabled: true,
              name: "codigoSAP",
              value: codigoSAP,
            }}
          />
          <BoxOutline
            label={"CNPJ / CPF"}
            mask={
              Validation.cpf(cnpj) ? "999.999.999-99" : "99.999.999/9999-99"
            }
            inputProps={{
              id: "cpfCnpj",
              disabled: true,
              name: "cnpj",
              value: cnpj,
            }}
          />
          <BoxOutline
            label={"Razão Social / Nome"}
            inputProps={{
              id: "razao-social",
              disabled: true,
              name: "razaoSocial",
              value: razaoSocial,
            }}
          />
          <BoxOutline
            label={"Telefone"}
            mask={
              telefone && telefone.length > 10
                ? "(99) 99999-9999"
                : "(99) 9999-9999"
            }
            inputProps={{
              id: "telefone",
              disabled: true,
              name: "telefone",
              value: telefone,
            }}
          />
          <BoxOutline
            label={"E-mail"}
            inputProps={{
              id: "email",
              disabled: true,
              name: "email",
              value: email,
            }}
          />
          <BoxOutline
            label={"Endereço"}
            inputProps={{
              id: "endereco",
              disabled: true,
              name: "endereco",
              value: endereco,
            }}
          />

          <BoxOutline
            label={"Bairro"}
            inputProps={{
              id: "bairro",
              disabled: true,
              name: "bairro",
              value: bairro,
            }}
          />
          <BoxOutline
            label={"Cidade"}
            inputProps={{
              id: "cidade",
              disabled: true,
              name: "cidade",
              value: cidade,
            }}
          />
          <BoxOutline
            label={"Estado"}
            inputProps={{
              id: "uf",
              disabled: true,
              name: "uf",
              value: uf,
            }}
          />
          <BoxOutline
            label={"CEP"}
            mask={"99999-999"}
            inputProps={{
              id: "cep",
              disabled: true,
              name: "cep",
              value: cep,
            }}
          />
          <BoxOutline
            label={"Ponto Referência"}
            inputProps={{
              id: "referencia",
              disabled: true,
              name: "referencia",
              value: pontoReferencia,
            }}
          />
        </div>
      </section>

      <label htmlFor="period-select" className="label-dist">
      Horário de funcionamento
      </label>
      <div className="box-selected-period" id="period-select">
        {visitTypes.map((item) => {
          return (
            <RadioButton
              key={item.id}
              label={item.value}
              // checked={periodoVisita === 0 ? true : periodoVisita === item.id}
              inputProps={{
                id: `periodo-${item.id}`,
                name: "periodo",
                value: `${item.id}`,
                defaultChecked: false,
              }}
            />
          );
        })}
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({
  myData: state.myData.data,
});
export default withRouter(connect(mapStateToProps)(MyData));
