import {SingleSignOnTypes} from './types';

const INITIAL_STATE = {
    data: {},
    loading: true,
    error: false,
    success: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SingleSignOnTypes.SSO_REQUEST:
        return {...state, loading: true, data: { valid: false, message: null}};
      case SingleSignOnTypes.SSO_SUCCESS: 
       return {
          ...state,
          data: action.payload.data.data,
          loading: false,
          success: action.payload.data.data.success
        }
      case SingleSignOnTypes.SSO_FAILURE: 
        return {...state, loading: false, error: true, success: false}
      case SingleSignOnTypes.SSO_RESET:
        return {...state, data: { valid: false, message: null}}
      default:
        return state;
    }
};

export default reducer;
