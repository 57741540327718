import React from "react";
import InputMask from "react-input-mask";
import "./src/fields.min.css";

const InputTelAndCellphone = ({ error, label, inputProps, type }) => {

  return (
    <div className={`fm-field text-field ${error ? "filled-error" : ""}`}>
      <label htmlFor={inputProps.id}>{label}</label>
      {type === "tel" ? (
        <InputMask {...inputProps} mask="(99) 9999-9999" />
      ) : (
        <InputMask {...inputProps} mask="(99) 99999-9999" />
      )}
      {error && <span className="error-label">{error.message}</span>}
    </div>
  );
};

export default InputTelAndCellphone;
