
import { signIn, signOut, loadPermissions, reentryUserNegociationRequest, reentryHistoricRequest } from '@/store/auth/sagas';
//Auth
import { AuthTypes } from '@/store/auth/types';
import { getMyData, putMyData } from '@/store/my-data/sagas';
import { MyDataTypes } from '@/store/my-data/types';
import { all, takeLatest } from 'redux-saga/effects';
import { acceptanceTerm } from './acceptanceTerm/sagas';
//Acceptance Term
import { AcceptanceTermTypes } from './acceptanceTerm/types';
import { loadBalance } from './balance/sagas';
//Balance
import { BalanceTypes } from './balance/types';
import { loadBanner } from './banner/sagas';
//Banner
import { BannerTypes } from './banner/types';
import { contactUs, loadSubjects } from './contactUs/sagas';
//ContactUs
import { ContactUsTypes } from './contactUs/types';
import { postDeliveryPay, deliveryPayAcceptTermsRequest, deliveryPayPortalLinkRequest } from './deliveryPay/sagas';
import { DeliveryPayTypes } from './deliveryPay/types';
import { getDeliveryPayBanks } from './deliveryPayBanks/sagas';
import { DeliveryPayBanksTypes } from './deliveryPayBanks/types';
import { loadFaq } from './faq/sagas';
//Faq
import { FaqTypes } from './faq/types';
import { loadFirstAccess, updateFirstAccess } from './firstAccess/sagas';
//First Access
import { FirstAccessTypes } from './firstAccess/types';
import { getParticipantSimulator } from './participant/simulator/sagas';
import { ParticipantSimulatorTypes } from './participant/simulator/types';
import { loadRegulation } from './regulation/sagas';
//Regulation
import { RegulationTypes } from './regulation/types';
import { loadShowCase } from './showCase/sagas';
//Show case
import { ShowCaseTypes } from './showCase/types';

//Account Statement
import { AccountStatementTypes } from './accountStatement/types';
import { loadAccountStatement } from './accountStatement/sagas';

//Benefits
import { BonusTypes } from './bonus/types';
import { loadBonus, loadBonusRedes } from './bonus/sagas';

//Single sign on
import { SingleSignOnTypes } from './singleSignOn/types';
import { validSSOParameters } from './singleSignOn/sagas';

//Performance
import { PerformanceTypes } from './performance/types';
import { loadPerformance, selectMonthPerformance } from './performance/sagas';
//Simulator
import { SimulatorTypes } from './simulator/types';
import { simulatorRequest } from './simulator/sagas';


//Payment
import { PaymentTypes } from './payments/types';
import { loadPaymentMethodsRequest, participantPaymentHistoryRequest, participantPaymentMethodRequest, participantPaymentChangeRequest, participantCopyOfTheBankSlipRequest, changeFlagShowNotificationChangePaymentRequest, setCreditCardPayment, setChangeCreditCardPayment, sporadicBankSlipRequest} from './payments/sagas';

//Retail Chain
import { RetailChainTypes } from './retailChain/types';
import { retailChainListRequest, facilityIdentificationRequest, retailChainSolicitationRequest, retailChainReportRequest } from './retailChain/sagas';

//Migration of points
import { MigrationOfPointsTypes } from './migrationOfPoints/types';
import { migrationPointsSubsidiaryRequest, migrationPointsRequest, migrationPointsHistoryRequest } from './migrationOfPoints/sagas';

//Cancel
import { CancelTypes } from './cancel/types';
import { updateCancelRequest } from './cancel/sagas';

// Centralized Billing
import { CentralizedBillingTypes } from './centralizedBilling/types';
import { acceptanceCentralizedBilling, centralizedBillingHistory } from './centralizedBilling/sagas';

//Club benefits
import { ClubBenefitsTypes } from './clubBenefits/types';
import { clubBenefitsRequest, myBenefitsRequest, myBenefitsDetailsRequest, myBenefitsBannerSuperiorRequest } from './clubBenefits/sagas';


// Query Bank Slip
import { QueryBankSlipTypes } from './queryBankSlip/types';
import { participantBankSlipHistoryRequest, participantGenerateBankSlipRequest } from './queryBankSlip/sagas';

// Popup
import { PopupTypes } from './segmentPopups/types';
import { loadPopupHome, loadPopupCampaigns, marcarVisualizacao } from './segmentPopups/sagas';

// Awards
import { AwardsTypes } from './awardsSelection/types';
import { getTracks, choiceAward } from './awardsSelection/sagas';

// Campaigns
import { CampaignTypes } from './campaigns/types';
import { loadCampaign, getCampaignDetails, acceptCampaignRegulation } from './campaigns/sagas';

// Delivery System - Seu Delivery
import { DeliverySystemTypes } from './deliverySystem/types';
import { loadProjectsListRequest, createNewProjectRequest, loadCategoriesAndProductsRequest, putProductOnMenuRequest, updateProjectRequest, createNewProductRequest, createNewCategoryRequest, updateProductRequest, updateCategoryRequest, removeProductRequest, removeProductFromMenuRequest, removeCategoryRequest } from './deliverySystem/sagas';


export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.LOAD_PERMISSIONS_REQUEST, loadPermissions),
    takeLatest(AuthTypes.REENTRY_NEGOCIATION_REQUEST, reentryUserNegociationRequest),
    takeLatest(AuthTypes.REENTRY_HISTORIC_REQUEST, reentryHistoricRequest),
    takeLatest(MyDataTypes.MY_DATA_REQUEST, getMyData),
    takeLatest(MyDataTypes.MY_DATA_UPDATE, putMyData),
    takeLatest(BannerTypes.LOAD_BANNER_REQUEST, loadBanner),
    takeLatest(ContactUsTypes.CONTACTUS_REQUEST, contactUs),
    takeLatest(ContactUsTypes.SUBJECT_REQUEST, loadSubjects),
    takeLatest(FaqTypes.FAQ_REQUEST, loadFaq),
    takeLatest(BalanceTypes.BALANCE_REQUEST, loadBalance),
    takeLatest(RegulationTypes.LOAD_REGULATION_REQUEST, loadRegulation),
    takeLatest(AcceptanceTermTypes.ACCEPTANCETERM_REQUEST, acceptanceTerm),
    takeLatest(FirstAccessTypes.LOAD_FIRSTACCESS_REQUEST, loadFirstAccess),
    takeLatest(FirstAccessTypes.UPDATE_FIRSTACCESS_REQUEST, updateFirstAccess),
    takeLatest(DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_REQUEST, getDeliveryPayBanks),
    takeLatest(DeliveryPayTypes.DELIVERY_PAY_REQUEST, postDeliveryPay),
    takeLatest(ShowCaseTypes.LOAD_SHOWCASE_REQUEST, loadShowCase),
    takeLatest(ParticipantSimulatorTypes.PARTICIPANT_SIMULATOR_REQUEST, getParticipantSimulator),
    takeLatest(AccountStatementTypes.ACCOUNT_STATEMENT_REQUEST, loadAccountStatement),
    takeLatest(BonusTypes.BONUS_REQUEST, loadBonus),
    takeLatest(BonusTypes.BONUS_REDES_REQUEST, loadBonusRedes),
    takeLatest(SingleSignOnTypes.SSO_REQUEST, validSSOParameters),
    takeLatest(PerformanceTypes.PERFORMANCE_REQUEST, loadPerformance),
    takeLatest(PerformanceTypes.SELECT_MONTH_PERFORMANCE_REQUEST, selectMonthPerformance),
    takeLatest(SimulatorTypes.SIMULATOR_REQUEST, simulatorRequest),
    takeLatest(PaymentTypes.LOAD_PAYMENT_METHODS_REQUEST, loadPaymentMethodsRequest),
    takeLatest(DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_REQUEST, deliveryPayAcceptTermsRequest),
    takeLatest(DeliveryPayTypes.GET_CONECTA_PAY_LINK_REQUEST, deliveryPayPortalLinkRequest),
    takeLatest(RetailChainTypes.RETAIL_CHAIN_LIST_REQUEST, retailChainListRequest),
    takeLatest(RetailChainTypes.FACILITY_IDENTIFICATION_REQUEST, facilityIdentificationRequest),
    takeLatest(RetailChainTypes.RETAIL_CHAIN_SOLICITATION_REQUEST, retailChainSolicitationRequest),
    takeLatest(RetailChainTypes.RETAIL_CHAIN_REPORT_REQUEST, retailChainReportRequest),
    takeLatest(PaymentTypes.PAYMENT_HISTORY_REQUEST, participantPaymentHistoryRequest),
    takeLatest(PaymentTypes.PARTICIPANT_PAYMENT_METHOD_REQUEST, participantPaymentMethodRequest),
    takeLatest(PaymentTypes.CHANGE_FLAG_PAYMENT_REQUEST, changeFlagShowNotificationChangePaymentRequest),
    takeLatest(PaymentTypes.CHANGE_PAYMENT_METHOD_REQUEST, participantPaymentChangeRequest),
    takeLatest(PaymentTypes.COPY_OF_THE_BANK_SLIP_REQUEST, participantCopyOfTheBankSlipRequest),
    takeLatest(PaymentTypes.SET_CREDIT_CARD_PAYMENT_REQUEST, setCreditCardPayment),
    takeLatest(PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_REQUEST, setChangeCreditCardPayment),
    takeLatest(PaymentTypes.SPORADIC_BANK_SLIP_REQUEST, sporadicBankSlipRequest),
    takeLatest(MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_REQUEST, migrationPointsSubsidiaryRequest),
    takeLatest(MigrationOfPointsTypes.MIGRATION_POINTS_REQUEST, migrationPointsRequest),
    takeLatest(MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_REQUEST, migrationPointsHistoryRequest),
    takeLatest(CancelTypes.UPDATE_CANCEL_REQUEST, updateCancelRequest),
    takeLatest(QueryBankSlipTypes.BANK_SLIP_HISTORY_REQUEST, participantBankSlipHistoryRequest),
    takeLatest(QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_REQUEST, participantGenerateBankSlipRequest),
    takeLatest(ClubBenefitsTypes.CLUBBENEFITS_REQUEST, clubBenefitsRequest),
    takeLatest(ClubBenefitsTypes.MYBENEFITS_REQUEST, myBenefitsRequest),
    takeLatest(ClubBenefitsTypes.MYBENEFITSDETAILS_REQUEST, myBenefitsDetailsRequest),
    takeLatest(ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_REQUEST, myBenefitsBannerSuperiorRequest),
    takeLatest(PopupTypes.LOAD_POPUP_HOME_REQUEST, loadPopupHome),
    takeLatest(PopupTypes.LOAD_POPUP_CAMPAIGNS_REQUEST, loadPopupCampaigns),
    takeLatest(PopupTypes.LOAD_POPUP_CONFIRM_REQUEST, marcarVisualizacao),
    takeLatest(AwardsTypes.LOAD_AWARDS_REQUEST, getTracks),
    takeLatest(AwardsTypes.LOAD_AWARDS_CHOICE_REQUEST, choiceAward),
    takeLatest(CampaignTypes.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(CampaignTypes.DETAILS_CAMPAIGN_REQUEST, getCampaignDetails),
    takeLatest(CampaignTypes.ACCEPT_CAMPAIGN_REQUEST, acceptCampaignRegulation),
    takeLatest(DeliverySystemTypes.LOAD_PROJECTS_LIST_REQUEST, loadProjectsListRequest),
    takeLatest(DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_REQUEST, loadCategoriesAndProductsRequest),
    takeLatest(DeliverySystemTypes.CREATE_NEW_PROJECT_REQUEST, createNewProjectRequest),
    takeLatest(DeliverySystemTypes.CREATE_NEW_CATEGORY_REQUEST, createNewCategoryRequest),
    takeLatest(DeliverySystemTypes.PUT_PRODUCT_ON_MENU_REQUEST, putProductOnMenuRequest),
    takeLatest(DeliverySystemTypes.UPDATE_PROJECT_REQUEST, updateProjectRequest),
    takeLatest(DeliverySystemTypes.UPDATE_CATEGORY_REQUEST, updateCategoryRequest),
    takeLatest(DeliverySystemTypes.CREATE_NEW_PRODUCT_REQUEST, createNewProductRequest),
    takeLatest(DeliverySystemTypes.UPDATE_PRODUCT_REQUEST, updateProductRequest),
    takeLatest(DeliverySystemTypes.REMOVE_PRODUCT_REQUEST, removeProductRequest),
    takeLatest(DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_REQUEST, removeProductFromMenuRequest),
    takeLatest(DeliverySystemTypes.REMOVE_CATEGORY_REQUEST, removeCategoryRequest),
    takeLatest(CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_REQUEST, acceptanceCentralizedBilling),
    takeLatest(CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_REQUEST, centralizedBillingHistory),
  ])
}
