
import React from 'react';
import "./src/radioButton.min.css";


const RadioButton = ({ label, inputProps, checked, disabled, customClass }) => {

      return (
        <>
          <div className={`fm-field-radioButton ${customClass ? customClass : ''}`}>
            <input type="radio" {...inputProps} checked={checked}  disabled={disabled} />
            <label htmlFor={inputProps.id}>
              {label}
            </label>
          </div>
        </>
      )
};

export default RadioButton;
