import React from "react";

const IconPerson = (props) => {

  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.8 24"
    >
      <path
        d="M17.561,14.514h0a9.423,9.423,0,0,0-2.215-2.628,7.4,7.4,0,1,0-11.892,0A9.456,9.456,0,0,0,0,19.208v2.378A2.409,2.409,0,0,0,2.4,24h14a2.409,2.409,0,0,0,2.4-2.413V19.208A9.5,9.5,0,0,0,17.561,14.514ZM9.4,2.194A5.256,5.256,0,1,1,4.176,7.45,5.246,5.246,0,0,1,9.4,2.194ZM5.008,13.444a7.354,7.354,0,0,0,8.784,0,7.261,7.261,0,0,1,2.828,5.764v2.378a.219.219,0,0,1-.218.22H2.4a.219.219,0,0,1-.218-.22V19.208a7.261,7.261,0,0,1,2.828-5.764Z"
        transform="translate(0)"
        stroke='none'
        fill={props.fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconPerson;
