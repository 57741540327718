import PropTypes from "prop-types";
import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import "./src/overview-points.min.css";
import { Tooltip } from "@/components/tooltip";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const OverviewPoints = ({
  // valueConectaPay = 0,
  // valueConectaPayStr = '',
  conectaPayPoints = 0,
  conectaPayPointsStr = "",
  promotionPoints = 0,
  promotionPointsStr = "",
  pointsTotal = 0,
  pointsTotalStr = "",
  totalPackages = 0,
  totalPackagesStr = "",
  totalBonificacao = 0,
  totalBonificacaoStr = "",
  valorMedioPacote = 0,
  valorMedioPacoteStr = "",
  uf = "",
  afiliadoConectaPay,
  dataAceiteRegulamento,
}) => {
  const activeBenefits = []; // Em desenvolvimento na PBI Menu Meu Desempenho Fase 2
  const availableBenefits = []; // Em desenvolvimento na PBI Menu Meu Desempenho Fase 2
  const hasBenefits = Boolean(
    activeBenefits.length > 0 || availableBenefits.length > 0
  );

  function addWhiteSpace(number) {
    let beforeUnity = number.substring(0, 2);
    let afterUnity = number.substring(2).trim();

    return beforeUnity + " " + afterUnity;
  }

  return (
    <section className="overview-extract-points">
      <p>
        Cliente Prime desde: <strong>{dataAceiteRegulamento}</strong>
      </p>
      <p>Suas vendas, desde que você é Conecta Prime já renderam em prêmios:</p>

      <div className="wrap-extract">
        <div className={`bonus-total ${hasBenefits ? "has-benefits" : ""}`}>
          <ul>
            <li>
              <span className="label">Total em Bonificação:</span>
              {/* <Tooltip message={totalPackages > 0 ? totalPackagesStr : 'R$ 0,00'}> */}
              <strong className="value value-packeges">
                {totalBonificacao > 0
                  ? addWhiteSpace(totalBonificacaoStr)
                  : "R$ 0,00"}
                <div className="obs">
                  <span>*</span>
                </div>
              </strong>
              {/* </Tooltip> */}
              <small>{totalPackages} Pacotes extra de cigarro</small>
            </li>

            <li>
              <span className="label">Total em Pontos:</span>
              <strong className="value">
                {pointsTotal > 0 ? addWhiteSpace(pointsTotalStr) : "R$ 0,00"}
              </strong>
              <small>{pointsTotal} Pontos</small>
            </li>
            {afiliadoConectaPay && (
              <li>
                <span className="label"></span>
                {/* <Tooltip message={conectaPayPoints > 0 ? conectaPayPointsStr : 'R$ 0,00'}> */}
                <strong className="value">
                  {addWhiteSpace(conectaPayPointsStr)}
                </strong>
                {/* </Tooltip> */}
                <small>
                  {conectaPayPoints} Pontos de vendas na Conecta Pay
                </small>
              </li>
            )}
            <li>
              <span className="label"></span>
              <strong className="value">
                {promotionPoints > 0
                  ? addWhiteSpace(promotionPointsStr)
                  : "R$ 0,00"}
              </strong>
              <small>{promotionPoints} Pontos em campanhas</small>
            </li>
          </ul>
          <p className="description">
            <span>
              *Considerando o valor médio do pacote em{" "}
              {valorMedioPacote > 0
                ? addWhiteSpace(valorMedioPacoteStr)
                : "R$ 60,00"}{" "}
              {uf != null || uf != "" ? "em " + uf : ""}
            </span>
            <span className="line-space"></span>
          </p>

          {/* <div>
              <Link className="btn ic-go btn-inverted-white" to={`bonificacao`} target='_self'>
                Acompanhe suas bonificações
              </Link>
          </div> */}
        </div>
        {hasBenefits && (
          <div className="benefits">
            <ul>
              <li>
                <span className="label">Beneficios ativos</span>

                {activeBenefits.map((benefit, i) => {
                  return (
                    <small key={`active-benefits-item-${i}`}>{benefit}</small>
                  );
                })}
              </li>
              <li>
                <span className="label">Beneficios disponíveis</span>

                {availableBenefits.map((benefit, i) => {
                  return (
                    <small key={`available-benefits-item-${i}`}>
                      {benefit}
                    </small>
                  );
                })}
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

OverviewPoints.propTypes = {
  // valueConectaPay: PropTypes.number.isRequired,
  conectaPayPoints: PropTypes.number.isRequired,
  promotionPoints: PropTypes.number.isRequired,
  pointsTotal: PropTypes.number.isRequired,
  totalPackages: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
  dataAceiteRegulamento: state.auth.userInfo.dataAceiteRegulamento,
});

export default connect(mapStateToProps)(OverviewPoints);
