import api from '@/common/api';
import {
  toast
} from 'react-toastify';
import {
  call,
  put
} from 'redux-saga/effects';
import {
  signInFailure,
  signInSuccess,
  loadPermissionsSuccess,
  resetAllDebtorState, resetDebtorState, reentryUserNegociationSuccess, reentryUserNegociationFailure, reentryHistoricSuccess, reentryHistoricFailure,
} from './actions';
import { resetDebtorState as resetMyDataDebtorState } from '../my-data/actions';
import { getMyDataRequest } from '../my-data/actions';

export function* signIn({
  payload
}) {
  const {
    idToken,
    accessToken
  } = payload;

  try {
    const {
      data: login
    } = yield call(
      api.post,
      `/auth?v=${new Date().getTime()}`, {
      idToken: idToken,
      accessToken: accessToken,
    }
    );
    if (!login.success) {
      toast.error('Falha ao logar!');
      return;
    }
    yield put(signInSuccess(login.data.accessToken, login.data.cognitoAccessToken, login.data.cognitoIdToken, login.data.userInfo, login.data.returnUrl));
    yield put(getMyDataRequest());
  } catch (error) {
    console.warn('Falha ao logar!');
    yield put(signInFailure());
  }
}


export function* signOut({ payload }) {
  localStorage.clear();
  const redirect = `${process.env.REACT_APP_SSO_AUTH}?erro=${encodeURIComponent(payload.text)}`;
  window.location.replace(redirect);
}


export function* loadPermissions() {
  try {
    const { data } = yield call(api.get, '/secao');

    yield put(loadPermissionsSuccess(data));
  } catch (error) {
    console.log(error)
    return false
  }
}

// Reingresso - Decisão negociação do usuário
export function* reentryUserNegociationRequest(action) {
  try {
    const { data } = yield call(api.post, `/reativacao/negociacao?NegociaDebito=${action.payload.negociation}`);
    yield put(reentryUserNegociationSuccess());
    if (!action.payload.negociation) {
      yield put(resetAllDebtorState());
      yield put(resetMyDataDebtorState());
    } else {
      yield put(resetDebtorState());
      yield put(resetMyDataDebtorState());
    }
  } catch (error) {
    yield put(reentryUserNegociationFailure());
    console.log(error)
    return
  }
}

// Reingresso - Historico de pagamentos + Paramatros de campanha de incentivo
export function* reentryHistoricRequest(action) {
  try {
    const { data } = yield call(api.get, `/reativacao/ListaHistoricoRenegociacao?PromocaoIncentivoId=${action.payload.incentivoId}`);
    yield put(reentryHistoricSuccess(data));
  } catch (error) {
    yield put(reentryHistoricFailure(error));
  }
}
