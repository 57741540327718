import { ModalPopup } from "@/components/modalPopup";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as PopupActions from "../../store/segmentPopups/actions";

const SegmentPopup = ({
  readOnly,
  acceptanceTerm,
  afiliadoConectaPayPendente,
  primeiroAcessoRealizado,
  loadPopupHome,
  loadCampaignsHome,
  loadConfirmPopup,
  participanteLoginId,
  type,
  home,
  campaigns,
  loading,
  mostrarEscolhas,
}) => {
  const [popupData, setPopup] = useState(undefined);
  const [isShow, setIsShow] = useState(false);
  const [modalState, setModalState] = useState("close");

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    title: "",
    iconAlert: true, // Ícone de alerta no topo do modal
    btnClose: true,
    mediaTag: true, // Imagem OU vídeo (Os dois não) -- Ativar essa flag caso deseje utilizar.
    callback: () => {
      acceptView(false);
    },
  };

  const acceptView = (link) => {
    if (readOnly === false) {
      loadConfirmPopup({
        id: popupData.id,
        login: participanteLoginId,
        type: type,
      });
    }
    setModalState("close");
    setIsShow(false);
    if (popupData.url && link) {
      const target =
        popupData.url.indexOf("http") !== -1 ||
        popupData.url.indexOf("HTTP") !== -1
          ? "_blank"
          : "_self";
      window.open(popupData.url, target);
    }
  };

  useEffect(() => {
    if (type === "home") {
      loadPopupHome();
    }
    if (type === "campaign") {
      loadCampaignsHome();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isShow === false &&
      loading === false &&
      !(mostrarEscolhas === true) &&
      acceptanceTerm &&
      !afiliadoConectaPayPendente &&
      primeiroAcessoRealizado
    ) {
      const popups = type === "home" ? home : campaigns;
      const firstPopup = (popups || []).find(
        (item) =>
          item.participanteLoginId < participanteLoginId &&
          (item.mostrarNumeroVezes === 0 ||
            item.mostrarNumeroVezes > item.visualizacoes)
      );
      if (firstPopup) {
        setPopup(firstPopup);
        setIsShow(true);
        setModalState("open");
      }
    }
    // eslint-disable-next-line
  }, [campaigns, home, loading, acceptanceTerm, mostrarEscolhas]);

  return (
    isShow &&
    popupData && (
      <>
        <ModalPopup {...propsModal} size={popupData.codigoTamanho}>
          {popupData.codigoTipo === "2" && popupData.imagemVideoUri && (
            <img src={popupData.imagemVideoUri} alt="" />
          )}
          {popupData.codigoTipo === "3" && popupData.imagemVideoUri && (
            <video controls>
              <source src={popupData.imagemVideoUri} type="video/ogg" />
            </video>
          )}

          <div
            className="popup-text"
            dangerouslySetInnerHTML={{
              __html: popupData.texto,
            }}
          />
          <button
            className="btn"
            onClick={() => {
              acceptView(true);
            }}
          >
            {popupData.url ? "Clique aqui para acessar" : "Fechar"}
          </button>
        </ModalPopup>
      </>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    idCampanha: state.regulation.idCampanha,
    readOnly: state.auth.userInfo.readOnly,
    participanteLoginId: state.auth.userInfo.participanteLoginId,
    afiliadoConectaPayPendente: state.myData.data.afiliadoConectaPayPendente,
    primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
    acceptanceTerm: state.myData.data.aceiteTermos,
    cancelado: state.myData.data.cancelado,
    home: state.segmentPopups.home,
    campaigns: state.segmentPopups.campaigns,
    loading: state.segmentPopups.loading,
    mostrarEscolhas: state.awardsSelection.data.mostrarEscolhas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadPopupHome: () => {
      dispatch(PopupActions.loadPopupHome());
    },
    loadCampaignsHome: () => {
      dispatch(PopupActions.loadCampaignsHome());
    },
    loadConfirmPopup: (data) => {
      dispatch(PopupActions.loadConfirmPopup(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentPopup);
