import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as RetailChainActions from "store/retailChain/actions";
import PowerbiEmbedded from "react-powerbi";
import "./src/retailChainEmbedded.scss";

const RetailChainEmbedded = ({
  retailChainReportRequest,
  accessToken,
  embedTokenExpiry,
  embedUrl,
  reportId,
}) => {

  useEffect(() => {
    retailChainReportRequest();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }, []);

  return (
    <div>
      {/* <SectionTitle title="Extrato da rede" /> */}
      <div className="bx-retailChainEmbedded">
        {(accessToken && embedUrl && reportId ? (
          <PowerbiEmbedded
            id={`${reportId}`} // required
            embedUrl={`${embedUrl}`} // required
            accessToken={`${accessToken}`} // required
            filterPaneEnabled={false}
            navContentPaneEnabled={false}
            settings={{
              embedTokenExpiry: embedTokenExpiry,
            }}
            width="100%"
            height="1000px"
          />
        ) : 'Aguarde...')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.retailChain.embedded.accessToken,
    embedTokenExpiry: state.retailChain.embedded.embedTokenExpiry,
    embedUrl: state.retailChain.embedded.embedUrl,
    reportId: state.retailChain.embedded.reportId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    retailChainReportRequest: () => {
      dispatch(RetailChainActions.retailChainReportRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailChainEmbedded);
