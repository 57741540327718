import { call, put } from 'redux-saga/effects';
import api from '../../common/api';

import { acceptanceCentralizedBillingSuccess, acceptanceCentralizedBillingFailure, centralizedBillingHistorySuccess, centralizedBillingHistoryFailure } from './actions';

export function* acceptanceCentralizedBilling() {
    try {
        const { data } = yield call(api.post, `/cobranca-centralizada/Solicitacao` );

        yield put(acceptanceCentralizedBillingSuccess(data));
    } catch (error) {
        yield put(acceptanceCentralizedBillingFailure());
    }
}

export function* centralizedBillingHistory() {
    try {
        const { data } = yield call(api.get, `/cobranca-centralizada/Historico` );

        yield put(centralizedBillingHistorySuccess(data));
    } catch (error) {
        yield put(centralizedBillingHistoryFailure(error));
    }
}