import React, { useEffect, useState } from "react";
import { IconClose } from "@/components/icons";
import iconAlertV2 from "@/assets/icon_library/PNG/icon_alert_v2.png";

import "./src/modalPopup.min.css";

const ModalPopup = ({
  title,
  children,
  modalState,
  setModalState,
  callback,
  iconAlert,
  btnClose,
  mediaTag,
  size,
  className,
}) => {
  const closeModal = () => {
    if (callback) {
      callback();
    }
    setModalState("close");
  };

  return (
    <div className={`overlay modal-popout ${modalState} ${className ? className : ''}`}>
      <section
        className={`modal-popout ${
          size == "4" ? "biggest" : size == "3" ? "big" : size == "2" ? "medium" : "small"
        } ${mediaTag ? "media" : ""}`}
      >
        {iconAlert ? (
          <div className="icon-alert-content">
            <img
              className="icon-alert-v2"
              src={iconAlertV2}
              alt="Iconé Alerta V2"
            />
          </div>
        ) : (
          ""
        )}
        <div className={`bx-modal ${iconAlert ? "with-alert-icon" : ""}`}>
          {btnClose ? (
            <a onClick={closeModal} className="btn-close-modal-popout">
              <IconClose
                width="20px"
                height="20px"
                className="ic-close"
                fill="#20346C"
              />
            </a>
          ) : (
            ""
          )}
          {title ? (
            <div className="modal-header">
              <h2>{title}</h2>
            </div>
          ) : (
            ""
          )}
          <div className={`modal-popout-content ${mediaTag ? "media" : ""}`}>
            {children}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModalPopup;
