import Immutable from "seamless-immutable";
import {ShowCaseTypes} from './types';

const INITIAL_STATE = Immutable({
    products: [],
    loading: false,
    error: false,
    success: false
});

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ShowCaseTypes.LOAD_SHOWCASE_REQUEST:
        return {...state, loading: true};
      case ShowCaseTypes.LOAD_SHOWCASE_SUCCESS: 
       return {
          ...state,
          loading: false,
          products: action.payload.data.data.products,
          success: action.payload.data.data.success
        }
      case ShowCaseTypes.LOAD_SHOWCASE_FAILURE: 
        return {...state, loading: false, error: true, success: false}
      default:
        return state;
    }
};

export default reducer;
