import { CentralizedBillingTypes } from './types';

const INITIAL_STATE = {
  solicitation: {
    loading: false,
    success: false,
    error: false,
  },
  history: {
    loading: false,
    success: false,
    error: false,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_REQUEST:
      return {
        ...state,
        solicitation: {
          ...state.solicitation,
          loading: true,
        }
      };
    case CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_SUCCESS:
      return {
        ...state,
        solicitation: {
          ...state.solicitation,
          error: false,
          loading: false,
          data: action.payload.data,
          success: action.payload.data.data.success,
          message: action.payload.data.data.message,
        }
      };
    case CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_FAILURE:
      return {
        ...state,
        solicitation: {
          ...state.solicitation,
          loading: false,
          success: false,
          error: true,
          errorMessage: action.payload.data,
        }
      };
    case CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_RESET:
      return {
        ...state,
        solicitation: {
          ...state.solicitation,
          loading: false,
          success: false,
          error: false,
        }
      };
    case CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          loading: true,
        }
      };
    case CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          success: true,
          error: false,
          loading: false,
          data: action.payload.data
        }
      };
    case CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          loading: false,
          success: false,
          error: true,
          errorMessage: action.payload.error[0],
        }
      };
    default:
      return state;
  }
};

export default reducer;
