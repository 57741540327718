import React from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import illPromotions from "@/assets/img/simulator/promotions.png";

const Promotion = ({ navigation, promocoes, mostrarBeneficioEnergia, bannersBeneficios }) => {
  return (
    <section className="bx bx-section-Promotion">
      <SectionTitle title="E além disso tudo, temos promoções exclusivas todos os meses, em que você pode ganhar até:" />
      <div
        className="content"
        style={{
          backgroundImage: `url(${illPromotions})`,
        }}
      >
        <div className="bonus">
          <strong>{promocoes}</strong>
          <span>em pontos</span>
        </div>
      </div>
      <div className="disclaimer txt-center">
        <span>
          <strong>*Valor médio de campanhas vigentes na plataforma </strong>
        </span>
      </div>
      <div className="bx-buttons">
        <button
          type="submit"
          onClick={() => {mostrarBeneficioEnergia ? navigation.next() : !!bannersBeneficios.length ? navigation.go(6) : navigation.go(7)}}
          className="btn btn-main ic-go"
        >
          Próximo
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    promocoes: state.simulator.data.promocoesString,
    bannersBeneficios: state.simulator.data.banners,
    mostrarBeneficioEnergia: state.simulator.data.mostrarBeneficioEnergia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotion);
