import React from 'react'
import { IconChevron } from '@/components/icons'
import './src/style.min.css'

const FilterPerfomance = ({ label, options, inputProps }) => {
    return (
        <div className='bx-filter'>
            <label htmlFor={inputProps.id} className='filter-label'>{label}</label>
            <select {...inputProps} className='filter-field'>
                {options.map((option, i) => {
                    return (<option key={i} value={option.value}>{option.description}</option>)
                })}
            </select>
            <IconChevron className='ic-chevron' height='10px' width='10px' />
        </div>
    );
};

export default FilterPerfomance;