import { action } from 'typesafe-actions';
import { MyDataTypes } from './types';

export const myDataState = (node, key, value) => action(MyDataTypes.MY_DATA_STATE, {node, key, value});

export const getMyDataRequest = () => action(MyDataTypes.MY_DATA_REQUEST)

export const myDataSuccess = (data) => action(MyDataTypes.MY_DATA_SUCCESS, { data });

export const updateMyDataRequest = (data) => action(MyDataTypes.MY_DATA_UPDATE, { data })

export const updateMyDataSuccess = () => action(MyDataTypes.MY_DATA_UPDATE_SUCCESS)

export const myDataFailure = () =>
  action(MyDataTypes.MY_DATA_FAILURE);

export const resetLoadingFlag = () =>  action(MyDataTypes.RESET_LOADING_FLAG)

export const resetDebtorState = () => action(MyDataTypes.REMOVE_DEBTOR_STATE);
