import {RetailChainTypes} from './types';

const INITIAL_STATE = {
    data: [],
    retailIdentification: [
        {
        id: 'CPF/CNPJ',
        value: 'CPF/CNPJ',
        }, 
        {
        id: 'SAP',
        value: 'SAP',
        }],
    facilityIdentification: {

    },
    retailChainSolicitation: {

    },
    embedded: {},
    loading: false,
    error: false, 
    success: false,
    errorList: []
}

const reducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case RetailChainTypes.RETAIL_CHAIN_STATE:
           return {...state, [action.payload.node]: {...state[action.payload.node], [action.payload.key] : action.payload.value}}
        case RetailChainTypes.RETAIL_CHAIN_LIST_REQUEST:
            return {...state, loading: true}
        case RetailChainTypes.RETAIL_CHAIN_LIST_SUCCESS:
            return {...state, loading: false, success: true, data: action.payload.data.data}
        case RetailChainTypes.RETAIL_CHAIN_LIST_FAILURE:
            return {...state, loading: false, success: false, error: true}
        case RetailChainTypes.FACILITY_IDENTIFICATION_REQUEST:
            return {...state, loading: true}
        case RetailChainTypes.FACILITY_IDENTIFICATION_SUCCESS:
            return {...state, loading: false, error: false, success: true, facilityIdentification: action.payload.data.data}
        case RetailChainTypes.FACILITY_IDENTIFICATION_FAILURE:
            return {...state, loading: false, success: false, error: true, errorList: action.payload.error}
        case RetailChainTypes.RETAIL_CHAIN_SOLICITATION_REQUEST:
            return {...state, loading: true}
        case RetailChainTypes.RETAIL_CHAIN_SOLICITATION_SUCCESS:
            return {...state, loading: false, success: true, retailChainSolicitation: action.payload.data}
        case RetailChainTypes.RETAIL_CHAIN_SOLICITATION_FAILURE:
            return {...state, loading: false, success: false, error: true,  errorList: action.payload.error}
        case RetailChainTypes.RETAIL_CHAIN_REPORT_REQUEST:
            return {...state, loading: true};
        case RetailChainTypes.RETAIL_CHAIN_REPORT_SUCCESS: 
            return {
                ...state,
                embedded: action.payload.data.data,
                loading: false,
                success: true,
                error: false
            }
        case RetailChainTypes.RETAIL_CHAIN_REPORT_FAILURE: 
            return {...state, loading: false, error: true, success: false}
        default:
            return state;
    }
}

export default reducer;