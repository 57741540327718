import React from 'react';

const IconCheck = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8.776 8.776">
            <path
                fill='none'
                stroke={props.fill}
                strokeLinecap="round" 
                strokeWidth="2"
                d="M-4.892-17.7H-10.5v-2.8"
                transform="translate(23.335 12.451) rotate(-45)"
            />
        </svg>

    );
};

export default IconCheck;