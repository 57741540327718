import { IconChevron, IconClose, IconMenu } from "@/components/icons";
import {
  IconAward,
  IconBankSlip,
  IconCoin,
  IconConectaPay,
  IconDocument,
  IconBonus,
  IconExit,
  IconFlag,
  IconPerson,
  IconShopping,
  IconStore,
  IconBenefitClub,
  IconDeliverySystem,
} from "components/icons";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as ClubBenefitsActions from "store/clubBenefits/actions";
import Swal from "sweetalert2";
import AuthHelper from "./../../Utils/AlertHelper";
import "./src/userMenu.min.css";
import * as PaymentActions from "store/payments/actions";
import TipoPagamentoHelper from "./../../Utils/TipoPagamentoHelper";
import { ShowUnderPermission } from "@/components/showUnderPermission";

const UserMenu = ({
  matriz,
  tabBar,
  inadimplente,
  inadimplenteVencimentos,
  conectaPayAtiva,
  afiliadoConectaPay,
  clubBenefitsLinkUrlRequest,
  clubBenefitsSucesso,
  clubBenefitsUrl,
  clubBenefitsLoading,
  clubBenefitsError,
  clubBenefitsMsgError,
  readOnly,
  mostrarMenuBoletos,
  cobrancaCentralizada,
  participantPaymentMethodRequest,
}) => {
  const mobileMenu = tabBar;
  const [dropdownMenu, setDropdownMenu] = useState("");
  const [subMenuHeight, setSubMenuHeight] = useState(
    mobileMenu ? "initial" : "0px"
  );
  const [clubBenefitsClick, setClubBenefitsClick] = useState(false);

  useEffect(() => {
    if (
      clubBenefitsSucesso === true &&
      clubBenefitsUrl &&
      clubBenefitsClick &&
      clubBenefitsClick
    ) {
      setClubBenefitsClick(false);
      window.open(clubBenefitsUrl, "_blank");
    }
    // eslint-disable-next-line
  }, [clubBenefitsSucesso, clubBenefitsUrl]);

  useEffect(() => {
    if (
      clubBenefitsSucesso === false &&
      clubBenefitsMsgError &&
      clubBenefitsMsgError.length > 0 &&
      clubBenefitsError &&
      clubBenefitsClick
    ) {
      setClubBenefitsClick(false);
      var msgError = clubBenefitsMsgError[0];
      ShowErrorUrlClubBenefitsAlert(msgError);
    }
    // eslint-disable-next-line
  }, [clubBenefitsSucesso, clubBenefitsError, clubBenefitsMsgError]);

  useEffect(() => {
    participantPaymentMethodRequest();
  }, []);

  // DROPDOW MENU DO USUARIO
  const content = useRef(null);

  const toggleDropdown = () => {
    setDropdownMenu(dropdownMenu === "open" ? "" : "open");
    if (!mobileMenu) {
      setSubMenuHeight(
        dropdownMenu === "open" ? "0px" : `${content.current.scrollHeight}px`
      );
    }
  };

  const checkReadOnly = (event, props) => {
    event.preventDefault();

    if (readOnly == true) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (props.target == "_blank") {
      window.open(props.url, "_blank");
    } else {
      window.location.assign(props.url);
    }
  };

  const onClick = (event, props) => {
    event.preventDefault();

    if (readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }

    if (props.target === "_blank") {
      window.open(props.url, "_blank");
    } else {
      window.location.assign(props.url);
    }
  };
  const ShowErrorUrlClubBenefitsAlert = (msgError) => {
    Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text:
        "Ops, não foi possível acessar, atualize seus dados e tente novamente. Dados inválidos: " +
        msgError,
    });
  };

  // const onClickRedirectClubBenefits = () => {
  //   if (clubBenefitsLoading) return;

  //   if (inadimplenteVencimentos) {
  //     AuthHelper.alertInadimplenteVencimentos();
  //     return;
  //   } else if (inadimplente) {
  //     AuthHelper.alertInadimplente();
  //     return;
  //   }

  //   if (readOnly) {
  //     AuthHelper.alertReadOnly();
  //     return;
  //   }

  //   setClubBenefitsClick(true);
  //   clubBenefitsLinkUrlRequest();
  // };

  const checkInadimplente = (event) => {
    if (inadimplenteVencimentos) {
      event.preventDefault();
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      event.preventDefault();
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }
  };

  const userMenuList = [
    {
      active: true,
      label: "Meus dados",
      codigo: "MEUS_DADOS",
      description: "Meus dados",
      class: "mn-meus-dados",
      icon: <IconPerson className="ic ic-user" />,
      url: "/meus-dados",
      target: "_self",
      new: false,
    },
    {
      active: true,
      codigo: "BENEFFICIOS_PRIME",
      label: clubBenefitsLoading ? "Aguarde..." : "Benefícios Prime",
      description: "Benefícios Prime",
      class: "mn-benefícios-prime",
      icon: <IconBenefitClub className="ic ic-benefit-club" />,
      target: "_self",
      onClick: checkInadimplente,
      url: "/beneficios-prime",
      new: false,
    },
    {
      active: false,
      codigo: "SEU_DELIVERY",
      label: "Seu Delivery",
      description: "Seu Delivery",
      class: "mn-delivery-system",
      icon: <IconDeliverySystem className="ic ic-delivery-system" />,
      target: "_self",
      onClick: checkReadOnly,
      url: "/seu-delivery",
      new: false,
    },
    {
      active: mobileMenu ? true : false,
      codigo: "COMO_FUNCIONA",
      label: "Como Funciona",
      description: "Como Funciona",
      class: "mn-como-funciona",
      icon: (
        <span>
          <i className="ic ic-conecta-prime"></i>
        </span>
      ),
      url: "/como-funciona",
      target: "_self",
      new: false,
    },
    {
      active: mobileMenu ? true : false,
      codigo: "CAMPANHAS",
      label: "Campanhas",
      description: "Campanhas",
      class: "mn-campanhas",
      icon: <IconFlag className="ic ic-campanhas" />,
      url: "/campanhas",
      target: "_self",
      new: false,
    },
    {
      active: mobileMenu ? true : false,
      codigo: "COMPRE_PRODUTOS_BAT",
      label: "Compre produtos BAT",
      description: "Compre produtos BAT",
      class: "mn-produtos",
      icon: <IconShopping className="ic ic-pontos" />,
      url:
        inadimplenteVencimentos || inadimplente || readOnly
          ? undefined
          : `${process.env.REACT_APP_URL_CATALOG}/vendor?vendor=61906`,
      onClick: onClick,
      target: "_blank",
      new: false,
    },
    {
      active: mobileMenu ? true : false,
      codigo: "TROQUE_SEUS_PONTOS",
      label: "Troque seus pontos",
      description: "Troque seus pontos",
      class: "mn-pontos",
      icon: <IconAward className="ic ic-pontos" />,
      url:
        inadimplenteVencimentos || inadimplente || readOnly
          ? undefined
          : process.env.REACT_APP_URL_CATALOG,
      onClick: onClick,
      target: "_blank",
      new: false,
    },
    {
      active: true,
      codigo: "MEUS_RESGATES",
      label: "Meus resgates",
      description: "Meus resgates",
      class: "mn-resgates",
      icon: <IconAward className="ic ic-pontos" />,
      url:
        inadimplenteVencimentos || inadimplente || readOnly
          ? undefined
          : `${process.env.REACT_APP_URL_CATALOG}/my-orders?days=365`,
      onClick: onClick,
      target: "_blank",
      new: false,
    },
    {
      active: true,
      codigo: "EXTRATO",
      label: "Extrato",
      description: "Extrato",
      class: "mn-extrato",
      icon: <IconDocument className="ic ic-extrato" />,
      url: "/extrato",
      target: "_self",
      new: false,
    },
    {
      active: true,
      codigo: "BONIFICACAO",
      label: "Bonificação",
      description: "Bonificação",
      class: "mn-bonificacao",
      icon: <IconBonus className="ic ic-bonificacao" />,
      url: "/bonificacao",
      target: "_self",
      new: false,
    },
    {
      active:
        TipoPagamentoHelper.GetAllPaymentsStatusActive().DEBITO_RECEBIVEIS &&
        conectaPayAtiva &&
        afiliadoConectaPay,
      codigo: "CONECTA_PAY",
      label: "Conecta Pay",
      description: "Conecta Pay",
      class: "mn-Conecta-Pay",
      icon: <IconConectaPay className="ic ic-conecta-pay" />,
      url: "/conecta-pay",
      target: "_self",
      new: false,
    },
    {
      active: true,
      codigo: "MEUS_PAGAMENTOS",
      label: "Meus Pagamentos",
      description: "Meus Pagamentos",
      class: "mn-meus-pagamentos",
      icon: <IconCoin className="ic ic-payments" />,
      url: "/meus-pagamentos",
      target: "_self",
      new: false,
    },
    {
      active: mostrarMenuBoletos,
      codigo: "MEUS_BOLETOS",
      label: "Meus Boletos",
      description: "Meus Boletos ",
      class: "mn-meus-pagamentos",
      icon: <IconBankSlip className="ic ic-payments" />,
      url: "/bank-slip",
      target: "_self",
      new: false,
    },
    {
      active: true,
      codigo: "MINHA_REDE_VAREJOS",
      label: "Minha rede de varejos",
      description: "Minha rede de varejos",
      class: "mn-meus-varejos",
      icon: <IconStore className="ic ic-retail" />,
      url: "/minha-rede-de-varejos",
      target: "_self",
      new: false,
    },
    {
      active: false,
      codigo: "CANCELAR_ADESAO",
      label: "Cancelar adesão",
      description: "Cancelar adesão",
      class: "mn-cancelamento",
      icon: <IconClose className="ic ic-close" />,
      url: "/cancel",
      target: "_self",
      new: false,
    },
    {
      active: true,
      codigo: "SAIR",
      label: "Sair",
      description: "Sair",
      class: "mn-sair",
      icon: <IconExit className="ic ic-payments" />,
      url: `${process.env.REACT_APP_URL_LOGOUT}`,
      target: "_self",
      new: false,
    },
  ];

  return (
    <section
      className={`user-menu ${mobileMenu ? "mobile-menu" : "desktop-menu"}`}
    >
      <a onClick={toggleDropdown} className={`btn-user-menu ${dropdownMenu}`}>
        {mobileMenu ? (
          <>
            {" "}
            <span>
              <IconMenu className="ic ic-menu" />
            </span>{" "}
            <strong>Mais</strong>{" "}
          </>
        ) : (
          <>
            Menu{" "}
            <IconChevron className="ic-chevron" width="10px" fill="#ffffff" />
          </>
        )}
      </a>
      <div
        ref={content}
        style={{ maxHeight: subMenuHeight }}
        className={`menu-dropdown ${dropdownMenu}`}
      >
        {mobileMenu && (
          <a onClick={toggleDropdown}>
            <IconClose className="ic ic-closer" />
          </a>
        )}
        <ul className="user-menu-list">
          {userMenuList &&
            userMenuList.map((menuItem, i) => {
              if (menuItem.active) {
                return (
                  <ShowUnderPermission codePermission={menuItem.codigo} key={i}>
                    <li
                      className={menuItem.new ? "novo" : ""}
                    >
                      {menuItem.onClick ? (
                        <a
                          href={menuItem.url}
                          target={menuItem.target}
                          title={menuItem.description}
                          onClick={(event) => {
                            menuItem.onClick(event, menuItem);
                          }}
                        >
                          {menuItem.icon}
                          {menuItem.label}
                        </a>
                      ) : (
                        <a
                          href={menuItem.url}
                          target={menuItem.target}
                          title={menuItem.description}
                        >
                          {menuItem.icon}
                          {menuItem.label}
                        </a>
                      )}
                    </li>
                  </ShowUnderPermission>
                );
              }
            })}
        </ul>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clubBenefitsLinkUrlRequest: () => {
      dispatch(ClubBenefitsActions.clubBenefitsRequest());
    },

    participantPaymentMethodRequest: () => {
      dispatch(PaymentActions.participantPaymentMethodRequest());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    matriz: state.myData.data.matriz,
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    conectaPayAtiva: state.myData.data.conectaPayAtiva,
    afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
    clubBenefitsSucesso: state.clubBenefits.success,
    clubBenefitsUrl: state.clubBenefits?.data?.data,
    clubBenefitsLoading: state.clubBenefits.loading,
    clubBenefitsError: state.clubBenefits.error,
    clubBenefitsMsgError: state.clubBenefits.errorList,
    readOnly: state.auth.userInfo.readOnly,
    mostrarMenuBoletos: state.myData.data.mostrarMenuBoletos,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
