import React from 'react';

import './src/containers.min.css';

const DefaultContainer = ({ children }) => {
    return (
        <>
            <div className='ct defaultContainer'>
                {children}
            </div>
        </>
    )
}

export default DefaultContainer;