import React, { useState, useEffect } from "react";
import { SectionTitle } from "@/components/sectionTitle";
import { InputMonthYear } from "@/components/formFields";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as BonusActions from "@/store/bonus/actions";
import "./src/trackingResumeData.min.css";
import { ModalPopup } from "@/components/modalPopup";

const TrackingResumeData = ({
  loadBonus,
  bonusProdutos,
  deixouDeGanhar,
  bonificacao,
  acumulado,
  ...props
}) => {
  const [date, setDate] = useState(new Date());
  const [datePeridoDe, setDatePeridoDe] = useState(new Date());
  const [datePeridoAte, setDatePeridoAte] = useState(new Date());
  const { register } = useForm({
    mode: "onChange",
  });
  const [modalState, setModalState] = useState("close");

  useEffect(() => {
    if (props.retailChain?.length == 0 || !props.matriz) {
      searchByMonth(new Date());
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [props.retailChain]);

  useEffect(() => {
    if (props.targetAccordion == props.keyId) {
      searchByMonth(new Date());
    }
  }, [props.targetAccordion]);

  const searchByMonth = (event) => {
    if (props.codigoSap) {
      let month = event.getMonth() + 1;
      let year = event.getFullYear();

      loadBonus(`ano=${year}&mes=${month}&idRevenda=${props.codigoSap}`);
    } else {
      let month = event.getMonth() + 1;
      let year = event.getFullYear();

      loadBonus(`ano=${year}&mes=${month}`);
    }
  };

  const searchByPeriod = () => {
    function format(number) {
      return ("0" + number).slice(-2);
    }

    if (props.codigoSap) {
      let monthPeridoDe = format(datePeridoDe.getMonth() + 1);
      let yearPeridoDe = datePeridoDe.getFullYear();

      let monthPeridoAte = format(datePeridoAte.getMonth() + 1);
      let yearPeridoAte = datePeridoAte.getFullYear();

      loadBonus(`periodoDe=${monthPeridoDe}${yearPeridoDe}&periodoAte=${monthPeridoAte}${yearPeridoAte}&idRevenda=${props.codigoSap}`);
    } else {
      let monthPeridoDe = format(datePeridoDe.getMonth() + 1);
      let yearPeridoDe = datePeridoDe.getFullYear();

      let monthPeridoAte = format(datePeridoAte.getMonth() + 1);
      let yearPeridoAte = datePeridoAte.getFullYear();

      loadBonus(`periodoDe=${monthPeridoDe + yearPeridoDe}&periodoAte=${monthPeridoAte + yearPeridoAte}`);
    }
  };

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    title: "O QUE VOCÊ DEIXOU DE GANHAR",
    iconAlert: false, // Ícone de alerta no topo do modal
    btnClose: true,
    mediaTag: false, // Imagem OU vídeo (Os dois não) -- Ativar essa flag caso deseje utilizar.
    className: "deixou-de-ganhar-detalhes",
  };

  const formatDate = (date) => {
    let splitDate = date.substr(0, 10).split("-");
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
  };

  return (
    <>
      <SectionTitle title="Bonificação" />
      <div className="resumo">
        <div className="bonificacao">
          <div className="titulo">
            Bonificação
            {props.trackingStates?.loading ? (
              <p> ... </p>
            ) : bonificacao?.mes !== "" ? (
              <p>{bonificacao?.mes}</p>
            ) : (
              <p>
                {bonificacao?.periodoDe} até {bonificacao?.periodoAte}
              </p>
            )}
          </div>
          <div className="descricao">
            <div className="item">
              <span>
                {props.trackingStates?.loading
                  ? "..."
                  : bonificacao?.pacotesComprados}
              </span>
              Pacotes <br />
              comprados
            </div>
            <div className="item">
              <span>
                {props.trackingStates?.loading
                  ? "..."
                  : bonificacao?.pacotesBonificados}
              </span>
              Pacotes <br />
              bonificados
            </div>
          </div>
        </div>

        <div className="deixou-de-ganhar">
          <div className="titulo">
            O que você deixou de ganhar
            <br />
          </div>
          <div className="descricao">
            <div className="item">
              <span>
                {props.trackingStates?.loading
                  ? "..."
                  : deixouDeGanhar?.pacotePotencial}
              </span>
              Pacotes não
              <br />
              bonificados
            </div>
            {deixouDeGanhar?.pacotePotencial > 0 && (
              <div className="item saiba-mais">
                <button
                  className="btn btn-secondary"
                  onClick={() => setModalState("open")}
                >
                  Saiba mais
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="acumulado">
          <div className="titulo">
            Acumulado<span> | Total</span>
          </div>
          <div className="descricao">
            <div className="item">
              <span>
                {props.trackingStates?.loading
                  ? "..."
                  : acumulado?.pacotesBonificados}
              </span>
              Pacotes <br />
              bonificados
            </div>
          </div>
        </div>
      </div>
      <div className="bx bonus busca">
        <div className="search-box">
          <section className="busca-section mes">
            <div className="fields">
              <InputMonthYear
                label="Buscar pelo mês"
                register={register}
                controlState={(setDate, searchByMonth)}
                inputProps={{
                  id: `BuscarPeloMes ${props.keyId ? props.keyId : ""}`,
                  name: "BuscarPeloMes",
                  ref: register,
                  value: date,
                  disabled: props.readOnly,
                }}
              />
            </div>
          </section>
          <section className="busca-section periodo">
            <div className="inputPeriod">
              <div className="fields">
                <InputMonthYear
                  label="Período De"
                  register={register}
                  controlState={setDatePeridoDe}
                  inputProps={{
                    id: `BuscarPeriodoDe ${props.keyId ? props.keyId : ""}`,
                    name: "BuscarPeriodoDe",
                    ref: register,
                    value: datePeridoDe,
                    disabled: props.readOnly,
                  }}
                  error={datePeridoAte < datePeridoDe ? true : false}
                />
              </div>
              <div className="fields">
                <InputMonthYear
                  label="Período Até"
                  register={register}
                  controlState={setDatePeridoAte}
                  inputProps={{
                    id: `BuscarPeriodoAte ${props.keyId ? props.keyId : ""}`,
                    name: "BuscarPeriodoAte",
                    ref: register,
                    value: datePeridoAte,
                    disabled: props.readOnly,
                  }}
                />
              </div>
              {datePeridoAte < datePeridoDe && (
                <span className="error">
                  "O período de ínicio deve ser menor que o final"
                </span>
              )}
            </div>
            <div className="fm-button">
              <button
                className="btn ic-go btn-main btn--save"
                disabled={
                  props.trackingStates?.loading ||
                  props.readOnly ||
                  datePeridoAte < datePeridoDe
                }
                onClick={(e) => {
                  searchByPeriod();
                }}
              >
                {props.trackingStates?.loading ? "Buscando..." : "Buscar"}
              </button>
            </div>
          </section>
        </div>
      </div>
      <ModalPopup {...propsModal} size={4}>
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th>
                <span>Descritivo</span>
              </th>
              <th>
                <span>Pacotes Potencial</span>
              </th>
              <th>
                <span>Motivo</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {deixouDeGanhar?.deixouDeGanharDetalhes.map((item, i) => (
              <tr keyId={`deixou-de-ganhar-${i}`}>
                <td>{item.descritivo}</td>
                <td>{item.pacotePotencial}</td>
                <td>{item.motivo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalPopup>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bonificacao: state.bonus.data.bonificacao,
    bonusProdutos: state.bonus.data.bonusProdutos,
    deixouDeGanhar: state.bonus.data.deixouDeGanhar,
    acumulado: state.bonus.data.acumulado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBonus: (data) => {
      dispatch(BonusActions.loadBonus(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingResumeData);
