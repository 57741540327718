import { action } from 'typesafe-actions';
import { PopupTypes } from './types';

export const loadPopupHome = () => action(PopupTypes.LOAD_POPUP_HOME_REQUEST);

export const loadPopupHomeSuccess = data => action(PopupTypes.LOAD_POPUP_HOME_SUCCESS, { data });

export const loadPopupHomeFailure = () => action(PopupTypes.LOAD_POPUP_HOME_FAILURE);


export const loadCampaignsHome = () => action(PopupTypes.LOAD_POPUP_CAMPAIGNS_REQUEST);

export const loadCampaignsHomeSuccess = data => action(PopupTypes.LOAD_POPUP_CAMPAIGNS_SUCCESS, { data });

export const loadCampaignsHomeFailure = () => action(PopupTypes.LOAD_POPUP_CAMPAIGNS_FAILURE);


export const loadConfirmPopup = (data) => action(PopupTypes.LOAD_POPUP_CONFIRM_REQUEST, { data });

export const loadConfirmPopupSuccess = data => action(PopupTypes.LOAD_POPUP_CONFIRM_SUCCESS, { data });

export const loadConfirmPopupFailure = () => action(PopupTypes.LOAD_POPUP_CONFIRM_SUCCESS);
