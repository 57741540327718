import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AsideContainer } from "@/components/containers";
import { AsideMenu } from "@/components/menus";
import {
  IconMessage,
  IconQuestionMark,
  IconRegulation,
  IconArrowToLeft,
} from "@/components/icons";
import Routes from "./routes";
import JwtDecode from "@/Utils/jwtDecode";

import "./src/help.min.css";

const index = () => {
  const signed = JwtDecode.isAutenticed();

  const navigation = [
    {
      id: 1,
      label: "Perguntas frequentes",
      classItem: "mn-faq",
      url: "ajuda",
      isAtive: true,
      icon: <IconQuestionMark className="ic ic-question-mark" />,
    },
    {
      id: 2,
      label: "Fale conosco",
      classItem: "mn-contact",
      url: "fale-conosco",
      isAtive: true,
      icon: <IconMessage className="ic ic-message" />,
    },
    {
      id: 3,
      label: "Termos de política e privacidade",
      classItem: "mn-terms",
      url: "termos",
      isAtive: true,
      icon: <IconRegulation className="ic ic-regulation" />,
    },
    {
      id: 4,
      label: "Regulamento",
      classItem: "mn-regulation",
      url: "regulamento",
      isAtive: true,
      icon: <IconRegulation className="ic ic-regulation" />,
    },
  ];

  return (
    <Router>
      <section className="pg pg-help">
        <AsideContainer>
          {!signed && (
            <a className="btn-back" href="/">
              <IconArrowToLeft
                fill="#8F23D5"
                className="ic-arrow"
                width="20px"
              />
              Voltar
            </a>
          )}
          <aside className="container-aside">
            <AsideMenu
              title="Podemos ajudar?"
              signed={signed}
              menuItens={navigation}
            />
          </aside>
          <main className="container-section">
            <Routes />
          </main>
        </AsideContainer>
      </section>
    </Router>
  );
};

export default index;
