import { BenefitCard } from "@/components/cards";
import { DefaultContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import {default as React } from "react";
import { connect } from "react-redux";
import { default as Carousel } from "react-slick";
import "./src/showcase.min.css";

const BenefitsShowcase = ({ loading, inadimplente, inadimplenteVencimentos, matriz, cobrancaCentralizada, myBenefits }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 548,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          dots: true,
          slidesToShow: 4,
        },
      },
    ],
  };

  return myBenefits && !loading ? (
    <section className="showcase">
      <DefaultContainer>
        <SectionTitle title="Conheça seus benefícios exclusivos." />
        <Carousel {...settings}>
          {myBenefits &&
            myBenefits.map((benefit, i) => {
              if (benefit) {
                return (
                  <BenefitCard
                    key={`benefit-${i}`}
                    cardType="benefit"
                    benefitId={benefit.id}
                    image={benefit.imagemCard}
                    title={benefit.titulo}
                    subtitle={benefit.subtitulo}
                    imageLogo={benefit.imagemLogo}
                    description={benefit.descricaoDosGanhos}
                    slug={benefit.slug}
                  />
                );
              }
            })}
        </Carousel>
      </DefaultContainer>
    </section>
  ) : (
    <></>
  );
  
};



const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const mapStateToProps = (state) => {
  return {
    myBenefits: state.clubBenefits.data,
    loading: state.clubBenefits.loading,
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsShowcase);
