import React from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import BgTotal from "@/assets/img/simulator/bg-total.png";
import illTotalAwards from "@/assets/img/simulator/total-awards.png";
import * as SimuladorActions from "store/simulator/actions";

const Total = ({ soma, somaSemMensalidade, simulatorStateInitial }) => {

  const handleRedirect = () => {
    window.open(process.env.REACT_APP_SSO_AUTH, "_blank");
  };


  const redirectSimuladorIdentificacao = async (e) => {
    e.preventDefault();
    await simulatorStateInitial();

    window.location.assign("/simulador-identificacao");
  };


  return (
    <section
      className="bx bx-section-Total"
      style={{
        backgroundImage: `url(${BgTotal})`,
      }}
    >
      <SectionTitle
        title="Resultado: com o Conecta Prime, <br />
        você só tem a ganhar!"
      />
      <div
        className="content"
        style={{
          backgroundImage: `url(${illTotalAwards})`,
        }}
      >
        <div className="bonus">
          <span>Até</span>
          <strong>{soma}</strong>
          <span>em forma de pontos ou bonficações</span>
        </div>
      </div>
      <SectionTitle
        title={`Descontando sua mensalidade de R$ 120,00, você fica com <strong>R$ ${somaSemMensalidade}</strong> por mês`}
      />
      <div className="bx-buttons">
        <button
          onClick={redirectSimuladorIdentificacao}
          className="btn btn-inverted ic-back"
        >
          Nova simulação
        </button>
        <button
          onClick={handleRedirect}
          type="button"
          className="btn btn-main ic-go"
        >
          Quero me cadastrar
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    soma: state.simulator.data.somaString,
    somaSemMensalidade: state.simulator.data.somaSemMensalidadeString,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulatorStateInitial: () => {
      dispatch(SimuladorActions.simulatorStateInitial());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Total);
