export const AppMessages = {
    FORM_FIELDS_REQUIRED : 'Campos obrigatórios em branco. Por favor, verifique',
    DATE_START_BIGGERTHAN_END : 'Data final deve ser maior que a data inicial',
    ERROR_GENERIC_400 : 'Erro ao obter dados.',
    ERROR_GENERIC_FILEUPLOAD : 'Erro ao enviar arquivo.',
    SUCCESS_UPLOAD_GENERIC : 'Arquivo enviado com sucesso',
    ERROR_SAVE_GENERIC : 'Erro ao salvar dados',
    CONTACTUS_MESSAGE_SENT : "Mensagem enviada com sucesso",
    INVALID_PARAMETERS: "Parâmetros inválidos",
    END_OF_SESSION: "Sessão finalizada",
    EXPIRED_SESSION: "Sua sessão expirou",
    READONLY_SESSION: "Não é possível realizar essa ação em modo de leitura!",
    INADIMPLENTE_SESSION: "Por favor verifique o pagamento de sua mensalidade!",
    INADIMPLENTE_VENCIMENTOS: "Caro varejista, você está inadimplente há mais de 90 dias. Regularize seu pagamento para aproveitar os benefícios Conecta Prime",
    INADIMPLENTE_COBRANCA_CENTRALIZADA_FILIAL: "Caro varejista, vimos que possui cobrança centralizada com sua matriz e a mesma encontra-se inadimplente, após a regularização você poderá aproveitar os benefícios Conecta Prime",
    CANCEL_SUCCESS: "Cancelamento realizado com sucesso!"
  }
  