import React, { useEffect, useRef, useState } from "react";
import imgEditIcon from "@/deliverySystem/assets/images/editar.svg";
import "./src/accordion.min.css";

const Accordion = (props) => {
  const [accordionState, setAccordionState] = useState("close");
  const [contentHeigh, setContentHeigh] = useState("0px");

  const content = useRef(null);

  const toggleAccordion = () => {
    setAccordionState(accordionState === "close" ? "open" : "close");
  };

  useEffect(() => {
    setContentHeigh(
      accordionState === "open" ? `auto` : "0px"
    );

    if (props.updateProductList.status === 1) {
      setContentHeigh(
        accordionState === "open" ? `auto` : "0px"
      );
    }
  }, [accordionState, props.updateProductList]);

  return (
    <div className={`accordion-ct ${accordionState} ${props.className}`}>
      <div
        className="accordion-header"
        onClick={() => toggleAccordion(props.title)}
      >
        <span className="logo"></span>
        <div
          className={`title ${
            props.propsModal ? "category-config-icons-container" : ""
          }`}
        >
          <h2>{props.title}</h2>
          {props.description && (
            <strong className="description">{props.description}</strong>
          )}
          {props.propsModal && props.categoriaId > 5 && (
            <div className="category-config-icons">
              <button
                onClick={() => {
                  props.propsModal.setModalState(true);
                  props.propsModal.setEditModalTargetProps({
                    ...props.propsModal.editModalTargetProps,
                    mode: "category-delete",
                    nome: props.title,
                    categoriaId: props.categoriaId
                  });
                }}
                className="icons delete-icon"
              >
                <i className="icon icon-icon_close_circle" />
              </button>

              <button
                onClick={() => {
                  props.propsModal.setModalState(true);
                  props.propsModal.setEditModalTargetProps({
                    ...props.propsModal.editModalTargetProps,
                    mode: "category-edit",
                    nome: props.title,
                    categoriaId: props.categoriaId
                  });
                }}
                className="icons edit-icon"
              >
                <img alt="" src={imgEditIcon} />
              </button>
            </div>
          )}
        </div>
        <i className="icon icon-icon_down"></i>
      </div>
      <div
        ref={content}
        style={{ height: `${contentHeigh}` }}
        className="accordion-content"
      >
        <div className="accordion-body">{props.children}</div>
      </div>
    </div>
  );
};

export default Accordion;
