import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DataComplement } from "@/components/dataComplement";
import { IconCheck } from "@/components/icons";
import { DisconnectedContainer } from "@/components/containers";
import LayoutDisconnected from "@/layouts/layoutDisconnected";
import "./src/firstAccess.min.css";
import { Redirect } from "react-router-dom";

const FirstAccess = ({
  firstAccess,
  conectaPayAtiva,
  readOnly,
}) => {
  const [progressStep, setProgressStep] = useState(0);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!firstAccess) setRedirect(true);

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (redirect) return <Redirect to="/" />;

  return (
    <LayoutDisconnected>
      <section className="pg pg-first-access">
        <DisconnectedContainer>
          <h2>Bem-vindo ao Conecta Prime.</h2>
          {readOnly ? (
            <p>
              <strong>ATENÇÃO:</strong> Não é possível realizar o primeiro
              acesso em modo leitura!
            </p>
          ) : (
            <p>
              Como é a sua primeira vez por aqui, vamos confirmar algumas
              informações sobre você, ok?
            </p>
          )}

          <ul className="progress">
            <li className="current">
              <span className="shape">
                <IconCheck
                  width="10px"
                  height="10px"
                  fill="#21346C"
                  className="ic-check"
                />
              </span>
              <strong>
                Dados <br />
                pessoais
              </strong>
            </li>
            <li className="">
              <span className="shape">
                <IconCheck
                  width="10px"
                  height="10px"
                  fill={progressStep >= 1 ? "#21346C" : "none"}
                  className="ic-check"
                />
              </span>
              <strong>
                Dados
                <br />
                de contato
              </strong>
            </li>
            {conectaPayAtiva && (
              <li className="">
                <span className="shape">
                  <IconCheck
                    width="10px"
                    height="10px"
                    fill={progressStep >= 2 ? "#21346C" : "none"}
                    className="ic-check"
                  />
                </span>
                <strong>
                  Peça sua <br />
                  Conecta Pay
                </strong>
              </li>
            )}
            <li className="">
              <span className="shape">
                <IconCheck
                  width="10px"
                  height="10px"
                  fill={progressStep >= 3 ? "#21346C" : "none"}
                  className="ic-check"
                />
              </span>
              <strong>
                Método de <br />
                Pagamento
              </strong>
            </li>
            <li className="">
              <span className="shape">
                <IconCheck
                  width="10px"
                  height="10px"
                  fill={progressStep >= 4 ? "#21346C" : "none"}
                  className="ic-check"
                />
              </span>
              <strong>
                Cadastro
                <br />
                finalizado
              </strong>
            </li>
          </ul>
          <DataComplement progressStep={setProgressStep} />
        </DisconnectedContainer>
      </section>
    </LayoutDisconnected>
  );
};

const mapStateToProps = (state) => {
  return {
    firstAccess: state.auth.userInfo.firstAccess,
    conectaPayAtiva: state.myData.data.conectaPayAtiva,
    readOnly: state.auth.userInfo.readOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccess);
