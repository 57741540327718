import { PaymentTypes } from './types';

const INITIAL_STATE = {
  data: [],
  loading: false,
  errorList: [],
  error: false,
  success: false,
  paymentHistory: [],
  participantPaymentMethod: [],
  sporadicBankSlip: {
    loading: false,
    success: false,
    error: false,
    errorList: [],
  },
  changedPayment: false,
  linkBankSlip: "",
  creditCardData: {},
  notficationModal: false,
  dataPayment: {
    tipoPagamento: 0,
    bancoCodigo: "",
    bancoAgencia: "",
    bancoAgenciaDigito: "",
    bancoContaBancaria: "",
    bancoContaBancariaDigito: ""
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentTypes.PAYMENT_STATE:
      if (action.payload.node && action.payload.node !== "")
        return { ...state, [action.payload.node]: { ...state[action.payload.node], [action.payload.key]: action.payload.value } };
      else if (action.payload.key && action.payload.key !== "")
        return { ...state, [action.payload.key]: action.payload.value };
      else
        return { ...state, ...action.payload.value };
    case PaymentTypes.LOAD_PAYMENT_METHODS_REQUEST:
      return { ...state, loading: true };
    case PaymentTypes.LOAD_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload.data
      }
    case PaymentTypes.LOAD_PAYMENT_METHODS_FAILURE:
      return { ...state, loading: false, error: true, success: false }
    case PaymentTypes.PAYMENT_HISTORY_REQUEST:
      return { ...state, loadingPaymentHistory: true, errorPaymentHistory: false, successPaymentHistory: false }
    case PaymentTypes.PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loadingPaymentHistory: false,
        successPaymentHistory: true,
        errorPaymentHistory: false,
        paymentHistory: action.payload.data
      }
    case PaymentTypes.PAYMENT_HISTORY_FAILURE:
      return { ...state, loadingPaymentHistory: false, errorPaymentHistory: true, successPaymentHistory: false }
    case PaymentTypes.PARTICIPANT_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true };
    case PaymentTypes.PARTICIPANT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        participantPaymentMethod: action.payload.data
      }
    case PaymentTypes.PARTICIPANT_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: true, success: false }
    case PaymentTypes.CHANGE_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true, errorList: [] };
    case PaymentTypes.CHANGE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        changedPayment: action.payload.data,
        dataPayment: action.payload.dataPayment
      }
    case PaymentTypes.CHANGE_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: true, errorList: action.payload.error }
    case PaymentTypes.COPY_OF_THE_BANK_SLIP_REQUEST:
      return { ...state, loading: true, errorList: [] };
    case PaymentTypes.COPY_OF_THE_BANK_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        error: false,
        errorList: [],
        linkBankSlip: action.payload.data.data
      }
    case PaymentTypes.COPY_OF_THE_BANK_SLIP_FAILURE:
      return { ...state, loading: false, error: true, success: false, errorList: action.payload.error }
    case PaymentTypes.CHANGE_FLAG_PAYMENT_REQUEST:
      return { ...state, loading: true, errorList: [] };
    case PaymentTypes.CHANGE_FLAG_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        notficationModal: true,
      }
    case PaymentTypes.CHANGE_FLAG_PAYMENT_FAILURE:
      return { ...state, loading: false, error: true, errorList: action.payload.error }
    case PaymentTypes.SET_CREDIT_CARD_PAYMENT_REQUEST:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: true,
        },
      };
    case PaymentTypes.SET_CREDIT_CARD_PAYMENT_SUCCESS:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: false,
          success: true,
          error: false,
          ...action.payload.data
        },
      }
    case PaymentTypes.SET_CREDIT_CARD_PAYMENT_FAILURE:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: false,
          success: false,
          error: true,
        },
      }
    case PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_REQUEST:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: true,
        },
      };
    case PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_SUCCESS:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: false,
          success: true,
          error: false,
          ...action.payload.data
        },
      }
    case PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_FAILURE:
      return {
        ...state,
        creditCardData: {
          ...state.creditCardData,
          loading: false,
          success: false,
          error: true,
        },
      }
    case PaymentTypes.SPORADIC_BANK_SLIP_REQUEST:
      return {
        ...state,
        sporadicBankSlip: {
          ...state.sporadicBankSlip,
          loading: true,
        }
      };
    case PaymentTypes.SPORADIC_BANK_SLIP_SUCCESS:
      return {
        ...state,
        sporadicBankSlip: {
          ...state.sporadicBankSlip,
          loading: false,
          success: true,
          error: false,
          ...action.payload.data
        }
      };
    case PaymentTypes.SPORADIC_BANK_SLIP_FAILURE:
      return {
        ...state,
        sporadicBankSlip: {
          ...state.sporadicBankSlip,
          loading: false,
          success: false,
          error: true,
          errorList: action.payload.error,
        }
      };
    case PaymentTypes.SPORADIC_BANK_SLIP_RESET_FLAG:
      return {
        ...state,
        sporadicBankSlip: {
          ...INITIAL_STATE.sporadicBankSlip,
        }
      };
    default:
      return state;
  }
};

export default reducer;
