import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Contact from './contact';
import { DefaultContainer } from '@/components/containers';
import './src/footer.min.css';

const Footer = () => {
    const userProfile = useSelector(state => state.auth.userInfo);

    return (
        <Fragment>
            {/* { &&(<StickyFooter />)} */}
            <Contact />
            <footer className="wr wr-footer">
                <DefaultContainer>
                    <div className='bx-footer'>
                        {userProfile.username && !userProfile.firstAccess ? (
                            <div className='navegation'>
                                <nav>
                                    <ul>
                                        <li>
                                            <a href='/regulamento'>
                                                Regulamento
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/termos'>
                                                Termos de uso
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <p>
                                    Denúncias e dúvidas sobre assuntos relacionados às políticas e procedimentos da BAT,
                                    entre em contato através do número 0800 601 8600 ou <a href='/fale-conosco'>
                                        clique aqui
                                    </a>.
                                </p>
                            </div>
                        ) : (
                                <div className='censure'>
                                    <p>O conteúdo deste site só pode ser visto e acessado por varejistas e funcionários envolvidos na venda de tabaco, que tenham atingido a maioridade e que tenham registrado suas contas de acordo com as informações fornecidas pela BAT. As informações contidas neste documento destinam-se a garantir que os varejistas de tabaco e os funcionários envolvidos na venda de tabaco possuam informações corretas e adequadas sobre os produtos e / ou serviços da BAT. Este site e as informações aqui contidas não se destinam a ser usados na promoção de produtos de tabaco para os consumidores e tal uso é formalmente proibido.</p>
                                </div>
                            )}
                        <img className="logo" src="/img/logo_bat_white.png" alt="BAT" />
                    </div>
                </DefaultContainer>
            </footer>
        </Fragment>
    );
};

export default Footer;
