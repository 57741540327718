import { action } from 'typesafe-actions';
import { AcceptanceTermTypes , RegulamentTypes } from './types';

// export const loadRegulamentRequest = () => action(RegulamentTypes.REGULAMENT_REQUEST);
// export const loadRegulamentSuccess = () => action(RegulamentTypes.REGULAMENT_SUCCESS), {data};
// export const loadRegulamentFailure = () => action(RegulamentTypes.REGULAMENT_FAILURE);

export const acceptanceTerm = idCampanha => action(AcceptanceTermTypes.ACCEPTANCETERM_REQUEST, {idCampanha});
export const acceptanceTermSuccess = data => action(AcceptanceTermTypes.ACCEPTANCETERM_SUCCESS, { data });

export const acceptanceTermFailure = () => action(AcceptanceTermTypes.ACCEPTANCETERM_FAILURE);
