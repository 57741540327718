import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import {SectionTitle} from '@/components/sectionTitle'
import * as RegulationActions from 'store/regulation/actions'
import './src/regulation.min.css'
import '../privacyPolicy/src/acceptanceTerms.min.css'

const Regulation = (props) => {
    const [regulation, setRegulation] = useState("");

    useEffect(() => {
        props.loadRegulation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setRegulation(props.regulamento);
    }, [props.regulamento]);

    return (
        <section className='regulation'>
            <SectionTitle title='Regulamento da Campanha Regular' />
            <div className='bx bx-acceptance-terms'>
                {
                    <div className='container-scroll' dangerouslySetInnerHTML={{ __html: regulation }}></div>
                }
            </div>

        </section>
    );
};

const mapStateToProps = state => {
    return {
        regulamento: state.regulation.regulation,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadRegulation: () => {
            dispatch(RegulationActions.loadRegulation());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Regulation);