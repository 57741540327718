import { Modal } from "@/components/modal";
import React, { useEffect, useState } from "react";
import IconConversation from "../icons/iconConversation";
import IconChat from "../icons/IconChatComponent";
import IconLetter from "../icons/iconLetter";
import IconClose from "../icons/iconClose";

import "./css/modalPopup.min.css";

const ModalChat = (props) => {
  const [modalState, setModalState] = useState("close");

  const toogleScroll = () => {
    if (modalState === "open") {
      document.querySelector("body").style.overflow = "hidden";
    } else if (modalState === "close") {
      document.querySelector("body").style.overflow = "scroll";
    }
  };

  const closeModal = () => {
    setModalState("close");
  };

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
  };

  useEffect(() => {
    toogleScroll();
  });

  return (
    <div>
      <div onClick={() => setModalState("open")} className="modal-help">
        <IconConversation
          className="ic-conversation"
          fill="transparent"
          width="35px"
          height="35px"
        />
      </div>

      <div className="wrap-help">
        <Modal
          className="wrap-help-modal"
          title="Como podemos ajudar?"
          reservedClassName="bonus-card-modal-img"
          {...propsModal}
          style={{ background: "#ffffff" }}
        >
          {" "}
          <div className="wrap-ic-close" onClick={closeModal}>
            {" "}
            <IconClose
              className="ic-close"
              fill="#ffffff"
              width="18px"
              height="18px"
            />
          </div>
          <div className="helpButtons">
            <div className="bx-chat">
              <a
                target="blank"
                href="https://lnk_bat.vollsc.com/w/ubkxa"
                className="btn btn-main btn-help"
              >
                <IconChat
                  className="ic-accordion"
                  fill="transparent"
                  width="35px"
                  height="35px"
                />
              </a>
              <div>
                <span>Chat</span>
              </div>
            </div>
            <div className="bx-fale-conosco">
              <a href="/ajuda" className="btn btn-main btn-help">
                <IconLetter
                  className="ic-letter"
                  fill="transparent"
                  width="35px"
                  height="35px"
                />
              </a>
              <div>Fale conosco</div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ModalChat;
