import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';
import {
  loadPaymentMethodsSuccess, loadPaymentMethodsFailure, participantPaymentHistorySuccess, participantPaymentHistoryFailure, participantPaymentMethodSuccess,
  participantPaymentMethodFailure, participantPaymentChangeSuccess, participantPaymentChangeFailure, participantCopyOfTheBankSlipSuccess,
  participantCopyOfTheBankSlipFailure,
  changeFlagShowNotificationChangePaymentFailure, changeFlagShowNotificationChangePaymentSuccess, setCreditCardPaymentSuccess, setCreditCardPaymentFailure, sporadicBankSlipSuccess, sporadicBankSlipFailure
} from './actions';

export function* loadPaymentMethodsRequest() {
  try {
    const { data } = yield call(api.get, '/pagamento');

    yield put(loadPaymentMethodsSuccess(data));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
    yield put(loadPaymentMethodsFailure());
  }
}


export function* participantPaymentHistoryRequest(action) {
  try {
    const { data } = yield call(api.get, `/pagamento/ListaHistorico`);

    yield put(participantPaymentHistorySuccess(data));

  } catch (error) {
    yield put(participantPaymentHistoryFailure());
  }
}

export function* participantPaymentMethodRequest() {
  try {
    const { data } = yield call(api.get, `/pagamento/ListaMetodosPgto`);

    yield put(participantPaymentMethodSuccess(data));

  } catch (error) {
    yield put(participantPaymentMethodFailure());
  }
}

export function* participantPaymentChangeRequest(action) {
  try {
    const { data } = yield call(api.put, `/pagamento/AlterarTipoPagamento`, action.payload.data);

    yield put(participantPaymentChangeSuccess(data));

  } catch (error) {
    yield put(participantPaymentChangeFailure(error));
  }
}

export function* participantCopyOfTheBankSlipRequest(action) {
  try {
    const { data } = yield call(api.post, `/pagamento/SegundaViaBoleto`, action.payload.data);

    yield put(participantCopyOfTheBankSlipSuccess(data));

  } catch (error) {
    yield put(participantCopyOfTheBankSlipFailure(error));
  }
}

export function getParticipantCopyOfTheBankSlipRequest(data) {
  return api.post(`/pagamento/SegundaViaBoleto`, data);
}

export function* changeFlagShowNotificationChangePaymentRequest(action) {
  try {
    const { data } = yield call(api.post, `/pagamento/AlterarEscolhaPagamentoModal`, action.payload.data);

    yield put(changeFlagShowNotificationChangePaymentSuccess(data));

  } catch (error) {
    yield put(changeFlagShowNotificationChangePaymentFailure(error));
  }
}

export function* setCreditCardPayment(action) {
  try {
    const { data } = yield call(api.post, '/pagamento/CadastrarCartaoCredito', action.payload.data);

    yield put(setCreditCardPaymentSuccess(data));
  } catch (error) {
    yield put(setCreditCardPaymentFailure(error));
  }
}

export function* setChangeCreditCardPayment(action) {
  try {
    const { data } = yield call(api.put, '/pagamento/AlterarCartaoCredito', action.payload.data);

    yield put(setCreditCardPaymentSuccess(data));
  } catch (error) {
    yield put(setCreditCardPaymentFailure(error));
  }
}

export function* sporadicBankSlipRequest(action) {
  try {
    const { data } = yield call(api.post, `/boleto-avulso/quitacao`, action.payload.data);
    yield put(sporadicBankSlipSuccess(data));
  } catch (error) {
    yield put(sporadicBankSlipFailure(error));
  }
}
