export const AuthTypes =  {
    AUTH_STATE: '@auth/AUTH_STATE',
    SIGN_IN_REQUEST : '@auth/SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS : '@auth/SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE : '@auth/SIGN_IN_FAILURE',
    SIGN_IN_START : '@auth/SIGN_IN_START',
    SIGN_OUT : '@auth/SIGN_OUT',
    RETURN_URL_RESET: '@auth/RETURN_URL_RESET' ,
    LOAD_PERMISSIONS_REQUEST: '@auth/LOAD_PERMISSIONS_REQUEST',
    LOAD_PERMISSIONS_SUCCESS: '@auth/LOAD_PERMISSIONS_SUCCESS',
    REENTRY_NEGOCIATION_REQUEST: "@auth/REENTRY_NEGOCIATION_REQUEST",
    REENTRY_NEGOCIATION_SUCCESS: "@auth/REENTRY_NEGOCIATION_SUCCESS",
    REENTRY_NEGOCIATION_FAILURE: "@auth/REENTRY_NEGOCIATION_FAILURE",
    REMOVE_ALL_DEBTOR_STATE: '@auth/REMOVE_ALL_DEBTOR_STATE',
    REMOVE_DEBTOR_90_STATE: '@auth/REMOVE_DEBTOR_90_STATE',
    REENTRY_HISTORIC_REQUEST: "@auth/REENTRY_HISTORIC_REQUEST",
    REENTRY_HISTORIC_SUCCESS: "@auth/REENTRY_HISTORIC_SUCCESS",
    REENTRY_HISTORIC_FAILURE: "@auth/REENTRY_HISTORIC_FAILURE",
}
