import React from "react";
import StartCreate from "@/deliverySystem/assets/images/home/StartCreate.png";
import downloadGraphicDesign from "@/deliverySystem/assets/images/home/download-graphic-design.png";
import { formatToCellphone } from "@/deliverySystem/common/globalFunctions";
import { connect } from "react-redux";
import "./src/projectCreationSteps.min.css";
import { Link } from "react-router-dom";

const ProjectCompleted = ({ navigation, inputValue, propsModalDownload, projectList, projectListLoading }) => {
  const hendleDownload = () => {
    let targetProject = projectList.find((project) => { return project.id == inputValue.id })

    propsModalDownload.setDownloadTargetProps({...targetProject, whatsApp: formatToCellphone(targetProject.whatsApp)})
    propsModalDownload.setModalState("open");
  };

  return (
    <section className="project-completed">
      <div className="title">
        <h2>PARABÉNS</h2>
        <span>
          {inputValue?.editMode
            ? "Foram editados seu enxoval com imã de geladeira, panfleto e cartão de visita. Caso também queira editar seu cardápio continue o progresso no botão de edição abaixo."
            : "O seu enxoval com imã de geladeira, panfleto e cartão de visita foi criado. Agora só falta editar seu cardápio e começar a enviar para os seus clientes! Boas vendas."}
        </span>
      </div>
      <div className="bx-buttons img-container">
        <button className="btn-box" onClick={!projectListLoading && hendleDownload}>
          <img src={downloadGraphicDesign} />
          <div className="text-box">
            <span className="btn btn--secondary">DOWNLOAD</span>
            <span className="text-purple">
              Baixe as peças de divulgação do seu estabelecimento.
            </span>
          </div>
        </button>
        <Link
          className="btn-box"
          to={{
            pathname: "/seu-delivery/criar-cardapio",
            state: {
              cardapioId: inputValue.id,
            },
          }}
          target="_self"
        >
          <img src={StartCreate} />
          <div className="text-box">
            <span className="btn btn--secondary">
              {inputValue?.editMode ? "EDITAR" : "COMEÇAR"}
            </span>
            <span className="text-purple">
              Comece a editar o cardápio do seu estabelecimento
            </span>
          </div>
        </Link>
      </div>
      {inputValue?.editMode && (
        <div className="bx-buttons">
          <button onClick={navigation.previous}>Voltar</button>
        </div>
        )  
      }
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    projectList: state.deliverySystem.projectList.data,
    projectListLoading: state.deliverySystem.projectList.loading,
  };
};

export default connect(mapStateToProps)(ProjectCompleted);
