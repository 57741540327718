import React from "react";
import InputMask from "react-input-mask";
import "./src/fields.min.css";

const InputCpf = ({ error, label, inputProps }) => {
  return (
    <div className={`fm-field text-field ${error ? "filled-error" : ""}`}>
      <label htmlFor={inputProps.id}>{label}</label>
      <InputMask
        {...inputProps}
        value={inputProps.value}
        mask="999.999.999-99"
      />
      {error && <span className="error-label">{error.message}</span>}
    </div>
  );
};

export default InputCpf;
