import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { loadBonusSuccess, loadBonusFailure, loadBonusRedesSuccess, loadBonusRedesFailure } from './actions';

import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';


export function* loadBonus(){
    try{
        const {data} = yield call(api.get, '/participante/Performance');

        yield put(loadBonusSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadBonusFailure());
    }
} 

// Novo Tracking
// export function* loadBonus(action) {
//     try {
//         const { data } = yield call(api.get, `/api/tracking?${action.payload.data}`);
//         yield put(loadBonusSuccess(data));
//     } catch (error) {
//         yield put(loadBonusFailure());
//     }
// }

export function* loadBonusRedes() {
    try {
        const { data } = yield call(api.get, `/api/Tracking/redes`);
        yield put(loadBonusRedesSuccess(data));
    } catch (error) {
        yield put(loadBonusRedesFailure());
    }
}