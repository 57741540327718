import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { loadAccountStatementSuccess, loadAccountStatementFailure } from './actions';

export function* loadAccountStatement(){
    try{
        const {data} = yield call(api.get, '/participante/Extrato');

        yield put(loadAccountStatementSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadAccountStatementFailure());
    }
} 