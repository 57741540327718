import { action } from 'typesafe-actions';
import { AuthTypes } from './types';

export const authState = (node, key, value) => action(AuthTypes.AUTH_STATE, { node, key, value });

export const signInRequest = data => ({
  type: AuthTypes.SIGN_IN_REQUEST,
  payload: data
})


export const signInSuccess = (accessToken, cognitoAccessToken, cognitoIdToken, userInfo, returnUrl) =>
  action(AuthTypes.SIGN_IN_SUCCESS, { accessToken, cognitoAccessToken, cognitoIdToken, userInfo, returnUrl });

export const signInFailure = () => action(AuthTypes.SIGN_IN_FAILURE);

export const signInStart = () => action(AuthTypes.SIGN_IN_START);

export const signOut = (text) => action(AuthTypes.SIGN_OUT, { text });

export const returnUrlResetParam = () => action(AuthTypes.RETURN_URL_RESET);

export const loadPermissions = () => action(AuthTypes.LOAD_PERMISSIONS_REQUEST);

export const loadPermissionsSuccess = (data) => action(AuthTypes.LOAD_PERMISSIONS_SUCCESS, { data });

export const reentryUserNegociationRequest = (negociation) => action(AuthTypes.REENTRY_NEGOCIATION_REQUEST, { negociation });
export const reentryUserNegociationSuccess = () => action(AuthTypes.REENTRY_NEGOCIATION_SUCCESS);
export const reentryUserNegociationFailure = (error) => action(AuthTypes.REENTRY_NEGOCIATION_FAILURE, { error });
export const resetAllDebtorState = () => action(AuthTypes.REMOVE_ALL_DEBTOR_STATE);
export const resetDebtorState = () => action(AuthTypes.REMOVE_DEBTOR_90_STATE);

export const reentryHistoricRequest = (incentivoId) => action(AuthTypes.REENTRY_HISTORIC_REQUEST, { incentivoId });
export const reentryHistoricSuccess = (data) => action(AuthTypes.REENTRY_HISTORIC_SUCCESS, { data });
export const reentryHistoricFailure = (error) => action(AuthTypes.REENTRY_HISTORIC_FAILURE, { error });
