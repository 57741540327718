import { Accordion } from "@/components/accordion";
import { Checkbox, Input, RadioButton, InputDate, InputTelAndCellphone, InputCpfCnpj } from "@/components/formFields";
import { SectionTitle } from "@/components/sectionTitle";
import { ValidationRules } from "@/components/utils";
import * as MyDataActions from "@/store/my-data/actions";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from 'sweetalert2';
import "./src/myData.min.css";

const MyData = ({ loading, updateSuccess, error, getMyDataRequest, myData, updateMyDataRequest, resetLoadingFlag, readOnly }) => {

  const [state, setState] = useState(myData);
  const [gender, setGender] = useState("");
  const [tel, setTel] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [date, setDate] = useState("1900/01/12");
  const [preferredContact, setPreferredContact] = useState("");
  const [cellphonePreferredContact, setCellphonePreferredContact] = useState("");
  const [cellIsInvalid, setCellIsInvalid] = useState(false);
  const [cellIsInvalidMessage, setCellIsInvalidMessage] = useState('');

  useEffect(() => {
    getMyDataRequest();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }, []);

  useEffect(() => {
    const {
      dataNascimento,
      genero,
      telefone,
      celular
    } = myData;

    setGender(genero !== null ? genero.toString() : 0);
    setTel(telefone);
    setCellphone(celular);
    setDate(dataNascimento !== null ? dataNascimento.split('T')[0].split('-').join('/') : date);

    let contatoPreferencial = myData?.preferredContact?.id
    
    setPreferredContact(contatoPreferencial && contatoPreferencial !== null ? contatoPreferencial.toString() : '1');
    if (myData?.preferredContact?.preferredContact && myData?.preferredContact?.id == 1) {
      setCellphonePreferredContact(myData?.preferredContact?.preferredContact)
    } else if (myData?.preferredContact?.preferredContact && myData?.preferredContact?.id == 2) {
      myData.emailPreferred = myData.preferredContact.preferredContact
    }

    setState(myData);
  }, [myData]);

  useEffect(() => {
    if (updateSuccess && !error) {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados atualizados com sucesso!',
        timer: 2000,
        timerProgressBar: true
      });

      getMyDataRequest();

    } else if (error && !updateSuccess) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Opa! Alguma coisa deu errado, entre em contato com o administrador',
        timer: 2000,
        timerProgressBar: true
      });
    }
    resetLoadingFlag();
  }, [updateSuccess, error]);

  const { register, errors, getValues, triggerValidation } = useForm({
    validationSchema: ValidationRules(),
    mode: "onChange"
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const {
      nome,
      genero,
      email,
      enviarEmail,
      enviarSMS,
      contatoPreferencial,
      emailPreferred,
    } = getValues();

    const isValid = await triggerValidation(["email"]);
    let isValidContactPreferred = false

    if (contatoPreferencial == 1) {
      let currentCel = cellphonePreferredContact?.replace(/[^\d]+/g, "");

      if (!currentCel || currentCel.length == 0) {
        setCellIsInvalid(true)
        setCellIsInvalidMessage("CELULAR É OBRIGATÓRIO")
      } else if (currentCel.length < 11) {
        setCellIsInvalid(true)
        setCellIsInvalidMessage("DIGITE UM CELULAR VÁLIDO")
      } else if (!cellIsInvalid) {
        setCellIsInvalid(false)
        isValidContactPreferred = true  
      }   
    } else if (contatoPreferencial == 2) {
      isValidContactPreferred = await triggerValidation(["emailPreferred"]);
    }

    if (isValid && isValidContactPreferred) {
      
      let novaDataNascimento = date

      if (date.length == undefined && novaDataNascimento.getHours() != '00') {
        novaDataNascimento.setHours('00', '00', '00');
      }

      novaDataNascimento = new Date(novaDataNascimento).toJSON()

      updateMyDataRequest({
        nome,
        dataNascimento: novaDataNascimento,
        genero: Number(genero),
        email,
        telefone: tel,
        celular: cellphone,
        enviarEmail,
        enviarSMS,
        preferredContact: {
          id: Number(contatoPreferencial),
          preferredContact: contatoPreferencial == 1 ? cellphonePreferredContact : emailPreferred
        },
      });
    }
  };

  const changePreferredContact = (id, value) => {
    if (id == 1) {
      setCellphonePreferredContact(value)
      setState({...state, emailPreferred: ''})

      let currentCel = value?.replace(/[^\d]+/g, "");

      if (!currentCel || currentCel.length < 11) {

        setCellIsInvalid(true)
        if (currentCel.length == 0) {
          setCellIsInvalid(false)
          setCellIsInvalidMessage('')
        } else {
          setCellIsInvalidMessage("DIGITE UM CELULAR VÁLIDO")
        }
      } else {
        setCellIsInvalid(false)
        setCellIsInvalidMessage('')
      }

    } else if (id == 2) {
      setCellphonePreferredContact('')
    }
  }

  return (
    <section className='my-data'>
      <SectionTitle title={"Meus dados"} />
      <Accordion
        title={'Dados pessoais'}
      >

        <form className="myData">
          <Input
            label="Nome completo"
            inputProps={{ 
              id: "nome", 
              name: "nome", 
              defaultValue: state.nome, 
              ref: register, 
              disabled:readOnly, 
            }}
          />
          <InputDate
            label="Nascimento"
            register={register}
            controlState={setDate}
            inputProps={{
              id: "nascimento",
              name: "dataNascimento",
              ref: register,
              value: date,
              disabled:readOnly
            }}
          />
          <div className="box">
            <span>Gênero</span>
            <div>
              <RadioButton
                label="Masculino"
                checked={gender === "0"}
                inputProps={{
                  id: "masculino",
                  name: "genero",
                  ref: register,
                  value: "0",
                  disabled:readOnly,
                  onChange: e => {
                    setGender(e.target.value)
                  }
                }}
              />
              <RadioButton
                label="Feminino"
                checked={gender === "1"}
                inputProps={{
                  id: "feminino",
                  name: "genero",
                  ref: register,
                  value: "1",
                  disabled:readOnly,
                  onChange: e => {
                    setGender(e.target.value)
                  }
                }}
              />
              <RadioButton
                label="Prefiro não dizer"
                checked={gender === "3"}
                inputProps={{
                  id: "genreNotDeclare",
                  name: "genero",
                  ref: register,
                  value: "3",
                  disabled:readOnly,
                  onChange: e => {
                    setGender(e.target.value)
                  }
                }}
              />
            </div>
          </div>
          <Input
            label="E-mail"
            error={errors.email}
            inputProps={{
              id: "email",
              type: "email",
              name: "email",
              defaultValue: state.email,
              disabled:readOnly,
              ref: register
            }}
          />
          <InputTelAndCellphone
            label="Telefone"
            type="tel"
            error={errors.phone}
            inputProps={{ 
              id: "telefone", 
              name: "telefone", 
              value: tel, 
              ref: register,
              disabled:readOnly, 
              onChange: e => {setTel(e.target.value)}
            }}
          />
          <InputTelAndCellphone
            label="Celular"
            type="cel"
            error={errors.cell}
            inputProps={{ 
              id: "Celular", 
              name: "celular", 
              value: cellphone, 
              ref: register,
              disabled:readOnly,
              onChange: e => {setCellphone(e.target.value)}
            }}
          />
          <div className="box">
            <span>Contato Preferencial </span>
            <div>
              <RadioButton
                label="Celular"
                checked={preferredContact === "1"}
                inputProps={{
                  id: "contatoPreferencialCelular",
                  name: "contatoPreferencial",
                  ref: register,
                  value: "1",
                  disabled:readOnly,
                  onChange: e => {
                    setPreferredContact(e.target.value) 
                    changePreferredContact(e.target.value, '')
                  }
                }}
              />
              <RadioButton
                label="E-mail"
                checked={preferredContact === "2"}
                inputProps={{
                  id: "contatoPreferencialEmail",
                  name: "contatoPreferencial",
                  ref: register,
                  value: "2",
                  disabled:readOnly,
                  onChange: e => {
                    setPreferredContact(e.target.value)
                    changePreferredContact(e.target.value, '')
                  }
                }}
              />
            </div>
          
            <div>
              {preferredContact == "1" &&
                <InputTelAndCellphone
                  label=""
                  error={
                    cellIsInvalid ? {cellIsInvalid, message: cellIsInvalidMessage } : ``
                  }
                  inputProps={{
                    id: "cellPreferred",
                    type: "cell",
                    name: "cellPreferred",
                    defaultValue: cellphonePreferredContact,
                    value: cellphonePreferredContact,
                    disabled: readOnly,
                    ref: register,
                    onChange: e => {
                      changePreferredContact(preferredContact, e.target.value)
                    }
                  }}
                />
              } 
              {preferredContact == "2" &&
                <Input
                  label=""
                  error={errors.emailPreferred}
                  inputProps={{
                    id: "emailPreferred",
                    type: "email",
                    name: "emailPreferred",
                    defaultValue: state.emailPreferred,
                    disabled: readOnly,
                    ref: register,
                  }}
                />
              } 
            </div>
          </div>
          <div className="checkboxes">
            <Checkbox
              label="Aceita receber comunicações por e-mail?"
              isChecked={state.enviarEmail}
              inputProps={{
                id: "receber-email",
                name: "enviarEmail",
                ref: register,
                disabled:readOnly,
              }}
            />
            <Checkbox
              label="Aceita receber comunicações por sms?"
              isChecked={state.enviarSMS}
              inputProps={{
                id: "receber-sms",
                name: "enviarSMS",
                ref: register,
                disabled:readOnly,
              }}
            />
          </div>
          <div className='fm-button'>
            <button className="btn ic-go btn-main btn--save" disabled={loading || readOnly} onClick={(e) => {
              onSubmit(e)
            }}>
              {loading ? 'Salvando' : 'Salvar'}
            </button>
          </div>
        </form>

      </Accordion>
      <Accordion
        title={'Empresa'}
      >

        <div className='myData'>
          <Input
            label="Código SAP"
            inputProps={{
              id: "codigo-sap",
              disabled: true,
              name: "empresa.codigoSAP",
              defaultValue: state.empresa.codigoSAP,
              ref: register,
            }}
          />
          <InputCpfCnpj
            label="CNPJ/CPF"
            inputProps={{
              id: "cnpj-cpf",
              disabled: true,
              name: "empresa.cnpj",
              ref: register,
              value: state.empresa.cnpj
            }}
          />
          <Input
            label="Razão social"
            inputProps={{
              id: "razao-social",
              disabled: true,
              name: "empresa.razaoSocial",
              defaultValue: state.empresa.razaoSocial,
              ref: register
            }}
          />
          <InputTelAndCellphone
            label="Telefone"
            type="tel"
            inputProps={{
              id: "telefone-empresa",
              disabled: true,
              name: "empresa.telefone",
              ref: register,
              value: state.empresa.telefone,
              onChange: e => {
                setCellphone(e.target.value)
              }
            }}
          />
          <Input
            label="E-mail"
            inputProps={{
              id: "e-mail",
              disabled: true,
              defaultValue: state.empresa.email,
              name: "empresa.email",
              ref: register
            }}
          />
          <Input
            label="Endereço"
            inputProps={{
              id: "endereco",
              disabled: true,
              name: "enderecoCompleto",
              defaultValue: state.empresa.endereco,
              ref: register
            }}
          />
          <Input
            label="Bairro"
            inputProps={{
              id: "bairro",
              disabled: true,
              name: "empresa.bairro",
              defaultValue: state.empresa.bairro,
              ref: register
            }}
          />
          <Input
            label="Cidade"
            inputProps={{
              id: "cidade",
              disabled: true,
              name: "empresa.cidade",
              defaultValue: state.empresa.cidade,
              ref: register
            }}
          />
          <Input
            label="Estado"
            inputProps={{
              id: "estado",
              disabled: true,
              name: "empresa.uf",
              defaultValue: state.empresa.uf,
              ref: register
            }}
          />
          <Input
            label="Cep"
            inputProps={{
              id: "cep",
              disabled: true,
              name: "empresa.cep",
              defaultValue: state.empresa.cep,
              ref: register
            }}
          />
        </div>

      </Accordion>
    </section>
  );
};

const mapStateToProps = (state) => ({
  myData: state.myData.data,
  loading: state.myData.loading,
  error: state.myData.error,
  updateSuccess: state.myData.updateSuccess,
  readOnly: state.auth.userInfo.readOnly
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...MyDataActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyData);
