import {default as React } from "react";
import { Link } from "react-router-dom"

import './src/benefitCard.min.css'

// CARDS DAS CAPANHAS

const BenefitCard = (props) => {
  return (
      <div className='benefit-card'>
        <Link to={`beneficios-prime/${props.benefitId}/${props.slug}`} target='_self'>
          <div className='container-card-banner'>
            <img className='card-banner' src={props.image} alt="" />
            <div className='card-banner-label'>
              <h4 className='title'>{props.title}</h4>
              <strong className='description'>{props.subtitle}</strong>
            </div>
          </div>
        </Link>
        <img className='logo' src={props.imageLogo} alt=""/>
        <div className='btn-benefit-container'>
          <Link className="btn ic-go" to={`beneficios-prime/${props.benefitId}/${props.slug}`} target='_self'>
                veja mais detalhes
          </Link>
        </div>
      </div>
  )
}

export default BenefitCard
