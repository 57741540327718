import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';



export async function loadBilletIC(){
    try{
        const {data} = await api.get('/pagamento/BoletosAVencer');
        return data;
    }catch(error){
        showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true });
    }
} 
