import React from 'react';
import './src/containers.min.css';

const DisconnectedContainer = ({ children }) => {
    return (
        <>
            <div className='ct disconnectedContainer'>
                {children}
            </div>
        </>
    )
}

export default DisconnectedContainer;