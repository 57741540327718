import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Accordion } from "@/components/accordion";
import { formatDate } from "@/deliverySystem/common/globalFunctions";
import { SectionTitle } from "@/components/sectionTitle";
import * as CentralizedBillingActions from "store/centralizedBilling/actions";
import "./src/timelineCentralization.min.css";

const TimelineCentralization = (props) => {
  useEffect(() => {
    props.centralizedBillingHistory();
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.history && (!props.history[2].erro && props.history[2].status !== 6)) {
        props.centralizedBillingHistory();
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [props.history]);

  const etapaStr = {
    1: "Início",
    2: "Processamento",
    3: "Conclusão",
  };

  const statusMatrizStr = {
    1: "started",
    2: "progress",
    3: "cancel",
    4: "finishing",
    5: "awaiting",
    6: "success",
  };

  const statusFilialIcon = {
    1: "started",
    2: "progress",
    3: "error",
    4: "success",
  };

  const customizedContent = (item) => {
    return (
      <Card title={etapaStr[item.numeroEtapa]} subTitle={subTitle(item)}>
        <div className="filial-content">
          {item.filiais &&
            item.filiais.map((x) => {
              return (
                <Accordion
                  className={`${x.erro ? "error" : statusFilialIcon[x.status]}`}
                  title={`${x.razaoSocial}`}
                >
                  <div className="filial-box">
                    {x.erro && (
                      <p className="error-message">
                        <strong>Motivo do erro: {x.descricaoErro}</strong>
                      </p>
                    )}
                    <p>
                      <strong>CPNJ/CPF: </strong>
                      {x.username}
                    </p>
                    <p>
                      <strong>SAP: </strong>
                      {x.codigoSap}
                    </p>
                    <p>
                      <strong>RAZÃO SOCIAL: </strong>
                      {x.razaoSocial}
                    </p>
                    <p>
                      <strong>Data: </strong>
                      {formatDate(x.data)}
                    </p>
                  </div>
                </Accordion>
              );
            })}
        </div>
      </Card>
    );
  };

  const progressSuccessCentralization = (item) => {
    if (item.filiais) {
      const retailSuccess = item.filiais.filter((x) => x.status == 4);
      return retailSuccess.length;
    }
  };

  const progressErrorCentralization = (item) => {
    if (item.filiais) {
      const resultSuccess = item.filiais.filter((x) => x.erro);
      return resultSuccess;
    }
  };

  const subTitle = (item) => {
    if (item.numeroEtapa === 1) {
      return (
        <>
          <p>Atualização: {formatDate(item.data)}</p>
          {!!props.retailChain.length && (
            <div className={`progress-filial ${statusMatrizStr[item.status]}`}>
              <p className="flex">
                <strong>
                  {props.retailChain.length} filiais iniciando centralização
                </strong>
              </p>
            </div>
          )}
        </>
      );
    } else if (item.numeroEtapa === 2) {
      return (
        <>
          <p>Atualização: {formatDate(item.data)}</p>
          {!!props.retailChain.length && (
            <div className={`progress-filial ${statusMatrizStr[item.status]}`}>
              <p className="flex">
                <strong>
                  {progressSuccessCentralization(item)}/
                  {props.retailChain.length} filiais finalizadas
                </strong>
              </p>
            </div>
          )}
          {!!progressErrorCentralization(item).length && (
            <p className="error-message">
              <strong>
                Ocorreu um erro em {progressErrorCentralization(item).length}{" "}
                filial(is), verifique detalhadamente abaixo
              </strong>
            </p>
          )}
        </>
      );
    } else {
      return (
        <>
          <p>Atualização: {formatDate(item.data)}</p>
          {item.numeroEtapa == 6 && (
            <p className={`progress-filial ${statusMatrizStr[item.status]}`}>
              <strong>Centralização concluída</strong>
            </p>
          )}
          {item.erro && (
            <span className="error-message">{item.descricaoErro}</span>
          )}
        </>
      );
    }
  };

  const customizedMarker = (item) => {
    if (item.status == 6) {
      return (
        <div
          className="p-timeline-event-marker"
          style={{ backgroundColor: "#39cea0", border: "2px solid #39cea0" }}
        ></div>
      );
    } else if (item.status == 2) {
      return (
        <div
          className="p-timeline-event-marker"
          style={{ border: "2px solid #00bff3" }}
        ></div>
      );
    } else if (item.erro) {
      return (
        <div
          className="p-timeline-event-marker"
          style={{ backgroundColor: "#ff5f5f", border: "2px solid #ff5f5f" }}
        ></div>
      );
    }
  };

  return (
    <>
      {props.history && (
        <>
          <SectionTitle title="Status da Centralização de Cobrança" />
          <Timeline
            value={!!props.history.length && props.history}
            layout="horizontal"
            align="top"
            className="container-scroll"
            content={customizedContent}
            marker={customizedMarker}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    history: state.centralizedBilling.history?.data?.data?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    centralizedBillingHistory: () => {
      dispatch(CentralizedBillingActions.centralizedBillingHistory());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineCentralization);
