import React, { useEffect, useState, useRef } from 'react'
import { Toast } from 'primereact/toast'
import Skeleton from "react-loading-skeleton"
import imgLoading from '@/deliverySystem/assets/images/loading-btn.svg'
import { connect } from 'react-redux'
import { InputDefault } from "@/deliverySystem/components/inputs"
import * as DeliverySystemActions from "store/deliverySystem/actions"

import './src/addNewProduct.min.css'

const AddNewProduct = ({ setNewProductStatus, createNewProductRequest, cardapioId, categoriaId, createNewProductSuccess, createNewProductLoading }) => {
    const toast = useRef(null)

    const initialProduct = {
        cardapioId,
        categoriaId,
        nome: ''
    }

    const [newProduct, setNewProduct] = useState(initialProduct)
    const handleAddNewProduct = (key, value) => {
        setNewProduct({
            ...newProduct,
            nome: value
        })
    }

    useEffect(() => {
        if (!createNewProductLoading) {
            if (createNewProductSuccess) {
                handleReset();
            } 
        }
    }, [createNewProductSuccess])

    const handleSaveNewProduct = () => {
        createNewProductRequest(newProduct);
    }

    const handleReset = () => {
        setNewProduct(initialProduct);
        setNewProductStatus(false);
    }

    return (
        <>
            <div className='bx-field'>
                {createNewProductLoading
                    ? <Skeleton count={1} height={60} />
                    : <div className='add-new-product-field'>
                        <InputDefault
                            labelText='Nome do produto'
                            callBack={handleAddNewProduct}
                            inputProps={{
                                type: 'text',
                                className: 'newProductname',
                                name: 'newProductname',
                                value: newProduct.name,
                            }}
                        />
                        <a className='btn-cancel' onClick={handleReset} ><i className='icon icon-icon_close_circle'></i></a>
                        <button className='btn-save-new-product' disabled={!newProduct.nome || createNewProductLoading} onClick={handleSaveNewProduct}>
                            {createNewProductLoading ? <img src={imgLoading} /> : <i className='icon icon-icon_check_circle'></i>}
                        </button>
                    </div>
                }
            </div>
            <Toast ref={toast} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNewProductRequest: (data) => {
            dispatch(DeliverySystemActions.createNewProductRequest(data));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        createNewProductSuccess: state.deliverySystem.createNewProduct.success,
        createNewProductLoading: state.deliverySystem.createNewProduct.loading,
        createNewProductError: state.deliverySystem.createNewProduct.error,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewProduct);