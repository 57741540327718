import { call, put } from 'redux-saga/effects';
import api from '../../common/api';

import { updateCancelSuccess, updateCancelFailure } from './actions';
import { getMyDataRequest } from '../my-data/actions';

export function* updateCancelRequest(action){
    try {
        const {data} = yield call(api.post, `/participante/cancelamento`, action.payload.data);

        yield put(updateCancelSuccess(data));
        yield put(getMyDataRequest());
        
    } catch (error) {
        yield put(updateCancelFailure(error));
    }
}