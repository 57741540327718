import React from "react";
import { Route, Switch } from "react-router-dom";
import ConectaPay from "@/pages/myAccount/conectaPay";
import { Cancel } from "@/pages/myAccount/cancel";
import { AccountStatement } from "@/pages/myAccount/accountStatement";
import { Bonus } from '@/pages/myAccount/bonus';
import { MyData } from "@/pages/myAccount/myData";
import { NewRetail } from "@/pages/myAccount/retailChain";
import { PaymentMethods, PaymentPage } from "@/pages/myAccount/payment"
import { PointsTransfer } from "@/pages/myAccount/pointsTransfer"
import { ManageRetail } from '@/pages/myAccount/manageRetail'
import { BilletIC } from '@/pages/myAccount/billetIC';

const Routes = () => (
  <Switch>
    <Route path="/meus-dados" component={MyData} />
    <Route path="/extrato" component={AccountStatement} />
    <Route path="/bonificacao" component={Bonus} />
    <Route path="/conecta-pay" exact component={ConectaPay} />
    <Route path="/meus-pagamentos" exact component={PaymentPage} />
    <Route path="/alterar-pagamento" exact component={PaymentMethods} />
    <Route path="/minha-rede-de-varejos" exact component={ManageRetail} />
    <Route path="/cancel" exact component={Cancel} />
    {/* <Route path="/minha-rede-de-varejos" exact component={MyRetailNetwork} /> */}

    <Route path="/associar-novo-varejo" exact component={NewRetail} />
    <Route path="/transferir-pontos" exact component={PointsTransfer} />
    <Route path="/bank-slip" exact component={BilletIC} />
    {/* <Route path="/historico-de-transferencias" exact component={TransferHistory} /> */}
  </Switch>
);

export default Routes;
