import React from "react";
import ima from "@/deliverySystem/assets/images/ima.png";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

const ImaPdf = ({ ...downloadTargetProps }) => {
  const styles = StyleSheet.create({
    backgroundImage: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
    },
    headerBox: {
      width: "100%",
      height: "100%",
    },
    section: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    subSection: {
      display: "flex",
      height: "50%",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    cellphoneSection: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
    },
    companyText: {
      fontSize: "10px",
      paddingVertical: "8px",
      width: "100%",
      textAlign: "center",
    },
    companyTextNoLogo: {
      fontSize: "10px",
      paddingBottom: "8px",
      width: "100%",
      textAlign: "center",
    },
    logoCompany: {
      maxHeight: "45px",
      maxWidth: "45px",
    },
    cellText: {
      fontSize: "14px",
      color: "#ffffff",
      position: "relative",
      left: "10px",
    },
  });

  return (
    <Document>
      <Page size={[184, 184]}>
        <Image style={styles.backgroundImage} src={`${ima}`} fixed />
        <View style={styles.section}>
          {downloadTargetProps.logoUrl64 !== "" ? (
            <View style={styles.subSection}>
              <Image
                src={`${downloadTargetProps.logoUrl64}`}
                style={styles.logoCompany}
              />
              <Text style={styles.companyText}>
                {downloadTargetProps.nomeRevenda}
              </Text>
            </View>
          ) : (
            <View style={styles.subSection}>
              <Text style={styles.companyTextNoLogo}>
                {downloadTargetProps.nomeRevenda}
              </Text>
            </View>
          )}

          <View style={styles.cellphoneSection}>
            <Text style={styles.cellText}>{downloadTargetProps.whatsApp}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ImaPdf;
