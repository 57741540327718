import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion } from "@/components/accordion";
import { Filter } from "@/components/filter";
import { slugfy } from "@/components/gtm";
import * as PaymentActions from "store/payments/actions";
import { getParticipantCopyOfTheBankSlipRequest } from "store/payments/sagas";
import { IconCopyPaste, IconCheck } from "@/components/icons";
import { PaymentDebtor } from "@/pages/myAccount/payment";
import "./src/payment.scss";

const PaymentHistory = ({
  paymentState,
  participantPaymentHistoryRequest,
  paymentHistory,
  linkBankSlip,
  readOnly,
  cancelado,
  inadimplente,
  inadimplenteVencimentos,
}) => {
  const [filter, setFilter] = useState(0);
  const [showErrorLabel, setShowErrorLabel] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(false);

  useEffect(() => {
    participantPaymentHistoryRequest(0);
  }, []);

  const filterOptions = [
    "Todos",
    ...new Set(paymentHistory.map((payment) => payment.refYear)),
  ];

  const getCopyOftheBankSlip = (refYear, refMonth) => {
    setLoadingRequest(true);
    getParticipantCopyOfTheBankSlipRequest({
      anoVencimento: refYear,
      mesVencimento: refMonth,
    })
      .then((response) => {
        setLoadingRequest(false);
        const url = response.data.replace("http", "https");
        const link = document.createElement("a");
        link.href = url;

        const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

        if (!mobileDevices.test(navigator.userAgent)) {
          // Only desktop device
          link.setAttribute("target", "_blank");
        }

        link.setAttribute("download", `ConectaPrimeBoleto_${refMonth}_${refYear}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        setLoadingRequest(false);
        setShowErrorLabel(true);
        console.log(err);
      });
  };

  const restrict = () => {
    if (!readOnly && !cancelado && !inadimplente && !inadimplenteVencimentos) {
      return "/alterar-pagamento";
    } else {
      return false;
    }
  };

  const copyBankSlipCode = (code) => {
    code = code.replace(/\s/g, "").replace(/\./g, "");
    navigator.clipboard.writeText(code);

    setTimeout(() => setTooltipMessage(false), 500);
  };

  return (
    <section className="payment-history">
      {inadimplenteVencimentos && (
        <PaymentDebtor
          paymentHistory={paymentHistory}
          participantPaymentHistoryRequest={participantPaymentHistoryRequest}
        />
      )}
      <div className="bx-retail-management">
        <div className="fm-button">
          <Link
            to={restrict}
            disabled={
              readOnly || cancelado || inadimplente || inadimplenteVencimentos
            }
            className="btn btn-main btn-new ic-go"
          >
            Alterar forma de pagamento
          </Link>
        </div>
      </div>
      {showErrorLabel && (
        <div className="bx-message bx-message--error">
          <strong>Não foi possível baixar o boleto, tente novamente!</strong>
        </div>
      )}
      {filterOptions.length > 1 && paymentHistory.length > 0 && (
        <Filter
          label="Exibindo: "
          options={filterOptions}
          inputProps={{
            name: "filter",
            id: "filter",
            value: filter,
            onChange: (e) => {
              setFilter(e.target.value);
            },
          }}
        />
      )}
      {paymentHistory.length > 0 ? (
        <div className="bx-accordion">
          {paymentHistory
            .filter((payment, i) => {
              return (
                filter == 0 || filter == "Todos" || filter == payment.refYear
              );
            })
            .map((payment, i) => {
              return (
                <Accordion
                  key={`list-item-${i}`}
                  className={`payment-item ${slugfy(payment.statusString)}`}
                  title={
                    payment.idTipoPagamento == 7
                      ? `Boleto Negociado - ${payment.pagamentosNegociados} ${
                          payment.pagamentosNegociados == 1 ? "mês" : "meses"
                        }`
                      : `${payment.competencia}`
                  }
                >
                  <span className="status">{payment.statusString}</span>
                  <div className="payment-details">
                    <p>
                      <span>{payment.tipoPagamento}</span>
                    </p>
                    <p className="expiration">
                      Vence em: <span>{payment.vencimento}</span>
                    </p>
                    {payment.linhaDigitavel != null && (
                      <p className="copyBankSlip">
                        Código do Boleto: <span>{payment.linhaDigitavel}</span>
                        <button
                          onClick={() => {
                            copyBankSlipCode(payment.linhaDigitavel);
                            setTooltipMessage(true);
                          }}
                        >
                          <IconCopyPaste
                            width={40}
                            height={40}
                            fill="var(--secondaryColor)"
                          />
                          <span
                            className={`copied ${tooltipMessage ? "" : "none"}`}
                          >
                            <IconCheck
                              width="10px"
                              height="10px"
                              fill="var(--success)"
                            />
                            Código Copiado
                          </span>
                        </button>
                      </p>
                    )}
                    {payment.permiteBaixarBoleto && (
                      <div className="fm-button">
                        <button
                          type="button"
                          className="btn btn-main ic-go"
                          disabled={loadingRequest}
                          onClick={() => {
                            getCopyOftheBankSlip(
                              payment.refYear,
                              payment.refMonth
                            );
                          }}
                        >
                          {loadingRequest
                            ? "Gerando boleto..."
                            : payment.status === 1
                            ? "Baixar boleto"
                            : "Baixar segunda via"}
                        </button>
                      </div>
                    )}
                  </div>
                </Accordion>
              );
            })}
        </div>
      ) : (
        <div className="bx">
          <h3>Não encontramos registros de pagamentos.</h3>
          <p>
            Não identificamos registros de pagamento.
          </p>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentHistory: state.payment.paymentHistory,
    linkBankSlip: state.payment.linkBankSlip,
    readOnly: state.auth.userInfo.readOnly,
    cancelado: state.myData.data.cancelado,
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paymentState: (node, key, value) => {
      dispatch(PaymentActions.paymentState(node, key, value));
    },
    participantPaymentHistoryRequest: () => {
      dispatch(PaymentActions.participantPaymentHistoryRequest());
    },
    participantCopyOfTheBankSlipRequest: (data) => {
      dispatch(PaymentActions.participantCopyOfTheBankSlipRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
