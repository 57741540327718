import { MyDataTypes } from './types';
import Immutable from 'seamless-immutable';


const INITIAL_STATE = Immutable({
  data: {
  id: 0,
  nome: '',
  dataNascimento: '2020-05-08T19:50:22.975Z',
  genero: 1,
  email: '',
  telefone: '',
  celular: '',
  enviarEmail: true,
  enviarSMS: true,
  cancelado: true,
  MotivoCancelamento: '',
  DataCancelamento: '2020-05-08T19:50:22.975Z',
  empresa: {
    codigoSAP: '',
    razaoSocial: '',
    cnpj: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    cep: '',
    uf: '',
    email: '',
    telefone: ''
  },
  accesstageDadosBancarios: {
    bancoCodigo: '',
    bancoAgencia: '',
    bancoAgenciaDigito: '',
    bancoContaBancaria: '',
    bancoContaBancariaDigito: ''
  },
},
loading: false,
error: false,
success: false
});

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MyDataTypes.MY_DATA_STATE:
        return {...state, [action.payload.node]: {...state[action.payload.node], [action.payload.key] : action.payload.value}}
      case MyDataTypes.MY_DATA_REQUEST:
        return {...state, loading: true};
      case MyDataTypes.MY_DATA_SUCCESS:
       return {
          ...state,
          data: action.payload.data,
          loading: false,
          success: true,
          error: false
      }
      case MyDataTypes.MY_DATA_UPDATE:
        return {...state, loading: true};
      case MyDataTypes.MY_DATA_UPDATE_SUCCESS:
        return {...state, loading: false, error: false, updateSuccess: true};
      case MyDataTypes.MY_DATA_FAILURE:
        return {...state, loading: false, error: true, success: false, updateSuccess: false}
      case MyDataTypes.RESET_LOADING_FLAG:
        return {...state, loading: false, error: false, success: false, updateSuccess: false}
      case MyDataTypes.REMOVE_DEBTOR_STATE:
        return { ...state, data: { ...state.data, inadimplente: false } }
      default:
        return state;
    }
};

export default reducer;
