import { action } from 'typesafe-actions';
import { CampaignTypes } from './types';

export const loadCampaign = (pageSize) => action(CampaignTypes.LOAD_CAMPAIGN_REQUEST, { pageSize });

export const loadCampaignSuccess = data => action(CampaignTypes.LOAD_CAMPAIGN_SUCCESS, { data });

export const loadCampaignFailure = () => action(CampaignTypes.LOAD_CAMPAIGN_FAILURE);

export const detailsCampaign = (id) => action(CampaignTypes.DETAILS_CAMPAIGN_REQUEST, { id });

export const detailsCampaignSuccess = data => action(CampaignTypes.DETAILS_CAMPAIGN_SUCCESS, { data });

export const detailsCampaignFailure = () => action(CampaignTypes.DETAILS_CAMPAIGN_FAILURE);


export const acceptCampaign = (id) => action(CampaignTypes.ACCEPT_CAMPAIGN_REQUEST, { id });

export const acceptCampaignSuccess = data => action(CampaignTypes.ACCEPT_CAMPAIGN_SUCCESS, { data });

export const acceptCampaignFailure = () => action(CampaignTypes.ACCEPT_CAMPAIGN_FAILURE);
