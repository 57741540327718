import {
  Input,
  InputCpf,
  InputDateMask,
  InputTelAndCellphone,
  RadioButton,
} from "@/components/formFields";
import React from "react";
import { connect } from "react-redux";

const DadosPessoais = ({
  fullName,
  register,
  formData,
  isValidDate,
  date,
  data,
  gender,
  isValidTel,
  recommendedBy,
  recommendedByValidation,
  loading,
  setDate,
  dateValidation,
  tel,
  setTel,
  setGender,
  telValidation,
  setRecommendedBy,
  nextStep,
  readOnly,
  firstAccessData,
  stepsProps
}) => {
  return (
    <div id="userInfo">
      <Input
        label="Nome completo"
        error={fullName}
        inputProps={{
          ref: register,
          name: "fullName",
          type: "text",
          id: "fullName",
          defaultValue: formData.nome,
          disabled: readOnly,
        }}
      />
      <InputDateMask
        label="Data de nascimento"
        error={isValidDate ? false : { message: "Digite uma data válida" }}
        inputProps={{
          id: "nascimento",
          name: "dataNascimento",
          disabled: readOnly,
          value: date,
          onChange: (e) => {
            setDate(e.target.value);
          },
          onBlur: (e) => {
            dateValidation(e.target.value);
          },
        }}
      />
      <InputTelAndCellphone
        label="Telefone fixo"
        type="tel"
        error={!isValidTel ? { message: "Telefone é obrigatório" } : false}
        inputProps={{
          id: "phone",
          name: "phone",
          disabled: readOnly,
          ref: register,
          value: tel,
          onChange: (e) => setTel(e.target.value),
          onBlur: (e) => {
            telValidation(e.target.value);
          },
        }}
      />
      <div className="bx-radio">
        <p>Gênero</p>
        <RadioButton
          label="Masculino"
          checked={gender === "0"}
          inputProps={{
            ref: register,
            id: "genreMale",
            name: "genre",
            value: "0",
            disabled: readOnly,
            onChange: (e) => {
              setGender(e.target.value);
            },
          }}
        />
        <RadioButton
          label="Feminino"
          checked={gender === "1"}
          inputProps={{
            ref: register,
            id: "genreFemale",
            name: "genre",
            value: "1",
            disabled: readOnly,
            onChange: (e) => {
              setGender(e.target.value);
            },
          }}
        />

        <RadioButton
          label="Outro"
          checked={gender === "2"}
          inputProps={{
            ref: register,
            id: "genreOther",
            name: "genre",
            value: "2",
            disabled: readOnly,
            onChange: (e) => {
              setGender(e.target.value);
            },
          }}
        />
      </div>
      <InputCpf
        label="CPF de quem está te ajudando a realizar o cadastro"
        inputProps={{
          id: "recommendedBy",
          name: "recommendedBy",
          disabled: readOnly,
          ref: register,
          value: recommendedBy,
          onChange: (e) => setRecommendedBy(e.target.value),
          onBlur: (e) => {
            recommendedByValidation(e.target.value);
          },
        }}
      />
      <div className="fm-button">
        <button
          type="button"
          className="btn btn-main ic-go"
          onClick={() => {
            nextStep();
          }}
          disabled={loading || readOnly}
        >
          Próximo
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firstAccessData: state.firstAccess.data,
    success: state.firstAccess.updateData.success,
    error: state.firstAccess.updateData.error,
    message_error: state.firstAccess.updateData.message_error,
    loading: state.firstAccess.loading,
    conectaPay: state.firstAccess.current_state.conectaPay,
    payments: state.payment.data,
    // hasConnectPay: state.deliveryPay.hasConnectPay,
    paymentTypeOld: state.auth.userInfo.tipoPagamento,
    readOnly: state.auth.userInfo.readOnly,
  };
};

export default connect(mapStateToProps)(DadosPessoais);
