import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { loadAwardsSuccess, loadAwardsFailure, choiceAwardsSuccess, choiceAwardsFailure } from './actions';

export function* getTracks(){
    try{
        const {data} = yield call(api.get, '/campanha/RegularFaixas');

        yield put(loadAwardsSuccess(data));
    }catch(error){
        yield put(loadAwardsFailure());
    }
} 

export function* choiceAward(action){
    try{
        const {data} = yield call(api.post, '/campanha/escolha', action.payload.data);

        yield put(choiceAwardsSuccess(action.payload.data.idFaixa));
    }catch(error){
        yield put(choiceAwardsFailure());
    }
} 