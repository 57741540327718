import React, { useEffect, useState } from "react";
import { loadShowCaseAvailability } from "store/showCase/sagas";
import AuthHelper from "./../../Utils/AlertHelper";
import "./src/showcaseCard.min.css";

// CARDS DA VITRINE DE PRODUTOS

const ProductCard = ({ ...rest }) => {
  const [availability, setAvailability] = useState({
    price: "00",
    available: false,
  });

  const onClick = (event) => {
    event.preventDefault();

    if (rest.readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (
      rest.reativacaoAtual &&
      AuthHelper.validateUpdateDateReentry(
        rest.reativacaoAtual.dataConclusao
      ) &&
      !rest.reativacaoAtual.negociaDebito
    ) {
      AuthHelper.alertReentry(false);
      return;
    } else if (rest.inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (rest.inadimplente) {
      AuthHelper.alertInadimplente(
        false,
        rest.matriz,
        rest.cobrancaCentralizada
      );
      return;
    }

    if (rest.blank) {
      window.open(rest.url, "_blank");
    } else {
      window.location.assign(rest.url);
    }
  };

  useEffect(() => {
    loadAvailability();
  }, []);

  async function loadAvailability() {
    if (rest.originalId) {
      let payload = {
        originalId: rest.originalId,
        sku: rest.sku,
      };

      const { data } = await loadShowCaseAvailability(payload);

      setAvailability(data);
    }
  }

  return (
    <div>
      <div
        className="showcase-card"
        style={{
          backgroundImage: `url(${encodeURI(rest.image)})`,
        }}
      >
        <div className="card-label">
          {rest.description && (
            <h4 className="description">{rest.description}</h4>
          )}

          {rest.subtitle && (
            <strong className="benefits">{rest.subtitle}</strong>
          )}

          {rest.segment && <strong className="segment">{rest.segment}</strong>}

          {availability && availability.price > 0 && (
            <strong className="price">{availability.price} Pontos</strong>
          )}

          {rest.button && (
            <a
              className="btn ic-go"
              onClick={(e) => {
                onClick(e);
              }}
            >
              Resgate Agora
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
