import React from "react";
import AuthHelper from "./../../Utils/AlertHelper";
import "./src/showcaseCard.min.css";

// CARDS SEGMENTOS DE NEGOCIOS

const SegmentsCard = (props) => {
  const onClick = (event) => {
    event.preventDefault();

    if (props.readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (
      props.reativacaoAtual &&
      AuthHelper.validateUpdateDateReentry(
        props.reativacaoAtual.dataConclusao
      ) &&
      !props.reativacaoAtual.negociaDebito
    ) {
      AuthHelper.alertReentry(false);
      return;
    } else if (props.inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (props.inadimplente) {
      AuthHelper.alertInadimplente(
        false,
        props.matriz,
        props.cobrancaCentralizada
      );
      return;
    }

    if (props.blank === true) {
      window.open(props.url, "_blank");
    } else {
      window.location.assign(props.url);
    }
  };

  return (
    <div>
      <div
        className="showcase-card"
        style={{
          backgroundImage: `url(${props.image})`,
        }}
      >
        <div className="card-label">
          {props.description && (
            <h4 className="description">{props.description}</h4>
          )}

          {props.subtitle && (
            <strong className="benefits">{props.subtitle}</strong>
          )}

          {props.segment && (
            <strong className="segment">{props.segment}</strong>
          )}

          {props.price && <strong className="price">{props.price}</strong>}

          {props.button && (
            <a
              className="btn ic-go"
              onClick={(e) => {
                onClick(e);
              }}
            >
              Resgate Agora
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SegmentsCard;
