import Immutable from "seamless-immutable";
import { SimulatorTypes } from "./types";

const INITIAL_STATE = Immutable({
  current_state: {
    idSimulacao: 0,
    codigoPdv: "",
    percentualBonificacao: 0,
    qtdePacotes: 0,
    valorCartaoDebito: 0,
    taxaDebito: 0,
    valorCartaoCredito: 0,
    taxaCredito: 0,
    valorParceiros: 0,
    step: 1,
    vendasConectaPay: false,
    valorContaEnergia: 0,
    valorContaTelefonia: 0
  },
  data: {
    comprasOnline: 0,
    codigoPdv: "",
    comprasOnlineString: "0,00",
    idSimulacao: 0,
    promocoes: 0,
    promocoesString: "0,00",
    reembolsoPorCigarros: 0,
    reembolsoPorCigarrosString: "0,00",
    retornoPorParceiros: 0,
    retornoPorParceirosString: "0,00",
    soma: 0,
    somaString: "0,00",
    vendasNoCartao: 0,
    vendasNoCartaoString: "0,00",
    bonificacao100: 0,
    bonificacao100String: "0,00",
    bonificacao130: 0,
    bonificacao130String: "0,00",
    pacotesEquivBonificacao100: 0,
    pacotesEquivBonificacao100String: "",
    pacotesEquivBonificacao130String: "",
    bonificacaoSelecionada: 0,
    bonificacaoSelecionadaString: "0,00",
    conectaPayAtiva: false,
    mostrarBannerBeneficio: false
  },
  loading: false,
  error: false,
  success: false,
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulatorTypes.SIMULATOR_STATE_INITIAL:
      return INITIAL_STATE;
    case SimulatorTypes.SIMULATOR_STATE:
      return {
        ...state,
        current_state: {
          ...state.current_state,
          [action.payload.key]: action.payload.value,
        },
      };
    case SimulatorTypes.SIMULATOR_REQUEST:
      return { ...state, loading: true };
    case SimulatorTypes.SIMULATOR_SUCCESS:
      return {
        ...state,
        current_state: {
          ...state.current_state,
          idSimulacao: action.payload.data.idSimulacao,
        },
        data: action.payload.data,
        loading: false,
        success: action.payload.data.success,
      };
    case SimulatorTypes.SIMULATOR_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    default:
      return state;
  }
};

export default reducer;
