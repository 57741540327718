import React from 'react';

const IconMedal = (props) => {
    return (
        <svg
            className={props.className}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30.112 16.19">
            <path
                d="M311.245,558.828a28.864,28.864,0,0,0-4.554.5c-2.152.416-4.983,1.271-6.5,2.995.369-4.995-3.144-9.6-3.822-10.258L296.3,552l-.064.061c-.678.654-4.191,5.263-3.822,10.258-1.518-1.724-4.349-2.579-6.5-2.995a28.864,28.864,0,0,0-4.554-.5h-.115l.025.108c1.478,6.359,6.292,9.255,10.139,9.255h.339l-3.564-1.98,4.722,1.7-6.874-5.526,7.766,5.295-2.336-4.4,3.365,4.343-1.081-5.239,2.1,5.234.458-10.191.458,10.191,2.1-5.234-1.082,5.239,3.365-4.343-2.336,4.4,7.766-5.3-6.874,5.526,4.722-1.7-3.564,1.98h.339c3.847,0,8.661-2.9,10.14-9.255l.025-.108Z"
                transform="translate(-281.248 -552)"
                fill={props.fill}
                stroke='none' />
        </svg>

    );
};

export default IconMedal;