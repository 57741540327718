import React from 'react';

const IconShopping = (props) => {
    return (
        <svg
            className={props.className}
            height={props.height}
            width={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30">
            <g id="Grupo_1137">
                <path
                    stroke='none'
                    fill={props.fill}
                    d="M29.756,7.394h0a1.2,1.2,0,0,0-.922-.444H7.888L6.86,1.829A1.25,1.25,0,0,0,5.9.856L1.434.027a1.17,1.17,0,0,0-.888.19A1.2,1.2,0,0,0,.035,1a1.226,1.226,0,0,0,.96,1.417l3.662.668,3.265,16.7a1.2,1.2,0,0,0,1.163.968H26.2a1.21,1.21,0,0,0,1.163-.93l2.6-11.4A1.078,1.078,0,0,0,29.756,7.394Zm-2.711,2.4-2.03,8.246H10.3L8.649,9.792Z"
                    transform="translate(-0.014 -0.006)"
                />
                <path
                    stroke='none'
                    fill={props.fill}
                    d="M167.806,527.4a3.849,3.849,0,1,0,3.806,3.848A3.832,3.832,0,0,0,167.806,527.4Zm1.4,3.848a1.4,1.4,0,1,1-1.4-1.421A1.415,1.415,0,0,1,169.21,531.248Z"
                    transform="translate(-157.065 -505.097)"
                />
                <path
                    stroke='none'
                    fill={props.fill}
                    d="M463.406,527.4a3.849,3.849,0,1,0,3.806,3.848A3.832,3.832,0,0,0,463.406,527.4Zm1.4,3.848a1.4,1.4,0,1,1-1.4-1.421A1.415,1.415,0,0,1,464.81,531.248Z"
                    transform="translate(-440.164 -505.097)"
                />
            </g>
        </svg>

    );
};

export default IconShopping;