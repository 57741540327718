import React, { cloneElement, useState } from 'react'
import { Tab } from './tab'

import './src/tabs.min.css'
const Tabs = (props) => {

    const [contentIndex, setContentIndex] = useState({
        currentTab: props.defaultActiveIndex || 0,
        nextTab: 1
    });

    const { children } = props;

    const handleTabClick = (tabIndex) => {
        if (tabIndex !== contentIndex.currentTab) {
            setContentIndex({
                currentTab: tabIndex,
                nextTab: tabIndex < children.length - 1 ? tabIndex + 1 : 0
            });
        }
    }

    const cloneTabElement = (Tab, index) => {
        return (
            cloneElement(Tab, {
                key: `customTab-${index}`,
                onClick: (e) => {
                    handleTabClick(index);
                },
                tabIndex: index,
                isActive: index === contentIndex.currentTab,
            })
        );
    }

    const renderChildrenTabs = () => {

        if (!Array.isArray(children)) {
            return cloneTabElement(children);
        }
        return children.map(cloneTabElement);
    }

    let hasButton
    const renderActiveTabContent = () => {
        if (children[contentIndex.currentTab]) {
            hasButton = children[contentIndex.currentTab].props.nextButton
            return children[contentIndex.currentTab].props.children
        }
        hasButton = children.props.children.nextButton
        return children.props.children;
    }
    const { className } = props;
    const { label } = children[contentIndex.nextTab].props

    return (
        <section className={`tabs ${className}`}>
            <ul className={'tabs-list'}>
                {renderChildrenTabs()}
            </ul>
            <hr />
            <div className={'tab-content'}>
                {renderActiveTabContent()}
                {hasButton && (
                    <div className='bx-button'>
                        <a className='btn btn-main ic-go' onClick={(e) => {
                            handleTabClick(contentIndex.nextTab)
                        }}>{label}</a>
                    </div>
                )}
            </div>
        </section>
    );
};
export default Tabs;