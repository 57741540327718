import { DefaultContainer } from '@/components/containers'
import { Performance } from '@/components/performance'
import { OverviewPoints } from 'components/extractPoints'
import { IconArrowToRight, IconArrowToLeft } from '@/components/icons'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { bindActionCreators } from 'redux'
import * as participantSimulatorActions from 'store/participant/simulator/actions'
import './src/featured.min.css'

const Featured = ({ getParticipantSimulator, simulator, bannerData }) => {

  useEffect(() => {
    getParticipantSimulator()
    // eslint-disable-next-line
  }, [])

  const { pontosConectaPay, pontosConectaPayStr, pontosPromocoes, pontosPromocoesStr, pontosTotais, pontosTotaisStr, pacotesTotais, pacotesTotaisStr, totalBonificacao, totalBonificacaoStr, valorMedioPacote, valorMedioPacoteStr, uf } = simulator

  return (
    <section className={`featured ${!!bannerData.length ? 'actived-banner' : ''}`}>
      <DefaultContainer>
        <Tabs className='bx-tabs'>
          <div className='tab-title'>
            <TabList>
              <Tab>
                <IconArrowToLeft width='20px' height='20px' className='ic ic-arrow-left' fill='#ffffff' />
                <strong>Ganhe produtos com suas compras</strong>
              </Tab>
              <Tab>
                <strong>Quanto já ganhou com o Conecta Prime</strong>
                <IconArrowToRight width='20px' height='20px' className='ic ic-arrow-right' fill='#ffffff' />
              </Tab>
            </TabList>
          </div>
          <div className='tab-content'>
            <TabPanel>
              <Performance />
            </TabPanel>
            <TabPanel>
              <OverviewPoints
                // valueConectaPay={valorConectaPay}
                // valueConectaPayStr={valorConectaPayStr}
                conectaPayPoints={pontosConectaPay}
                conectaPayPointsStr={pontosConectaPayStr}
                promotionPoints={pontosPromocoes}
                promotionPointsStr={pontosPromocoesStr}
                pointsTotal={pontosTotais}
                pointsTotalStr={pontosTotaisStr}
                totalPackages={!pacotesTotais ? 0 : pacotesTotais}
                totalPackagesStr={pacotesTotaisStr}
                totalBonificacao={totalBonificacao}
                totalBonificacaoStr={totalBonificacaoStr}
                valorMedioPacote={valorMedioPacote}
                valorMedioPacoteStr={valorMedioPacoteStr}
                uf={uf} />
            </TabPanel>
          </div>
        </Tabs>
      </DefaultContainer>
    </section>
  )
}

const mapStateToProps = state => ({
  simulator: state.participantSimulator.data,
  bannerData: state.banner.data,
})

const mapDispatchToProps = dispatch => bindActionCreators(participantSimulatorActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Featured)
