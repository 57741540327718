import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import Modal from '@/components/modal/modal';
import { IconAlert } from '@/components/icons';
import { SectionTitle } from '@/components/sectionTitle';
import { IconArrowToLeft } from '@/components/icons';
import './src/points-transfer.min.css';
import {removeArrayItem} from '../../../common/Utils';
import * as MigrationOfPointsActions from '../../../store/migrationOfPoints/actions';

const PointsTransfer = ({ migrationState, loading, error, success, errorList, migrationOfPoints, myData, migrationPointsSubsidiaryRequest, subsidiary, migrationPointsRequest }) => {
    const [transferSelected, setTransferSelected] = useState([]);
    const [totalTransfer, setTotalTransfer] = useState(0);
    const [approvalDeadline, setApprovalDeadline] = useState('10');
    const [showConfirmation, setshowConfirmation] = useState('close');
    const [showErrorLabel, setShowErrorLabel] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const propsModal = {
        setModalState: setshowConfirmation,
        modalState: showConfirmation,
    };

    const { empresa } = myData;

    const {
        razaoSocial,
    } = empresa;

    useEffect(() =>{
        migrationPointsSubsidiaryRequest();
    }, []);

    useEffect(() =>{
        if(!loading && !success && error)
            setShowErrorLabel(true);
        else if(!loading && success && migrationOfPoints && !error){
            migrationState("", "", {success: false, migrationOfPoints: false, error: false, errorList: []});
            setRedirect(true);
        }
        else
            setShowErrorLabel(false);

    }, [loading, error, success, migrationOfPoints]);

    const handleClick = event => {
        let selectedPoints = parseFloat(subsidiary[event.target.id].saldo.balance);

        if (event.target.checked) {
            let newArray = [...transferSelected];

            newArray.push(event.target.id);

            setTransferSelected(newArray);

            setTotalTransfer(totalTransfer => totalTransfer + selectedPoints);
        } else {
            let newArray = removeArrayItem([...transferSelected], event.target.id);

            if(newArray)
                setTransferSelected(newArray);

            setTotalTransfer(totalTransfer => totalTransfer - selectedPoints);
        }
    }

    const handleCancelCreditPointsRole = event => {
        event.preventDefault();
        setshowConfirmation('close');
    }

    const handleSaveCreditPointsRole = event => {
        event.preventDefault();

        let requestBody = [];

        transferSelected.forEach(item =>{
            requestBody.push({
                idParticipanteFilial: subsidiary[item].id,
                pontos: subsidiary[item].saldo.balance
            });
        });

        migrationPointsRequest(requestBody);

        setshowConfirmation('close');
    }

    if(redirect)
        return <Redirect to="/minha-rede-de-varejos" />

    return (
        <section className='points-transfer'>
            <SectionTitle title='Solicitação de transferência de pontos' >
                <Link to='/minha-rede-de-varejos' className='btn btn-link'><IconArrowToLeft fill='#8f23d6' className='ic ic-arrow-to-left ic-left' />Voltar</Link>
            </SectionTitle>
            <div className='bx bx--transfer'>
                <p>Selecione abaixo os estabelecimentos dos quais deseja transferir pontos <strong>junho de 2020</strong></p>
            </div>
            {subsidiary.length > 0 ? (
                <div className='bx-retails-list'>
                    <ul>
                        {subsidiary.map((retail, i) => {
                            return (
                                <li key={`retail-item-${i}`} className='retail-item fm-field-checkbox'>
                                    <input
                                        type="checkbox"
                                        id={i}
                                        name={retail.razaoSocial}
                                        onChange={handleClick}
                                        disabled={parseFloat(retail.saldo.balance) === 0 ? true : false}
                                    />
                                    <label htmlFor={i}>
                                        {retail.razaoSocial}
                                        <strong>{retail.saldo.balance}</strong>
                                    </label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            ) : (
                    <div className='bx'>
                        <h3>Não temos registros de varejos associados à sua matriz.</h3>
                    </div>
                )}
            <div className='bx bx-finalize'>
                <div className='confirmation'>
                    <h3>Deseja transferir <strong>{totalTransfer}</strong> pontos para {razaoSocial}?</h3>
                    <div className='bx-button'>
                        <button disabled={totalTransfer === 0 || loading} onClick={() => setshowConfirmation('open')} className='btn btn-main ic-go'>Transferir</button>
                    </div>
                </div>
                {
                    showErrorLabel ?
                    errorList.map((er, i) =>
                        <div key={i} className="bx-message bx-message--error">
                            <strong>{er}</strong>
                        </div>
                    ) : null
                }
                <div className=' bx-alert'>
                    <p><IconAlert className='ic ic-alert' />A operação só será concluída caso os varejos tenham saldo suficiente no momento da aprovação do pedido. </p>
                </div>
                <div className=' bx-alert'>
                    <p><IconAlert className='ic ic-alert' />O status da solicitação será atualizado em até {approvalDeadline} dias úteis. </p>
                </div>
            </div>
            <Modal {...propsModal}>
                <SectionTitle title='Transferência<br /> de pontos' />
                <p>
                    Você solicitou a transferência de <strong>{totalTransfer}</strong> pontos dos varejos associados para a matriz <strong>{razaoSocial}</strong>.
                    Após a aprovação do pedido, a transferência poderá ser visualizada no Extrato de Pontos. Deseja confirmar?
                    </p>
                <div className="bx-button">
                    <button onClick={handleCancelCreditPointsRole} className='btn btn-inverted ic-go'>cancelar</button>
                    <button onClick={handleSaveCreditPointsRole} className='btn btn-main ic-go' disabled={loading}>confirmar</button>
                </div>
            </Modal>
        </section>
    )
}

const mapStateToProps = state => {
    return{
        myData: state.myData.data,
        subsidiary: state.migrationOfPoints.subsidiary,
        loading: state.migrationOfPoints.loading,
        error: state.migrationOfPoints.error,
        success: state.migrationOfPoints.success,
        errorList: state.migrationOfPoints.errorList,
        migrationOfPoints: state.migrationOfPoints.migrationOfPoints
    };
};

const mapDispatchToProps = dispatch =>{
    return{
        migrationState: (node, key, value) =>{
            dispatch(MigrationOfPointsActions.migrationState(node, key, value));
        },
        migrationPointsSubsidiaryRequest: ()=>{
            dispatch(MigrationOfPointsActions.migrationPointsSubsidiaryRequest());
        },
        migrationPointsRequest: (data) =>{
            dispatch(MigrationOfPointsActions.migrationPointsRequest(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsTransfer);