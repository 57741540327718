import React, { useState } from "react";
import { Button } from "../buttons";
import { ModalLayout } from "@/deliverySystem/components/modais";
import BgSuccess from "../../assets/images/bg-success.png";
import { formatToCellphone } from "@/deliverySystem/common/globalFunctions";
import { slugfy } from "@/deliverySystem/common/globalFunctions";
import { DownloadPdf } from "@/deliverySystem/components/preview";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./src/preview.min.css";

const Preview = ({ CategoryList }) => {
  const [modalState, setModalState] = useState("close");
  const [formatedCellphone, setFormatedCellphone] = useState();
  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    closeButton: true,
  };

  const hendleClick = () => {
    setModalState("open");
    setFormatedCellphone(formatToCellphone(CategoryList.whatsApp));
  };

  return (
    <div className="bx bx-preview">
      <Button
        btnClass="default btn-save"
        id="btnSave"
        labelButton="salvar"
        callBack={hendleClick}
        closeButton={true}
      />
      <div id="carte-canvas">
        <div className="carte">
          <div className="head"></div>
          <div className="content">
            <h2
              className={`title ${
                CategoryList.logoUrl !== "" ? "with-logo" : ""
              }`}
            >
              {CategoryList.logoUrl !== "" ? (
                <img id="image-logo" src={CategoryList.logoUrl} alt="" />
              ) : (
                ""
              )}
              <span>{CategoryList.nomeRevenda}</span>
            </h2>
            <div className="wrap-table">
              {(CategoryList.categorias || []).map((item, i) => {
                return (
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    className="table-preview"
                    key={i}
                  >
                    {item.produtos.length > 0 &&
                      item.produtos.findIndex(
                        (produto) => produto.incluidoCardapio == true
                      ) >= 0 && (
                        <thead>
                          <tr>
                            <th>
                              <div className="categoria">
                                <span className={`logo ${item.nome}`}></span>
                                {item.nome}
                              </div>
                            </th>
                            <th>Preços</th>
                          </tr>
                        </thead>
                      )}
                    <tbody key={`category-list-${item.id}`}>
                      {(item.produtos || []).map((p) => {
                        return (
                          p.incluidoCardapio && (
                            <tr key={`product-list-${p.id}`}>
                              <td>{p.nome}</td>
                              <td>R$ {p.valor}</td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                );
              })}
            </div>
            <div className="footer">
              <h4 className="phone">
                <span>
                  {formatToCellphone(CategoryList.whatsApp)}
                  <i className="icon icon-whatsapp" />
                </span>
              </h4>
              <p className="address">{CategoryList.endereco}</p>
            </div>
          </div>
        </div>
      </div>

      <ModalLayout customClass="success-modal" {...propsModal}>
        <section className="ct-banner banner-success">
          <div
            className="image-banner"
            style={{
              backgroundImage: `url(${BgSuccess})`,
            }}
          ></div>
          <div className="text-banner">
            <h2>PROJETO SALVO!</h2>
            <p>
              Parabéns, seu projeto foi criado e salvo. Agora você pode editar e
              baixar sempre que precisar.
            </p>
            <div className="bx-buttons">
              <PDFDownloadLink
                className="btn btn--default btn--download"
                document={
                  <DownloadPdf
                    CategoryList={CategoryList}
                    formatedCellphone={formatedCellphone}
                  />
                }
                fileName={`cardapio-${slugfy(CategoryList.nomeRevenda)}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Aguarde..." : "DOWNLOAD"
                }
              </PDFDownloadLink>

              <a className="btn--link" href="/seu-delivery">
                VER MEUS PROJETOS
              </a>
            </div>
          </div>
        </section>
      </ModalLayout>
    </div>
  );
};

export default Preview;
