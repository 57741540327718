import React, { useState } from "react";
import { connect } from "react-redux";
import * as DeliverySystemActions from "store/deliverySystem/actions";
import "./src/uploaderFile.min.css";

const UploaderFile = ({ ...props }) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const formData = new FormData();

  const changeHandler = (event) => {
    if (event?.target?.files?.length == 0) {
      setIsFilePicked(false);
      setSelectedFile([]);
    } else {
      setIsFilePicked(true);
      setSelectedFile(event.target.files[0]);
      props.validationFileSize(event.target.files[0].size)

      returnPreviewImgSrc(event.target.files[0]);

      formData.append('urlLogo', event.target.files[0]);
      props.setInputValue({...props.inputValue, formData});
    }
  };

  const returnPreviewImgSrc = (eventTargetFile) => {
    const reader = new FileReader();
    reader.onload = function() {
      var output = document.getElementById('preview');
      output.src = reader.result;
     }

     reader.readAsDataURL(eventTargetFile);
  };

  const returnFormatedFileSize = (number) => {
    if (number < 1024) {
      return number + " bytes";
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + " KB";
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + " MB";
    }
  };
  
  return (
    <div className="fileupload">
      <div className="fileupload-buttons">
        <label className={`btn-upload ${isFilePicked ? 'success' : ''}`} htmlFor="file">
          {selectedFile?.name ? (
            <span>
              <i className='icon icon-icon_check' />
              {selectedFile?.name}
              <i className='icon icon-icon_edit' />
            </span>
          ) : (
            <span>
              <i className="icon icon-icon_add" />
              Logo do estabelecimento
            </span>
          )}
        </label>
        <input
          type="file"
          name="file"
          id="file"
          accept=".jpg, .jpeg"
          onChange={changeHandler}
        />
      </div>

      {isFilePicked && selectedFile != undefined && (
        <div className="fileupload-content">
          <img
            className="preview"
            id="preview"
            alt={`${selectedFile?.name}`}
          />
        </div>
      )}
      {isFilePicked && !props.isValidFileSize && (<span className='error'>*Sua imagem possui {returnFormatedFileSize(selectedFile?.size)} e ultrapassa o limite máximo de 2 MB</span>)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewProjectRequest: (data, formData) => {
      dispatch(DeliverySystemActions.createNewProjectRequest(data, formData));
    },
  };
};

export default connect(null, mapDispatchToProps)(UploaderFile);
