import { call, put } from 'redux-saga/effects';
import api from '@/common/api';
import { myDataFailure, myDataSuccess, updateMyDataSuccess } from './actions';

export function* getMyData() {
  try {
    const {data} = yield call(
      api.get,
      '/meus-dados',
      );

    yield put(myDataSuccess(data));
  } catch (error) {
    yield put(myDataFailure());
  }
}

export function* putMyData(action) {
  try {
    yield call(api.patch,'/meus-dados',action.payload.data);

    yield put(updateMyDataSuccess());
  } catch (error) {
    yield put(myDataFailure());
  }
}

