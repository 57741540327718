import React from "react";
import "./src/titleSection.min.css";

const SectionTitle = (props) => (
  <div className={`section-title ${props.customClass ? props.customClass : ''}`}>

    <h2 dangerouslySetInnerHTML={{
      __html: props.title
    }} />
    {props.children}
  </div>
);

export default SectionTitle;
