import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';
import { getMyDataRequest } from '../my-data/actions';
import { acceptanceTermSuccess, acceptanceTermFailure } from './actions';

export function* acceptanceTerm(value) {
    try {
        const { data } = yield call(api.post, `regulamento/${value.payload.idCampanha}/aceitar`);

        yield put(acceptanceTermSuccess(data));
        yield put(getMyDataRequest());
    } catch (error) {
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(acceptanceTermFailure());
    }
}


