import React, { Fragment } from 'react';
import { DefaultContainer } from '@/components/containers';

const contact = () => {
    return (
        <Fragment>
            <section className="wr wr-contact">
                <DefaultContainer>
                    <a href='/ajuda' className='redirect-contact'>
                        Precisa de ajuda?
                    </a>
                    <ul className="communication-channels">
                        <li className="phone">
                            <span>Por telefone</span>
                            {/* <Link href='#'> */}
                            <strong>0800 723 22 21</strong>
                            {/* </Link> */}
                        </li>
                        <li className="email">
                            <span>ou via e-mail</span>
                            {/* <Link href='#'> */}
                            <a href='/fale-conosco' className='btn-link'>
                                consultoratendimento@interactioncenter.com.br
                            </a>
                            {/* </Link> */}
                        </li>
                    </ul>
                    <p> <small>Atendimento de segunda à sexta das 8h às 18h, exceto feriados</small></p>
                </DefaultContainer>
            </section>
        </Fragment>
    );
}
export default contact;