import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import { Wrapper } from "./callback/style";
import Spinner from "../../components/spinner";
import * as AuthActions from 'store/auth/actions';
import {AppMessages} from '../../common/AppMessages';
import { store } from "store/store";

const SignOut = () =>{
    const dispatch = useDispatch();

    useEffect(() =>{
        store.dispatch(AuthActions.signOut(AppMessages.END_OF_SESSION));
    }, []);

    return(
        <Wrapper>
            <Spinner />
        </Wrapper>
    );
}

export default SignOut;