/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IconChatComponent = () => (
  <svg width={30} height={30} viewBox="0 0 30 30">
    <defs>
      <style>{".a{fill:#fff;fill-rule:evenodd;}"}</style>
    </defs>
    <path
      className="a"
      d="M26.951,23.091H14.473L6.337,29.723a1.28,1.28,0,0,1-1.4.122A1.252,1.252,0,0,1,4.3,28.59l.741-5.5H3.055A3.059,3.059,0,0,1,0,20.042V3.056A3.06,3.06,0,0,1,3.055,0h23.9A3.055,3.055,0,0,1,30,3.056V20.042A3.053,3.053,0,0,1,26.951,23.091ZM27.5,3.056a.549.549,0,0,0-.551-.551H3.055V2.5a.549.549,0,0,0-.551.551V20.036a.549.549,0,0,0,.551.551H6.484A1.249,1.249,0,0,1,7.72,22L7.212,25.78l6.031-4.917a1.235,1.235,0,0,1,.79-.282H26.951a.549.549,0,0,0,.551-.551Zm-5.939,13H8.437a1.249,1.249,0,0,1,0-2.5H21.563a1.249,1.249,0,0,1,0,2.5Zm0-6.521H8.437a1.249,1.249,0,0,1,0-2.5H21.563a1.249,1.249,0,0,1,0,2.5Z"
    />
  </svg>
);

export default IconChatComponent;
