import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { CategoryListing } from '@/deliverySystem/components/categoryListing'
import { Preview } from '@/deliverySystem/components/preview'
import * as DeliverySystemActions from "store/deliverySystem/actions"
import AuthHelper from "@/deliverySystem/common/AlertHelper";

const MenuMaker = ({ loadCategoriesAndProductsRequest, loadCategoriesAndProductsResetFlag, categoriesProductsList, success, auth, ...props }) => {
    const [width, setWidth] = useState(0)
    const [toggle, setStatus] = useState(0)
    const [categoriesAndProducts, setCategoriesAndProducts] = useState()
    const [updateProductList, setUpdateProductList] = useState({status: 0});

    useEffect(() => {
        if (auth.userInfo.readOnly === true) {
            const redirectToHome = true
            AuthHelper.alertReadOnly(redirectToHome);
            return;
          }

        if (props.location?.state?.cardapioId === undefined) window.location.replace('/seu-delivery');

        loadCategoriesAndProductsRequest(props.location?.state?.cardapioId);
        setWidth(window.innerWidth);
    }, [])

    useEffect(() => {
        if (success || updateProductList.status == 1) {
            setCategoriesAndProducts(categoriesProductsList);
            loadCategoriesAndProductsResetFlag();
            setUpdateProductList({status: 2});
        }
    }, [success, updateProductList])

    useEffect(() => {
        setStatus(width > 1024)
    }, [width])

    return (
        <div className={`menu-maker toggle-${toggle ? 'open' : 'closed'}`}>
            <aside>
                {toggle && <CategoryListing categoriesProductsList={categoriesAndProducts} setCategoriesAndProducts={setCategoriesAndProducts} setUpdateProductList={setUpdateProductList} updateProductList={updateProductList} />}
                <a className='btn-toggle-menu-maker' onClick={() => setStatus(toggle => !toggle)}><i className='icon icon-icon_left'></i></a>
            </aside>
            <main>
                <Preview CategoryList={categoriesProductsList}/>
            </main>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCategoriesAndProductsRequest: (cardapioId) => {
            dispatch(DeliverySystemActions.loadCategoriesAndProductsRequest(cardapioId))
        },
        loadCategoriesAndProductsResetFlag: () => {
            dispatch(DeliverySystemActions.loadCategoriesAndProductsResetFlag())
        },
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesProductsList: state.deliverySystem.categoriesProductsList.data,
        success: state.deliverySystem.categoriesProductsList.success,
        auth: state.auth,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuMaker)