import Redirect from "@/components/redirect/"
import { AccumulatePoints } from '@/pages/about'
import Callback from '@/pages/auth/callback/'
import SingleSignOn from '../pages/auth/SingleSignOn'
import FirstAccess from '@/pages/firstAccess/'
import Help from '@/pages/help/'
import MyAccount from "@/pages/myAccount"
import { CampaignDetails, MyCampaigns } from '@/pages/myCampaigns'
import { BenefitsDetails, MyBenefits } from '@/pages/myBenefits'
import { NotFaund } from '@/pages/notFound'
import { InMaintenance } from '@/pages/inMaintenance'
import PrivateRoute from '../routes/privateRoute'
import { Simulator, Identifier } from 'pages/simulator'
import QueryBankSlip from '@/pages/queryBankSlip'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SignOut from "@/pages/auth/SignOut"
import { Documentation } from '@/pages/documentation'
import { QuizPrime } from '@/pages/QuizPrime'
import { DeliverySystem } from '@/deliverySystem/pages/home'
import { MenuMaker } from '@/deliverySystem/pages/menuMaker'

// const disableSimulator = process.env.REACT_APP_DISABLED_SIMULATOR;
const documentationPage = process.env.REACT_APP_ADD_DOCUMENTATION_PAGE

const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute path="/" exact component={Redirect} />
      <PrivateRoute path="/home" exact component={Redirect} isPrivate />
      <PrivateRoute path="/como-funciona" exact component={AccumulatePoints} isPrivate />

      {/* SEU DELIVERY */}
      <PrivateRoute path="/seu-delivery" exact component={DeliverySystem} isPrivate />
      <PrivateRoute path="/seu-delivery/criar-cardapio" exact component={MenuMaker} isPrivate />

      {/* CAMPANHAS */}
      <PrivateRoute path="/campanhas" exact component={MyCampaigns} isPrivate />
      <PrivateRoute path="/campanha/:id/:slug" exact component={CampaignDetails} isPrivate />

      {/* BENEFICIOS PRIME */}
      <PrivateRoute path="/beneficios-prime" exact component={MyBenefits} isPrivate />
      <PrivateRoute path="/beneficios-prime/:id/:slug" exact component={BenefitsDetails} isPrivate />

      {/* NAVEGAÇÃO INTERNA AJUDA */}
      <PrivateRoute path="/ajuda" exact component={Help} isPrivate />
      <PrivateRoute path="/faq" exact component={Help} isPrivate />
      <PrivateRoute path="/fale-conosco" exact component={Help} isPrivate />
      <PrivateRoute path="/termos" exact component={Help} isPrivate />
      <PrivateRoute path="/regulamento" exact component={Help} isPrivate />

      {/* NAVEGAÇÃO INTERNA MINHA CONTA */}
      <PrivateRoute path="/meus-dados" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/meus-resgates" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/conecta-pay" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/conecta-pay-sucesso" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/extrato" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/bonificacao" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/minha-rede-de-varejos" exact component={MyAccount} />
      <PrivateRoute path="/associar-novo-varejo" exact component={MyAccount} />
      <PrivateRoute path="/meus-pagamentos" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/alterar-pagamento" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/transferir-pontos" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/bank-slip" exact component={MyAccount} isPrivate />
      <PrivateRoute path="/cancel" exact component={MyAccount} isPrivate />
      {/* <PrivateRoute path="/historico-de-transferencias" exact component={MyAccount} isPrivate /> */}

      {/* SIMULADOR DE PONTOS */}
      <Route path="/simulador" component={Simulator} />
      <Route path="/simulador-identificacao" component={Identifier} />

      {/* CONSULTA DE BOLETOS */}
      <Route path="/consulta-boleto" exact component={QueryBankSlip} />
      <Route path="/boletos" exact component={QueryBankSlip} />

      <Route path="/primeiro-acesso" exact component={FirstAccess} />
      <Route path="/logout" exact component={SignOut} />
      <Route path="/auth/callback" exact component={Callback} />
      <Route path="/primeiro-acesso" exact component={FirstAccess} isPrivate />

      {/*Boleto Bancario*/}
      {/* <PrivateRoute path="/bank-slip" exact component={BilletIC} isPrivate /> */}

      {/* EXTERNAL LINK */}
      <Route exact path="/extrato-detalhado" render={() => (window.location.href = `${process.env.REACT_APP_URL_EXTRACT_WEBPREMIOS}`)} />

      <Route path="/Account/SingleSignOn" exact component={SingleSignOn} />

      {/* PESQUISA PRIME */}
      <Route path="/pesquisa-prime" component={QuizPrime} />

      {/* PÁGINA DE DOCUMENTAÇÃO - SOMENTE EM AMBIENTE DE DESENVOLVIMENTO E UAT */}
      {
        documentationPage && (
          <Route path="/docs-example" exact component={Documentation} />
        )
      }
      <Route path='/manutencao' exact={true} component={InMaintenance} />

      <Route path='*' exact={true} component={NotFaund} />
    </Switch>
  </Router>
)

export default Routes
