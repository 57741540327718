import React from 'react';
import * as yup from 'yup';

const ValidationRules = () => {
    return yup.object().shape({
        fullName: yup
            .string('Insira um nome válido!')
            .required('O Nome é obrigatório!'),
        name: yup
            .string('Insira um nome válido!')
            .required('O Nome é obrigatório!'),
        cpf: yup
            .string('Insira um cpf válido!')
            .required('CPF é obrigatório')
            .matches(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g, 'CPF inválido'),
        CpfNotRequired: yup
            .string('Insira um cpf válido!')
            .matches(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g, 'CPF inválido'),
        // codSap: yup
        //     .number('Informe somente números')
        //     .positive('Informe somente números')
        //     .truncate('Informe somente números')
        //     .integer('Informe somente números'),
        //     .string('Informe somente números'),
        birth: yup.string('Uma data de nascimento Válida!').required('Digite sua data de nascimento').default(function () {
            return new Date();
        }),
        cell: yup
            .string('Celular é obrigatório')
            .required('Celular é obrigatório'),
        whatsApp: yup
            .string('Digite um telefone válido!'),
        phone: yup
            .string('Digite um telefone válido!')
            .required('Telefone é obrigatório'),
        email: yup
            .string('Digite um telefone válido!')
            .email('Digite um e-mail válido!')
            .required('E-mail é obrigatório'),
        message: yup
            .string('Escreva sua mensagem!')
            .min(50, 'Sua mensagen deve conter no mínimo 50 caracteres!')
            .max(2000, 'Sua mensagen deve conter o máximo de 2000 caracteres!')
            .required('A Mensagem é obrigatória!'),
        // communication: yup.mixed.oneOf(communication, 'teste'),
        subject: yup
            .string('Selecione um assunto')
            .required('Selecione o assunto da sua mensagem!'),
        //genre: yup.string().oneOf([yup.ref('genre'), null], 'Selecione uma das opção'),

        cellPreferred: yup
            .string('Celular é obrigatório')
            .required('Celular é obrigatório'),
        emailPreferred: yup
            .string('Digite um telefone válido!')
            .email('Digite um e-mail válido!')
            .required('E-mail é obrigatório'),
    })
};

export default ValidationRules;