import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox } from "@/components/formFields";
import { SectionTitle } from "@/components/sectionTitle";
import * as PaymentActions from "store/payments/actions";
import { IconAlert } from "@/components/icons";
import { ModalLayout } from "@/deliverySystem/components/modais";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import "./src/payment-debtor.scss";

const PaymentDebtor = ({ ...props }) => {
  const [modalState, setModalState] = useState("close");
  const [overduePayments, setOverduePayments] = useState([]);
  const [targetPayments, setTargetPayments] = useState({
    total: 0,
    idTargetPayments: [],
  });

  const Currency = ({ value }) => (
    <NumberFormat
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      value={value}
    />
  );

  useEffect(() => {
    if (props?.paymentHistory?.length > 0) {
      setOverduePayments(
        // Filtro de boletos vencidos do mais antigo para mais novo.
        props.paymentHistory.filter((filter) => filter.status == 3).reverse()
      );
    }
  }, [props?.paymentHistory]);

  useEffect(() => {
    if (props.sporadicBankSlip) {
      if (props.sporadicBankSlip.error) {
        Swal.fire({
          icon: "error",
          title: "Ops!",
          text: `${props.sporadicBankSlip.errorList[0]}`,
        });

        setModalState("close");
        props.sporadicBankSlipResetFlag();
        props.participantPaymentHistoryRequest(0);
      } else if (props.sporadicBankSlip.success) {
        Swal.fire({
          icon: "success",
          title: "Boleto avulso gerado com sucesso!",
          text: "Lembrando que será emitido em até 2 dias úteis.",
        });

        setModalState("close");
        props.sporadicBankSlipResetFlag();
        props.participantPaymentHistoryRequest(0);
      }
    }
  }, [props.sporadicBankSlip]);

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    closeButton: true,
    overduePayments: overduePayments,
    customClass: "overdue-payments-modal",
  };

  const checkBoxValidation = (target, bankSlipId, bankSlipValue) => {
    if (target) {
      let targetName = document.getElementsByName(target.name);

      for (let i = 0; i < targetName.length; i++) {
        if (targetName.length === 1) {
          // If has one bankslip
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (
          targetName[i].checked &&
          !!targetName[i + 1] &&
          !targetName[i + 1].checked
        ) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (
          !targetName[i].checked &&
          !!targetName[i - 1] &&
          targetName[i - 1].checked
        ) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (targetName[i].checked && i === targetName.length) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (!targetName[i].checked && i === 0) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else {
          targetName[i].disabled = true;
          targetName[i].parentElement.classList.add("disabled");
        }
      }
    }

    // Remover ou Adicionar boletos para inclusão no boleto avulso
    if (target && bankSlipId) {
      if (target.checked) {
        setTargetPayments({
          ...targetPayments,
          total: targetPayments.total + bankSlipValue,
          ...targetPayments.idTargetPayments.push(bankSlipId),
        });
      } else {
        setTargetPayments({
          ...targetPayments,
          total: targetPayments.total - bankSlipValue,
          idTargetPayments: targetPayments.idTargetPayments.filter((item) => {
            return item !== bankSlipId;
          }),
        });
      }
    }
  };

  return (
    <section className="payment-debtor">
      <div className="box-information">
        <p className="text">
          Ops! Identificamos algumas mensalidades pendentes na sua conta Conecta
          Prime e seus pontos ficarão bloqueados até a atualização dos status de
          pagamentos.
        </p>

        <div className="inform">
          <div className="icon-box">
            <IconAlert width="30px" height="30px" fill="#ffab46" />
          </div>
          <div>
            <p className="user-points">
              <Currency value={props.userPoints} />
            </p>
            <p>pontos bloqueados</p>
          </div>
        </div>
      </div>
      <div className="box-negotiation">
        <p>
          Efetue agora o pagamento do valor total e não deixe de aproveitar seus
          benifícios Conecta Prime.
        </p>
        <div className="actions">
          <button
            className="generate-btn btn btn-main btn-new ic-go"
            onClick={() => setModalState("open")}
          >
            GERAR BOLETO
          </button>
          <div className="or-box">
            <p>Ou</p>
          </div>
          <p className="contact-box">
            Entre em contato com o Interaction Center no telefone{" "}
            <strong>0800 723 22 21</strong>.
          </p>
        </div>
      </div>

      <ModalLayout {...propsModal}>
        <SectionTitle title="Quais mensalidades deseja quitar?" />
        <p>
          <strong>
            Escolha abaixo as mensalidades vencidas que deseja a quitação.
          </strong>
        </p>
        {overduePayments.map((payment, i) => {
          return (
            <Checkbox
              key={i}
              label={`${payment.competencia} / R$ ${payment.valorString}`}
              inputProps={{
                name: `boleto`,
                id: `boleto-${i}`,
                type: "checkbox",
                onChange: (e) => {
                  checkBoxValidation(e.target, payment.id, payment.valor);
                },
              }}
            />
          );
        })}
        {targetPayments.total != 0 && (
          <p>
            <strong>
              Valor total à ser cobrado:{" "}
              {targetPayments.total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </p>
        )}
        <button
          disabled={
            props.sporadicBankSlip.loading ||
            !targetPayments.idTargetPayments.length
          }
          className="generate-btn btn btn-main btn-new ic-go"
          onClick={() => {
            props.sporadicBankSlipRequest({
              idsParticipantePagamentos: targetPayments.idTargetPayments,
            });
          }}
        >
          CONFIRMAR
        </button>
      </ModalLayout>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentHistory: state.payment.paymentHistory,
    sporadicBankSlip: state.payment.sporadicBankSlip,
    userPoints: state.balance.pontuacao,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sporadicBankSlipRequest: (data) => {
      dispatch(PaymentActions.sporadicBankSlipRequest(data));
    },
    sporadicBankSlipResetFlag: () => {
      dispatch(PaymentActions.sporadicBankSlipResetFlag());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDebtor);
