import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InputCurrency } from "@/components/formFields";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import * as SimulatorActions from "../../../store/simulator/actions";

const InternetVivo = ({
  steps,
  navigation,
  index,
  current_state,
  simulator_state,
  simulatorRequest,
  mostrarBeneficioEnergia,
}) => {
  const [valorContaTelefonia, setValorContaTelefonia] = useState(
    current_state.valorContaTelefonia
  );

  useEffect(() => {
    simulator_state("step", 7);
  }, []);

  useEffect(() => {
    //Updating state
    simulator_state("valorContaTelefonia", parseFloat(valorContaTelefonia.toString().replace(".", "").replace(",", ".").replace("R$ ", "")));
  }, [valorContaTelefonia]);

  const handleStep = () => {
    simulatorRequest(current_state);
    navigation.next();
  };

  const ValidationRules = () => {
    return yup.object().shape({
      purchase: yup
        .string("Insira uma quantidade médio de compras")
        .required(
          "A Quantidade das compras é obrigatório dar continuidade a simulação"
        ),
    });
  };

  const { register, errors, reset, getValues, triggerValidation } = useForm({
    validationSchema: ValidationRules(),
    mode: "onChange",
  });

  const sendForm = async (event) => {
    event.preventDefault();

    const isValid = await triggerValidation();

    if (isValid) {
      simulatorRequest(current_state);
      navigation.next();
    }
  };

  return (
    <section className="bx bx-section-internetVivo">
      <SectionTitle
        title={`${
          mostrarBeneficioEnergia ? "E o Prime" : "O Prime"
        } oferece planos incríveis de internet e telefonia da VIVO para os varejistas cadastrados:`}
      />
      <div className="content">
        <InputCurrency
          label="Quanto você paga por em média por 10GB de Internet:"
          error={errors.valorContaTelefonia}
          onChange={setValorContaTelefonia}
          inputProps={{
            ref: register,
            name: "valorContaEnergia",
            type: "text",
            id: "valorContaEnergia",
            value: valorContaTelefonia,
            prefix: "R$ "
          }}
        />
      </div>
      <div className="bx-buttons">
        <button
          // disabled={!advanceStep ? true : false}
          type="submit"
          onClick={() => handleStep()}
          className="btn btn-main ic-go"
        >
          Próximo
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    mostrarBeneficioEnergia: state.simulator.data.mostrarBeneficioEnergia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InternetVivo);
