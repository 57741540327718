import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import {AppMessages} from '../../common/AppMessages';

import {retailChainListSuccess, retailChainListFailure, facilityIdentificationSuccess, facilityIdentificationFailure, retailChainSolicitationSuccess, retailChainSolicitationFailure,
    retailChainReportSuccess, retailChainReportFailure} from './actions';

export function* retailChainListRequest(action){
    try{
        const {data} = yield call(api.get, action.payload.matriz ? '/participante/rede/Listar' : '/participante/rede/ListarByFilial');
        yield put(retailChainListSuccess(data));
    }catch(error){
        yield put(retailChainListFailure());
    }
}


export function* facilityIdentificationRequest(action){
    try{
        const {data} = yield call(api.get, `/participante/Pesquisar?documento=${action.payload.document}`);
        yield put(facilityIdentificationSuccess(data));
    }catch(error){
        yield put(facilityIdentificationFailure(error));
    }
}

export function* retailChainSolicitationRequest(action){
    try{
        const {data} = yield call(api.post, `/participante/rede/Solicitar`, action.payload.data);
        yield put(retailChainSolicitationSuccess(data));
    }catch(error){
        yield put(retailChainSolicitationFailure(error));
    }
}

export function* retailChainReportRequest(){
    try{
        const {data} = yield call(api.get, '/participante/Rede');

        yield put(retailChainReportSuccess(data));
    }catch(error){
        yield put(retailChainReportFailure());
    }
} 