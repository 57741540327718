import {FirstAccessTypes} from './types';

const INITIAL_STATE = {
    current_state:{
      conectaPay: {}
    },
    data: {
        idParticipante: 0,
        contatoCpfCnpj: "",
        nome: "",
        nascimento: "1900-01-01",
        genero: 0,
        telefone: "",
        canalComunicacao: 0,
        indicadoPor: "",
        username: ""
    },
    updateData: {
        success: false,
        error: false,
        errorList: [],
        message_error: ""
    },
    loading: false,
    error: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FirstAccessTypes.FIRSTACCESS_STATE:
        return {...state, [action.payload.node]: {...state[action.payload.node], [action.payload.key] : action.payload.value}}
      case FirstAccessTypes.LOAD_FIRSTACCESS_REQUEST:
        return {...state, loading: true};
      case FirstAccessTypes.LOAD_FIRSTACCESS_SUCCESS: 
       return {
            ...state,
            loading: false,
            success: true,
            data: action.payload.data.data
        }
      case FirstAccessTypes.LOAD_FIRSTACCESS_FAILURE: 
        return {...state, loading: false, error: true, success: false}
      case FirstAccessTypes.UPDATE_FIRSTACCESS_REQUEST:
        return {...state, loading: true};
      case FirstAccessTypes.UPDATE_FIRSTACCESS_SUCCESS: 
        return {
            ...state,
            loading: false,
            updateData: {
              ...state.updateData,
                success: true,
                error: false,
                errorList: [],
                message_error: "",
                data: action.payload.data.data
            },
            error: false
        }
      case FirstAccessTypes.UPDATE_FIRSTACCESS_FAILURE:
        return {
          ...state, 
          loading: false, 
          updateData: {
            ...state.updateData, 
            success: false, 
            error: true, 
            message_error: action.payload.error,
          }
      }
      case FirstAccessTypes.UPDATE_FIRSTACCESS_FLAGS: 
        return {
          ...state, 
          updateData: {
            success: false,
            error: false,
            message_error: ""
          }
      }
      default:
        return state;
    }
};

export default reducer;
