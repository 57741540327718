import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as ClubBenefitsActions from "store/clubBenefits/actions";
import Swal from "sweetalert2";
import AuthHelper from "./../../Utils/AlertHelper";
import "./src/secondaryBanner.min.css";

// banner secundario pe fixo
const SecondaryBanner = ({
  clubBenefitsLinkUrlRequest,
  clubBenefitsSucesso,
  clubBenefitsUrl,
  clubBenefitsLoading,
  clubBenefitsError,
  clubBenefitsMsgError,
  readOnly,
  inadimplente,
  inadimplenteVencimentos,
  matriz,
  cobrancaCentralizada
}) => {
  const [clubBenefitsClick, setClubBenefitsClick] = useState(false);

  useEffect(() => {
    if (
      clubBenefitsSucesso === true &&
      clubBenefitsUrl &&
      clubBenefitsClick &&
      clubBenefitsClick
    ) {
      setClubBenefitsClick(false);
      window.open(clubBenefitsUrl, "_blank");
    }
  }, [clubBenefitsSucesso, clubBenefitsUrl]);

  useEffect(() => {
    if (
      clubBenefitsSucesso === false &&
      clubBenefitsMsgError &&
      clubBenefitsMsgError.length > 0 &&
      clubBenefitsError &&
      clubBenefitsClick
    ) {
      setClubBenefitsClick(false);
      var msgError = clubBenefitsMsgError[0];
      ShowErrorUrlClubBenefitsAlert(msgError);
    }
  }, [clubBenefitsSucesso, clubBenefitsError, clubBenefitsMsgError]);

  const ShowErrorUrlClubBenefitsAlert = (msgError) => {
    Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text:
        "Ops, não foi possível acessar, atualize seus dados e tente novamente. Dados inválidos: " +
        msgError,
    });
  };

  const onClickRedirectClubBenefits = () => {
    if (clubBenefitsLoading) return;

    if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }

    if (readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }
    setClubBenefitsClick(true);
    clubBenefitsLinkUrlRequest();
  };

  return (
    <section className="secondary-banner">
      <a onClick={onClickRedirectClubBenefits}>
        <div className="banner-conecta-pay" />
      </a>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clubBenefitsLinkUrlRequest: () => {
      dispatch(ClubBenefitsActions.clubBenefitsRequest());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    clubBenefitsSucesso: state.clubBenefits.success,
    clubBenefitsUrl: state.clubBenefits?.tokenClubBenefit?.data,
    clubBenefitsLoading: state.clubBenefits.loading,
    clubBenefitsError: state.clubBenefits.error,
    clubBenefitsMsgError: state.clubBenefits.errorList,
    readOnly: state.auth.userInfo.readOnly,
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryBanner);
