import React, { useEffect, useState } from "react";
import { Modal } from "@/components/modal";
import { connect } from "react-redux";
import * as AuthActions from "store/auth/actions";

import "./src/notificationModalDebtor.min.css";

const NotificationModalDebtor = ({
  inadimplenteVencimentos,
  userPoints,
  promocaoIncentivoElegivel,
  reentryHistoric,
  reentryHistoricRequest,
}) => {
  const [showModal, setShowModal] = useState("close");
  const propsModal = {
    setModalState: setShowModal,
    modalState: showModal,
    customClass: "payment-debtor-modal",
  };

  useEffect(() => {
    if (
      !!promocaoIncentivoElegivel &&
      !reentryHistoric.success &&
      !reentryHistoric.loading
    ) {
      reentryHistoricRequest(promocaoIncentivoElegivel.id);
    }
    // eslint-disable-next-line
  }, [promocaoIncentivoElegivel, reentryHistoric.loading]);

  useEffect(() => {
    showModalF();
    // eslint-disable-next-line
  }, []);

  const showModalF = () => {
    if (inadimplenteVencimentos) setShowModal("open");
  };

  return (
    <>
      <Modal {...propsModal} title="Atenção!">
        {!!promocaoIncentivoElegivel && reentryHistoric.success && (
          <div className="reentry-reward">
            <h3> Você foi incluso em uma incrível promoção!</h3>
            <div className="reward">
              <ul>
                {!!reentryHistoric.data.descricaoPromocaoAcao.length &&
                  reentryHistoric.data.descricaoPromocaoAcao.map((param) => {
                    return <li key={param.id}>- {param.texto}</li>;
                  })}
              </ul>
              <span className="equal">=</span>
              <ul>
                {!!reentryHistoric.data.descricaoPromocaoIncentivo.length &&
                  reentryHistoric.data.descricaoPromocaoIncentivo.map(
                    (param) => {
                      return <li key={param.id}>+ {param.texto}</li>;
                    }
                  )}
              </ul>
            </div>
          </div>
        )}

        <p>
          Caro varejista, você está inadimplente há mais de 90 dias. Que tal
          regularizar suas pendências e ter acesso a todos os benefícios prime?{" "}
          {userPoints > 0 && (
            <span>
              Você possui <strong>{userPoints}</strong> pontos a serem
              resgatados após adimplência.
            </span>
          )}
        </p>
        <a className="btn" href="/meus-pagamentos">
          Ir para meus pagamentos
        </a>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    userPoints: state.balance.pontuacao,
    promocaoIncentivoElegivel: state.auth.userInfo.promocaoIncentivoElegivel,
    reentryHistoric: state.auth.reentryHistoric,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reentryHistoricRequest: (incentivoId) => {
      dispatch(AuthActions.reentryHistoricRequest(incentivoId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModalDebtor);
