import { RadioButton } from "@/components/formFields";
import { IconInfo } from "@/components/icons";
import { Tooltip } from "@/components/tooltip";
import IconArrowToLeft from "../../icons/iconArrowToLeft";
import React, { useEffect, useState } from "react";

const MetodoDePagamento = ({
  AcessStageForm,
  AcessStageCreditCard,
  dataPayment,
  firstAccessData,
  afiliadoConectaPay,
  loading,
  payments,
  paymentMethod,
  paymentTypeOld,
  clickedBtnSolicitarMaquininha,
  readOnly,
  register,
  previousStep,
  setDataPayment,
  setPaymentMethod,
  showIframeCard,
  setShowIframeCard,
  creditCardData,
}) => {
  const [optionsPayments, setOptionsPayments] = useState([]);

  useEffect(() => {
    handlePaymentOptions();
  }, [clickedBtnSolicitarMaquininha, payments]);

  const handlePaymentOptions = () => {
    if (!afiliadoConectaPay && !clickedBtnSolicitarMaquininha) {
      setOptionsPayments(payments);
    } else {
      setOptionsPayments(
        payments.filter((item) => item.codigo !== "DEBITO_RECEBIVEIS")
      );
    }
  };

  const tooltipDiscountConectaPay = () => {
    return (
      <span className="bx-info-conecta-pay">
        <Tooltip
          width="200px"
          message="Ao selecionar a opção 'Desconto via Conecta Pay' você autoriza a pagar a mensalidade através dos recebíveis da maquininha."
        >
          <IconInfo className="ic ic-info" fill="#00CAFB" />
        </Tooltip>
      </span>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: "40px" }}>
        <button
          onClick={(e) => {
            previousStep(e);
          }}
          className="btn btn-link"
        >
          <IconArrowToLeft
            fill="#8f23d6"
            className="ic ic-arrow-to-left ic-left"
          />
          Voltar
        </button>
      </div>
      <div id="paymentMethod">
        <section className="bx-payment-method">
          <h2>
            Como deseja pagar sua a mensalidade Conecta Prime?
            <span className="bx-info">
              <Tooltip
                width="250px"
                message="<strong>Mês de Entrada Gratuita:</strong> para celebrar sua entrada no Conecta Prime, oferecemos o mês em que foi realizada a adesão isento de mensalidade. Exemplo: ao ingressar no dia 20/03, a mensalidade de MARÇO não será cobrada."
              >
                <IconInfo className="ic ic-info" fill="#00CAFB" />
              </Tooltip>
            </span>
            <span className="message">
              <br />
              Para permanecer no clube de benefícios Conecta Prime, é necessário
              o pagamento da mensalidade. Apenas os varejistas adimplentes serão
              bonificados e terão direito aos demais benefícios.
            </span>
            {!firstAccessData.permiteAlterarTipoPagamento ? (
              <div>
                *Seu método de pagamento já foi selecionado pelo varejo matriz.
              </div>
            ) : (
              ""
            )}
          </h2>
          {firstAccessData.permiteAlterarTipoPagamento &&
            optionsPayments.map((payment) => {
              return (
                <RadioButton
                  key={payment.id}
                  label={payment.nome}
                  tooltip={payment.id == 2 && tooltipDiscountConectaPay()}
                  customClass={payment.id == 2 ? "recebiveis-conecta" : ""}
                  checked={
                    firstAccessData.permiteAlterarTipoPagamento
                      ? parseInt(paymentMethod) === payment.id
                      : false
                  }
                  inputProps={{
                    ref: register,
                    id: `paymentMethod-${payment.id}`,
                    name: "paymentMethod",
                    value: firstAccessData.permiteAlterarTipoPagamento
                      ? payment.id
                      : paymentTypeOld,
                    onChange: (e) => {
                      setPaymentMethod(e.target.value);
                    },
                  }}
                />
              );
            })}
          {Number(paymentMethod) == 3 &&
          firstAccessData.permiteAlterarTipoPagamento ? (
            <AcessStageForm
              dataPayment={dataPayment}
              setDataPayment={setDataPayment}
            />
          ) : null}

          {Number(paymentMethod) == 4 &&
          firstAccessData.permiteAlterarTipoPagamento ? (
            <>
              <AcessStageCreditCard
                readOnly={readOnly}
                showIframeCard={showIframeCard}
                setShowIframeCard={setShowIframeCard}
                creditCardData={creditCardData}
              />
            </>
          ) : null}

          {Number(paymentMethod) != 4 ||
          (Number(paymentMethod) == 4 && showIframeCard.status == 1) ? (
            <div className="fm-button">
              <button
                type="submit"
                className="btn btn-main ic-go"
                disabled={loading || readOnly}
              >
                {loading ? "Salvando dados" : "Confirmar informações"}
              </button>
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
};

export default MetodoDePagamento;
