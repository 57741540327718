import React from 'react'
import CurrencyInput from 'react-currency-input-field';

import './src/inputs.min.css'

// COMPONENTES DE INPUTS PADRAO (TEXT, PASSWORD, EMAIL)
const InputCurrency = ({ label, inputProps, onChange}) => {


  return (
    <div className={`field-ct filled`}>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      <CurrencyInput {...inputProps} groupSeparator="." decimalSeparator="," decimalScale={2} onValueChange={(value) => onChange(value)}/>
    </div>
  )
};

export default InputCurrency;