import { SegmentsCard } from "@/components/cards";
import { DefaultContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import React from "react";
import { connect } from "react-redux";
import { default as Carousel } from "react-slick";
import "./src/showcase.min.css";
import AuthHelper from "./../../Utils/AlertHelper";

// VITRINE DE SERVIÇOS (CARROSSEL)

const SegmentsShowcase = ({
  readOnly,
  inadimplente,
  inadimplenteVencimentos,
  matriz,
  cobrancaCentralizada,
  reativacaoAtual,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 548,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          dots: true,
          slidesToShow: 4,
        },
      },
    ],
  };

  const Services = [
    {
      id: 1,
      thumbnail: "/img/segments/pagcontas.png",
      url: `${process.env.REACT_APP_URL_CATALOG}/billpayment`,
      segment: "Pague suas contas usando pontos",
      buttons: true,
      blank: true,
    },
    {
      id: 2,
      thumbnail: "/img/segments/market.png",
      url: `${process.env.REACT_APP_URL_CATALOG}/vendor?vendor=21252`,
      segment: "Vale compras de supermercados",
      buttons: true,
      blank: true,
    },
    {
      id: 3,
      thumbnail: "/img/segments/business.png",
      url: `${process.env.REACT_APP_URL_CATALOG}/search?category=1`,
      segment: "Itens para seu negócio",
      buttons: true,
      blank: true,
    },
    {
      id: 4,
      thumbnail: "/img/segments/electronic.png",
      url: `${process.env.REACT_APP_URL_CATALOG}/search?category=6`,
      segment: "Eletrônicos e mais de 80 mil itens",
      buttons: true,
      blank: true,
    },
  ];

  const onClick = (event) => {
    event.preventDefault();

    if (readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }
    if (
      reativacaoAtual &&
      AuthHelper.validateUpdateDateReentry(reativacaoAtual.dataConclusao) &&
      !reativacaoAtual.negociaDebito
    ) {
      AuthHelper.alertReentry(false);
      return;
    } else if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }

    window.open(process.env.REACT_APP_URL_CATALOG, "_blank");
  };

  return (
    <section className="showcase services-list">
      <DefaultContainer>
        <SectionTitle title="Ou ainda, resgate diversas opções de produtos e serviços.">
          <a
            className="btn ic-go"
            onClick={(e) => {
              onClick(e);
            }}
          >
            veja tudo
          </a>
        </SectionTitle>

        <Carousel {...settings}>
          {Services &&
            Services.map((Service, i) => {
              return (
                <SegmentsCard
                  key={`service-${i}`}
                  cardType="service-card"
                  image={Service.thumbnail}
                  description={Service.description}
                  price={Service.price}
                  url={Service.url}
                  segment={Service.segment}
                  button={true}
                  benefits={Service.benefits}
                  sku={Service.sku}
                  blank={Service.blank}
                  inadimplente={inadimplente}
                  inadimplenteVencimentos={inadimplenteVencimentos}
                  readOnly={readOnly}
                  matriz={matriz}
                  cobrancaCentralizada={cobrancaCentralizada}
                  reativacaoAtual={reativacaoAtual}
                />
              );
            })}
        </Carousel>
      </DefaultContainer>
    </section>
  );
};

const mapStateToProps = (state) => ({
  inadimplente: state.myData.data.inadimplente,
  inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
  readOnly: state.auth.userInfo.readOnly,
  matriz: state.myData.data.matriz,
  cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  reativacaoAtual: state.auth.userInfo.reativacaoAtual,
});

export default connect(mapStateToProps)(SegmentsShowcase);
