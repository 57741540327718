import { IconChevron } from '@/components/icons';
import React, { Fragment } from 'react';
import './src/fields.min.css';

// COMPONENTES DE INPUTS PADRAO (TEXT, PASSWORD, EMAIL)
const Select = ({ error, label, options, inputProps, value = "value" }) => {
  // const [selectedValue, setSelectedValue] = useState('Selecione');

  // const handleChange = () =>

  return (
    <Fragment>
      <div className={`fm-field fm-select ${error ? 'filled-error' : ''}`}>
        <label htmlFor={inputProps.id}>
          {label}
        </label>
        <div className='custom-select'>
          <select {...inputProps}>
            <option value=''>Selecione</option>
            {options.map((option, i) => {
              return (<option key={`assunto-${i}`} value={option.id}>{option[`${value}`]}</option>)
            })}
          </select>
          <IconChevron className='ic-chevron' height='10px' width='10px' fill='#21346C' />
        </div>
        {error && <span className='error-label'>{error.message}</span>}
      </div>
    </Fragment>
  );
};

export default Select;
