import { useSelector } from 'react-redux';


export default class TipoPagamentoHelper {  
  static IsActivePaymentByCode(code) {
    try {
      let payment = useSelector(state => state.payment.participantPaymentMethod);
      return (payment.filter((data) => { return data.codigo == code }).length > 0);
    } catch (error) {
      return false;
    }
  }

  static GetAllPaymentsStatusActive() {    
    let paymentsStatus = {};

    ['BOLETO', 'DEBITO_RECEBIVEIS', 'DEBITO_CONTA'].map(item => {
      paymentsStatus[item] = this.IsActivePaymentByCode(item);
    });

    return paymentsStatus;
  }
}

