import { IconChevron } from "@/components/icons";
import React, { useEffect, useRef, useState } from "react";
import "./src/accordion.min.css";

const Accordion = (props) => {
  const [accordionState, setAccordionState] = useState("close"); // SETA O ESTADO O ITEM DO Accordion
  const [contentHeigh, setContentHeigh] = useState("0px"); // SETA O CONTEUDO DO  Accordion COMO ABERTO OU FECHAD

  const content = useRef(null);
  const container = useRef(null);

  const toggleAccordion = (title) => {
    setAccordionState(accordionState === "close" ? "open" : "close");
  };

  useEffect(() => {
    setContentHeigh(
      accordionState === "open" ? `${props.customHeight ? props.customHeight : content.current.scrollHeight + 'px'}` : "0px"
    );

    // Expandir somente UM accordion por vez
    if (props.expandOneByOneAccordion !== undefined) {
        if (container.current.contains(props.expandOneByOneAccordion)) {
          setAccordionState("open");
        } else {
          setAccordionState("close");
        }
        return
      }

      setContentHeigh(
        accordionState === "open" ? `${props.customHeight ? props.customHeight : content.current.scrollHeight + 'px'}` : "0px"
      );

  }, [accordionState, props.expandOneByOneAccordion]);
  return (
    <div ref={container} className={`accordion-item ${accordionState} ${props.className}`}>
      <h2 className="accordion-title" onClick={(e) => {toggleAccordion(props.title); props.callback && props.callback(e)}}>
       <strong>{props.title}</strong>
        <IconChevron className="ic-accordion" width="10px" fill="#21346C" />
      </h2>
      <div ref={content} style={{ maxHeight: `${contentHeigh}` }} className="accordion-content">
        <div className="accordion-body">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
