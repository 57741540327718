import React from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import { IconBonus, IconCheck, IconChevron } from "components/icons";
import { slugfy } from "@/components/gtm";
import { TrackingResumeData } from "@/pages/myAccount/bonus";
import "./src/trackingMatriz.min.css";

const TrackingMatriz = ({ tracking, trackingStates, bonusProdutos, ...props }) => {
  const alterarExibicaoDetalhe = (id) => {
    let detalheSelecionado = props.showDetailItem;

    if (detalheSelecionado == id) {
      detalheSelecionado = "";
    } else {
      detalheSelecionado = id;
    }

    props.setShowDetailItem(detalheSelecionado);
  };

  return (
    <>
      <TrackingResumeData matriz={props.matriz} trackingStates={trackingStates} />
      {bonusProdutos?.bonusProdutos.length > 0 && (
        <>
          <SectionTitle title="Bônus em produtos" />
          <div className="bx bonus bonus-em-produtos">
            <table cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th>
                    <span>Descrição</span>
                  </th>
                  <th>
                    <span>Data</span>
                  </th>
                  <th>
                    <span>Produtos</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bonusProdutos.bonusProdutos.map((item, i) => (
                  <tr key={`bonusProduto-${i}`}>
                    <td>{item.descritivo}</td>
                    <td>{item.dataApuracao.replace(/-/gi, '/')}</td>
                    <td className="produtos">{item.pacotes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <SectionTitle title={`Tracking <span class="disclaimer">*Trackings disponíveis a partir de JAN/2021</span>`} />
      {trackingStates.loading ? "Carregando..." 
        : tracking !== undefined && tracking.length > 0 ? tracking.map((item, i) => (
        <div className="bx bonus itemTracking">
          <div className="titulo" onClick={() => alterarExibicaoDetalhe(i + 1)}>
            <IconBonus
              className={`ic ic-bonificacao ${
                item.cor ? slugfy(item.cor) : ""
              }`}
            />
            <div className="descricao">
              <p>
                <strong>{item.produto}</strong>
              </p>
              <p>{item.data}</p>
              <p>
                {item.quantidade == 1
                  ? `${item.quantidade} produto`
                  : `${item.quantidade} produtos`}{" "}
              </p>
            </div>
            <div className={`badge ${item.cor ? slugfy(item.cor) : ""}`}>
              {item.status}
            </div>
            <IconChevron className="ic-chevron" height="10px" width="10px" />
          </div>
          <div
            className={`detalhe ${
              props.showDetailItem == i + 1 ? "active" : ""
            }`}
          >
            <div className="tituloDetalhe">
              <strong>TRACKING DETALHADO</strong>
            </div>

            {item?.detalhes?.length > 0 ? (
              <ul className="listaDetalheTrack">
                {item.detalhes.map((track, i) => (
                  <li>
                    <div className={"data"}>{track.data}</div>
                    <div className={`iconeStatusTrack ${slugfy(track.cor)}`}>
                      <span>
                        {track.descricao != null &&
                          track.descricao.length >= 1 && (
                            <IconCheck className={`ic ic-check`} />
                          )}
                      </span>
                      <div
                        className={`ic-next ${
                          i + 1 == item?.detalhes?.length ? "" : "active"
                        }`}
                      ></div>
                    </div>
                    <div>
                      <strong>{track.status}</strong>{" "}
                      {track.descricao ? ` - ${track.descricao}` : ""}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      )) : "Não existem Tracks para a referência informada."}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tracking: state.bonus.data.tracking,
    bonusProdutos: state.bonus.data.bonusProdutos,
    trackingStates: state.bonus
  };
};

export default connect(mapStateToProps)(TrackingMatriz);
