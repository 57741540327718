import React from 'react'

const IconStore = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            className={props.className}
            // width='27' height='28.718'    
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 27 28.718'>
            <g transform='translate(-396 -749.282)'>
                <g transform='translate(398 756)'
                    fill='none'
                    stroke={props.fill}
                    strokeWidth='2'>
                    <rect
                        width='23'
                        height='22'
                        rx='3'
                        stroke='none' />
                    <rect
                        x='1'
                        y='1'
                        width='21'
                        height='20'
                        rx='2'
                        fill='none' />
                </g>
                <g transform='translate(405 767)'
                    fill='none'
                    stroke={props.fill}
                    strokeWidth='2'>
                    <path d='M1,0H8A1,1,0,0,1,9,1V11a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z' stroke='none' />
                    <path d='M1,1H8A0,0,0,0,1,8,1V9a1,1,0,0,1-1,1H2A1,1,0,0,1,1,9V1A0,0,0,0,1,1,1Z' fill='none' />
                </g>
                <path
                    d='M18.5,13.351a3.5,3.5,0,0,1-6,0,3.5,3.5,0,0,1-6,0,3.5,3.5,0,0,1-6.5-1.8v-4.5L6.605,1.328H18.416L25,7.047v4.5a3.5,3.5,0,0,1-6.5,1.8Z'
                    transform='translate(397 748.953)'
                    fill='#ffffff'
                    stroke={props.fill}
                    strokeWidth='2' />
                <line
                    x2='25'
                    transform='translate(397.5 756.5)'
                    fill='none'
                    stroke={props.fill}
                    strokeWidth='2' />
            </g>
        </svg>

    )
}

export default IconStore