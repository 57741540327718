import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { IconCheck } from '@/components/icons';
import * as DeliveryPayActions from 'store/deliveryPay/actions';
import './src/conecta-pay-member.min.css';

const ConectaPayMember = ({ deliveryPayPortalLinkRequest, financialPortalURL, loading, readOnly, afiliadoConectaPay, conectaPayAtiva, setBtnSubscribeConectaPay }) => {

    useEffect(()=>{
        if (afiliadoConectaPay) deliveryPayPortalLinkRequest();
    }, []);

    return (
        <section className='bx-conecta-pay-member'>
            <div className='bn-conectapay-benefits'>
                <h1> {afiliadoConectaPay ? 'Bem-vindo a Conecta Pay.' : 'Está é a Conecta Pay.'}<br />
                    Quanto mais vendas você faz pela maquininha, mais você ganha.</h1>
                <p><strong>Na Conecta Pay você conta com:</strong></p>
                <ul className='conectapay-benefits'>
                    <li>
                        <span><IconCheck className='ic ic-check' /></span>
                        <p> <strong>Retorno em pontos</strong> nas taxas de todas as
                            vendas de produtos BAT que realizar.</p>
                    </li>
                    <li>
                        <span><IconCheck className='ic ic-check' /></span>
                        <p> Pagamento da sua mensalidade por <strong>recebíveis.</strong></p>
                    </li>
                    <li>
                        <span><IconCheck className='ic ic-check' /></span>
                        <p><strong>Antecipação do valor</strong> das suas vendas no crédito e no débito.</p>
                    </li>
                    <li>
                        <span><IconCheck className='ic ic-check' /></span>
                        <p>Acesso as <strong>principais funções</strong> diretamente pela máquina.</p>
                    </li>
                </ul>
            </div>
            {
                afiliadoConectaPay && (
                    <>
                        <div className='bx-financial-portal'>
                            <h1>Portal Financeiro</h1>
                            <p>Acompanhe as vendas realizadas e sua agenda de recebíveis, solicite bobinas e antecipe seus valores. </p>
                            <div className='bx-button'>
                                <a
                                    href={readOnly === false ? financialPortalURL: '#'}
                                    target="_blank" className='btn btn-main ic-go'
                                    disabled={readOnly||!financialPortalURL || financialPortalURL === '' || loading}
                                >
                                    Acesse
                                </a>
                            </div>
                        </div>
                        <div className='bx-conecta-pay-support'>
                            <h1>0800 723 2221</h1>
                            <p><strong>Nosso suporte está sempre à disposição para te ajudar caso você precise:</strong></p>
                            <ul className='support-list'>
                                <li className='new-machine'>
                                    <p>Solicitar um <strong>novo terminal</strong></p>
                                </li>
                                <li className='coils'>
                                    <p>Solicitar <strong>bobinas</strong> para a sua máquina</p>
                                </li>
                                <li className='portal'>
                                    <p>Verificar <strong>transações</strong> e <strong>relatórios</strong> para ajudar você e o seu negócio</p>
                                </li>
                            </ul>
                        </div>
                    </>
                )
            }
            {
                !afiliadoConectaPay && (
                    <button
                        type="button"
                        onClick={() => {
                            setBtnSubscribeConectaPay(true);
                        }}
                        className="btn btn-main ic-go">
                        Quero ser pay
                    </button>
                )
            }
        </section>
    )
}

const mapStateToProps = state =>{
    return{
        financialPortalURL: state.deliveryPay.conectaPayPortal,
        loading: state.deliveryPay.loading,
        readOnly: state.auth.userInfo.readOnly,
        afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
        conectaPayAtiva: state.myData.data.conectaPayAtiva,
    };
};

const mapDispatchToProps = dispatch =>{
    return{
        deliveryPayPortalLinkRequest: () =>{
            dispatch(DeliveryPayActions.deliveryPayPortalLinkRequest());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConectaPayMember);
