import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal } from "@/components/modal";
import { connect } from "react-redux";
import ChoiseStep from "./choiseStep/choiseStep";
import Swal from "sweetalert2";
import PaymentChoiseStep from "./paymentChoiseStep/paymentChoiseStep";
import * as AuthActions from "store/auth/actions";
import * as PaymentActions from "store/payments/actions";

import "./src/notificationModaReentryDebtor.min.css";

const helperSteps = [{ CHOISE_STEP: 1 }, { DEBT_NEGOCIATION: 2 }];
export const USER_DECISION = {
  NEGOCIAR: "0",
  NAO_NEGOCIAR: "1",
  VER_DEPOIS: "2",
};

const HISTORIC_STATUS = {
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
};

const NotificationModaReentryDebtor = ({
  inadimplente,
  inadimplenteVencimentos,
  reativacaoConcluido,
  reentryUserNegociationRequest,
  participantPaymentHistoryRequest,
  promocaoIncentivoElegivel,
  reentryHistoricRequest,
  reentryHistoricState,
  loadingPaymentHistory,
  successPaymentHistory,
  errorPaymentHistory,
}) => {
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState("close");
  const [userDecision, setUserDecision] = useState("");
  const [historicStatus, setHistoricStatus] = useState({
    success: false,
    loading: false,
    error: false,
  });

  useEffect(() => {
    handleModal();
    if (!!promocaoIncentivoElegivel) {
      reentryHistoricRequest(promocaoIncentivoElegivel.id);
      controlLoadHistoricStatus(HISTORIC_STATUS.LOADING);
      return;
    } else {
      participantPaymentHistoryRequest();
      controlLoadHistoricStatus(HISTORIC_STATUS.LOADING);
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!promocaoIncentivoElegivel) {
      return !reentryHistoricState.loading && reentryHistoricState.success
        ? controlLoadHistoricStatus(HISTORIC_STATUS.SUCCESS)
        : reentryHistoricState.error
        ? controlLoadHistoricStatus(HISTORIC_STATUS.ERROR)
        : controlLoadHistoricStatus(HISTORIC_STATUS.LOADING);
    } else {
      return !loadingPaymentHistory && successPaymentHistory
        ? controlLoadHistoricStatus(HISTORIC_STATUS.SUCCESS)
        : errorPaymentHistory
        ? controlLoadHistoricStatus(HISTORIC_STATUS.ERROR)
        : controlLoadHistoricStatus(HISTORIC_STATUS.LOADING);
    }
  }, [reentryHistoricState.loading, loadingPaymentHistory]);

  const controlLoadHistoricStatus = useCallback((type) => {
    switch (type) {
      case HISTORIC_STATUS.LOADING:
        setHistoricStatus({ loading: true, success: false, error: false });
        break;
      case HISTORIC_STATUS.SUCCESS:
        setHistoricStatus({ loading: false, success: true, error: false });
        break;
      case HISTORIC_STATUS.ERROR:
        setHistoricStatus({ loading: false, success: false, error: true });
        break;
    }
  });

  const handleModal = () => {
    if (localStorage.getItem("reentrySeeLaterDecision")) {
      return;
    } else {
      if (
        (inadimplente || inadimplenteVencimentos) &&
        reativacaoConcluido !== undefined &&
        !reativacaoConcluido
      )
        return setShowModal("open");
    }
  };

  const nextStep = () => {
    if (helperSteps.length === step) return;
    setStep((step) => step + 1);
  };

  const previousStep = () => {
    if (step <= 1) return;
    setStep((step) => step - 1);
  };

  const handleReentryDecision = async (event) => {
    switch (userDecision) {
      case "0":
        return nextStep();
      case "1":
        return handleNegociationStatus();
      case "2":
        localStorage.setItem("reentrySeeLaterDecision", true);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Sua decisão foi gravada, decida novamente em um futuro acesso",
          timer: 2000,
          timerProgressBar: true,
        });

        return setShowModal("close");
    }
  };

  const handleNegociationStatus = async () => {
    await reentryUserNegociationRequest(false);
  };

  const compProps = {
    setStep,
    step,
    nextStep,
    setUserDecision,
    userDecision,
    previousStep,
    handleReentryDecision,
    promocaoIncentivoElegivel,
    setModalState: setShowModal,
    modalState: showModal,
  };

  const propsModal = {
    setModalState: setShowModal,
    modalState: showModal,
    customClass: "reentry-debtor",
    closeBtn: false,
  };

  return (
    <Modal {...propsModal} title="Bem vindo(a) de Volta!">
      {historicStatus.loading ? (
        "Aguarde, estamos carregando..."
      ) : !historicStatus.loading && historicStatus.success ? (
        <>
          {step === helperSteps[0].CHOISE_STEP && (
            <ChoiseStep
              {...compProps}
              withPromo={!!promocaoIncentivoElegivel}
            />
          )}
          {step === helperSteps[1].DEBT_NEGOCIATION && (
            <PaymentChoiseStep
              {...compProps}
              withPromo={!!promocaoIncentivoElegivel}
            />
          )}
        </>
      ) : (
        "Ocorreu algum erro, por gentileza atualize sua página e, caso o erro persista, pedimos que contate o administrador."
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    reativacaoConcluido: state.auth.userInfo.reativacaoAtual?.concluido,
    promocaoIncentivoElegivel: state.auth.userInfo.promocaoIncentivoElegivel,
    reentryHistoricState: state.auth.reentryHistoric,
    loadingPaymentHistory: state.payment?.loadingPaymentHistory,
    successPaymentHistory: state.payment?.successPaymentHistory,
    errorPaymentHistory: state.payment?.errorPaymentHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reentryUserNegociationRequest: (negociation) => {
      dispatch(AuthActions.reentryUserNegociationRequest(negociation));
    },
    reentryHistoricRequest: (incentivoId) => {
      dispatch(AuthActions.reentryHistoricRequest(incentivoId));
    },
    participantPaymentHistoryRequest: () => {
      dispatch(PaymentActions.participantPaymentHistoryRequest());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModaReentryDebtor);

NotificationModaReentryDebtor.propTypes = {
  inadimplente: PropTypes.bool.isRequired,
  inadimplenteVencimentos: PropTypes.bool.isRequired,
  reativacaoConcluido: PropTypes.bool.isRequired,
  reentryUserNegociationRequest: PropTypes.func.isRequired,
  participantPaymentHistoryRequest: PropTypes.func.isRequired,
  promocaoIncentivoElegivel: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  reentryHistoricRequest: PropTypes.func.isRequired,
  reentryHistoricState: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  loadingPaymentHistory: PropTypes.bool.isRequired,
  successPaymentHistory: PropTypes.bool.isRequired,
  errorPaymentHistory: PropTypes.bool.isRequired,
};
