import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "@/components/tooltip";
import * as PerformanceActions from "../../store/performance/actions";
import { IconShopping, IconAlert } from "@/components/icons";
import "./src/performance.min.css";
import { FilterPerfomance } from "@/components/filterPerformance";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Performance = ({
  loadPerformance,
  performanceProgress,
  userPerformanceMonthHistory,
  selectMonthPerformance,
  performance,
  loading,
  success,
  ...props
}) => {
  const [ranges, setRanges] = useState([]);

  const [performanceData, setPerformanceData] = useState({
    progress: 0,
    bought: 0,
    updated: "",
    pacotes: 0,
    qtdPacotesProximaFaixa: 0,
    mesReferencia: 0,
    anoReferencia: 0,
    mesAtual: false,
  });

  const metaAtualizando = new Date().getDate() <= 2;

  const [filter, setFilterList] = useState();

  useEffect(() => {
    selectMonthPerformance();
  }, []);

  useEffect(() => {
    // Set first option value
    if (!loading && !!userPerformanceMonthHistory.length) {
      setHistoryMonth(userPerformanceMonthHistory[0]);
    }
  }, [performanceData.mesReferencia == 0 && userPerformanceMonthHistory]);

  useEffect(() => {
    loadPerformance(filter);
  }, [filter]);

  const setHistoryMonth = (value) => {
    setFilterList({
      anoReferencia: value.anoReferencia,
      mesReferencia: value.mesReferencia,
      mes: value.mes,
    });
  };

  useEffect(() => {
    setPerformanceData({
      progress: performanceProgress.percentualAtingido,
      bought: performanceProgress.pacotesComprados,
      updated: performanceProgress.dataAtualizacaoStr,
      pacotes: performanceProgress.pacotesComprados,
      qtdPacotesProximaFaixa: performanceProgress.qtdPacotesProximaFaixa,
      mesReferencia: performanceProgress.mesReferencia,
      anoReferencia: performanceProgress.anoReferencia,
      mesAtual: performanceProgress.mesAtual,
    });
    setRanges(performanceProgress.faixas);
  }, [performance]);

  const calcPosition = (range, i) => {
    let position = [];
    switch (range.length) {
      case 1:
        position = [100];
        break;
      case 2:
        position = [100, 85];
        break;
      case 3:
        position = [100, 80, 60];
        break;
      case 4:
        position = [100, 85, 70, 55];
        break;
    }

    const p = range.length - i + (position.length - range.length);
    return position[p - 1];
  };

  const calcularBarraProgresso = (range, saldo) => {
    if (range.length == 0) return 0;
    let posicao = [];
    switch (range.length) {
      case 1:
        posicao = [100];
        break;
      case 2:
        posicao = [85, 100];
        break;
      case 3:
        posicao = [60, 80, 100];
        break;
      case 4:
        posicao = [55, 70, 85, 100];
        break;
    }

    let index = -1;
    for (let i = 0; i < range.length; i++) {
      if (saldo >= range[i].meta) {
        index = i;
      }
    }
    if (index + 1 == range.length) return posicao[range.length - 1];
    if (index < 0) {
      let escala = posicao[index + 1] / range[index + 1].meta;
      let width = escala * saldo;
      return width;
    }

    let escala =
      (posicao[index + 1] - posicao[index]) /
      (range[index + 1].meta - range[index].meta);
    let width = posicao[index] + escala * (saldo - range[index].meta);
    return width;
  };

  return (
    <section className="performance">
      <p>Quanto mais produtos BAT você compra, mais pacotes você ganha. </p>
      <div>
        {userPerformanceMonthHistory && userPerformanceMonthHistory.length > 1 && (
          <FilterPerfomance
            label="Exibindo: "
            options={(userPerformanceMonthHistory || []).map((item, index) => {
              return { value: index, description: item.mes };
            })}
            inputProps={{
              name: "filter",
              id: "filter",
              onChange: (e) => {
                setHistoryMonth(userPerformanceMonthHistory[e.target.value]);
              },
            }}
          />
        )}
      </div>
      <div className="bought-box">
        <label className="bought">
          Você já comprou <strong>{performanceData.bought} pacotes</strong>
        </label>
      </div>
      {loading ? (
        <SkeletonTheme color="#ffffff33" highlightColor="#8f23d6">
          <p className={"thermometer-loading"}>
            <Skeleton count={1} />
          </p>
        </SkeletonTheme>
      ) : (
        <>
          <div className="thermometer">
            <span
              className="progress"
              style={{
                width:
                  performanceData.progress > 0
                    ? `calc(${calcularBarraProgresso(
                        ranges,
                        performanceData.pacotes
                      )}%`
                    : 0,
              }}
            ></span>

            {ranges && ranges.length > 0 && (
              <ul className="goal-list">
                {ranges.map((range, i) => {
                  return (
                    <li
                      key={`progress-range-${i}`}
                      className={`step-${range.faixa}`}
                      style={{
                        left: `${calcPosition(ranges, i)}%`,
                      }}
                    >
                      <strong className="range">
                        {range.percentualFaixa + "%"}
                      </strong>
                      <span
                        className={
                          performanceData.progress > range.percentual
                            ? "goal-medal completed"
                            : "goal-medal"
                        }
                      ></span>
                      <div className="bx-awards">
                        {metaAtualizando && performanceData.mesAtual ? (
                          ""
                        ) : (
                          <strong className="goal">
                            {range.meta}
                            <br /> pacotes
                          </strong>
                        )}

                        <Tooltip message={range.opcaoEscolhida}>
                          <p className="btn btn-secondary">
                            <span className="gain-option">O que eu ganho</span>?
                          </p>
                        </Tooltip>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {(!metaAtualizando && !ranges) ||
            (ranges.length === 0 && <p>você não possui meta configurada</p>)}
          {!metaAtualizando &&
            performanceData.updated != "" &&
            performanceData.updated && (
              <p className="update-date">
                atualizado em <strong>{performanceData.updated}</strong>
              </p>
            )}
          {!metaAtualizando &&
            performanceData.mesAtual &&
            performanceData.qtdPacotesProximaFaixa > 0 &&
            performanceData.qtdPacotesProximaFaixa && (
              <p className="next-goal">
                <span className="icon">
                  <IconShopping
                    className="ic-shopping"
                    width="18px"
                    height="18px"
                    fill="#ffffff"
                  />
                </span>
                <span className="next-goal-text">
                  Compre
                  <span className="packages">
                    {performanceData.qtdPacotesProximaFaixa} pacotes
                  </span>
                  para atingir o próximo nível
                </span>
              </p>
            )}
          {metaAtualizando && performanceData.mesAtual && (
            <p className="update-goal">
              <IconAlert
                className="icon-alert"
                width="20px"
                height="20px"
                fill="#4e5055"
              />
              <strong>ATENÇÃO! A sua meta está sendo atualizada.</strong>
            </p>
          )}
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    performanceProgress: state.performance.data,
    performance: state.performance,
    userPerformanceMonthHistory: state.performance.months,
    loading: state.performance.loading,
    success: state.performance.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPerformance: (filter) => {
      dispatch(PerformanceActions.loadPerformance(filter));
    },
    selectMonthPerformance: () => {
      dispatch(PerformanceActions.selectMonthPerformance());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Performance);
