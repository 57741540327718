import { QueryBankSlipTypes } from './types';

const INITIAL_STATE = {
  loading: false,
    errorList: [],
  error: false,
  success: false,
  paymentHistory: [],
  linkBankSlip: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QueryBankSlipTypes.PAYMENT_STATE:
      if (action.payload.node && action.payload.node !== "")
        return { ...state, [action.payload.node]: { ...state[action.payload.node], [action.payload.key]: action.payload.value } };
      else if (action.payload.key && action.payload.key !== "")
        return { ...state, [action.payload.key]: action.payload.value };
      else
        return { ...state, ...action.payload.value };
    case QueryBankSlipTypes.BANK_SLIP_HISTORY_REQUEST:
      return { ...state, loading: true };
    case QueryBankSlipTypes.BANK_SLIP_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        paymentHistory: action.payload.data
      }
    case QueryBankSlipTypes.BANK_SLIP_HISTORY_FAILURE:
      return { ...state, ...INITIAL_STATE, loading: false, error: true, success: false, errorList: action.payload.error }
    case QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_REQUEST:
      return { ...state, loading: true, errorList: [] };
    case QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        error: false,
        errorList: [],
        linkBankSlip: action.payload.data.data
      }
    case QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_FAILURE:
      return { ...state, loading: false, error: true, success: false, errorList: action.payload.error }
    default:
      return state;
  }
};

export default reducer;