import { action } from 'typesafe-actions';
import { DeliveryPayTypes } from './types';

export const saveDeliveryPay = (data) => action(DeliveryPayTypes.DELIVERY_PAY_REQUEST, { data })

export const LoadDeliveryPaySuccess = (data) =>
  action(DeliveryPayTypes.DELIVERY_PAY_SUCCESS, { data });

export const LoadDeliveryPayFailure = () =>
  action(DeliveryPayTypes.DELIVERY_PAY_FAILURE);


export const deliveryPayAcceptTermsRequest = (accepted) => action(DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_REQUEST, { accepted })

export const deliveryPayAcceptTermsSuccess = (data) =>  action(DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_SUCCESS, { data });

export const deliveryPayAcceptTermsFailure = () =>  action(DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_FAILURE);


export const deliveryPayPortalLinkRequest = () => action(DeliveryPayTypes.GET_CONECTA_PAY_LINK_REQUEST);

export const deliveryPayPortalLinkSuccess = (data) => action(DeliveryPayTypes.GET_CONECTA_PAY_LINK_SUCCESS, {data});

export const deliveryPayPortalLinkFailure = () => action(DeliveryPayTypes.GET_CONECTA_PAY_LINK_FAILURE);