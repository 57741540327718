import { BonusTypes } from './types';

const INITIAL_STATE = {
  data: {},
  redes: [],
  loading: false,
  error: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BonusTypes.BONUS_REQUEST:
      return { ...state, loading: true, errorList: [] };
    case BonusTypes.BONUS_SUCCESS:
      return {
        ...state,
        // data: action.payload.data, // Novo tracking
        data: action.payload.data.data,
        loading: false,
        error: false,
        success: true,
      }
    case BonusTypes.BONUS_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: {} }
    case BonusTypes.BONUS_RESET_FLAG:
      return {
        ...state,
          data: {},
          loading: false,
          error: false,
          success: false,
        }
    case BonusTypes.BONUS_REDES_REQUEST:
      return {
        ...state, redes: {
          ...state.redes,
          loading: true,
          error: false,
          success: false,
        },
      }
    case BonusTypes.BONUS_REDES_SUCCESS:
      return {
        ...state, redes: {
          ...state.redes,
          data: action.payload.data,
          loading: false,
          error: false,
          success: true,
        },
      }
    case BonusTypes.BONUS_REDES_FAILURE:
      return {
        ...state, redes: {
          ...state.redes,
          loading: false,
          error: true,
          success: false,
        },
      }
    default:
      return state;
  }
};

export default reducer;
