import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AsideContainer } from "@/components/containers";
import { IconArrowToLeft, IconCopyPaste, IconCheck } from "@/components/icons";
import { NavLink } from "react-router-dom";
import { SectionTitle } from "@/components/sectionTitle";
import { Filter } from "@/components/filter";
import { slugfy } from "@/components/gtm";
import { getParticipantGenerateBankSlipRequest } from "store/queryBankSlip/sagas";
import * as QueryBankSlipActions from "store/queryBankSlip/actions";
import { Accordion } from "@/components/accordion";

import "@/assets/css/main.min.css";
import "./src/resultBankSlipHistoric.min.css";

const ResultBankSlipHistoric = ({
  paymentHistory,
  loading,
  errorList,
  error,
  success,
  linkBankSlip,
  paymentState,
}) => {
  const filterOptions = [
    "Todos",
    ...new Set(paymentHistory.map((payment) => payment.refYear)),
  ];
  const [filter, setFilter] = useState(0);
  const [showErrorLabel, setShowErrorLabel] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(false);

  const getCopyOftheBankSlip = (refYear, refMonth) => {
    setLoadingRequest(true);
    getParticipantGenerateBankSlipRequest({
      anoVencimento: refYear,
      mesVencimento: refMonth,
      cpfCnpjCodSapParticipante: localStorage.getItem("document"),
    })
      .then((response) => {
        setLoadingRequest(false);
        const url = response.data.replace("http", "https");
        const link = document.createElement("a");
        link.href = url;

        const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

        if (!mobileDevices.test(navigator.userAgent)) {
          // Only desktop device
          link.setAttribute("target", "_blank");
        }

        link.setAttribute("download", `ConectaPrimeBoleto_${refMonth}_${refYear}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        setLoadingRequest(false);
        setShowErrorLabel(true);
        console.log(err);
      });
  };

  const copyBankSlipCode = (code) => {
    code = code.replace(/\s/g, "").replace(/\./g, "");
    navigator.clipboard.writeText(code);

    setTimeout(() => setTooltipMessage(false), 500);
  };

  return (
    <section className="">
      <AsideContainer>
        <main className="bx container-section">
          <NavLink to="/consulta-boleto" className="btn btn-link">
            <IconArrowToLeft
              fill="#8f23d6"
              className="ic ic-arrow-to-left ic-left"
            />
            Voltar
          </NavLink>

          <section className="payment-history">
            <SectionTitle title="Histórico de Pagamentos" />
            {showErrorLabel && (
              <div className="bx-message bx-message--error">
                <strong>
                  Não foi possível baixar o boleto, tente novamente!
                </strong>
              </div>
            )}
            {filterOptions.length > 1 && paymentHistory.length > 0 && (
              <Filter
                label="Exibindo: "
                options={filterOptions}
                inputProps={{
                  name: "filter",
                  id: "filter",
                  value: filter,
                  onChange: (e) => setFilter(e.target.value),
                }}
              />
            )}
            {loading ? (
              <div className="bx">
                <h3>Aguarde, carregando registros...</h3>
              </div>
            ) : paymentHistory.length > 0 ? (
              <div className="bx-accordion">
                {paymentHistory
                  .filter((payment, i) => {
                    return (
                      filter == 0 ||
                      filter == "Todos" ||
                      filter == payment.refYear
                    );
                  })
                  .map((payment, i) => {
                    return (
                      <Accordion
                        key={`list-item-${i}`}
                        className={`payment-item ${slugfy(payment.statusString)}`}
                        title={`${payment.competencia}`}
                      >
                        <span className="status">{payment.statusString}</span>
                        <div className="payment-details">
                          <p>
                            <span>{payment.tipoPagamento}</span>
                          </p>
                          <p className="expiration">
                            Vence em: <span>{payment.vencimento}</span>
                          </p>
                          {payment.linhaDigitavel != null && (
                            <p className="copyBankSlip">
                              Código do Boleto:{" "}
                              <span>{payment.linhaDigitavel}</span>
                              <button
                                onClick={() => {
                                  copyBankSlipCode(payment.linhaDigitavel);
                                  setTooltipMessage(true);
                                }}
                              >
                                <IconCopyPaste
                                  width={40}
                                  height={40}
                                  fill="var(--secondaryColor)"
                                />
                                <span
                                  className={`copied ${
                                    tooltipMessage ? "" : "none"
                                  }`}
                                >
                                  <IconCheck
                                    width="10px"
                                    height="10px"
                                    fill="var(--success)"
                                  />
                                  Código Copiado
                                </span>
                              </button>
                            </p>
                          )}
                          {payment.permiteBaixarBoleto && (
                              <div className="fm-button">
                                <button
                                  type="button"
                                  className="btn btn-main ic-go"
                                  disabled={loadingRequest}
                                  onClick={() =>
                                    getCopyOftheBankSlip(
                                      payment.refYear,
                                      payment.refMonth
                                    )
                                  }
                                >
                                  {loadingRequest
                                    ? "Gerando boleto..."
                                    : payment.status === 1
                                    ? "Baixar boleto"
                                    : "Baixar segunda via"}
                                </button>
                              </div>
                            )}
                        </div>
                      </Accordion>
                    );
                  })}
              </div>
            ) : (
              <div className="bx">
                <h3>Não encontramos registros de pagamentos.</h3>
                <p>
                  Não identificamos registros de pagamento.
                </p>
              </div>
            )}
          </section>
        </main>
      </AsideContainer>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentHistory: state.queryBankSlip.paymentHistory,
    linkBankSlip: state.queryBankSlip.linkBankSlip,
    loading: state.queryBankSlip.loading,
    errorList: state.queryBankSlip.errorList,
    error: state.queryBankSlip.error,
    success: state.queryBankSlip.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paymentState: (node, key, value) => {
      dispatch(QueryBankSlipActions.paymentState(node, key, value));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultBankSlipHistoric);
