import api from '@/common/api';
import { AlertMessage } from '@/components/notification'
import {
  call,
  put
} from 'redux-saga/effects';
import {
  LoadDeliveryPayFailure,
  LoadDeliveryPaySuccess,
  deliveryPayAcceptTermsSuccess,
  deliveryPayAcceptTermsFailure,
  deliveryPayPortalLinkSuccess,
  deliveryPayPortalLinkFailure
} from './actions';


export function* postDeliveryPay(action) {
  try {
    const {
      data
    } = yield call(
      api.post,
      '/deliverypay',
      action.payload.data
    );

    yield put(LoadDeliveryPaySuccess(data.data));
  } catch (error) {
    if (error && error.length > 0) {
      let msg = '';
      for (let i = 0; i < error.length; i++) {
        if (i > 0 && error.length > 1)
          msg += ', ';
        msg += error[i];
      }
      AlertMessage(msg, 'error')
    } else {
      AlertMessage('Não foi possível processar sua solicitação, tente novamente', 'error')
    }
    yield put(LoadDeliveryPayFailure());
  }
}


export function* deliveryPayAcceptTermsRequest(action){
  try{
      const {data} = yield call(api.put, "/deliverypay/confirmar-adesao", {confirmar: action.payload.accepted});

      yield put(deliveryPayAcceptTermsSuccess(data));
  }catch(error){
      yield put(deliveryPayAcceptTermsFailure());
  }
}

export function* deliveryPayPortalLinkRequest(){
  try{
      const {data} = yield call(api.get, "/deliverypay/portalDeliveryPay");

      yield put(deliveryPayPortalLinkSuccess(data));
  }catch(error){
      yield put(deliveryPayPortalLinkFailure());
  }
}
