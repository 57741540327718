import { CampaignTypes } from './types';

const INITIAL_STATE = {
  data: [],
  campaign: null,
  loading: false,
  error: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignTypes.LOAD_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case CampaignTypes.LOAD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      }
    case CampaignTypes.LOAD_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: true };

    case CampaignTypes.DETAILS_CAMPAIGN_REQUEST:
      return { ...state, loading: true, campaign: null };
    case CampaignTypes.DETAILS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.payload.data
      }
    case CampaignTypes.DETAILS_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: true };

    case CampaignTypes.ACCEPT_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case CampaignTypes.ACCEPT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CampaignTypes.ACCEPT_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};

export default reducer;
