import { DefaultContainer } from "@/components/containers";
import LoggedBar from "@/components/loggedBar";
import JwtDecode from "@/Utils/jwtDecode";
import React from "react";
import "./src/header.min.css";

// HEADER
const Header = () => {
  const signed = JwtDecode.isAutenticed();

  return (
    <header className="wr wr-header">
      <DefaultContainer>
        <a 
          className="logo-programa" 
          href="/"
        >
        </a>
        {/* menu usuario em LoggedBar  */}
        {signed && <LoggedBar />}
      </DefaultContainer>
    </header>
  );
};

export default Header;
