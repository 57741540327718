import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as PaymentActions from "store/payments/actions";
import * as AuthActions from "store/auth/actions";
import { Checkbox } from "@/components/formFields";
import Swal from "sweetalert2";

import "../src/notificationModaReentryDebtor.min.css";

const PaymentChoiseStep = (props) => {
  const [historicPayments, setHistoricPayments] = useState([]);
  const [targetPayments, setTargetPayments] = useState({
    total: 0,
    idTargetPayments: [],
  });

  useEffect(() => {
    if (
      props.withPromo &&
      !!props.reentryHistoricData.lstPagamentosHistorico.length
    ) {
      return setHistoricPayments(
        // Filtro de boletos 'vencidos'.
        props.reentryHistoricData.lstPagamentosHistorico.filter(
          (payment) => payment.status == 3
        )
      );
    } else {
      if (!!props.paymentHistory.length) {
        return setHistoricPayments(
          // Filtro de boletos vencidos do mais antigo para mais novo.
          props.paymentHistory.filter((filter) => filter.status == 3).reverse()
        );
      }
      return;
    }
    // eslint-disable-next-line
  }, [props.reentryHistoricData, props.paymentHistory]);

  const checkBoxValidation = (target, bankSlipId, bankSlipValue) => {
    if (target) {
      let targetName = document.getElementsByName(target.name);
      let selectedPayments = [...targetName].filter((x) => x.checked).length;

      for (let i = 0; i < targetName.length; i++) {
        if (props.withPromo && validatePromoConfig(selectedPayments)) {
          let qtdParcelasAbonadas = counterPromo(selectedPayments);
          targetName[i].labels[0].classList.remove("abonado");

          for (let i = 1; i <= qtdParcelasAbonadas; i++) {
            if (targetName[targetName.length - i].checked) {
              console.log("A parcela já é uma selecionada");
            } else {
              targetName[targetName.length - i].labels[0].classList.add(
                "abonado"
              );
              targetName[targetName.length - i].disabled = true;
              targetName[targetName.length - i].parentElement.classList.add(
                "disabled"
              );
            }
          }
        } else if (
          targetName[i].checked &&
          !!targetName[i + 1] &&
          !targetName[i + 1].checked
        ) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (
          !targetName[i].checked &&
          !!targetName[i - 1] &&
          targetName[i - 1].checked
        ) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (targetName[i].checked && i === targetName.length) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else if (!targetName[i].checked && i === 0) {
          targetName[i].disabled = false;
          targetName[i].parentElement.classList.remove("disabled");
        } else {
          targetName[i].disabled = true;
          targetName[i].labels[0].classList.remove("abonado");
          targetName[i].parentElement.classList.add("disabled");
        }
      }
    }

    // Remover ou Adicionar boletos para inclusão no boleto avulso
    if (target && bankSlipId) {
      if (target.checked) {
        setTargetPayments({
          ...targetPayments,
          total: targetPayments.total + bankSlipValue,
          ...targetPayments.idTargetPayments.push(bankSlipId),
        });
      } else {
        setTargetPayments({
          ...targetPayments,
          total: targetPayments.total - bankSlipValue,
          idTargetPayments: targetPayments.idTargetPayments.filter((item) => {
            return item !== bankSlipId;
          }),
        });
      }
    }
  };

  const handleBankSlip = () => {
    if (props.withPromo) {
      let pagando = props.reentryHistoricData.descricaoPromocaoAcao.find(
        (item) => item.codigo === "PAGAMENTO"
      );

      if (targetPayments.idTargetPayments.length < parseInt(pagando.valor)) {
        return Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: `Não foi selecionado o mínimo de parcelas para aderir a promoção, caso optar por selecionar mais ${
            parseInt(pagando.valor) - targetPayments.idTargetPayments.length
          } parcela(s) passará a ganhar as bonificações.`,
          showCancelButton: true,
          confirmButtonText: `Entendi, desejo revisar`,
          cancelButtonText: `Desejo continuar assim mesmo`,
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          } else {
            return props.sporadicBankSlipRequest({
              idsParticipantePagamentos: targetPayments.idTargetPayments,
            });
          }
        });
      } else {
        return props.sporadicBankSlipRequest({
          idsParticipantePagamentos: targetPayments.idTargetPayments,
        });
      }
    } else {
      return props.sporadicBankSlipRequest({
        idsParticipantePagamentos: targetPayments.idTargetPayments,
      });
    }
  };

  const validatePromoConfig = (selectedPayments) => {
    let promoConfig = props.reentryHistoricData;
    let hasAbono = props.reentryHistoricData.descricaoPromocaoIncentivo.find(
      (item) => item.codigo === "ABONO"
    );

    return !!hasAbono &&
      selectedPayments >= parseInt(promoConfig.descricaoPromocaoAcao[0].valor)
      ? true
      : false;
  };

  const counterPromo = (selectedPayments) => {
    let pagando = props.reentryHistoricData.descricaoPromocaoAcao.find(
      (item) => item.codigo === "PAGAMENTO"
    );

    return parseInt(selectedPayments / pagando.valor);
  };

  useEffect(() => {
    if (props.sporadicBankSlip) {
      if (props.sporadicBankSlip.error) {
        Swal.fire({
          icon: "error",
          title: "Ops!",
          text: `${props.sporadicBankSlip.errorList[0]}`,
        });

        props.sporadicBankSlipResetFlag();
      } else if (props.sporadicBankSlip.success) {
        Swal.fire({
          icon: "success",
          title: "Boleto avulso gerado com sucesso!",
          text: "Lembrando que será emitido em até 2 dias úteis.",
        });
        props.setModalState("close");
        props.reentryUserNegociationRequest(true);
        props.sporadicBankSlipResetFlag();
      }
    }
  }, [props.sporadicBankSlip]);

  return (
    <>
      <div>
        <p>
          <strong>
            Escolha abaixo as mensalidades vencidas que deseja a quitação.
          </strong>
        </p>
        {!!historicPayments &&
          historicPayments.map((payment, i) => {
            return (
              <Checkbox
                key={payment.id}
                className="payments-reentry"
                label={
                  <span>{`${payment.competencia} / R$ ${payment.valorString}`}</span>
                }
                inputProps={{
                  name: `boleto`,
                  id: `boleto-${i}`,
                  type: "checkbox",
                  // disabled: i !== 0,
                  onChange: (e) => {
                    checkBoxValidation(e.target, payment.id, payment.valor);
                  },
                }}
              />
            );
          })}
        <p>
          <strong>
            Valor total à ser cobrado:{" "}
            {targetPayments.total.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </strong>
        </p>
      </div>
      <div className="btn-container">
        <button className="btn btn-link" onClick={props.previousStep}>
          Voltar
        </button>
        <button
          className="btn"
          disabled={
            props.sporadicBankSlip.loading ||
            !targetPayments.idTargetPayments.length
          }
          onClick={handleBankSlip}
        >
          Gerar Boleto
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentHistory: state.payment.paymentHistory,
    sporadicBankSlip: state.payment.sporadicBankSlip,
    reentryHistoricData: state.auth.reentryHistoric.data,
    reentryHistoricState: state.auth.reentryHistoric,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    participantPaymentHistoryRequest: () => {
      dispatch(PaymentActions.participantPaymentHistoryRequest());
    },
    sporadicBankSlipRequest: (data) => {
      dispatch(PaymentActions.sporadicBankSlipRequest(data));
    },
    sporadicBankSlipResetFlag: () => {
      dispatch(PaymentActions.sporadicBankSlipResetFlag());
    },
    reentryUserNegociationRequest: (negociation) => {
      dispatch(AuthActions.reentryUserNegociationRequest(negociation));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoiseStep);

PaymentChoiseStep.propTypes = {
  withPromo: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setUserDecision: PropTypes.func.isRequired,
  userDecision: PropTypes.string.isRequired,
  handleReentryDecision: PropTypes.func.isRequired,
  participantPaymentHistoryRequest: PropTypes.func.isRequired,
  sporadicBankSlipRequest: PropTypes.func.isRequired,
  sporadicBankSlipResetFlag: PropTypes.func.isRequired,
  reentryUserNegociationRequest: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  reentryHistoricState: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
};
