import { action } from 'typesafe-actions';
import { QueryBankSlipTypes } from './types';

export const paymentState = (node, key, value) => action(QueryBankSlipTypes.PAYMENT_STATE, {node, key, value});

export const participantBankSlipHistoryRequest = (document) => action(QueryBankSlipTypes.BANK_SLIP_HISTORY_REQUEST, {document});

export const participantBankSlipHistorySuccess = (data) => action(QueryBankSlipTypes.BANK_SLIP_HISTORY_SUCCESS, {data});

export const participantBankSlipHistoryFailure = (error) => action(QueryBankSlipTypes.BANK_SLIP_HISTORY_FAILURE, {error});

export const participantGenerateBankSlipRequest = (data) => action(QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_REQUEST, {data});

export const participantGenerateBankSlipSuccess = (data) => action(QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_SUCCESS, {data});

export const participantGenerateBankSlipFailure = (error) => action(QueryBankSlipTypes.COPY_OF_THE_BANK_SLIP_FAILURE, {error});