import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import "./src/fields.min.css";

const InputDate = ({ error, label, inputProps, controlState }) => {
  const [startDate, setStartDate] = useState(new Date(inputProps.value));
  registerLocale('ptBR', ptBR);

  useEffect(() => {
    setStartDate(new Date(inputProps.value));
  }, [inputProps.value]);
  
  return (
    <div className={`fm-field text-field ${error ? "filled-error" : ""}`}>
      <label htmlFor={inputProps.id}>{label}</label>
      <DatePicker
        className="datepicker"
        selected={startDate === new Date() ? null : startDate}
        onChange={(date) => { 
          setStartDate(date); 
          controlState(date);
        }}
        id={inputProps.id}
        disabled={inputProps.disabled}
        regional={['pt-BR']}
        locale='ptBR'
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default InputDate;
