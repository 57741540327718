import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { IconBenefitClub } from "@/components/icons";
import { IconShopping, IconChevron, IconAward } from "@/components/icons";
import { connect } from "react-redux";
import * as ClubBenefitsActions from "store/clubBenefits/actions";
import AuthHelper from "./../../Utils/AlertHelper";
import { ShowUnderPermission } from "@/components/showUnderPermission";
import "./src/mainMenu.min.css";
import "./src/userMenu.min.css";

const MainMenu = (props) => {
  const [currentNavigationItem, setCurrentNavigationItem] = useState("home");

  const isInadimplente = (event) => {
    if (props.inadimplenteVencimentos) {
      event.preventDefault();
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (props.inadimplente) {
      event.preventDefault();
      AuthHelper.alertInadimplente(
        false,
        props.matriz,
        props.cobrancaCentralizada
      );
      return;
    }
  };

  const navigationteItens = [
    {
      codigo: "HOME",
      name: "home",
      current: currentNavigationItem === "home",
      label: "Home",
      url: "/",
    },
    {
      codigo: "COMO_FUNCIONA",
      name: "como_funciona",
      current: currentNavigationItem === "como_funciona",
      label: "Como funciona",
      url: "/como-funciona",
    },
    {
      codigo: "CAMPANHAS",
      name: "campanhas",
      current: currentNavigationItem === "campanhas",
      label: "Campanhas",
      url: "/campanhas",
    },
    {
      codigo: "BENEFICIOS_PRIME",
      label: "BENEFÍCIOS PRIME",
      name: "beneficios_prime",
      current: currentNavigationItem === "beneficios_prime",
      onClick: isInadimplente,
      url:
        !props.inadimplente && !props.inadimplenteVencimentos
          ? "/beneficios-prime"
          : "#",
    },
  ];

  const content = useRef();

  const [dropdownMenu, setDropdownMenu] = useState("");
  const [subMenuHeight, setSubMenuHeight] = useState("0px");

  const toggleDropdown = () => {
    setDropdownMenu(dropdownMenu == "active" ? "" : "active");
    setSubMenuHeight(
      dropdownMenu == "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  };
  const scrollToMenuItem = (e) => {
    const theTarget = e.target || e.srcElement;
    const container = document.getElementById("navigationMenu");
    if (container && container.scrollTo) {
      container.scrollTo({
        left: theTarget.offsetLeft - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="main-menu">
      <nav>
        <ShowUnderPermission codePermission="COMPRE_PRODUTOS_BAT">
          <a
            href={"https://www.conectabat.com.br"}
            target="_blank"
            className="btn-buy-souzacruz"
          >
            <IconShopping
              className="ic-shopping"
              width="30px"
              height="30px"
              fill="#ffffff"
            />
            Compre produtos BAT
          </a>
        </ShowUnderPermission>
        <ul id="navigationMenu" className="navigation-menu">
          {navigationteItens &&
            navigationteItens.map((item, i) => {
              return (
                <ShowUnderPermission codePermission={item.codigo} key={i}>
                  <li>
                    <NavLink
                      to={item.url}
                      onClick={(e) => {
                        scrollToMenuItem(e);
                      }}
                      activeClassName="active"
                      exact
                      className="item-menu"
                    >
                      {item.name === "beneficios_prime" ? (
                        <span
                          className="beneficios-prime-menu"
                          onClick={(e) => {
                            navigationteItens[3].onClick(e);
                          }}
                        >
                          <IconBenefitClub className="ic ic-benefit-club ic-clube" />
                          <span className="txt-clube">
                            {navigationteItens[3].label}
                          </span>
                        </span>
                      ) : (
                        item.label
                      )}
                    </NavLink>
                  </li>
                </ShowUnderPermission>
              );
            })}
        </ul>

        <div className={`points-menu ${dropdownMenu}`} onClick={toggleDropdown}>
          <span>
            <IconAward className="ic-points" width="20px" fill="#8F23D6" />
            <span className="txt-points">Troque seus pontos</span>
            <IconChevron className="ic-chevron" width="10px" fill="#8F23D6" />
          </span>
          <div
            ref={content}
            style={{ maxHeight: subMenuHeight }}
            className="menu-dropdown"
          >
            <ul>
              <ShowUnderPermission codePermission="COMPRE_PRODUTOS_BAT">
                <li>
                  <a
                    href={
                      !props.inadimplente &&
                      !props.inadimplenteVencimentos &&
                      !props.readOnly
                        ? `${process.env.REACT_APP_URL_CATALOG}/vendor?vendor=61906`
                        : "#"
                    }
                    target={!props.readOnly ? "_blank" : "_self"}
                    onClick={(event) => {
                      isInadimplente(event);
                    }}
                  >
                    Produtos BAT
                  </a>
                </li>
              </ShowUnderPermission>
              <li>
                <a
                  href={
                    !props.inadimplente &&
                    !props.inadimplenteVencimentos &&
                    !props.readOnly
                      ? `${process.env.REACT_APP_URL_CATALOG}`
                      : "#"
                  }
                  target={!props.readOnly ? "_blank" : "_self"}
                  onClick={(event) => {
                    isInadimplente(event);
                  }}
                >
                  Catálogo de Prêmios
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clubBenefitsLinkUrlRequest: () => {
      dispatch(ClubBenefitsActions.clubBenefitsRequest());
    },
  };
};

const mapStateToProps = (state) => ({
  matriz: state.myData.data.matriz,
  inadimplente: state.myData.data.inadimplente,
  inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
  afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
  clubBenefitsSucesso: state.clubBenefits.success,
  clubBenefitsUrl: state.clubBenefits?.data?.data,
  clubBenefitsLoading: state.clubBenefits.loading,
  clubBenefitsError: state.clubBenefits.error,
  clubBenefitsMsgError: state.clubBenefits.errorList,
  readOnly: state.auth.userInfo.readOnly,
  mostrarMenuBoletos: state.myData.data.mostrarMenuBoletos,
  cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
