import React from "react";

const iconAward = (props) => {

  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
    >
      <path
        d="M21.013,26v-.011H1.413A1.387,1.387,0,0,1,0,24.637V7.46A1.621,1.621,0,0,1,1.66,5.873H5.984A3.9,3.9,0,0,1,6,2.481,4.191,4.191,0,0,1,9.755.06h.053a5.5,5.5,0,0,1,3.159,1.018A5.413,5.413,0,0,1,16.116,0h.052a4.224,4.224,0,0,1,3.784,2.306A3.929,3.929,0,0,1,20,5.858h4.42a1.533,1.533,0,0,1,1.567,1.5V8.816H26v3.563H23.731v-.06H23.72v-3.5a.787.787,0,0,0-.807-.772H14.133V23.815h7.786v.014h1.046a.751.751,0,0,0,.766-.733V19.483h2.253V24.4a1.639,1.639,0,0,1-1.67,1.6Zm-9.147-2.185V8.044h-8.8V8.033H2.8a.534.534,0,0,0-.542.52V23.145a.679.679,0,0,0,.7.669h8.91ZM14.688,5.873A12.4,12.4,0,0,0,13,3.563a12.861,12.861,0,0,0-1.6,2.31Zm-5.425,0a16.019,16.019,0,0,1,2.28-3.5,3.911,3.911,0,0,0-1.7-.5H9.793A2.281,2.281,0,0,0,7.747,3.214a2.019,2.019,0,0,0,.208,2.21,1.9,1.9,0,0,0,.567.448Zm8.154-.014a2.081,2.081,0,0,0,.682-.52,2.045,2.045,0,0,0,.14-2.224,2.3,2.3,0,0,0-2.06-1.278h-.037a3.3,3.3,0,0,0-1.7.509,16.289,16.289,0,0,1,2.407,3.513Zm6.314,13.625v0Z"
        fill={props.fill}
        stroke='none'
      />
    </svg>
  );
};

export default iconAward;
