import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as PaymentMethodsActions from "store/payments/actions";
import { formatDate } from "@/deliverySystem/common/globalFunctions";
import "./src/acessStageCreditCard.min.css";

const AcessStageCreditCard = ({
  showIframeCard,
  setShowIframeCard,
  creditCardData,
  setChangeCreditCardPaymentRequest,
  myData,
  userInfo
}) => {
  const registerCreditCard = () => {
    if (!userInfo.firstAccess && userInfo.aceiteTermos) setChangeCreditCardPaymentRequest(data);
    setShowIframeCard({...showIframeCard, status: 1});
  };

  let data = {
    idParticipante: myData.id,
    nascimento: formatDate(myData.dataNascimento),
    celular: myData.celular,
    email: myData.email,
    tipoPagamento: 4
  };

  return (
    <section className="cartaoCredito">
      {!showIframeCard?.url &&
        <div className="fields">
          <div className="fm-button">
            <button
              className="btn btn-main ic-go"
              onClick={registerCreditCard}
              disabled={creditCardData?.loading}
            >
              {creditCardData?.loading ? "Aguarde..." : "Cadastrar cartão"}
            </button>
          </div>
        </div>
      }

      {showIframeCard.url && (
          <div className="fields">
            <iframe
              id="insertPaymentInfo"
              title=""
              src={showIframeCard.url}
              style={{
                width: "100%",
                height: "90vh",
                overflow: "hidden",
              }}
            />
          </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    myData: state.myData.data,
    userInfo: state.auth.userInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChangeCreditCardPaymentRequest: (data) => {
      dispatch(PaymentMethodsActions.setChangeCreditCardPayment(data));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AcessStageCreditCard)
);
