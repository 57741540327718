import React from 'react';
import './src/style.min.css'

const Tooltip = (props) => {
    return (
        <div className='bx-tooltip'>
            <div className='message-container'>
                <p className='message'
                    style={{
                        width: props.width
                    }}
                    dangerouslySetInnerHTML={{
                        __html: props.message
                    }}
                ></p>
            </div>
            {props.children}
        </div>
    );
};

export default Tooltip;