import Api from '@/common/api'
import { call, delay, put } from 'redux-saga/effects'
import { loadPerformanceSuccess, loadPerformanceFailure, selectMonthPerformanceSuccess, selectMonthPerformanceFailure } from './actions';

export function* loadPerformance(action) {

  try{
    const {data} = yield call(Api.get, `/participante/Desempenho?mes=${action.payload.params.mesReferencia}&ano=${action.payload.params.anoReferencia}`);
    yield delay(700);
    yield put(loadPerformanceSuccess(data));
  }catch(error){
    yield put(loadPerformanceFailure());
  }
}

export function* selectMonthPerformance() {
  try{
    const {data} = yield call(Api.get, '/participante/FiltroDesempenho');

    yield put(selectMonthPerformanceSuccess(data));
  }catch(error){
    yield put(selectMonthPerformanceFailure());
  }
}