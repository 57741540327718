import {BalanceTypes} from './types';

const INITIAL_STATE = {
    pontuacao: 0,
    loading: false,
    error: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case BalanceTypes.BALANCE_REQUEST:
        return {...state, loading: true};
      case BalanceTypes.BALANCE_SUCCESS: 
       return {
            pontuacao: action.payload.data.data.pontuacao,
            loading: false,
            error: false
        }
      case BalanceTypes.BALANCE_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
