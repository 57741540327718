import React from 'react';

const IconBenefitClub = (props) => {
    return (


<svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 21.458 22.276"             
    width={props.width}
    height={props.height}
    className={props.className}>
    <g transform="translate(-1144.964 -114.94)">
        <g transform="translate(1146 116)">
            <g transform="translate(117.43 315)">
                <g transform="translate(41 -267)">
                    <g transform="translate(12.57 16)">
                        <path d="M29.787,31.214,31.265,30.1a1.763,1.763,0,0,0,.622-1.918l-.544-1.788a1.065,1.065,0,0,1,0-.57l.544-1.788a1.763,1.763,0,0,0-.622-1.918L30.45,21.5l-.662-.5a1.2,1.2,0,0,1-.337-.467l-.622-1.762A1.762,1.762,0,0,0,27.2,17.581l-1.84-.026a.813.813,0,0,1-.544-.181l-1.529-1.063a1.8,1.8,0,0,0-2.022,0l-1.529,1.063a.977.977,0,0,1-.544.181l-1.866.026a1.816,1.816,0,0,0-1.633,1.192l-.622,1.762a.986.986,0,0,1-.337.467l-1.477,1.114a1.751,1.751,0,0,0-.6,1.918l.544,1.788a1.065,1.065,0,0,1,0,.57l-.544,1.788a1.763,1.763,0,0,0,.622,1.918l1.477,1.114a1.2,1.2,0,0,1,.337.467l.622,1.762a1.762,1.762,0,0,0,1.633,1.192l1.866.026a.813.813,0,0,1,.544.181L21.286,35.9a1.728,1.728,0,0,0,1.011.311,1.787,1.787,0,0,0,1.011-.311l1.529-1.063a.977.977,0,0,1,.544-.181l1.866-.026a1.816,1.816,0,0,0,1.633-1.192L29.5,31.68A.652.652,0,0,1,29.787,31.214Z" transform="translate(-183.57 -80)" fill="none" stroke={props.stroke} strokeWidth="2"/>
                    </g>
                </g>
            </g>
        </g>
        <path d="M11.011,4.494a.586.586,0,0,0-.5-.4L7.319,3.8,6.06.853a.587.587,0,0,0-1.08,0L3.72,3.8.533,4.091A.588.588,0,0,0,.2,5.117L2.609,7.23,1.9,10.358a.587.587,0,0,0,.873.634L5.52,9.35l2.748,1.643a.587.587,0,0,0,.874-.634L8.43,7.23,10.84,5.117a.587.587,0,0,0,.171-.624ZM5.572,9.318" transform="translate(1150 119.948)" fill={props.fill} stroke={props.stroke}/>
    </g>
</svg>
    );
};

export default IconBenefitClub;