import { combineReducers } from 'redux';
import {AuthTypes} from './auth/types';
import myData from './my-data';
import acceptanceTerm from './acceptanceTerm';
import auth from './auth';
import balance from './balance';
import banner from './banner';
import contactus from './contactUs';
import deliveryPay from './deliveryPay';
import deliveryPayBanks from './deliveryPayBanks';
import faq from './faq';
import firstAccess from './firstAccess';
import participantSimulator from './participant/simulator';
import regulation from './regulation';
import showcase from './showCase';
import accountStatement from './accountStatement';
import bonus from './bonus';
import singleSignOn from './singleSignOn';
import performance from './performance';
import simulator from './simulator';
import payment from './payments';
import retailChain from './retailChain';
import toastr from './toastr';
import migrationOfPoints from './migrationOfPoints';
import cancel from './cancel';
import queryBankSlip from './queryBankSlip';
import clubBenefits from './clubBenefits';
import segmentPopups from './segmentPopups';
import awardsSelection from './awardsSelection';
import campaigns from './campaigns';
import deliverySystem from './deliverySystem';
import centralizedBilling from './centralizedBilling';

const appReducer = combineReducers({
  toastr,
  auth,
  myData,
  banner,
  contactus,
  faq,
  balance,
  regulation,
  acceptanceTerm,
  firstAccess,
  deliveryPayBanks,
  deliveryPay,
  showcase,
  participantSimulator,
  accountStatement,
  bonus,
  singleSignOn,
  performance,
  simulator,
  payment,
  retailChain,
  migrationOfPoints,
  cancel,
  queryBankSlip,
  clubBenefits,
  segmentPopups,
  awardsSelection,
  campaigns,
  deliverySystem,
  centralizedBilling,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === AuthTypes.SIGN_IN_START || action.type === AuthTypes.SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;