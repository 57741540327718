import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import illCardMachine from "@/assets/img/simulator/rothman_isolado.png";
import * as SimulatorActions from "@/store/simulator/actions";

const BonusOnPurchasing = ({
  steps,
  navigation,
  index,
  current_state,
  dataSimulator,
  simulator_state,
  simulatorRequest,
}) => {
  useEffect(() => {
    simulator_state("step", 4);
  }, []);

  useEffect(() => {
    //Updating state
    simulator_state("percentualBonificacao");
  }, [current_state.percentualBonificacao]);

  const sendForm = async (e) => {
    e.preventDefault();

    if (e.target.id == "bonus130") {
      current_state.percentualBonificacao = 130;

      //SendingRequest
      simulatorRequest(current_state);

      navigation.next();
    }

    if (e.target.id == "bonus100") {
      current_state.percentualBonificacao = 100;

      //SendingRequest
      simulatorRequest(current_state);

      navigation.next();
    }
  };

  return (
    <section className="bx bx-section-bonusOnPurchasing">
      <SectionTitle title="Você pode ganhar, em bonificação, até:" />
      <div className="bonusOnPurchasingContainer">
        <div className="bx content content-bonus">
          <div className="bonus">
            <strong>{dataSimulator.bonificacao100String}</strong>
          </div>
          <div className="package">
            <img src={illCardMachine} alt="" />
            <span className="package-equivalent">
              Equivale a aproximadamente{" "}
              <strong>
                {dataSimulator.pacotesEquivBonificacao100String} pacotes
              </strong>{" "}
              de Rothmans
            </span>
          </div>
          <div className="msg">
            <span className="mensagem-bonificacao"></span>
          </div>
          <div className="bx-buttons">
            <button
              type="submit"
              id="bonus100"
              onClick={sendForm}
              className="btn btn-main ic-go"
            >
              Quero considerar atingimento de 100%
            </button>
          </div>
        </div>
        <div className="bx content content-bonus">
          <div className="bonus">
            <strong>{dataSimulator.bonificacao130String}</strong>
          </div>
          <div className="package">
            <img src={illCardMachine} alt="" />
            <span className="package-equivalent">
              Equivale a aproximadamente{" "}
              <strong>
                {dataSimulator.pacotesEquivBonificacao130String} pacotes
              </strong>{" "}
              de Rothmans
            </span>
          </div>
          <div className="msg">
            <span className="mensagem-bonificacao">
              Varejos Conecta Negócios não recebem bonificação adicional
            </span>
          </div>
          <div className="bx-buttons">
            <button
              type="submit"
              id="bonus130"
              onClick={sendForm}
              className="btn btn-main ic-go aprov130"
            >
              130%
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    dataSimulator: state.simulator.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusOnPurchasing);
