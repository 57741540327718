import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { loadShowCaseSuccess, loadShowCaseFailure} from './actions';


export function* loadShowCase(){
    try{
        const {data} = yield call(api.get, '/api/Showcase?limit=10');
        yield put(loadShowCaseSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadShowCaseFailure());
    }
}

export async function loadShowCaseAvailability(payload){
        const {data} = await api.get(`/api/Showcase/product/availability?originalId=${payload.originalId}&sku=${payload.sku}`);

        return data;
}
    