import {MigrationOfPointsTypes} from './types';

const INITIAL_STATE = {
    loading: false,
    success: false,
    error: false,
    errorList: [],
    subsidiary: [],
    migrationOfPoints: false,
    migrationOfPointsHistory: [],
};

const reducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case MigrationOfPointsTypes.MIGRATION_STATE:
            if(action.payload.node && action.payload.node !== "")
              return {...state, [action.payload.node]: {...state[action.payload.node], [action.payload.key] : action.payload.value}};
            else if(action.payload.key && action.payload.key !== "")
              return {...state, [action.payload.key] : action.payload.value};
            else
              return {...state, ...action.payload.value};
        case MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_REQUEST:
            return{...state, loading: true};
        case MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_SUCCESS:
            return{...state, loading: false, success: true, error: false, subsidiary: action.payload.data};
        case MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_FAILURE:
            return{...state, loading: false, success: false, error: true};
        case MigrationOfPointsTypes.MIGRATION_POINTS_REQUEST:
            return{...state, loading: true};
        case MigrationOfPointsTypes.MIGRATION_POINTS_SUCCESS:
            return{...state, loading: false, success: true, error: false, migrationOfPoints: true};
        case MigrationOfPointsTypes.MIGRATION_POINTS_FAILURE:
            return{...state, loading: false, success: false, error: true, migrationOfPoints: false, errorList: action.payload.error};
        case MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_REQUEST:
            return{...state, loading: true};
        case MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_SUCCESS:
            return{...state, loading: false, success: true, error: false, migrationOfPointsHistory: action.payload.data};
        case MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_FAILURE:
            return{...state, loading: false, success: false, error: true};
        default:
            return state;
    }
};

export default reducer;

