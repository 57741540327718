import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Input, Select, RadioButton, InputCpfCnpj } from '@/components/formFields'
import Modal from '@/components/modal/modal'
import { Link } from "react-router-dom"
import { IconArrowToLeft } from '@/components/icons'
import { SectionTitle } from '@/components/sectionTitle'
import { ConectaPayForm } from '@/components/conectaPayForm'
import Validation from '@/components/utils/validationFields'
import { Sanitizer } from '@/components/utils'
import * as RetailChainActions from 'store/retailChain/actions'
import regulamento from '@/assets/files/Termo_de_Responsabilidade_de_Redes_de_Varejo_Conecta_Prime.pdf'
import './src/newRetail.min.css'

const NewRetail = ({ error, success, loading, facilityErrorList, retailChainState, cnpj, retailIdentificationOptions, facilityIdentificationRequest, facilityIdentification, retailChainSolicitationRequest, retailChainSolicitation, afiliadoConectaPayMatriz, conectaPayAtivaMatriz }) => {
    const [retailId, setRetailId] = useState("")
    const [document, setDocument] = useState("")
    const [requestConectaPay, setRequestConectaPay] = useState(true)
    const [acceptedRegulation, setAcceptedRegulation] = useState(false)
    const [acceptAssociationTerms, setAcceptAssociationTerms] = useState(false);
    const [showConfirmation, setshowConfirmation] = useState('close')
    const [identifiedFacility, setIdentifiedFacility] = useState({})
    const [isPersonalDocValid, setIsPersonalDocValid] = useState(false)
    const [showErrorLabel, setShowErrorLabel] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [conectaPay, setConectaPay] = useState({})
    const [redirect, setRedirect] = useState(false)
    const propsModal = {
        setModalState: setshowConfirmation,
        modalState: showConfirmation,
    }

    useEffect(() => {
        if (document && document !== "")
            setIdentifiedFacility(facilityIdentification)
    }, [facilityIdentification])

    useEffect(() => {
        if (error && !success && document !== "") {
            setShowErrorLabel(true)
            setErrorList(facilityErrorList)
            setshowConfirmation('close')
        } else {
            setShowErrorLabel(false)
        }

    }, [error, success, facilityErrorList])

    useEffect(() => {
        if (retailId === "CPF/CNPJ")
            setIsPersonalDocValid(Validation.cnpj(document) || Validation.cpf(document))
        else if (document !== "" && document && retailId === "SAP")
            facilityIdentificationRequest(document)
    }, [document])

    useEffect(() => {
        if (isPersonalDocValid)
            facilityIdentificationRequest(document)
    }, [isPersonalDocValid])

    useEffect(() => {
        if (retailChainSolicitation && !loading) {
            retailChainState("retailChainSolicitation", "success", false)
            setRedirect(true)
        }
    }, [retailChainSolicitation])

    const callBackRetailChain = (conectaPayData) => {
        setConectaPay(conectaPayData)
        setshowConfirmation('open')
    }

    const handleSaveRetailChain = () => {
        let data = {
            filialId: facilityIdentification.id,
            bancoCodigo: "",
            bancoAgencia: "",
            bancoContaBancaria: "",
            bancoContaBancariaDigito: "",
            bancoContaTipo: 0,
            bancoFavorecidoDocumento: "",
            bancoFavorecidoNome: "",
            solicitarConectaPay: false
        }

        if (!identifiedFacility.afiliadoConectaPay && requestConectaPay && conectaPay.codigoBanco != undefined)
            data = {
                ...data,
                bancoCodigo: conectaPay.codigoBanco,
                bancoAgencia: conectaPay.agencia,
                bancoContaBancaria: conectaPay.conta,
                bancoContaBancariaDigito: conectaPay.digito,
                bancoContaTipo: conectaPay.poupanca ? 2 : 1,
                bancoFavorecidoDocumento: conectaPay.cpfCnpj,
                bancoFavorecidoNome: conectaPay.nomeOuRazao,
                solicitarConectaPay: true
            }

        retailChainSolicitationRequest(data)
    }

    if (redirect)
        return <Redirect to="/minha-rede-de-varejos" />

    return (
        <section className='my-new-retail'>
            <SectionTitle title='Nova solicitação de associação'>
                <Link to='/minha-rede-de-varejos' className='btn btn-link'><IconArrowToLeft fill='#8f23d6' className='ic ic-arrow-to-left ic-left' />Voltar</Link>
            </SectionTitle>

            <div className='bx bx-new-retail'>
                <form className='form--new-retail'>
                    <InputCpfCnpj
                        label='Pedido de associação sob a matriz'
                        error={''}
                        inputProps={{
                            name: 'matrizId',
                            id: 'matrizId',
                            disabled: true,
                            value: cnpj
                        }}
                    />
                    <Select
                        label='Identificador do estabelecimento '
                        options={retailIdentificationOptions}
                        inputProps={{
                            name: 'retailOptionId',
                            id: 'retailOptionId',
                            onChange: (e) => {
                                setRetailId(e.target.value);
                            },
                        }}
                    />
                    {retailId === 'CPF/CNPJ' ? (
                        <InputCpfCnpj
                            label='Número de identificação do estabelecimento'
                            error={
                                !isPersonalDocValid
                                    ? { message: 'Documento obrigatório e válido' }
                                    : ''
                            }
                            inputProps={{
                                type: "text",
                                id: 'CNPJRetail',
                                name: 'CNPJRetail',
                                value: document,
                                disabled: retailId === "" ? true : false,
                                onChange: (e) => setDocument(Sanitizer.cpfCnpj(e.target.value)),
                                onBlur: () => setAcceptedRegulation(false) // reset hook acceptedRegulation
                            }}
                        />
                    ) : (
                            <Input
                                label='Número de identificação do estabelecimento'
                                inputProps={{
                                    name: 'sapRetailCod',
                                    type: 'number',
                                    id: 'sapRetailCod',
                                    disabled: retailId === "" ? true : false,
                                    onChange: (e) => setDocument(e.target.value),
                                    onBlur: () => setAcceptedRegulation(false) // reset hook acceptedRegulation
                                }}
                            />
                        )}
                    <>
                        <Input
                            label='Estabelecimento identificado'
                            hasClass={identifiedFacility.conectaPrime && document !== '' ? 'retail-prime' : ''}
                            inputProps={{
                                name: 'ratailName',
                                type: 'text',
                                id: 'ratailName',
                                disabled: true,
                                defaultValue: !showErrorLabel && document !== "" ? identifiedFacility.razaoSocial : ""
                            }}
                        />
                    </>
                    {
                        identifiedFacility.id && conectaPayAtivaMatriz && afiliadoConectaPayMatriz && identifiedFacility.conectaPayAtiva && !identifiedFacility.afiliadoConectaPay && document !== "" && !showErrorLabel ?
                            <div className="bx-radius">
                                <label>Desejo solicitar a Conecta Pay para esse estabelecimento?</label>
                                <RadioButton
                                    label="Sim"
                                    checked={requestConectaPay}
                                    inputProps={{
                                        id: "requestConectaPay",
                                        name: "requestConectaPay",
                                        value: true,
                                        onChange: () => {setRequestConectaPay(true); setAcceptedRegulation(false)},
                                    }}
                                />
                                <RadioButton
                                    label="Não"
                                    inputProps={{
                                        id: "notRequestConectaPay",
                                        name: "requestConectaPay",
                                        value: false,
                                        onChange: () => {setRequestConectaPay(false); setAcceptAssociationTerms(false)},
                                    }}
                                />
                            </div>                            
                            : (showErrorLabel ?
                                errorList.map((er, i) =>
                                    <div key={i} className="bx-message bx-message--error">
                                        <strong>{er}</strong>
                                    </div>
                                )
                                : null)
                    }
                    {requestConectaPay && conectaPayAtivaMatriz && afiliadoConectaPayMatriz && identifiedFacility.conectaPayAtiva && !identifiedFacility.afiliadoConectaPay && identifiedFacility.id && document !== "" && !showErrorLabel ?
                        <ConectaPayForm retailChain={true} callBackRetailChain={callBackRetailChain} 
                            afiliadoConectaPayFilial={facilityIdentification.afiliadoConectaPay}
                            afiliadoConectaPayMatriz={afiliadoConectaPayMatriz}
                            setAcceptAssociationTerms={setAcceptAssociationTerms}
                            acceptAssociationTerms={acceptAssociationTerms}
                            />
                        : document !== "" && !showErrorLabel ?
                            <>
                                {
                                    identifiedFacility.afiliadoConectaPay ?
                                        <div className="bx-message bx-message--success">
                                            <strong>O estabelecimento identificado já possui a Conecta Pay!</strong>
                                        </div> : null
                                }
                                <div className="fm-field-checkbox">
                                    <input
                                        type="checkbox"
                                        id="acceptedRegulation"
                                        name="acceptedRegulation"
                                        onChange={() => { setAcceptedRegulation(acceptedRegulation => !acceptedRegulation) }}
                                    />
                                    <label htmlFor="acceptedRegulation">
                                        Informo que li o <a href={regulamento} download='download'>Termo de Responsabilidade</a> e assumo o aceite do Termo da Campanha em nome do varejo associado.
                                    </label>
                                </div>
                                <div className='fm-button'>
                                    <button type="button" className="btn btn-main ic-go" disabled={!acceptedRegulation} onClick={() => setshowConfirmation('open')}>solicitar associação</button>
                                </div>
                            </>
                            : null
                    }
                </form>
            </div>
            <Modal {...propsModal}>
                <SectionTitle title='Pedido de associação<br /> de estabelecimento' />
                <p>Você tem certeza que deseja prosseguir com esta solicitação de associação?</p>
                <p>Após a confirmação, o varejo <strong>{Sanitizer.dirtyCpfCnpj(cnpj)}</strong> assume a posição de matriz da sua rede de estabelecimentos.</p>
                <div className="bx-button">
                    <a onClick={() => setshowConfirmation('close')} className='btn btn-inverted ic-go'>cancelar</a>
                    <a onClick={() => handleSaveRetailChain()} className='btn btn-main ic-go'>confirmar</a>
                </div>
            </Modal>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        cnpj: state.myData.data.empresa.cnpj,
        retailIdentificationOptions: state.retailChain.retailIdentification,
        facilityIdentification: state.retailChain.facilityIdentification,
        error: state.retailChain.error,
        success: state.retailChain.success,
        loading: state.retailChain.loading,
        facilityErrorList: state.retailChain.errorList,
        retailChainSolicitation: state.retailChain.retailChainSolicitation.success,
        afiliadoConectaPayMatriz: state.myData.data.afiliadoConectaPay,
        conectaPayAtivaMatriz: state.myData.data.conectaPayAtiva,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        retailChainState: (node, key, value) => {
            dispatch(RetailChainActions.retailChainState(node, key, value))
        },
        facilityIdentificationRequest: (document) => {
            dispatch(RetailChainActions.facilityIdentificationRequest(document))
        },
        retailChainSolicitationRequest: (data) => {
            dispatch(RetailChainActions.retailChainSolicitationRequest(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRetail)