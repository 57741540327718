import { action } from 'typesafe-actions';
import { BonusTypes } from './types';

export const loadBonus = (data) => action(BonusTypes.BONUS_REQUEST, {data});

export const loadBonusSuccess = data => action(BonusTypes.BONUS_SUCCESS, { data });

export const loadBonusFailure = () => action(BonusTypes.BONUS_FAILURE);

export const bonusResetFlag = () => action(BonusTypes.BONUS_RESET_FLAG);

export const loadBonusRedes = () => action(BonusTypes.BONUS_REDES_REQUEST);

export const loadBonusRedesSuccess = data => action(BonusTypes.BONUS_REDES_SUCCESS, { data });

export const loadBonusRedesFailure = () => action(BonusTypes.BONUS_REDES_FAILURE);