import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import illOnLineShopping from "@/assets/img/simulator/compras-online.png";
import * as SimulatorActions from "../../../store/simulator/actions";
import DateValidator from "@/Utils/DateValidator";

const OnLineShopping = ({
  steps,
  navigation,
  index,
  current_state,
  simulator_state,
  simulatorRequest,
  PrimeiroPedidoOnline,
  ProxPedidoOnline,
}) => {
  useEffect(() => {
    simulator_state("step", 5);
  }, []);

  const handleStep = () => {
    simulatorRequest(current_state);
    navigation.next();
  };

  const dateValidation = (date) => {
    if (!DateValidator.validaDate(date)) {
      // setIsValidDate(false);
      return false;
    }

    // setIsValidDate(true);
    return true;
  };

  return (
    <section
      className="bx bx-section-OnLineShopping"
      style={{
        backgroundImage: `url(${illOnLineShopping})`,
      }}
    >
      <SectionTitle
        title="Quer saber outra forma de ganhar? <br />
            É só fazer as suas compras on-line no Conecta Prime BAT."
      />
      <ul className="content">
        <li>
          <p>
            No seu primeiro <br /> pedido, você já leva:
          </p>
          <div className="bonus">
            <strong>{PrimeiroPedidoOnline}</strong>
            <span>em pontos</span>
          </div>
        </li>
      </ul>
      <div className="bx-buttons">
        <button
          type="submit"
          onClick={() => handleStep()}
          className="btn btn-main ic-go"
        >
          Próximo
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    PrimeiroPedidoOnline: state.simulator.data.primeiroPedidoOnlineString,
    ProxPedidoOnline: state.simulator.data.proxPedidoOnlineString,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnLineShopping);
