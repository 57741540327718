import { call, put, delay } from 'redux-saga/effects';
import api from '../../common/api';
import { urlClubBenefitsSuccess, urlClubBenefitsFailure, myBenefitsSuccess, myBenefitsFailure, myBenefitsDetailsSuccess,myBenefitsDetailsFailure, myBenefitsBannerSuperiorSuccess, myBenefitsBannerSuperiorFailure} from './actions';

export function* clubBenefitsRequest(){
    try {
        const {data} = yield call(api.get, `/clube-beneficios/token`);

        yield put(urlClubBenefitsSuccess(data));
        
    } catch (error) {
        yield put(urlClubBenefitsFailure(error));
    }
}

export function* myBenefitsRequest(){
    try {
        const {data} = yield call(api.get, '/clube-beneficios/Lista');

        yield put(myBenefitsSuccess(data));
        
    } catch (error) {
        yield put(myBenefitsFailure(error));
    }
}

export function* myBenefitsDetailsRequest(action){
    try {
        const {data} = yield call(api.get, `/clube-beneficios/${action.payload.id}`);
        
        yield put(myBenefitsDetailsSuccess(data));
        
    } catch (error) {
        yield put(myBenefitsDetailsFailure(error));
    }
}
export function* myBenefitsBannerSuperiorRequest(){
    try {
        const {data} = yield call(api.get, `/banner/Beneficio`);
        
        yield put(myBenefitsBannerSuperiorSuccess(data));
        
    } catch (error) {
        yield put(myBenefitsBannerSuperiorFailure(error));
    }
}