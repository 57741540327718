import React, { Fragment, useEffect } from "react";
import {
  CampaignsShowcase,
  SegmentsShowcase,
  ProductsShowcase,
} from "@/components/showcase";
import { MainBanner, SecondaryBanner } from "@/components/banners";
import { Featured } from "@/components/featured";
import { HelpButton } from "@/components/helpButton";
import { AcceptanceMethod } from "@/components/privacyPolicy";
import { AcceptanceConectaPay } from "@/components/conectaPayForm";
import { AwardsSelection } from "@/components/awardsSelection/";
import { CustomLoading } from "@/components/customLoading";
import { SegmentPopup } from "@/components/segmentPopup";
import { NotificationChangePayment } from "@/components/notificationChangePayment";
import { NotificationModalDebtor } from "@/components/notificationModalDebtor";
import * as AuthActions from "store/auth/actions";
import { ShowUnderPermission } from "@/components/showUnderPermission";
import { NotificationModaReentryDebtor } from "@/components/notificationModaReentryDebtor";
import { connect } from "react-redux";

const Index = ({ loading, loadPermissions, ...props }) => {
  useEffect(() => {
    loadPermissions();
  }, []);

  return (
    <Fragment>
      <section className="pg pg-home">
        {/* BANNER PRINCIPAL */}
        <MainBanner />
        {/* AREA DE DESTAQUE */}
        <ShowUnderPermission codePermission="MEU_DESEMPENHO">
          <Featured />
        </ShowUnderPermission>
        {/* VITRINE DE PRODUTOS */}
        <ShowUnderPermission codePermission="DESCONTO_FATURA">
          <ProductsShowcase />
        </ShowUnderPermission>
        {/* VITRINE DE CAMPANHAS */}
        <CampaignsShowcase />
        {/* BANNER SECUNDARIO */}
        <SecondaryBanner />
        {/* VITRINE POR SEGMENTOS */}
        <SegmentsShowcase />
        <HelpButton />
      </section>
      {/* Aceite de Regulamento */}
      <AcceptanceMethod />
      {/* Aceite de Bonificação */}
      <ShowUnderPermission codePermission="MEU_DESEMPENHO">
        <AwardsSelection />
      </ShowUnderPermission>
      {/* Aceite da Conecta Pay */}
      <AcceptanceConectaPay />
      <NotificationChangePayment />
      {/* Modal Inadimplencia 90+ dias */}
      {props.reativacaoAtual === null && props.inadimplenteVencimentos && (
        <NotificationModalDebtor />
      )}
      {/* Modal Reativação e Reingresso */}
      {(props.inadimplente || props.inadimplenteVencimentos) &&
        props.reativacaoAtual !== null &&
        !props.reativacaoAtual?.concluido &&
        props.aceiteTermos && <NotificationModaReentryDebtor />}
      <SegmentPopup type={"home"} />
      <CustomLoading show={loading} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.performance.loading,
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    aceiteTermos: state.myData.data.aceiteTermos,
    reativacaoAtual: state.auth.userInfo.reativacaoAtual,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadPermissions: () => {
      dispatch(AuthActions.loadPermissions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
