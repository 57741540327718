import Immutable from 'seamless-immutable';
import { ParticipantSimulatorTypes } from './types';


const INITIAL_STATE = Immutable({
  data: {
    valorConectaPay: 0,
    pontosConectaPay: 0,
    pontosPromocoes: 0,
    pontosTotais: 0
  },
  loading: false,
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParticipantSimulatorTypes.PARTICIPANT_SIMULATOR_REQUEST:
      return { ...state, loading: true };
    case ParticipantSimulatorTypes.PARTICIPANT_SIMULATOR_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    case ParticipantSimulatorTypes.PARTICIPANT_SIMULATOR_FAILED:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
