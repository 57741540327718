import React, { Fragment } from 'react'
import Header from '@/components/header'
import Footer from '@/components/footer'
import { TabBar } from '@/components/tabBar'
import { MainMenu } from '@/components/menus'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const LayoutConnected = ({ children }) => {
    return (
        <Fragment>
            <div className="app logged-in">
                <div className="wr-site">
                    <Header />
                    <MainMenu />
                    {children}
                </div>
                <Footer />
                <TabBar />
            </div>
        </Fragment>
    );
}

export default LayoutConnected;