/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import LayoutConnected from "@/layouts/layoutConnected";
import LayoutDisconnected from "@/layouts/layoutDisconnected";
import SystemDeliveryLayout from "@/deliverySystem/layout/SystemDeliveryLayout";
import JwtDecode from "@/Utils/jwtDecode";

const PrivateRoute = ({
  component: Component,
  isPrivate,
  firstAccess,
  acceptTerms,
  singleSignOn,
  maintenance,
  ...rest
}) => {
  const ignore = ["/"];
  const signed = JwtDecode.isAutenticed();

  const SystemDelivery = rest.path.split('/')[1] === 'seu-delivery'

  const Layout = signed ? SystemDelivery ? SystemDeliveryLayout : LayoutConnected : LayoutDisconnected;


  if (!signed && maintenance) {
    return <Redirect to="/manutencao" />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/logout" />;
  }

  if (signed && firstAccess) {
    return <Redirect to="/primeiro-acesso"></Redirect>;
  } else if (signed && !firstAccess && !acceptTerms && ignore.indexOf(rest.path) === -1) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    maintenance: state.auth.userInfo?.manutencao,
    firstAccess: state.auth.userInfo.firstAccess,
    singleSignOn: state.singleSignOn.loading,
    acceptTerms: state.myData.data.aceiteTermos,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
