import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SectionTitle } from '@/components/sectionTitle'
import { ValidationRules } from '@/components/utils'
import { Input, Select, Textarea, InputTelAndCellphone } from '@/components/formFields'
import { connect } from 'react-redux'
import JwtDecode from '@/Utils/jwtDecode'
import Swal from 'sweetalert2'
import * as ContactActions from 'store/contactUs/actions'

const ContactForm = ({ loading, contactUs, loadSubjects, loadedSubjects, success, userInfo, updateContactSuccessFlag, readOnly }) => {

    const signed = JwtDecode.isAutenticed();

    const { register, errors, reset, getValues, triggerValidation } = useForm({
        validationSchema: ValidationRules(),
        mode: 'onChenge',
    });

    const [subjects, setSubjects] = useState([]);
    const [cellphone, setCellphone] = useState("");

    useEffect(() => {
        if (success){
            Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Mensagem enviada',
                timer: 2000,
                timerProgressBar: true
            });
            
            reset();
            setCellphone("");
        }

        updateContactSuccessFlag();
    }, [success]);

    useEffect(() => {
        loadSubjects();
    }, []);

    useEffect(() => {
        setSubjects(
            [
                ...loadedSubjects.map(subject => {
                    return {
                        id: subject.id,
                        value: subject.assunto
                    }
                })
            ]
        );
    }, [loadedSubjects]);

    const sendForm = async (event) => {

        event.preventDefault();
        const formdata = getValues();

        if (signed) {
            formdata.fullName = userInfo.name;
            formdata.email = userInfo.email;
            formdata.phone = userInfo.telefone;
            formdata.codSap = userInfo.codigoSap;
        }

        const isValid = await triggerValidation();

        if (isValid) {
            let data = {
                nome: formdata.fullName,
                codigoSap: formdata.codSap,
                email: formdata.email,
                fone: formdata.phone,
                idAssunto: parseInt(formdata.subject),
                mensagem: formdata.message
            };

            contactUs(data);
        } else {
            Swal.fire({
                // title: 'Erro',
                text: 'Preencha todos os campos',
                icon: 'error',
                confirmButtonText: 'Continuar preenchendo'
            })
        }
    };

    return (
        <section className='contact-us'>
            <SectionTitle title='Fale conosco' />
            <div className='bx bx-contact-us'>
                <form>
                    {!signed &&
                        (<>
                            <Input
                                label='Nome'
                                error={errors.fullName}
                                inputProps={{
                                    ref: register,
                                    name: 'fullName',
                                    type: 'text',
                                    id: 'fullName',
                                }}
                            />
                            <Input
                                label='Código SAP'
                                error={errors.codSap}
                                inputProps={{
                                    ref: register,
                                    name: 'codSap',
                                    type: 'number',
                                    id: 'codSap',
                                }}
                            />
                            <InputTelAndCellphone
                                label='Telefone'
                                error={errors.phone}
                                inputProps={{ id: "Celular", name: "celular", value: cellphone, ref: register, onChange: e => setCellphone(e.target.value) }}
                            />
                            <Input
                                label='E-mail'
                                error={errors.email}
                                inputProps={{
                                    ref: register,
                                    name: 'email',
                                    type: 'email',
                                    id: 'email',
                                }}
                            />
                        </>
                        )}

                    <Select
                        label='Assunto'
                        error={errors.subject}
                        options={subjects}
                        inputProps={{
                            ref: register,
                            name: 'subject',
                            id: 'subject',
                        }}
                    />
                    <Textarea
                        label='Mensagem'
                        error={errors.message}
                        inputProps={{
                            ref: register,
                            name: 'message',
                            id: 'message',
                        }} />
                    <div className='fm-buttons'>
                        <button className='btn btn-main ic-go' onClick={sendForm} disabled={loading || readOnly}>Enviar</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
        success: state.contactus.contactSuccess,
        loading: state.contactus.loading,
        loadedSubjects: state.contactus.subjects,
        readOnly: state.auth.userInfo.readOnly
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadSubjects: () => {
            dispatch(ContactActions.loadSubjects());
        },
        contactUs: (data) => {
            dispatch(ContactActions.contactUs(data));
        },
        updateContactSuccessFlag: () => {
            dispatch(ContactActions.updateContactSuccessFlag());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);