import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion } from "@/components/accordion";
import { Filter } from "@/components/filter";
import { SectionTitle } from "@/components/sectionTitle";
import * as RetailChainActions from "store/retailChain/actions";
import AuthHelper from "@/Utils/AlertHelper";
import "./src/myRetailNetwork.min.css";

const MyRetailNetwork = ({
  retailChainListRequest,
  listOfRetails,
  matriz,
  inadimplente,
  inadimplenteVencimentos,
  readOnly,
  cancelado,
  cobrancaCentralizada,
  setHasApprovedRetailsChain,
}) => {
  const [retailChain, setRetailChain] = useState([]);

  useEffect(() => {
    retailChainListRequest(matriz);
    // eslint-disable-next-line
  }, [matriz]);

  useEffect(() => {
    if (!readOnly && (retailChain != undefined || retailChain.length > 0)) {
      let approvedRetailsChain = retailChain.filter((aprov, i) => {
        return aprov.statusCodigo == "APROVADO";
      });

      setHasApprovedRetailsChain(approvedRetailsChain.length > 0);
    }
  }, [retailChain]);

  useEffect(() => {
    setRetailChain(listOfRetails);
  }, [listOfRetails]);

  const filterOptions = ["Todos", "Aprovado", "Reprovado", "Aguardando"];

  const [filter, setFilterList] = useState("Todos");

  const toggleFilter = (e) => {
    setFilterList(e.target.value);
  };

  const isInadimplente = (event) => {
    if (inadimplenteVencimentos) {
      event.preventDefault();
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      event.preventDefault();
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }
  };

  const restrict = () => {
    if (!readOnly && !cancelado && !inadimplente && !inadimplenteVencimentos) {
      return "/associar-novo-varejo";
    }
  };

  return (
    <section className="my-retail-network">
      {matriz && (
        <>
          <div className="fm-button">
            <Link
              to={restrict}
              className="btn btn-main btn-new ic-go"
              disabled={readOnly || cancelado}
              onClick={() => {
                isInadimplente();
              }}
            >
              Nova associação
            </Link>
          </div>
        </>
      )}
      <SectionTitle title={matriz ? "Status das associações" : "Associações"}>
        {matriz && (
          <Filter
            label="Exibindo: "
            options={matriz ? filterOptions : ["Todos"]}
            inputProps={{
              name: "filter",
              id: "filter",
              value: filter,
              onChange: (e) => {
                toggleFilter(e);
              },
            }}
          />
        )}
      </SectionTitle>
      {retailChain === null ||
      retailChain === "" ||
      retailChain === undefined ? (
        <div className="bx">
          <h3>Não encontramos registros de varejos associados.</h3>
        </div>
      ) : (
        <div className="bx-accordion">
          {retailChain.map((retail, i) => {
            if (filter === "Todos" || filter === retail.statusNome)
              return (
                <Accordion
                  key={`list-item-${i}`}
                  className={
                    matriz
                      ? retail.statusNome.toLowerCase()
                      : retail.tipoVarejo.toLowerCase()
                  }
                  title={retail.razaoSocial}
                >
                  <span className="status">
                    {matriz ? retail.statusNome : retail.tipoVarejo}
                  </span>
                  <p>
                    <strong>CPF/CNPJ:</strong> {retail.documento}
                  </p>
                  <p>
                    <strong>Código SAP:</strong> {retail.codigoSap}
                  </p>
                  <p>
                    <strong>Data da solicitação:</strong>{" "}
                    {retail.dataSolicitacao}
                  </p>
                  <p>
                    <strong>Dados do varejo:</strong> {retail.endereco}
                  </p>
                  {retail.statusNome &&
                    (retail.statusNome.toLowerCase() === "reprovado" ||
                      retail.statusNome.toLowerCase() === "cancelado") && (
                      <div className="bx-message bx-message--error">
                        <i className="icon-icon_qarning"></i>
                        <strong>{retail.motivo}</strong>
                      </div>
                    )}
                </Accordion>
              );
          })}
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    listOfRetails: state.retailChain.data,
    matriz: state.myData.data.matriz,
    creditPointsRule: state.myData.data.centralizarPontuacao,
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    readOnly: state.auth.userInfo.readOnly,
    cancelado: state.myData.data.cancelado,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    retailChainListRequest: (matriz) => {
      dispatch(RetailChainActions.retailChainListRequest(matriz));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRetailNetwork);
