import React from "react";

const CadastroFinalizado = ({ readOnly, loading, success, step, stepsProps }) => {
  return (
    <div id="success">
      <h2>Parabéns! Suas informações foram cadastradas.</h2>
      <p>
        Agora para aproveitar todas as vantagens que só o Conecta Prime oferece,
        basta aceitar o regulamento!
      </p>
      <div className="fm-button">
        <a
          disabled={(!success || loading) || readOnly}
          href="/"
          className="btn-main btn ic-go"
        >
          acessar o conecta prime
        </a>
      </div>
    </div>
  );
};

export default CadastroFinalizado;
