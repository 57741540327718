import { signOut } from '@/store/auth/actions';
import { store } from '@/store/store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AppMessages } from './AppMessages';

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout:  process.env.REACT_APP_API_ENDPOINT_TIMEOUT,
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION,
    'Accept': 'application/json'
  },
});

Api.interceptors.request.use(async config => {
  const  auth = store.getState().auth;
  config.headers.Authorization = `Bearer ${auth.accessToken}`;
  config.headers['X-API-Cognito'] = `${auth.cognitoAccessToken}`;
  return config;
});

Api.interceptors.response.use(
  async response => response,
    async error => {
      if (error.response.status === 500) {
        toast.error('Ops, ocorreu um erro, tente novamente!');
        throw [AppMessages.ERROR_GENERIC_400];
      } else if (error.response.status === 404) {
        toast.error('Ops, não encontrado!');
      } else if (error.response.status === 400) {
        if (error && error.response && error.response.data && error.response.data.errors) {
          for (const msg in error.response.data.errors) {
            toast.error(error.response.data.errors[msg]);
          }
        }
      } else if (error.response.status === 401) {
        store.dispatch(signOut('Sua%20sessão%20expirou%21'));
      }

      if(error.response.data.message)
        throw [error.response.data.message];
        
      throw error.response.data.errors;
    },
);

export default Api;
