import React, { useEffect, useState } from 'react';
import { Modal } from '@/components/modal'
import { connect } from "react-redux";
import SectionTitle from '../sectionTitle/sectionTitle';
import * as PaymentActions from '../../store/payments/actions';
import * as AuthActions from "store/auth/actions"

const NotificationChangePayment = ({
    showNotificationChangePayment,
    requestPayment,
    showModalRedux
}) => {

    const [showModal, setShowModal] = useState({ status: false, classStatus: 'close' });
    // eslint-disable-next-line
    const [chooseModal, setChooseModal] = useState({
        showNotificationChangePayment: showModal,
    });

    useEffect(() => {

        showModalF()
    }, []);

    function showModalF() {
        if (showNotificationChangePayment && !showModalRedux)
            setShowModal({ status: true, classStatus: 'open' });
    }

    async function sendChoose(choose, redirect) {
        let payload = {
            ShowNotificationChangePayment: choose,
        };

        await requestPayment(payload);

        if (redirect) {
            setTimeout(function () {
                window.location.pathname = ("meus-pagamentos");
            }, 1500);
        }

        setShowModal({ status: false, classStatus: 'close' });
    }

    return (
        <>
            <Modal
                modalState={showModal.classStatus}
            >
                {/* <section className='awards-selection'> */}
                <SectionTitle title='Desconto em recebíveis: pagar a mensalidade nunca foi tão simples' />
                <p>
                    Vimos que você escolheu a maneira mais rápida e fácil  de pagar sua mensalidade:
                        <br />
                        Desconto em recebíveis de sua Conecta Pay.
                    </p>
                <p>Queremos saber se gostaria de manter essa forma de pagamento.</p>

                <a
                    className='btn'
                    onClick={() => sendChoose(false,false)}
                >Sim</a>
                <a
                    className='btn'
                    onClick={() => sendChoose(false,true)
                    }
                // href="/meus-pagamentos"
                >Não</a>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.userInfo,
        showNotificationChangePayment: state.auth.userInfo.showNotificationChangePayment,
        showModalRedux: state.payment.notficationModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestPayment: (payload) => {
            dispatch(PaymentActions.changeFlagShowNotificationChangePaymentRequest(payload));
        },
        authState: (node, key, value) => {
            dispatch(AuthActions.authState(node, key, value));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationChangePayment);