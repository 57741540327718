import React, { useEffect } from "react";
import { DefaultContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "./src/accumulatePoints.min.css";

const AccumulatePoints = (props) => {
  useEffect(() => {
    if (!!props.userPermissions.length) {
      let perm = props.userPermissions.find((item) => {
        return item.codigo === "COMO_FUNCIONA";
      })?.permissao;

      return perm ? undefined : window.location.replace("/");
    }
  }, []);

  // IMAGENS
  const imagesURL = {
    BannerDesk: "/img/accumulate-points/banner-desk.png",
    BannerMobile: "/img/accumulate-points/banner-mobile.png",
    ServicePackage: "/img/accumulate-points/service-package.png",
    ProductPackage: "/img/accumulate-points/product-package.png",
    AwardsPackage: "/img/accumulate-points/awards-package.png",
    PortalConectaPrime: "/img/accumulate-points/conecta-prime.png",
    Partners: "/img/accumulate-points/partners.png",
    SouzaCruz: "/img/accumulate-points/souza-cruz.png",
  };
  return (
    <section className="pg pg-accumulate-points">
      <div className="banner-accumulate-points">
        <img
          src={
            window.innerWidth > 768
              ? imagesURL.BannerDesk
              : imagesURL.BannerMobile
          }
          alt="Confira as 3 vantagens imperdíveis do programa de benefícios da BAT."
        />
      </div>
      <div className="steps-accumulate-points">
        <DefaultContainer>
          <ul className="bx-steps flex-conatiner">
            <li className="item-step">
              <span className="step">1</span>
              <SectionTitle title="Você pode ganhar produtos todo mês" />
              <p>
                Atingindo a sua meta, ganhe produtos BAT para aumentar
                seus lucros.{" "}
              </p>
            </li>
            <li className="item-step">
              <span className="step">2</span>
              <SectionTitle title="Acumular pontos no programa" />
              <ul>
                <li className="conecta-prime">
                  <img src={imagesURL.PortalConectaPrime} />
                  <p>Fazendo pedidos on-line.</p>
                </li>
                <li className="partners">
                  <p>Comprando produtos parceiros.</p>
                  <img src={imagesURL.Partners} />
                </li>
                <li className="souza-cruz">
                  <img src={imagesURL.SouzaCruz} />
                  <p>Participando das promoções BAT.</p>
                </li>
              </ul>
            </li>
            <li className="item-step">
              <span className="step">3</span>
              <SectionTitle title="Aproveitar os benefícios que #SóQuemÉPrimeTem!" />
              <p>
                O Prime tem parceiros que fornecem vantagens e descontos
                exclusivos a você e ao seu varejo.
              </p>
            </li>
          </ul>
        </DefaultContainer>
      </div>
      <div className="how-to-use-accumulate-points">
        <DefaultContainer>
          <SectionTitle title="<span>Como usar os </span>pontos do programa?" />
          <p>Basta acessar o catálogo aqui na plataforma e trocar por: </p>
          <ul className="flex-conatiner">
            <li className="product-package">
              <img src={imagesURL.ProductPackage} alt="Desconto nas faturas" />
              <h3>Desconto nas faturas</h3>
              <p>
                Transforme seus pontos em <br />
                créditos com a BAT.
              </p>
            </li>
            <li className="service-package">
              <img src={imagesURL.ServicePackage} alt="Serviços" />
              <h3>Serviços</h3>
              <p>
                Vale-compras e<br />
                pagamento de contas
              </p>
            </li>
            <li className="awards-package">
              <img src={imagesURL.AwardsPackage} alt="Prêmios" />
              <h3>Prêmios</h3>
              <p>
                Mais de 80 mil opções de itens <br />
                nas maiores lojas do Brasil
              </p>
            </li>
          </ul>
        </DefaultContainer>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userPermissions: state.auth.userPermissions,
});

export default connect(mapStateToProps)(AccumulatePoints);
