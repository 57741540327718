import React from 'react';

const IconBonus = (props) => {
    return (
        <svg           
            width={props.width}
            height={props.height}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                <g transform="translate(7449 15092)">
                    <circle 
                        id="Elipse_498" 
                        data-name="Elipse 498" 
                        cx="12" cy="12" r="12" 
                        transform="translate(-7448 -15091)" 
                        fill="none" 
                        stroke={props.fill}
                        stroke-width="2"
                    />
                    <line 
                        id="Linha_256" 
                        data-name="Linha 256" 
                        y2="13.292" 
                        transform="translate(-7436 -15085.791)" 
                        fill="none" 
                        stroke={props.fill}
                        stroke-linecap="round" 
                        stroke-width="2"
                    />
                    <line 
                        id="Linha_257" 
                        data-name="Linha 257" 
                        y2="13.292" 
                        transform="translate(-7429.354 -15079.146) rotate(90)" 
                        fill="none" 
                        stroke={props.fill}
                        stroke-linecap="round" 
                        stroke-width="2"
                    />
                </g>
        </svg>
    );
};

export default IconBonus;