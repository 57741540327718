import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import DefaultContainer from '@/components/containers/defaultContainer'
import logo from '@/assets/img/logo-conecta-prime.png'
import './src/style.min.css'
const InMaintenance = () => {
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);
    return (
        <section className='page-maintenance'>
            <DefaultContainer>
                <img src={logo} className='logo-conecta-prime' alt='Conect | Prime' />
                <h1>Ops...</h1>
                <div className='msg-container'>
                    <p title='Desculpe, pagina não encontrada'>Desculpe, estamos em manutenção.</p>
                    <p title='Desculpe, pagina não encontrada'>Voltaremos em breve!</p>
                </div>
            </DefaultContainer>
        </section>
    )
}
export default InMaintenance;