import React, { useState, useEffect } from "react";
import { InputDefault, InputTelAndCellphone } from "@/deliverySystem/components/inputs";
import { UploaderFile } from "@/deliverySystem/components/uploaderFile";
import { connect } from "react-redux";
import * as DeliverySystemActions from "store/deliverySystem/actions";
import "./src/projectCreationSteps.min.css";

const BusinessDataForm = ({
  navigation,
  setInputValue,
  inputValue,
  createNewProjectRequest,
  createNewProjectResetFlag,
  updateProjectRequest,
  updateProjectResetFlag,
  newProjectState,
  updateProjectState,
  toastShow
}) => {
  const [tooltipMessage, setTooltipMessage] = useState();
  const [isValidFileSize, setIsValidFileSize] = useState();
  const [cellphone, setCellphone] = useState(inputValue.whatsApp);
  const [isValidCellphone, setIsValidCellphone] = useState(null);
  const handleChange = (key, value) => {
    if (value != undefined) {
      setInputValue({ ...inputValue, [key]: value });
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    if (inputValue.editMode) {
      if (inputValue.nomeRevenda && isValidCellphone ==  true || isValidCellphone == null && inputValue.endereco) {
        const data = {};
        updateProjectRequest(
          inputValue.id,
          {
            ...data,
            nomeRevenda: inputValue.nomeRevenda,
            whatsApp: cellphone.replace(/[^\d]+/g, ""),
            endereco: inputValue.endereco,
          },
          inputValue.formData
        );
      }
    } else {
      if (inputValue.nomeRevenda && isValidCellphone && inputValue.endereco) {
        const data = {};
        createNewProjectRequest(
          {
            ...data,
            nomeRevenda: inputValue.nomeRevenda,
            whatsApp: cellphone.replace(/[^\d]+/g, ""),
            endereco: inputValue.endereco,
          },
          inputValue.formData
        );
        setInputValue({...inputValue, formData: new FormData()}) // reset formData
      }
    }
  };

  const validationFileSize = (number) => {
    if (number >= 1048576 * 2) {
      // 1048576 * 2 = 2 mega bytes (tamanho máximo)
      return setIsValidFileSize(false)
    } else {
      return setIsValidFileSize(true)
    }
  };

  const cellValidation = (cellPhone) => {
    let currentCel = cellPhone?.replace(/[^\d]+/g, "");

    if (!currentCel || currentCel.length < 11 || cellPhone === "") {
      setIsValidCellphone(false);
      return;
    }

    setIsValidCellphone(true)
    return;
  };

  useEffect(() => {
    setTooltipMessage(
      !inputValue.nomeRevenda || isValidCellphone == false || !inputValue.endereco
        ? `Preencha todos os campos para prosseguir com a ${inputValue.editMode ? 'edição' : 'criação'} do seu novo projeto.`
        : `Clique para prosseguir e concluir ${inputValue?.editMode ? 'a edição do' : ''} seu projeto!`
    );
  }, [inputValue]);

  useEffect(() => {
    if (!newProjectState?.loading) {
      if (newProjectState?.success) {
        toastShow('success', 'Sucesso', `${newProjectState.response.message}`, 3500);
        let id = newProjectState.response.data
        setInputValue({...inputValue, id})
        navigation.next();
        createNewProjectResetFlag(); // reset states flag
      }

      if (newProjectState?.error) {
        toastShow('error', 'Algo deu errado...', 'Por gentileza, tente novamente e caso persista, por gentileza entre em contato conosco.', 4500);
        createNewProjectResetFlag(); // reset states flag
      }
    }

    if (!updateProjectState?.loading) {
      if (updateProjectState?.success) {
        toastShow('success', 'Sucesso', `${updateProjectState.response.message}`, 3500);
        navigation.next();
        updateProjectResetFlag(); // reset states flag
      }

      if (updateProjectState?.error) {
        toastShow('error', 'Algo deu errado...', 'Tente novamente. Caso persista favor entre em contato conosco.', 3500);
        updateProjectResetFlag(); // reset states flag
      }
    }
  }, [
    newProjectState?.success,
    newProjectState?.error,
    updateProjectState?.success,
    updateProjectState?.error,
  ]);

  return (
    <section className="business-data-form">
      <div className="title">
        <h2>DEFINA AS INFORMAÇÕES</h2>
        <span>
          O nome da empresa que você escolher vai ficar salvo nos seus projetos.
          Assim fica mais fácil de achar.
        </span>
      </div>
      <InputDefault
        labelText="Nome do Estabelecimento"
        callBack={handleChange}
        inputProps={{
          type: "text",
          id: "nomeRevenda",
          name: "nomeRevenda",
          value: inputValue.nomeRevenda,
        }}
      />
      <InputTelAndCellphone
        labelText="Número do whatsapp"
        callBack={handleChange}
        inputProps={{ 
          id: "whatsApp",
          name: "whatsApp",
          value: cellphone,
          onChange: (e) => {
            setCellphone(e.target.value);
            cellValidation(e.target.value);
          }
        }}
      />
      <InputDefault
        labelText="Endereço"
        callBack={handleChange}
        inputProps={{
          type: "text",
          id: "endereco",
          name: "endereco",
          value: inputValue.endereco,
        }}
      />
      <UploaderFile inputValue={inputValue} setInputValue={setInputValue} validationFileSize={validationFileSize} isValidFileSize={isValidFileSize}/>
      <div className="bx-buttons">
        <button
          data-tooltip={tooltipMessage}
          disabled={
            !inputValue.nomeRevenda || isValidCellphone  == false || !inputValue.endereco || (updateProjectState?.loading || newProjectState?.loading)
          }
          onClick={(e) => handleClick(e)}
          className={`btn-next btn-link ${!inputValue.nomeRevenda || isValidCellphone  == false || !inputValue.endereco ? 'error' : 'success'}`}
        >
          {(updateProjectState?.loading || newProjectState?.loading) ? 'Carregando...' : inputValue.editMode ? 'EDITAR' : 'PRÓXIMO'}
        </button>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewProjectRequest: (data, formData) => {
      dispatch(DeliverySystemActions.createNewProjectRequest(data, formData));
    },
    createNewProjectResetFlag: () => {
      dispatch(DeliverySystemActions.createNewProjectResetFlag());
    },
    updateProjectRequest: (cardapioId, data, formData) => {
      dispatch(
        DeliverySystemActions.updateProjectRequest(cardapioId, data, formData)
      );
    },
    updateProjectResetFlag: () => {
      dispatch(DeliverySystemActions.updateProjectResetFlag());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    newProjectState: state.deliverySystem.newProject,
    updateProjectState: state.deliverySystem.updateProject,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDataForm);
