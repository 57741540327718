import React from "react";
import InputMask from "react-input-mask";
import { IconCheck } from "../icons";
import "./src/boxOutline.min.css";

const BoxOutline = ({ label, parentClassCss, inputProps, mask }) => {
  return (
    <div className={`fm-field text-field boxOutline ${parentClassCss}`}>
      <label id={inputProps.id} className="label">
        {label}
      </label>
      <IconCheck className="icon" />
      <InputMask {...inputProps} mask={mask} />
    </div>
  );
};

export default BoxOutline;
