
import React, { Fragment, useState, useEffect } from 'react';
import './src/fields.min.css';

// COMPONENTES DE INPUTS CHeckbox 
const Checkbox = ({ error, label, isChecked, inputProps, className }) => {

  const [checked, setChecked] = useState(isChecked);

  useEffect(()=> {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <Fragment>
      <div className={`fm-field-checkbox ${className ? className : ''}`}>
        {React.createElement('input', { type: 'checkbox', defaultChecked: checked, ...inputProps })}
        <label htmlFor={inputProps.id} className={`${inputProps.disabled ? 'disabled' : ''}`}>
          {label}
        </label>
      </div>
      {error && <span className='error-label'>{error.message}</span>}
    </Fragment>
  )
};

export default Checkbox;
