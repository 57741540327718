import { CampaignCard } from "@/components/cards";
import { DefaultContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import { default as React, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import * as CampaignsActions from "../../store/campaigns/actions";
import "./src/showcase.min.css";

// CARDS DOS CARROSSEIS DAS VITRINES
const CampaignsShowcase = ({ campaigns, loading, readOnly, loadCampaign }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 548,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          dots: true,
          slidesToShow: 3,
        },
      },
    ],
  };

  useEffect(() => {
    loadCampaign(3);
    // eslint-disable-next-line
  }, []);

  return campaigns && !loading ? (
    <section className="showcase campaigns-list">
      <DefaultContainer>
        <SectionTitle title="Aproveite as promoções exclusivas.">
          <Link to="/campanhas" className="btn ic-go">
            veja todas
          </Link>
        </SectionTitle>
        <Slider {...settings}>
          {campaigns &&
            campaigns.map((campaign, i) => {
              if (campaign.vigente) {
                return (
                  <CampaignCard
                    key={`campaign-${i}`}
                    cardType="campaign"
                    campaignId={campaign.id}
                    accept={
                      campaign.participando || !campaign.temRegulamentoAceite
                    }
                    status={campaign.vigente}
                    startDate={campaign.vigenciaDeSrt}
                    endDate={campaign.vigenciaAteSrt}
                    image={campaign.bannerUrl}
                    title={campaign.nome}
                    url={campaign.slug}
                    subtitle={campaign.subtitulo}
                    description={campaign.nome}
                  />
                );
              }
            })}
        </Slider>
      </DefaultContainer>
    </section>
  ) : (
    <></>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCampaign: (pageSize) => {
      dispatch(CampaignsActions.loadCampaign(pageSize));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    readOnly: state.auth.userInfo.readOnly,
    campaigns: state.campaigns.data,
    loading: state.campaigns.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsShowcase);
