import { CustomLoading } from "@/components/customLoading";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as AuthOnActions from "store/auth/actions";
import * as SingleSignOnActions from "store/singleSignOn/actions";
import * as SsoOnActions from "store/singleSignOn/actions";
import { AppMessages } from "../../common/AppMessages";
import { Wrapper } from "./callback/style";

const SingleSignOn = ({
  validSSOParameters,
  signInStart,
  singleSignOnResponse,
  ssoResetParams,
  loading,
  ...rest
}) => {
  const [params, setParams] = useState({
    login: "firstRender",
    tokenDate: undefined,
    token: undefined,
    t4: undefined,
    ls: undefined,
    returnUrl: undefined,
  });

  useEffect(() => {
    signInStart();
    const prs = qs.parse(rest.location.search, { ignoreQueryPrefix: true });

    setParams({
      login: prs.Login || prs.login,
      tokenDate: prs.TokenDate || prs.tokenDate,
      token: prs.Token || prs.token,
      t4: prs.T4 || prs.t4,
      ls: prs.LS || prs.ls,
      returnUrl:
        prs.returnUrl || prs.ReturnUrl
          ? encodeURIComponent(prs.returnUrl || prs.ReturnUrl)
          : null,
    });
  }, []);

  useEffect(() => {
    if (
      params.login !== "firstRender" &&
      params.tokenDate !== undefined &&
      params.token !== undefined
    ) {
      validSSOParameters(params);
    } else if (params.login !== "firstRender" && !loading) {
      window.location.assign(
        `${process.env.REACT_APP_SSO_AUTH}?erro=${encodeURIComponent(
          AppMessages.INVALID_PARAMETERS
        )}`
      );
    } else if (params.login === "") {
      window.location.assign(process.env.REACT_APP_SSO_AUTH);
    }
  }, [params]);

  useEffect(() => {
    if (params.login !== "firstRender" && singleSignOnResponse.valid) {
      window.location.replace(singleSignOnResponse.redirectUrl);
    } else if (
      params.login !== "firstRender" &&
      !loading &&
      singleSignOnResponse.message
    ) {
      ssoResetParams();
      const url = `${process.env.REACT_APP_SSO_AUTH}?erro=${encodeURIComponent(
        singleSignOnResponse.message
      )}`;
      window.location.replace(url);
    }
  }, [singleSignOnResponse, loading]);

  return (
    <Wrapper>
      <CustomLoading show={true} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    singleSignOnResponse: state.singleSignOn.data,
    loading: state.singleSignOn.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validSSOParameters: (params) => {
      dispatch(SingleSignOnActions.validSSOParameters(params));
    },
    signInStart: () => {
      dispatch(AuthOnActions.signInStart());
    },
    ssoResetParams: () => {
      dispatch(SsoOnActions.ssoResetParams());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
