import { AwardsTypes } from './types';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorChoice: false,
  successChoice: false,
  atual: 0
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AwardsTypes.LOAD_AWARDS_REQUEST:
      return { ...state, data: [], loading: true, errorChoice: false, successChoice: false };
    case AwardsTypes.LOAD_AWARDS_SUCCESS:
      return {
        loading: false,
        data: action.payload.data.data, errorChoice: false, successChoice: false
      }
    case AwardsTypes.LOAD_AWARDS_FAILURE:
      return { ...state, data: [], loading: false, error: true, errorChoice: false, successChoice: false };

    case AwardsTypes.LOAD_AWARDS_CHOICE_REQUEST:
      return { ...state, loading: true, errorChoice: false, successChoice: false };
    case AwardsTypes.LOAD_AWARDS_CHOICE_SUCCESS:
      return { ...state, loading: false, errorChoice: false, successChoice: true, atual: action.payload.id };
    case AwardsTypes.LOAD_AWARDS_CHOICE_FAILURE:
      return { ...state, loading: false, errorChoice: true, successChoice: false };

    default:
      return state;
  }
};

export default reducer;
