import React, { useEffect, useState } from 'react'
import { InputDefault } from "../inputs"
import ProductItem from "./productItem"
import AddNewProduct from "./addNewProduct"
import { connect } from 'react-redux'
import './src/products.min.css'

const Products = ({ produtos, categoriaId, cardapioId, success, setUpdateProductList, updateProductList, ...propsModal }) => {

    const [search, setSearch] = useState('')
    const [filteredProducts, setFilteredProducts] = useState(produtos)

    // CONTROLE DOS STEPS - FILTRO E ADD NOVO PRODUDO 
    const [addNewProductStatus, setNewProductStatus] = useState(false)

    // FILTRO DE PRODUTOS
    const handleSearch = (key, value) => {
        if (!value) {
            setSearch('')
            return
        }
        setSearch(value)
    }
    useEffect(() => {
        if (!search || search !== '') {
            setFilteredProducts(produtos)
            return
        } else if (updateProductList.status == 2) {
            setFilteredProducts(produtos)
            setUpdateProductList({status: 3});
            return
        }
        let found = produtos.filter(productItem => productItem.nome.toLowerCase().includes(search.toLowerCase()))
        setFilteredProducts(found)
    }, [search, produtos.length, updateProductList])

    return (
        <section className='bx-products'>
            {!addNewProductStatus && produtos.length > 0
                ?
                <div className='bx-field'>
                    <div className='search-field'>
                        <InputDefault
                            callBack={handleSearch}
                            icon='search'
                            inputProps={{
                                type: 'text',
                                className: 'search',
                                name: 'search',
                                value: search,
                            }}
                        />
                    </div>
                    <a className='btn-register-product' onClick={() => setNewProductStatus(true)} >Adicionar novo produto</a>
                </div>
                : <AddNewProduct setNewProductStatus={setNewProductStatus} categoriaId={categoriaId} cardapioId={cardapioId} />
            }
            {produtos.length > 0 ?
                <ul className='bx-scroll'>
                    {(filteredProducts || []).map((productItem, i) => {
                        return (  
                            <li key={`Category-item-${i}`}>
                                <ProductItem produto={productItem} cardapioId={cardapioId} setUpdateProductList={setUpdateProductList} updateProductList={updateProductList} {...propsModal} />
                            </li>
                        )
                    })}
                </ul>
                : <p>
                    Ainda não existem produtos dessa categoria cadastrados, você pode adicionar um novo produtos <strong>Preenchendo o campo acima.</strong>
                </p>
            }
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        success: state.deliverySystem.categoriesProductsList.success
    }
}

export default connect(mapStateToProps)(Products);