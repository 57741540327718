import React from 'react';

const IconInfo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            viewBox="0 0 4.336 11.563">
            <path
                id="info-solid"
                d="M.452,9.581H.9V6.319H.452A.452.452,0,0,1,0,5.867V4.788a.452.452,0,0,1,.452-.452h2.53a.452.452,0,0,1,.452.452V9.581h.452a.452.452,0,0,1,.452.452v1.079a.452.452,0,0,1-.452.452H.452A.452.452,0,0,1,0,11.112V10.033A.452.452,0,0,1,.452,9.581ZM2.168,0A1.626,1.626,0,1,0,3.794,1.626,1.626,1.626,0,0,0,2.168,0Z"
                fill={props.fill}
                stroke='none' />
        </svg>

    );
};

export default IconInfo;