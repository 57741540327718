import React from "react";
import { connect } from "react-redux";
import "./src/extract.min.css";

const Extract = ({ extrato, onClick, balance }) => {
  function addWhiteSpace (number) {
    let beforeUnity = number.substring(0, 2);
    let afterUnity = number.substring(2).trim();
    
    return (beforeUnity + ' ' + afterUnity)
  }

  return (
        <div className="extrato">
          <div className="saldoPontos">
            <div className="totalSaldoPontos">
              Saldo: <span>{balance ? balance : 0} pontos</span>
            </div>
            {extrato?.extratoPontos?.length > 0 ? (
              <>
                {
                  extrato.extratoPontos.map((item, i) => (
                    <div className="extratoPontos" key={`item${i}`}>
                      <div>{item.insertDate}</div>
                      <div className="description">
                        <span>{item.description}</span>
                        <hr />
                      </div>
                      <div>
                        {`
                          ${item.operationSignal == '-' ? `${item.operationSignal} ` : ''}
                          ${item.valueInPoints} pontos
                        `}
                      </div>
                    </div>
                  ))
                }
              </>
            ) : (
              <>
                Você ainda não tem pontuação computada.
              </>
            )}
          </div>


          <div className='side-box'>
            <div className="fm-button">
              <a onClick={onClick} className="btn btn-main btn-new ic-go">
                Extrato detalhado
              </a>
            </div>
            
            <div className="totalGanho">
              Você já ganhou:<br />
              <span>
                {extrato.totalGanho ? addWhiteSpace(extrato.totalGanho ): 0}
              </span>
            </div>
          </div>
        </div>

  );
};

const mapStateToProps = (state) => {
  return {
    extrato: state.accountStatement.data,
    balance: state.balance.pontuacao,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Extract);
