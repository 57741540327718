import React from 'react'

const IconEye = ({ opened = true, width, height, className, fill }) => {
    if (opened) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={width}
                className={className}
                viewBox="0 0 20 13.333">
                <g transform="translate(-1395 -286)">
                    <g transform="translate(1395 286)">
                        <path
                            d="M19.85,6.185C17.25,2.231,13.75,0,10,0S2.75,2.231.15,6.185a.768.768,0,0,0,0,.913c2.6,4.056,6.1,6.236,9.85,6.236s7.25-2.231,9.85-6.185a.848.848,0,0,0,0-.963ZM10,11.66c-3.05,0-5.9-1.774-8.15-4.968C4.1,3.447,6.95,1.673,10,1.673s5.9,1.774,8.15,4.968C15.9,9.886,13.05,11.66,10,11.66Zm0,0"
                            fill={fill}
                            stroke='none' />
                    </g>
                    <path
                        d="M16,5.2a4,4,0,1,0,4,4,4.012,4.012,0,0,0-4-4Zm0,6.5a2.551,2.551,0,0,1-2.5-2.55A2.518,2.518,0,0,1,16,6.6a2.551,2.551,0,0,1,0,5.1Zm0,0"
                        transform="translate(1389 283.466)"
                        fill={fill}
                        stroke='none' />
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                width={width}
                height={height}
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    fill={fill}
                    stroke='none'
                    d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z">
                </path>
            </svg>
        )
    }
}

export default IconEye