import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect } from "react";
import "./src/bonus.min.css";
import { connect } from "react-redux";
import * as BonusActions from "@/store/bonus/actions";
import PowerbiEmbedded from "react-powerbi";

const Bonus = ({
  loadBonus,
  accessToken,
  embedTokenExpiry,
  embedUrl,
  reportId,
  userPermissions
}) => {
  useEffect(() => {
    loadBonus();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!!userPermissions.length) {
      let perm = userPermissions.find((item) => {
        return item.codigo === "BONIFICACAO";
      })?.permissao;

      return perm ? undefined : window.location.replace("/");
    }
  }, []);

  return (
    <div>
      <SectionTitle title="Bonificação" />
      <div className="bx bonus">
        {accessToken && embedUrl && reportId ? (
          <PowerbiEmbedded
            id={`${reportId}`} // required
            embedUrl={`${embedUrl}`} // required
            accessToken={`${accessToken}`} // required
            filterPaneEnabled={false}
            navContentPaneEnabled={false}
            settings={{
              embedTokenExpiry: embedTokenExpiry,
            }}
            width="100%"
            height="900px"
          />
        ) : (
          "Aguarde..."
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.bonus.data.accessToken,
    embedTokenExpiry: state.bonus.data.embedTokenExpiry,
    embedUrl: state.bonus.data.embedUrl,
    reportId: state.bonus.data.reportId,
    userPermissions: state.auth.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBonus: () => {
      dispatch(BonusActions.loadBonus());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bonus);
