import React, { Fragment, useState, useEffect } from "react";
// import { Link, NavLink } from 'react-router-dom';
import "./src/helpButton.css";
import IconConversation from "../icons/iconConversation";
import IconChat from "../icons/IconChatComponent";
import IconLetter from "../icons/iconLetter";
import IconClose from "../icons/iconClose";
import ModalChat from "../modal/modalPopup";

const HelpButton = () => {
  const toogleBox = () => {
    const boxChatFale = document.querySelector(".content-help");
    if (!boxChatFale.classList.contains("boxActive")) {
      boxChatFale.classList.add("boxActive");
    } else {
      boxChatFale.classList.remove("boxActive");
    }
  };

  return (
    <Fragment>
      <div className="wrap-content-help">
        <div className="content-help content-hight cursor">
          <ModalChat />
          <h3 className="title">
            Como podemos ajudar?
            <span className="wrap-close" onClick={toogleBox}>
              <IconClose
                className="ic-close"
                fill="#ffffff"
                width="14px"
                height="14px"
              />
            </span>
          </h3>
          <div className="helpButtons">
            <div className="wrap-ic-conversation" onClick={toogleBox}>
              <IconConversation
                className="ic-conversation"
                fill="transparent"
                width="35px"
                height="35px"
              />
            </div>
            <div className="bx-chat">
              <a
                target="blank"
                href="https://lnk_bat.vollsc.com/w/ubkxa"
                className="btn btn-main btn-help"
              >
                <div>
                  <IconChat
                    className="ic-accordion"
                    fill="transparent"
                    width="35px"
                    height="35px"
                  />
                </div>
                <div>
                  <span>Chat</span>
                </div>
              </a>
            </div>
            <div className="bx-fale-conosco">
              <a href="/ajuda" className="btn btn-main btn-help">
                <IconLetter
                  className="ic-letter"
                  fill="transparent"
                  width="35px"
                  height="35px"
                />
                <div>
                  <span>Fale conosco</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HelpButton;
