import './src/alertMessage.min.css'
import Swal from 'sweetalert2'


// MENSAGEM DE ALERTA
const AlertMessage = (message, typeAlert, callback) => {
    // const [open, setOpen] = React.useState(true);
    switch (typeAlert) {
        case 'success':
            return (
                Swal.fire({
                    title: 'Sucesso!',
                    text: message,
                    icon: 'success',
                    timer: 5000,
                    timerProgressBar: true,
                    showConfirmButton: true,
                    onClose: () => {
                        callback && callback()
                    }
                })
            )
        case 'error':
            return (
                Swal.fire({
                    title: 'Oops...',
                    text: message,
                    icon: 'error',
                    timer: 8000,
                    timerProgressBar: true,
                    showConfirmButton: true,
                    onClose: () => {
                        callback && callback()
                    }
                })
            )
        default:
            return (
                Swal.fire({
                    text: message,
                    timer: 5000,
                    onClose: () => {
                        callback && callback()
                    }
                })
            )
    }
};

export default AlertMessage;