import Immutable from 'seamless-immutable';
import { PerformanceTypes } from './types';


const INITIAL_STATE = Immutable({
  data: {
    percentualAtingido: 0,
    pacotesComprados: 0,
    dataAtualizacaoStr: 0,
    pacotesComprados: 0,
    faixas: [],
    mesReferencia: 0,
    anoReferencia: 0,
    mesAtual: false
  },
  months: [],
  loading: false,
  error: false,
  success: false,
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PerformanceTypes.PERFORMANCE_REQUEST:
      return { ...state, loading: true };

    case PerformanceTypes.PERFORMANCE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.data,
        loading: false,
        success: action.payload.data.success
      }
    case PerformanceTypes.PERFORMANCE_FAILURE:
      return { ...state, loading: false };

    case PerformanceTypes.SELECT_MONTH_PERFORMANCE_REQUEST:
      return { ...state, loading: true };

    case PerformanceTypes.SELECT_MONTH_PERFORMANCE_SUCCESS:
      return {
        ...state,
        months: action.payload.data.data.data,
        loading: false,
        success: action.payload.data.success
      }

    case PerformanceTypes.SELECT_MONTH_PERFORMANCE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
