import { action } from 'typesafe-actions';
import { ContactUsTypes } from './types';

export const contactUs = (data) => action(ContactUsTypes.CONTACTUS_REQUEST, {data});

export const contactUsSuccess = data => action(ContactUsTypes.CONTACTUS_SUCCESS, { data });

export const contactUsFailure = () => action(ContactUsTypes.CONTACTUS_FAILURE);

export const loadSubjects = (data) => action(ContactUsTypes.SUBJECT_REQUEST, {data});

export const loadSubjectsSuccess = data => action(ContactUsTypes.SUBJECT_SUCCESS, { data });

export const loadSubjectsFailure = () => action(ContactUsTypes.SUBJECT_FAILURE);

export const updateContactSuccessFlag = () => action(ContactUsTypes.CONTACTUS_SUCCESS_FLAG);