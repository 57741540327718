import { action } from "typesafe-actions";
import { SimulatorTypes } from "./types";

export const simulatorState = (key, value) =>
  action(SimulatorTypes.SIMULATOR_STATE, { key, value });

export const simulatorStateInitial = () =>
  action(SimulatorTypes.SIMULATOR_STATE_INITIAL);

export const simulatorRequest = (params) =>
  action(SimulatorTypes.SIMULATOR_REQUEST, { params });

export const simulatorSuccess = (data) =>
  action(SimulatorTypes.SIMULATOR_SUCCESS, { data });

export const simulatorFailure = () => action(SimulatorTypes.SIMULATOR_FAILURE);
