export const DeliverySystemTypes = {
    LOAD_PROJECTS_LIST_REQUEST : "@deliverySystem/LOAD_PROJECTS_LIST_REQUEST",
    LOAD_PROJECTS_LIST_SUCCESS : "@deliverySystem/LOAD_PROJECTS_LIST_SUCCESS",
    LOAD_PROJECTS_LIST_FAILURE : "@deliverySystem/LOAD_PROJECTS_LIST_FAILURE",
    LOAD_CATEGORIES_AND_PRODUCTS_REQUEST : "@deliverySystem/LOAD_CATEGORIES_AND_PRODUCTS_REQUEST",
    LOAD_CATEGORIES_AND_PRODUCTS_SUCCESS : "@deliverySystem/LOAD_CATEGORIES_AND_PRODUCTS_SUCCESS",
    LOAD_CATEGORIES_AND_PRODUCTS_FAILURE : "@deliverySystem/LOAD_CATEGORIES_AND_PRODUCTS_FAILURE",
    LOAD_CATEGORIES_AND_PRODUCTS_RESET_FLAG : "@deliverySystem/LOAD_CATEGORIES_AND_PRODUCTS_RESET_FLAG",
    CREATE_NEW_PROJECT_REQUEST: "@deliverySystem/CREATE_NEW_PROJECT_REQUEST",
    CREATE_NEW_PROJECT_SUCCESS : "@deliverySystem/CREATE_NEW_PROJECT_SUCCESS",
    CREATE_NEW_PROJECT_FAILURE : "@deliverySystem/CREATE_NEW_PROJECT_FAILURE",
    CREATE_NEW_PROJECT_RESET_FLAG : "@deliverySystem/CREATE_NEW_PROJECT_RESET_FLAG",
    PUT_PRODUCT_ON_MENU_REQUEST: "@deliverySystem/PUT_PRODUCT_ON_MENU_REQUEST",
    PUT_PRODUCT_ON_MENU_SUCCESS : "@deliverySystem/PUT_PRODUCT_ON_MENU_SUCCESS",
    PUT_PRODUCT_ON_MENU_FAILURE : "@deliverySystem/PUT_PRODUCT_ON_MENU_FAILURE",
    PUT_PRODUCT_ON_MENU_RESET_FLAG : "@deliverySystem/PUT_PRODUCT_ON_MENU_RESET_FLAG",
    UPDATE_PROJECT_REQUEST: "@deliverySystem/UPDATE_PROJECT_REQUEST",
    UPDATE_PROJECT_SUCCESS : "@deliverySystem/UPDATE_PROJECT_SUCCESS",
    UPDATE_PROJECT_FAILURE : "@deliverySystem/UPDATE_PROJECT_FAILURE",
    UPDATE_PROJECT_RESET_FLAG : "@deliverySystem/UPDATE_PROJECT_RESET_FLAG",
    CREATE_NEW_PRODUCT_REQUEST: "@deliverySystem/CREATE_NEW_PRODUCT_REQUEST",
    CREATE_NEW_PRODUCT_SUCCESS : "@deliverySystem/CREATE_NEW_PRODUCT_SUCCESS",
    CREATE_NEW_PRODUCT_FAILURE : "@deliverySystem/CREATE_NEW_PRODUCT_FAILURE",
    CREATE_NEW_PRODUCT_RESET_FLAG : "@deliverySystem/CREATE_NEW_PRODUCT_RESET_FLAG",
    UPDATE_PRODUCT_REQUEST: "@deliverySystem/UPDATE_PRODUCT_REQUEST",
    UPDATE_PRODUCT_SUCCESS : "@deliverySystem/UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILURE : "@deliverySystem/UPDATE_PRODUCT_FAILURE",
    UPDATE_PRODUCT_RESET_FLAG : "@deliverySystem/UPDATE_PRODUCT_RESET_FLAG",
    CREATE_NEW_CATEGORY_REQUEST: "@deliverySystem/CREATE_NEW_CATEGORY_REQUEST",
    CREATE_NEW_CATEGORY_SUCCESS : "@deliverySystem/CREATE_NEW_CATEGORY_SUCCESS",
    CREATE_NEW_CATEGORY_FAILURE : "@deliverySystem/CREATE_NEW_CATEGORY_FAILURE",
    CREATE_NEW_CATEGORY_RESET_FLAG : "@deliverySystem/CREATE_NEW_CATEGORY_RESET_FLAG",
    UPDATE_CATEGORY_REQUEST: "@deliverySystem/UPDATE_CATEGORY_REQUEST",
    UPDATE_CATEGORY_SUCCESS : "@deliverySystem/UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILURE : "@deliverySystem/UPDATE_CATEGORY_FAILURE",
    UPDATE_CATEGORY_RESET_FLAG : "@deliverySystem/UPDATE_CATEGORY_RESET_FLAG",
    REMOVE_PRODUCT_REQUEST: "@deliverySystem/REMOVE_PRODUCT_REQUEST",
    REMOVE_PRODUCT_SUCCESS : "@deliverySystem/REMOVE_PRODUCT_SUCCESS",
    REMOVE_PRODUCT_FAILURE : "@deliverySystem/REMOVE_PRODUCT_FAILURE",
    REMOVE_PRODUCT_RESET_FLAG : "@deliverySystem/REMOVE_PRODUCT_RESET_FLAG",
    REMOVE_PRODUCT_FROM_MENU_REQUEST: "@deliverySystem/REMOVE_PRODUCT_FROM_MENU_REQUEST",
    REMOVE_PRODUCT_FROM_MENU_SUCCESS : "@deliverySystem/REMOVE_PRODUCT_FROM_MENU_SUCCESS",
    REMOVE_PRODUCT_FROM_MENU_FAILURE : "@deliverySystem/REMOVE_PRODUCT_FROM_MENU_FAILURE",
    REMOVE_PRODUCT_FROM_MENU_RESET_FLAG : "@deliverySystem/REMOVE_PRODUCT_FROM_MENU_RESET_FLAG",
    REMOVE_CATEGORY_REQUEST: "@deliverySystem/REMOVE_CATEGORY_REQUEST",
    REMOVE_CATEGORY_SUCCESS : "@deliverySystem/REMOVE_CATEGORY_SUCCESS",
    REMOVE_CATEGORY_FAILURE : "@deliverySystem/REMOVE_CATEGORY_FAILURE",
    REMOVE_CATEGORY_RESET_FLAG : "@deliverySystem/REMOVE_CATEGORY_RESET_FLAG",
}