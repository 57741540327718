import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { SectionTitle } from '@/components/sectionTitle'
import { LoadCentralizationOfPoints } from '@/store/centralizationOfPoints/sagas'
import Modal from '@/components/modal/modal'
import './src/centralizationOfPoints.min.css'

const CentralizationOfPoints = ({ matriz, creditPointsRule, readOnly, cancelado }) => {

    const [creditPointsForMatrix, setCreditPointsForMatrix] = useState(creditPointsRule)

    const [showConfirmation, setshowConfirmation] = useState('close')

    const propsModal = {
        setModalState: setshowConfirmation,
        modalState: showConfirmation,
    }

    const handleCancelCreditPointsRole = (event) => {
        event.preventDefault()
        setCreditPointsForMatrix(false)
        setshowConfirmation('close')
    }
    const handleSaveCreditPointsRole = async (event) => {
        event.preventDefault()
        setshowConfirmation('close')
        const response = await LoadCentralizationOfPoints()
        if (response) {
            setCreditPointsForMatrix(true)
        }
    }

    if (matriz)
        return (
            <section className='bx bx-centralization-points'>
                <div className='centralization-of-points'>
                    <h3>Deseja receber os créditos de pontos de toda a sua rede?</h3>
                    <div className='bx-checkbox'>
                        <div className="fm-field-checkbox">
                            <input
                                type="checkbox"
                                id="creditPointsForMatrix"
                                name="creditPointsForMatrix"
                                disabled={creditPointsForMatrix || readOnly || cancelado}
                                checked={creditPointsForMatrix}
                                onChange={() => { setshowConfirmation('open') }}
                            />
                            <label htmlFor="creditPointsForMatrix"> Sim</label>
                        </div>
                        <div className="fm-field-checkbox">
                            <input
                                type="checkbox"
                                id="notCreditPointsForMatrix"
                                name="creditPointsForMatrix"
                                disabled={creditPointsForMatrix || readOnly || cancelado}
                                checked={!creditPointsForMatrix}
                            />
                            <label htmlFor="notCreditPointsForMatrix"> Não</label>
                        </div>
                    </div>
                </div>
                <Modal {...propsModal}>
                    <SectionTitle title='Transferência<br /> de pontos' />
                    <p>Você tem certeza que deseja receber todos os pontos das suas filiais?</p>
                    <p>
                    Após a confirmação, a matriz receberá automaticamente os créditos de pontos de toda a sua rede de varejos,
                     e essa ação só poderá ser alterada através dos canais de atendimento.
                    </p>
                    <div className="fm-button">
                        <button onClick={handleCancelCreditPointsRole} className='btn btn-inverted ic-go'>cancelar</button>
                        <button onClick={handleSaveCreditPointsRole} className='btn btn-main ic-go'>confirmar</button>
                    </div>
                </Modal>
            </section>
        )
}
const mapStateToProps = state => {
    return {
        matriz: state.myData.data.matriz,
        creditPointsRule: state.myData.data.centralizarPontuacao,
        readOnly: state.auth.userInfo.readOnly,
        cancelado: state.myData.data.cancelado,
    }
}
export default connect(mapStateToProps)(CentralizationOfPoints)