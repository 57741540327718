import { action } from 'typesafe-actions';
import { DeliverySystemTypes } from './types';

// GET lista de projetos - Home
export const loadProjectsListRequest = () => action(DeliverySystemTypes.LOAD_PROJECTS_LIST_REQUEST);
export const loadProjectsListSuccess = (data) => action(DeliverySystemTypes.LOAD_PROJECTS_LIST_SUCCESS, { data });
export const loadProjectsListFailure = (error) => action(DeliverySystemTypes.LOAD_PROJECTS_LIST_FAILURE, { error });

// GET Lista de categorias e Produtos
export const loadCategoriesAndProductsRequest = (cardapioId) => action(DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_REQUEST, { cardapioId });
export const loadCategoriesAndProductsSuccess = (data) => action(DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_SUCCESS, { data });
export const loadCategoriesAndProductsFailure = (error) => action(DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_FAILURE, { error });
export const loadCategoriesAndProductsResetFlag = () => action(DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_RESET_FLAG);

// POST Novo projeto - Home
export const createNewProjectRequest = (data, formData) => action(DeliverySystemTypes.CREATE_NEW_PROJECT_REQUEST, { data, formData });
export const createNewProjectSuccess = (data) => action(DeliverySystemTypes.CREATE_NEW_PROJECT_SUCCESS, { data });
export const createNewProjectFailure = (error) => action(DeliverySystemTypes.CREATE_NEW_PROJECT_FAILURE, { error });
export const createNewProjectResetFlag = () => action(DeliverySystemTypes.CREATE_NEW_PROJECT_RESET_FLAG);

// POST Adicionar Produto ao cardápio
export const putProductOnMenuRequest = (data) => action(DeliverySystemTypes.PUT_PRODUCT_ON_MENU_REQUEST, { data });
export const putProductOnMenuSuccess = (data) => action(DeliverySystemTypes.PUT_PRODUCT_ON_MENU_SUCCESS, { data });
export const putProductOnMenuFailure = (error) => action(DeliverySystemTypes.PUT_PRODUCT_ON_MENU_FAILURE, { error });
export const putProductOnMenuResetFlag = () => action(DeliverySystemTypes.PUT_PRODUCT_ON_MENU_RESET_FLAG);

// POST Nova categoria de produtos
export const createNewCategoryRequest = (data) => action(DeliverySystemTypes.CREATE_NEW_CATEGORY_REQUEST, { data });
export const createNewCategorySuccess = (data) => action(DeliverySystemTypes.CREATE_NEW_CATEGORY_SUCCESS, { data });
export const createNewCategoryFailure = (error) => action(DeliverySystemTypes.CREATE_NEW_CATEGORY_FAILURE, { error });
export const createNewCategoryResetFlag = () => action(DeliverySystemTypes.CREATE_NEW_CATEGORY_RESET_FLAG);

// PATCH editar projeto
export const updateProjectRequest = (cardapioId, data, formData) => action(DeliverySystemTypes.UPDATE_PROJECT_REQUEST, { cardapioId, data, formData });
export const updateProjectSuccess = (data) => action(DeliverySystemTypes.UPDATE_PROJECT_SUCCESS, { data });
export const updateProjectFailure = (error) => action(DeliverySystemTypes.UPDATE_PROJECT_FAILURE, { error });
export const updateProjectResetFlag = () => action(DeliverySystemTypes.UPDATE_PROJECT_RESET_FLAG);

// PATCH Editar categoria
export const updateCategoryRequest = (categoriaId, data) => action(DeliverySystemTypes.UPDATE_CATEGORY_REQUEST, { categoriaId, data });
export const updateCategorySuccess = (data) => action(DeliverySystemTypes.UPDATE_CATEGORY_SUCCESS, { data });
export const updateCategoryFailure = (error) => action(DeliverySystemTypes.UPDATE_CATEGORY_FAILURE, { error });
export const updateCategoryResetFlag = () => action(DeliverySystemTypes.UPDATE_CATEGORY_RESET_FLAG);

// POST Cadastrar novo produto a uma categoriaId
export const createNewProductRequest = (data) => action(DeliverySystemTypes.CREATE_NEW_PRODUCT_REQUEST, { data });
export const createNewProductSuccess = (data) => action(DeliverySystemTypes.CREATE_NEW_PRODUCT_SUCCESS, { data });
export const createNewProductFailure = (error) => action(DeliverySystemTypes.CREATE_NEW_PRODUCT_FAILURE, { error });
export const createNewProductResetFlag = () => action(DeliverySystemTypes.CREATE_NEW_PRODUCT_RESET_FLAG);

// PATCH Editar um produto
export const updateProductRequest = (produtoId, data) => action(DeliverySystemTypes.UPDATE_PRODUCT_REQUEST, { produtoId, data });
export const updateProductSuccess = (data) => action(DeliverySystemTypes.UPDATE_PRODUCT_SUCCESS, { data });
export const updateProductFailure = (error) => action(DeliverySystemTypes.UPDATE_PRODUCT_FAILURE, { error });
export const updateProductResetFlag = () => action(DeliverySystemTypes.UPDATE_PRODUCT_RESET_FLAG);

// DELETE Deletar um produto
export const removeProductRequest = (produtoId) => action(DeliverySystemTypes.REMOVE_PRODUCT_REQUEST, { produtoId });
export const removeProductSuccess = (data) => action(DeliverySystemTypes.REMOVE_PRODUCT_SUCCESS, { data });
export const removeProductFailure = (error) => action(DeliverySystemTypes.REMOVE_PRODUCT_FAILURE, { error });
export const removeProductResetFlag = () => action(DeliverySystemTypes.REMOVE_PRODUCT_RESET_FLAG);

// DELETE Remover um produto do cardápio
export const removeProductFromMenuRequest = (cardapioId, produtoId) => action(DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_REQUEST, { cardapioId, produtoId });
export const removeProductFromMenuSuccess = (data) => action(DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_SUCCESS, { data });
export const removeProductFromMenuFailure = (error) => action(DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_FAILURE, { error });
export const removeProductFromMenuResetFlag = () => action(DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_RESET_FLAG);

// DELETE Deletar uma categoria de um cardapio
export const removeCategoryRequest = (categoriaId, cardapioId) => action(DeliverySystemTypes.REMOVE_CATEGORY_REQUEST, { categoriaId, cardapioId });
export const removeCategorySuccess = (data) => action(DeliverySystemTypes.REMOVE_CATEGORY_SUCCESS, { data });
export const removeCategoryFailure = (error) => action(DeliverySystemTypes.REMOVE_CATEGORY_FAILURE, { error });
export const removeCategoryResetFlag = () => action(DeliverySystemTypes.REMOVE_CATEGORY_RESET_FLAG);
