import React, { useEffect, useState } from 'react'
import './src/inputs.min.css'

const InputDefault = ({ callBack, inputProps, ...props }) => {
    const [fieldStatus, SetFieldStatus] = useState(!props.value ? 0 : 1)

    useEffect(() => {
        if (inputProps.value) SetFieldStatus(1)
        // eslint-disable-next-line
    },[inputProps?.value])

    const handleChange = ({ target }) => {
        SetFieldStatus(!target.value ? 2 : 1)
        callBack(inputProps.name, target.value)
    }

    return (
        <div className={`field-ct ${fieldStatus ? 'filled' : 'empty'}`}>
            <input onChange={handleChange} {...inputProps} />
            <label htmlFor={inputProps.id}>
                {props.icon && <i className={`icon icon-icon_${props.icon}`}></i>}
                <span>{props.labelText}</span>
            </label>
            {fieldStatus === 2 && <span className='error'></span>}
        </div>
    )
}

export default InputDefault
