import React from 'react'
import {AcceptanceTermTypes} from './types';
import { useSelector } from 'react-redux';

const INITIAL_STATE = {
  loading: false,
  aceiteTermos : false,
};

const reducer = (state = INITIAL_STATE,  action) => {
  switch (action.type) {
      case AcceptanceTermTypes.ACCEPTANCETERM_REQUEST:
        return {...state, loading: true};

        case AcceptanceTermTypes.ACCEPTANCETERM_SUCCESS:
          return {
            ...state,
            loading: false,
            aceiteTermos : true, 
          };
      case AcceptanceTermTypes.ACCEPTANCETERM_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
