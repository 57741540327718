import {action} from 'typesafe-actions';
import {MigrationOfPointsTypes} from './types';

export const migrationState = (node, key, value) => action(MigrationOfPointsTypes.MIGRATION_STATE, {node, key, value});

export const migrationPointsSubsidiaryRequest = () => action(MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_REQUEST);
export const migrationPointsSubsidiarySuccess = (data) => action(MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_SUCCESS, {data});
export const migrationPointsSubsidiaryFailure = () => action(MigrationOfPointsTypes.MIGRATION_POINTS_SUBSIDIARY_FAILURE);

export const migrationPointsRequest = (data) => action(MigrationOfPointsTypes.MIGRATION_POINTS_REQUEST, {data});
export const migrationPointsSuccess = (data) => action(MigrationOfPointsTypes.MIGRATION_POINTS_SUCCESS, {data});
export const migrationPointsFailure = (error) => action(MigrationOfPointsTypes.MIGRATION_POINTS_FAILURE, {error});

export const migrationPointsHistoryRequest = () => action(MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_REQUEST);
export const migrationPointsHistorySuccess = (data) => action(MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_SUCCESS, {data});
export const migrationPointsHistoryFailure = () => action(MigrationOfPointsTypes.MIGRATION_POINTS_HISTORY_FAILURE);