import { call, put } from 'redux-saga/effects';
import api from '../../common/api';

import { participantBankSlipHistorySuccess, participantBankSlipHistoryFailure, participantGenerateBankSlipSuccess, participantGenerateBankSlipFailure } from './actions';

export function* participantBankSlipHistoryRequest(action){
    try {
        const {data} = yield call(api.get, `/boleto-hotsite/ListarPagamentos/${action.payload.document}`);

        yield put(participantBankSlipHistorySuccess(data));

    } catch (error) {
        yield put(participantBankSlipHistoryFailure(error));
    }
}

export function* participantGenerateBankSlipRequest(action){
    try {
        const {data} = yield call(api.post, `/boleto-hotsite/SegundaViaBoleto`, action.payload.data);

        yield put(participantGenerateBankSlipSuccess(data));

    } catch (error) {
        yield put(participantGenerateBankSlipFailure(error));
    }
}
export function getParticipantGenerateBankSlipRequest(data){
    return  api.post(`/boleto-hotsite/SegundaViaBoleto`, data);
}