import { action } from 'typesafe-actions';
import { AwardsTypes } from './types';

export const loadAwards = () => action(AwardsTypes.LOAD_AWARDS_REQUEST);

export const loadAwardsSuccess = data => action(AwardsTypes.LOAD_AWARDS_SUCCESS, { data });

export const loadAwardsFailure = () => action(AwardsTypes.LOAD_AWARDS_FAILURE);


export const choiceAwards = (data) => action(AwardsTypes.LOAD_AWARDS_CHOICE_REQUEST, { data });

export const choiceAwardsSuccess = (id) => action(AwardsTypes.LOAD_AWARDS_CHOICE_SUCCESS, { id });

export const choiceAwardsFailure = () => action(AwardsTypes.LOAD_AWARDS_CHOICE_FAILURE);
