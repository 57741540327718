import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@/components/modal";
import { Regulation } from "@/components/regulation/";
import * as AcceptanceTermActions from "../../store/acceptanceTerm/actions";
import { connect } from "react-redux";

const AcceptanceMethod = ({
  readOnly,
  acceptanceTerm,
  idCampanha,
  textRegulation,
  acceptanceTermClick,
  cancelado,
  loading
}) => {
  const [modalState, setModalState] = useState("close");
  const [termosAceite, setTermosAceite] = useState(false);
  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    customClass: "customClass",
  };

  useEffect(() => {
    const showModal =
    idCampanha > 0 &&
    !acceptanceTerm &&
    !cancelado &&
    textRegulation &&
    textRegulation !== "";
    setModalState(showModal ? "open" : "close");
  }, [idCampanha, acceptanceTerm]);

  const btnAccept = () => {
    if (termosAceite) {
      acceptanceTermClick(idCampanha);
      setModalState("close");
    }
  };

  return (
    <>
      <Modal {...propsModal}>
        <Regulation />

        <div className="fm-field-checkbox">
          <input
            type="checkbox"
            id="termosAceite"
            name="nTermosAceite"
            onChange={() => {
              setTermosAceite((termosAceite) => !termosAceite)
            }}
          />
          <label htmlFor="termosAceite">
            Declaro que sou maior de 18 anos e que aceito os termos de políticas
            e privacidade.
          </label>
        </div>

        <div className="bx-button">
          <a
            className="btn btn-main ic-go"
            disabled={readOnly || !termosAceite || loading || cancelado}
            onClick={() => {
              btnAccept()
            }}
          >
            Salvar e continuar navegando
          </a>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    readOnly: state.auth.userInfo.readOnly,
    acceptanceTerm: state.myData.data.aceiteTermos,
    idCampanha: state.regulation.idCampanha,
    textRegulation: state.regulation.regulation,
    cancelado: state.myData.data.cancelado,
    loading: state.acceptanceTerm.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    acceptanceTermClick: (idCampanha) => {
      dispatch(AcceptanceTermActions.acceptanceTerm(idCampanha));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceMethod);
