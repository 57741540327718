import React from 'react';

const IconDocument = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
            <g transform="translate(1 1)">
                <path
                    d="M0,0H14a2,2,0,0,1,2,2V19.953C16,23.6,13.693,24,9.663,24H-5.483S-2,23.319-2,20.284V2A2,2,0,0,1,0,0Z"
                    transform="translate(8)"
                    fill="none"
                    stroke={props.fill}
                    strokeWidth="2" />
                <line x2="9"
                    transform="translate(10.5 4.5)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="square"
                    strokeWidth="2" />
                <line x2="9"
                    transform="translate(10.5 8.5)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="square"
                    strokeWidth="2" />
                <line x2="9"
                    transform="translate(10.5 12.5)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="square"
                    strokeWidth="2" />
                <line x2="5"
                    transform="translate(10.5 16.5)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="square"
                    strokeWidth="2" />
                <path d="M269.882,756.815H264v8.2A2.945,2.945,0,0,0,267.037,768"
                    transform="translate(-264 -744)"
                    fill="none" 
                    stroke={props.fill} 
                    strokeWidth="2" />
            </g>
        </svg>

    );
};

export default IconDocument;