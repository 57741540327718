import React from 'react'
import {SectionTitle} from '@/components/sectionTitle'
import './src/acceptanceTerms.min.css'

const PrivacyPolicy = ({ termo }) => {
    return (
        <section className='acceptance-terms'>
            <SectionTitle title='Termos de política e privacidade' />
            <div className='bx bx-acceptance-terms'>
                <div className='container-scroll'>
                <p ><b>TERMOS DE USO E PRIVACIDADE </b></p>

<p><span><b><span >PORTAL
        CONECTA PRIME</span></b></span><span><b><span ></span></b></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><span>Bem-vindo ao </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span
     >.</span></span><span><b><span></span></b></span></p>

<p><span><span>A seguir
      apresentamos os <b>Termos de Uso e
        Privacidade</b>, documento que relaciona as principais regras que devem ser
      observadas por todos que acessam o <b>PORTAL
        CONECTA PRIME</b> ou utilizam suas funcionalidades.</span></span></p>

<p><span><span>Como condição
      para acesso e uso das funcionalidades exclusivas do <b>PORTAL CONECTA PRIME</b>, sobretudo a contratação dos
      serviços
      oferecidos, o <b>USUÁRIO</b> declara que
      fez a leitura completa e atenta das regras deste documento, estando plenamente
      ciente conferindo assim sua livre e expressa concordância com os termos aqui
      estipulados.</span></span></p>

<p><span><b><span><span>1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>FUNCIONAMENTO E
        CONDUTA DO <span>PORTAL CONECTA PRIME</span></span></b></span></p>

<p><span><span><span>1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>PORTAL CONECTA PRIME</b> é a plataforma de serviços do Programa de
      Relacionamento de Varejistas, intitulado <b>CONECTA
        PRIME</b> da <b>SOUZA CRUZ</b>. </span></span><span><span></span></span></p>

<p><span><span><span>1.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Somente empresas
      varejistas que já mantém relacionamento com a <b>SOUZA CRUZ</b> poderão cadastrar uma conta de acesso e utilizar
      os
      serviços do <b>PORTAL CONECTA PRIME</b>.
      Caso você seja um varejista, mas não possui relação com a <b>SOUZA CRUZ</b>, entre em contato por meio do 0800 723
      2221.</span></span><span><span></span></span></p>

<p><span><span><span>1.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Para cadastrar uma conta
      de acesso no <b>PORTAL CONECTA PRIME</b>,
      você deverá cadastrar o <i>login</i> e senha
      de acesso.</span></span><span><span></span></span></p>

<p><span><span><span>1.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Somente <b>USUÁRIOS</b> maiores de 18 (dezoito) anos
      que possui poderes de representação da empresa varejista poderão acessar o <b>PORTAL CONECTA
        PRIME</b></span></span><span><span>.</span></span><span></span><span><span></span></span></p>

<p><span><span><span>1.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Por medidas de segurança, no
      primeiro acesso o <b>USUÁRIO</b> deverá
      obrigatoriamente cadastrar uma senha e manifestar seu consentimento às regras
      do Programa de Relacionamento <b>CONECTA
        PRIME</b> e de seu Termo de Parceria Comercial junto ao </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span>.</span></span><span><span></span></span></p>

<p><span><span><span>1.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O
      <b>USUÁRIO</b> será informado pela forma de
      contato indicada no pré-cadastro (e-mail, WhatsApp ou SMS sobre a aprovação) de
      seu cadastro no</span></span><span><b><span> PORTAL CONECTA
        PRIME</span></b><span>.</span></span><span></span><span><span></span></span></p>

<p><span><span><span>1.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span></b> poderá alterar os
    critérios de elegibilidade de acesso e utilização do</span><span><span> <b>PORTAL CONECTA PRIEM</b></span>
    a qualquer momento, sem que para isso tenha de fazer qualquer tipo de
    comunicação ou aviso prévio ao </span><span><b><span>USUÁRIO.</span></b></span></p>

<p><span><span><span>1.8.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span></b> possui a faculdade de se
    recusar a conceder, suspender ou cancelar a <b>Conta de Acesso</b> de qualquer
  </span><span><b><span>USUÁRIO</span></b> que a utilizar de forma fraudulenta,
    violar ou tentar violar os presentes <b>Termos
      de Uso e Privacidade</b> ou qualquer documento legal da <b><span>SOUZA CRUZ</span></b>
    disponibilizado no</span><span><span> <b>PORTAL CONECTA PRIME</b></span><b>.</b></span></p>

<p><span><span><span>1.9.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span></b> se reserva ao direito
    ainda de solicitar ao </span><span><b><span>USUÁRIO</span></b> o envio de informações complementares e
    eventuais cópias de documentos, visando única e exclusivamente confirmar a
    identidade do </span><span><b><span>USUÁRIO</span></b> e evitar a ocorrência de eventuais danos
    ou prejuízos.</span></p>

<p><span>
    
  </span></p>

<p><span><b><span><span>2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b>RESPONSABILIDADES
      DA SOUZA CRUZ</b></span></p>

<p><span><span><span>2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>O</span><span><span> <b>PORTAL CONECTA PRIME</b></span> e suas
    funcionalidades são apresentadas ao <b>USUÁRIO</b>
    na maneira como estão disponíveis, podendo passar por constantes aprimoramentos
    e atualizações, obrigando-se a <b>SOUZA
      CRUZ</b> a:</span></p>

<p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Preservar a sua funcionalidade, com <i>links</i> não quebrados e utilizando <i>layout</i>
    que respeita a usabilidade e
    navegabilidade, sempre que possível;</span></p>

<p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Exibir as funcionalidades de maneira clara,
    completa, precisa e suficiente de modo que exista a exata percepção das
    operações realizadas; e</span></p>

<p><span><span><span>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Proteger, por meio do estado da técnica
    disponível, os dados coletados pelas funcionalidades disponibilizadas.</span></p>

<p><span><span><span>2.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span> </b>envida seus esforços
    para a manutenção da disponibilidade contínua e permanente do</span><span><span> <b>PORTAL
        CONECTA PRIME</b></span>. No entanto, pode ocorrer, eventualmente, programada
    ou não, alguma indisponibilidade temporária decorrente de manutenção necessária
    ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos
    sistemas de comunicação e acesso à Internet ou fatos de terceiro que fogem de
    sua esfera de vigilância e responsabilidade.</span></p>

<p><span><span><span>2.2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Se isso ocorrer, a <b><span>SOUZA
          CRUZ</span></b> fará o que estiver ao seu alcance para restabelecer o acesso ao</span></span><span><span>
      <b>PORTAL
        CONECTA PRIME</b></span><span> o mais breve possível,
      dentro das limitações técnicas de seus serviços e serviços de terceiros, dos
      quais depende para ficar online.</span></span></p>

<p><span><span><span>2.2.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Eventuais
      procedimentos de manutenção que acarretem a indisponibilidade d</span></span><span><span>o <b>PORTAL
        CONECTA PRIME</b></span><span> por longos períodos serão informados
      por meio dos canais oficiais de comunicação, tais como e-mails, SMS ou telefone
      de atendimento.</span></span></p>

<p><span><span><span>2.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>O </span><span><b><span>USUÁRIO</span></b> não possui qualquer direito para exigir a
    disponibilidade da <b><span>SOUZA CRUZ</span> </b>tampouco poderá pleitear
    indenização ou reparação de danos no caso do <b><span>PORTAL CONECTA PRIME</span> </b>permanecer
    fora do ar, independente da motivação.</span></p>

<p><span><span><span>2.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A<b> SOUZA CRUZ</b></span> reserva-se o direito
    de alterar, adequar ou remodelar o conteúdo, layout, funcionalidades e/ou
    ferramentas relacionadas ao <b>PORTAL
      CONECTA PRIME</b>, a qualquer tempo, segundo sua exclusiva conveniência, sempre
    que entender necessário para oferecer os melhores serviços ao <b>USUÁRIO</b>. </span></p>

<p><span><span><span>2.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span> </b>pode de acordo com seus
    objetivos de negócio modificar ou descontinuar (temporária ou permanentemente)
    a distribuição ou a atualização do <b>PORTAL
      CONECTA PRIME.</b></span></p>

<p><span><span><span>2.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b><span>SOUZA CRUZ</span> </b>não se responsabiliza:</span></p>

<p><span><span><span>2.6.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Por quaisquer problemas, <i>bugs</i>, <i>glitches</i> ou
    funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos <b>USUÁRIOS </b>e sejam resultantes
    direta ou
    indiretamente do uso regular do <b><span>PORTAL CONECTA PRIME</span>.</b></span></p>

<p><span><span><span>2.6.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Por qualquer dano direto ou indireto ocasionado
    por eventos de terceiros, a exemplo, mas não se limitando a ataque de <i>hackers</i>, falhas no sistema, no servidor
    ou na conexão à internet, inclusive por ações de softwares que possam, de algum
    modo, danificar ativos físicos ou lógicos ou a conexão dos<span> </span><b>USUÁRIOS</b>
    em decorrência do acesso, utilização ou navegação no <b><span>PORTAL CONECTA PRIME</span>,</b>
    bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos
    contidos neste.</span></p>

<p><span><span><span>2.6.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Pela
      navegação dos </span></span><span><b><span>USUÁRIOS</span></b></span><span><span> nos <i>links </i>externos
      disponibilizados no </span><b><span>PORTAL CONECTA PRIME</span></b></span><span><span>,
      sendo dever do</span></span><span><b><span> USUÁRIO</span></b></span><span><span> a leitura dos <b>Termos e
        Privacidade</b> do recurso acessado e agir conforme o
      determinado.</span></span></p>

<p><span><span><span>2.6.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Por verificar, controlar, aprovar ou garantir a
    adequação ou exatidão das informações ou dados disponibilizados em tais links,
    não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela
    visita de tais sites, cabendo ao interessado verificar a confiabilidade das
    informações e dados ali exibidos antes de tomar alguma decisão ou praticar
    algum ato.</span></p>

<p><span><span><span>2.6.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Pelas atitudes tomadas pelos </span><span><b><span>USUÁRIO</span></b> com base,
    direta ou indiretamente, no </span><span><b><span>PORTAL CONECTA PRIME</span></b>,
    suas regras, relatórios, regulamentos, opiniões, sugestões e outros,
    concordando o <b>USUÁRIO </b>que a
    utilizará com bom senso, baseado na legislação em vigor, moral, ética, boa-fé
    na condução dos negócios e bons costumes.</span></p>

<p><span>
    
  </span></p>

<p><span><b><span><span>3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b>RESPONSABILIDADES
      DOS USUÁRIO<span>S</span></b></span></p>

<p><span><span><span>3.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Os <b>USUÁRIOS
    </b>são responsáveis e se obrigam a:</span></p>

<p><span><span><span>3.1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Utilizar com retidão e de forma ética o </span><span><b><span>PORTAL CONECTA
        PRIME</span></b>, respeitando as condições que regem
    a sua utilização e finalidade.</span></p>

<p><span><span><span>3.1.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Fornecer dados cadastrais corretos, completos e
    atualizados, além de informar canal de contato apto a ser acionado pela <b><span>SOUZA
        CRUZ</span> </b>para o melhor cumprimento dos serviços. Se a qualquer momento
    for constatado que o <b>USUÁRIO</b>
    forneceu dados falsos ou não condizentes com a realidade, a <b><span>SOUZA
        CRUZ</span> </b>se reserva ao direito de suspender ou cancelar seu cadastro,
    sem prejuízo de adotar as medidas que entender cabíveis.</span></p>

<p><span><span><span>3.1.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Dispor de dispositivos e equipamentos
    compatíveis, serviço de conexão à <i>Internet</i>
    com antivírus e <i>firewall</i> habilitados,
    <i>softwares</i> devidamente atualizados.</span></p>

<p><span><span><span>3.1.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Manter o sigilo dos dados de acesso à sua Conta,
    em especial o seu login e senha, em relação a terceiros e utilizá-la de modo
    individual e intransferível, não disponibilizando, divulgando ou compartilhando
    sua senha ou qualquer mecanismo de autenticação com quem quer que seja, sob
    qualquer meio ou forma, inclusive em Plataformas ofertadas por terceiros. Caso
    suspeite que a confidencialidade de sua senha foi violada, o <b>USUÁRIO </b>deve proceder sua troca ou
    atualização o mais rápido possível ou contatar os canais de atendimento
    disponíveis.</span></p>

<p><span><span><span>3.1.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Adotar senha forte com ao menos 6 caracteres,
    contendo pelo menos uma letra e um número e não repetir caracteres sequencias.
    Além disso, recomendamos não adotar sequências óbvias de números e letras ou de
    fácil dedução. Em adição, recomendamos que o<b> USUÁRIO</b> não reutilize a mesma senha em aplicativos, portais e
    sites de terceiros.</span></p>

<p><span><span><span>3.1.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Adotar medidas em seus dispositivos tecnológicos para evitar o
      acesso físico e lógico por terceiros não autorizados, tais como utilização de
      senha e/ou biometria.</span></span></p>

<p><span><span><span>3.1.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Reconhecer que todos os acessos e operações realizadas após sua
      autenticação digital bem-sucedida são interpretados como sendo de sua
      responsabilidade de forma incontestável, inclusive aqueles derivados de uso
      indevido ou divulgação desta para terceiros.</span></span></p>

<p><span><span><span>3.1.8.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Deixar</span> seus sistemas de <i>anti-spam</i>,
    filtros similares ou configurações de redirecionamento de mensagens ajustados
    de modo que não interfiram no recebimento dos comunicados e materiais da<span> <b>SOUZA
        CRUZ</b></span>, não sendo aceitável nenhuma escusa caso não tenha tido acesso
    a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados. A<span> <b>SOUZA
        CRUZ</b></span><b> </b>não envia e-mails ou
    outros tipos de comunicação com links externos, desta forma, caso o </span><span><b><span>USUÁRIO</span></b> receba
    alguma mensagem desse tipo, deve estar ciente dos riscos ao clicar no link,
    pois pode ser tentativa de fraude conhecida como <i>phishing</i>.</span></p>

<p><span><span><span>3.1.9.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Respeitar</span> todos os direitos de propriedade intelectual de
    titularidade da <b><span>SOUZA CRUZ</span>,</b> incluindo todos os direitos referentes a
    terceiros que porventura estejam, ou estiveram, de alguma forma ali
    disponíveis. </span></p>

<p><span><span><span>3.1.10.<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>Não
    acessar áreas de programação do <b><span>PORTAL CONECTA PRIME</span></b>, seu banco de
    dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes
    ambientes.</span></p>

<p><span><span><span>3.1.11.<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>Não
    realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar,
    modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
    emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das
    funcionalidades do <b><span>PORTAL CONECTA PRIME</span>.</b></span></p>

<p><span><span><span>3.1.12.<span>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>Não
    utilizar softwares para automatização de acessos no </span><span><b><span>PORTAL CONECTA PRIME</span></b>, com
    exceção das bibliotecas
    disponibilizadas pela <b><span>SOUZA CRUZ</span></b>, bem como softwares de
    mineração ou coleta automatizada de dados, de qualquer tipo ou espécie, além de
    outro aqui não tipificado que atue de modo similar.</span></p>

<p><span><span><span>3.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>As funcionalidades que compõem o <b><span>PORTAL
        CONECTA PRIME</span></b> são oferecidas como ferramentas para apoiar os
    serviços da <b><span>SOUZA CRUZ</span></b>,<b> </b>não
    conferindo ao <b>USUÁRIO </b>nenhum direito
    sobre o <b><span>PORTAL CONECTA PRIME</span></b> ou sobre as estruturas tecnológicas
    que as sustentam.</span></p>

<p><span><span><span>3.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A eventual remoção, bloqueio ou suspensão de
    qualquer conteúdo ou funcionalidade do <b><span>PORTAL CONECTA PRIME</span></b> em decorrência
    de alguma reclamação, deverá ser sempre compreendida como demonstração de
    boa-fé e intenção de solução amigável de conflitos, jamais, como reconhecimento
    de culpa ou de qualquer infração pela <b><span>SOUZA CRUZ</span> </b>a direito de terceiro.</span></p>

<p><span><span><span>3.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Na incidência de danos ao <b><span>PORTAL CONECTA PRIME</span></b>
    ou a terceiros, o <b>USUÁRIO </b>responsável
    se compromete a arcar com todas as obrigações de indenizar o sujeito lesado,
    inclusive aqueles cuja origem for de atos praticados por meio de sua Conta,
    assumindo o polo passivo de ação judicial ou procedimento administrativo e
    requerendo a exclusão da <b><span>SOUZA CRUZ</span></b>, devendo arcar
    totalmente com as despesas e custas processuais atinentes, deixando-a livre de
    prejuízos e ônus.</span></p>

<p><span><span><span>3.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>O descumprimento de quaisquer das obrigações
    aqui estipuladas poderá acarretar na suspensão total ou parcial das
    funcionalidades do <b><span>PORTAL CONECTA PRIME</span></b>, ou exclusão
    da Conta de Acesso, sem aviso prévio, conforme previsto neste documento, sem
    prejuízo da adoção de medidas judiciais cabíveis na forma da legislação em
    vigor.</span></p>

<p><span>
    
  </span></p>

<p><span><b><span><span>4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b>ATENDIMENTO
      DO <span>PORTAL CONECTA prime</span></b></span></p>

<p><span><span><span>4.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>O atendimento aos </span><span><b><span>USUÁRIOS</span></b> poderá ser realizado
  </span><span><span>por meio dos canais de atendimento apontados a seguir, cujo
      horário de funcionamento é segunda à sexta, das 8h às 18h, exceto feriados
      nacionais:</span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><span><span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>i.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Telefone
      : 0800 723 2221;</span></span></p>

<p><span><span><span><span>&nbsp;&nbsp;&nbsp;
        </span>ii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>E-mail:<span>  </span>consultoratendimento@interactioncenter.com.br</span></span></p>

<p><span><span><span>4.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>Os </span><span><b><span>USUÁRIOS</span></b> também poderão se valer dos canais de
    atendimento toda vez que presenciarem ou verificarem conteúdo impróprio no <b><span>PORTAL
        CONECTA prime</span></b>, seja ele notadamente ilícito ou contrário às regras
    de uso aqui estipuladas. Nestes casos, as denúncias serão sigilosas e
    preservarão a identidade dos </span><span><b><span>USUÁRIOS</span></b>.</span></p>

<p><span>
    
  </span></p>

<p><span><b><span><span>5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b>COMUNICAÇÃO
      DA <span>SOUZA CRUZ COM OS USUÁRIOS</span></b></span></p>

<p><span><span><span>5.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A <b>SOUZA
      CRUZ</b> poderá utilizar todos os canais de comunicação fornecidos e
    autorizados com seus </span><span><b><span>USUÁRIOS</span></b>, nas suas mais diversas modalidades,
    incluindo, mas não se limitando a e-mail, ligação telefônica, envio de SMS,
    WhatsApp ou qualquer outra forma de mensagem eletrônica instantânea.</span></p>

<p><span><span><span>5.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>A responsabilidade pelo recebimento dos
    comunicados é exclusiva dos </span><span><b><span>USUÁRIOS</span></b>, por isso é indispensável que sempre
    forneçam os dados corretos e precisos, além de os manterem atualizados, sempre
    que navegarem pelo <b><span>PORTAL CONECTA prime</span></b>.</span></p>

<p><span><span><span>5.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span>É igualmente de responsabilidade dos </span><span><b><span>USUÁRIOS</span></b> deixarem
    seus sistemas de <i>anti-spam</i>
    configurados de modo que não interfiram no recebimento dos comunicados e
    materiais encaminhados pela <b><span>SOUZA CRUZ</span></b> ou quaisquer avisos
    encaminhados pelo <b><span>PORTAL CONECTA prime</span></b>, não sendo
    aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail em virtude
    desse bloqueio ou filtro similar.</span></p>

<p><span>
    
  </span></p>

<p><span><b><span><span>6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b>PRIVACIDADE
      DOS USUÁRIOS</b></span></p>

<p><span><span><span>6.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A <b><span>SOUZA
          CRUZ</span> </b><span>disponiliza em anexo </span>os
      termos de privacidade, que regula o tratamento dos dados coletados no </span><b><span>PORTAL
        CONECTA prime</span></b></span><span><span>, sendo</span> parte integrante e
    inseparável dos presentes <b>Termos de Uso<span> e Privacidade</span></b>.</span><span><span></span></span></p>

<p><span><b><span><span>7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>DIREITOS AUTORAIS E PROPRIEDADE
        INTELECTUAL </span></b></span></p>

<p><span><span><span>7.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O uso
      comercial da expressão “</span><b><span>SOUZA CRUZ</span></b></span><span><span>” ou
      ainda de palavras que se assemelham a estas, porém com grafia diferenciada,
      como marcas, nomes empresariais ou nomes de domínio, além dos conteúdos das
      telas do <b>PORTAL CONECTA PRIME</b>, assim
      como os programas de computador, bancos de dados, redes e seus arquivos, são de
      propriedade da </span><b><span>SOUZA CRUZ</span></b></span><span><span> e
      estão protegidos pelas leis e tratados internacionais de proteção à propriedade
      intelectual, incluindo, mas não se limitando, às Leis Federais 9.609/98,
      9.610/98, 9.279/96 e 10.406/2002 ou legislação que as substituam.</span></span></p>

<p><span><span><span>7.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Ao
      acessar o <b>PORTAL CONECTA PRIME</b> os </span></span><span><b><span>USUÁRIOS</span></b></span><span><span>
      declaram que irão respeitar todos os direitos de propriedade intelectual da </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, incluindo, mas não se limitando, aos
      programas de computador, direitos autorais e direitos de propriedade industrial
      sobre as marcas, patentes, nomes de domínio, denominação social e desenhos
      industriais, depositados ou registrados em nome da </span><b><span>SOUZA CRUZ</span></b></span><span><span>, bem
      como de todos os direitos referentes a terceiros que porventura estejam, ou
      estiveram, de alguma forma, disponíveis no <b>PORTAL
        CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>7.2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O
      acesso ou uso das funcionalidades do <b>PORTAL
        CONECTA PRIME</b> não confere aos </span></span><span><b><span>USUÁRIOS</span></b></span><span><span> quaisquer
      direitos ao uso dos nomes,
      títulos, palavras, frases, marcas, patentes, nomes de domínio, denominação
      social, obras literárias, artísticas, lítero-musicais, imagens, ilustrações,
      dados e informações, dentre outras, que estejam ou estiveram disponíveis.</span></span></p>

<p><span><span><span>7.2.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A
      reprodução dos conteúdos descritos na cláusula 7.2. está proibida, salvo com
      prévia autorização por escrito da </span><b><span>SOUZA CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>7.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>É
      vedada a utilização do <b>PORTAL CONECTA PRIME</b>
      para finalidades comerciais, publicitárias ou qualquer outra que contrarie a
      realidade para o qual foi concebido, conforme definido neste documento, bem
      como a reprodução, distribuição e divulgação, total ou parcial, dos textos,
      figuras e gráficos que a compõem, sem prévia e expressa autorização da </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>7.3.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Qualquer
      outro tipo de utilização de material autorizado, inclusive para fins
      editoriais, comerciais ou publicitários, só poderá ser feito na forma e
      mediante prévio e expresso consentimento da </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>7.3.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Qualquer
      reutilização de material autorizado deverá ser objeto de uma nova autorização
      da </span><b><span>SOUZA CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>7.3.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A
      autorização conferida para utilização do material solicitado não poderá ser
      transferida a terceiros, mesmo que vinculados ao sujeito autorizado.</span></span></p>

<p><span><span><span>7.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os </span></span><span><b><span>USUÁRIOS</span></b></span><span><span>
      assumem toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela
      utilização indevida das informações, textos, gráficos, marcas, patentes, nomes
      de domínio, obras, imagens, logotipos, enfim, de todo e qualquer direito de
      propriedade intelectual ou industrial existentes no <b>PORTAL CONECTA PRIME</b> ou de titularidade da
    </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>7.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Salvo
      disposição específica em contrato existente entre os
    </span></span><span><b><span>USUÁRIOS</span></b></span><span><span> com
      a </span><b><span>SOUZA CRUZ</span></b></span><span><span>, esses retêm direitos autorais dos conteúdos
      enviados ao <b>PORTAL CONECTA PRIME</b>,
      tais como comentários e seu conteúdo, concedendo licença irrevogável, perpétua,
      mundial, irrestrita, isenta de royalties e não exclusiva de reprodução,
      adaptação, modificação, tradução, publicação, distribuição ou exibição no <b>PORTAL CONECTA PRIME</b> para fins de
      melhor utilização da ferramenta, maior divulgação de algum produto, serviço ou
      atividade ligada ao <b>PORTAL CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>7.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A
      eventual retirada de qualquer conteúdo ou funcionalidade do <b>PORTAL CONECTA PRIME</b> em decorrência de
      alguma reclamação deverá ser sempre compreendida como uma demonstração da
      intenção de evitar danos e prejuízos, não podendo ser admitida como assunção de
      culpa ou de violação pela </span><b><span>SOUZA CRUZ</span></b></span><span><span> a
      direito de terceiro.</span></span></p>

<p><span><span><span>7.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O
      envio por parte do </span></span><span><b><span>USUÁRIO </span></b></span><span><span>para a </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, por
      meio do <b>PORTAL CONECTA PRIME</b> ou
      pelos canais divulgados, de conteúdo que diga respeito a ideias para novos
      produtos, melhorias daqueles já existentes, campanhas publicitárias ou qualquer
      outra ação de marketing, implicará na autorização de uso do conteúdo pela </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, ficando a critério exclusivo da </span><b><span>SOUZA
        CRUZ</span></b></span><span><span> em oferecer qualquer tipo de
      recompensa pecuniária ou compensação não financeira pelo que foi enviado, nada
      podendo o </span></span><span><b><span>USUÁRIO </span></b></span><span><span>reclamar neste sentido.</span></span>
</p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>8.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>MODIFICAÇÕES DESTES TERMOS DE USO E
        PRIVACIDADE</span></b></span></p>

<p><span><span><span>8.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os
      presentes <b>Termos de Uso e Privacidade</b>
      estão sujeitos a constante melhoria e aprimoramento. Assim, a </span><b><span>SOUZA
        CRUZ</span></b></span><span><span> se reserva o direito de modificá-los
      a qualquer momento, conforme sua finalidade ou conveniência, tal qual para
      adequação e conformidade legal de disposição de lei ou norma que tenha força
      jurídica equivalente, cabendo aos </span></span><span><b><span>USUÁRIOS</span></b></span><span><span> verificá-lo
      sempre que efetuar o
      acesso no <b>PORTAL CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>8.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Ocorrendo
      atualizações neste documento, a </span><b><span>SOUZA CRUZ</span></b></span><span><span> notificará
      o </span></span><span><b><span>USUÁRIO</span></b></span><span><span>
      mediante as ferramentas disponíveis no <b>PORTAL
        CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>8.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Ao
      navegar pelo <b>PORTAL CONECTA PRIME</b> e
      utilizar suas funcionalidades, o </span></span><span><b><span>USUÁRIO </span></b></span><span><span>aceita
      guiar-se pelos presentes <b>Termos de Uso</b> <b>e Privacidade </b>que
      se encontram vigentes na data de seu acesso. Por isso, o </span></span><span><b><span>USUÁRIO
      </span></b></span><span><span>deve
      se manter atualizado.</span></span></p>

<p><span><span><span>8.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A
      tolerância do eventual descumprimento de qualquer disposição no presente
      documento não constituirá novação das obrigações aqui estipuladas e tampouco
      impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.</span></span></p>

<p><span><span><span>8.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso
      alguma disposição do presente documento seja julgada inaplicável ou sem efeito,
      o restante do documento continua a viger, sem a necessidade de medida judicial
      que declare tal assertiva.</span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>9.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>DISPOSIÇÕES GERAIS</span></b></span></p>

<p><span><span><span>9.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A </span><b><span>SOUZA
        CRUZ</span></b></span><span><span> não é obrigada a fornecer nenhum
      serviço de suporte para o uso do <b>PORTAL
        CONECTA PRIME</b>. </span></span></p>

<p><span><span><span>9.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>PORTAL CONECTA PRIME</b> foi desenvolvido e
      disponibilizado para ser manipulado por pessoas com a finalidade específica de
      consultar regras e pontos dos Programa de Relacionamento <span>da </span><b>SOUZA CRUZ </b>e resgatar produtos.
      Qualquer uso fora deste padrão
      deverá ser previamente autorizado pela </span><b><span>SOUZA CRUZ</span></b></span><span><span>,
      sendo que qualquer resultado proveniente de uso não permitido ou fora dos
      padrões aqui estabelecidos não poderão ser aproveitados ou disponibilizados
      para quaisquer pessoas sem a autorização prévia e expressa da </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>9.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Além
      dos presentes <b>Termos de Uso e Privacidade</b>,
      o <b>PORTAL CONECTA PRIME</b> possui também
      o <b>Termo de Parceria Comercial</b>,
      regulamentos específicos voltados aos seus serviços prestados, funcionalidades
      disponibilizadas, procedimentos e políticas adotados pela </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>.</span></span></p>

<p><span><span><span>9.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Havendo
      quaisquer divergências entre o disposto nos presentes <b>Termos de Uso</b> <b>e</b><b>
        Privacidade</b>, no<b> </b>Regulamento dos
      Programas de Relacionamento e nos <b>Termo
        de Parceria Comercial</b> ou nos regulamentos do <b>PORTAL CONECTA PRIME</b>, deverá prevalecer o descrito na
      norma mais específica.</span></span></p>

<p><span><span><span>9.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os </span></span><span><b><span>USUÁRIO </span></b></span><span><span>devem
      arcar com os meios necessários para navegar no <b>PORTAL CONECTA PRIME</b>, incluindo a posse de equipamento
      necessário
      para acessar ao <b>PORTAL CONECTA PRIME</b>,
      bem como em contratar prestador de serviços de telecomunicações para preencher
      os requisitos mínimos de acesso à Internet.</span></span></p>

<p><span><span><span>9.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A </span><b><span>SOUZA
        CRUZ</span></b></span><span><span> não se responsabiliza por qualquer
      dano, prejuízo ou perda no equipamento do </span></span><span><b><span>USUÁRIO
      </span></b></span><span><span>causados por
      falhas no sistema, no servidor ou na conexão à Internet, ainda que decorrentes
      de condutas de terceiros, inclusive por ações de softwares maliciosos como
      vírus, cavalos de troia e outros tipos de conteúdo e arquivos maliciosos que
      possam, de algum modo, danificar o equipamento ou a conexão dos <b>USUÁRIOS</b> em decorrência do acesso,
      utilização ou navegação no <b>PORTAL
        CONECTA PRIME</b>, bem como pela transferência de dados, arquivos, imagens,
      textos, áudios ou vídeos ou links disponibilizados no <b>PORTAL CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>9.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>USUÁRIO</b> reconhece que toda comunicação
      realizada por e-mail, Aplicativo “WhatsApp” ou SMS (aos endereços informados no
      seu cadastro) também são válidas, eficazes e suficiente para a divulgação de
      qualquer assunto que se refira aos serviços prestados pela </span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, bem como às condições de sua
      prestação ou a qualquer outro assunto nele abordado, ressalvadas as disposições
      expressamente diversas previstas nestes <b>Termos
        de Uso e Privacidade.</b></span></span></p>

<p><span><span><span>9.8.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Todos
      os registros e guarda de dados realizados no ambiente do <b>PORTAL CONECTA PRIME</b></span><span>
    </span></span><span><span>serão
      realizados e catalogados com base no horário oficial de Brasília.</span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><b><span><span>10.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>SEGURANÇA</span></b></span><span><b><span></span></b></span></p>

<p><span><span><span>10.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A </span><b><span>SOUZA
        CRUZ</span></b></span><span><span> possui barreiras de proteção e
      procedimentos de segurança para salvaguardar os seus ativos, a fim de evitar
      eventual mau uso ou alteração não autorizada dos dados por ela custodiados.</span></span></p>

<p><span><span><span>10.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O
      fornecimento e envio dos dados é de responsabilidade do
    </span></span><span><b><span>USUÁRIO</span></b></span><span><span>.
      Somente após a coleta dos dados é que a </span><b><span>SOUZA CRUZ</span></b><span>
    </span></span><span><span>consegue aplicar seus controles de
      segurança e preservar a privacidade de seu
    </span></span><span><b><span>USUÁRIO</span></b></span><span><span>.</span></span></p>

<p><span><span><span>10.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A </span><b><span>SOUZA
        CRUZ</span></b><span> </span></span><span><span>adota
      diversos mecanismos de segurança para garantir o acesso aos dados do
    </span></span><span><b><span>USUÁRIO</span></b></span><span><span>,
      entre eles a senha de acesso a Conta do </span></span><span><b><span>USUÁRIO</span></b></span><span><span> no
      <b>PORTAL CONECTA PRIME</b>.</span></span></p>

<p><span><span><span>10.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O
      acesso ao <b>PORTAL CONECTA PRIME</b> é
      realizado mediante o uso do login e senha, criada diretamente pelo
    </span></span><span><b><span>USUÁRIO</span></b></span><span><span>
      conforme os requisitos de segurança indicados pela </span><b><span>SOUZA CRUZ</span></b><span>
    </span></span><span><span>no momento do seu primeiro acesso.
      Assim, a senha é a credencial de acesso ao <b>PORTAL
        CONECTA PRIME</b> sendo ela rigorosamente definida e gerenciada pelo próprio
    </span></span><span><b><span>USUÁRIO</span></b></span><span><span>.</span></span></p>

<p><span><span><span>10.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Se
      restar alguma dúvida, o </span></span><span><b><span>USUÁRIO </span></b></span><span><span>poderá em contato com a
    </span><b><span>SOUZA
        CRUZ</span></b><span> </span></span><span><span>pelos
      canais de contato indicados no <b>PORTAL
        CONECTA PRIME</b></span><span>.</span></span><span><span></span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>11.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>MUDANÇAS NO SISTEMA </span></b></span></p>

<p><span><span><span>11.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span>Eventualmente podem
      acontecer
      alterações no <b>PORTAL CONECTA PRIME</b>.
      Sempre que isso acontecer, a </span><b><span>SOUZA CRUZ</span></b></span><span><span>
      publicará as informações nesta página. Por isso, pedimos que sempre consulte-a
      regularmente para manter-se informado.</span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>12.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>GLOSSÁRIO</span></b></span></p>

<p><span><span><span>12.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span>Para
      os fins deste documento, aplicam-se seguintes definições:</span></span></p>

<p><span><b><i>Anti-Spam</i></b>:
    Sistema que evita correspondências não desejadas, como publicidade em massa,
    pelo bloqueio de mensagens ou as movendo para pasta específica.</span></p>

<p><span><b>Bugs:</b> Utilizado quando alguma falha
    lógica impede a execução de alguma operação no <b>PORTAL CONECTA PRIME</b><span>.</span></span></p>

<p><span><b><span>Conta
        de Acesso</span></b></span><span><i><span>:
      </span></i></span><span><span>Credencial de um <b>USUÁRIO</b>
      que pode ser necessária para utilizar ou acessar área restrita e
      funcionalidades exclusivas do </span></span><span><b><span>PORTAL CONECTA PRIME</span></b></span><span><b><span>
      </span></b></span><span><span>oferecido pela <b>SOUZA
        CRUZ.</b></span></span></p>

<p><span><b><i>Glitches:
      </i></b>Utilizado para designar um funcionamento inesperado que impede a
    execução ou o bom funcionamento da operação pretendida no <b>PORTAL CONECTA PRIME</b>.<b><i></i></b></span></p>

<p><span><b><i>Layout</i></b>:
    Conjunto compreendido entre aparência, design e fluxos do</span><span><span> <b>PORTAL
        CONECTA PRIME</b>.</span></span></p>

<p><span><b><i>Link</i></b>:
    Terminologia para endereço de internet.</span><span><span></span></span></p>

<p><span><a><b><span>PORTAL CONECTA PRIME:</span></b></a></span><span><span><span> </span><span>Plataforma de Internet
        do Programa de Relacionamento <b>CONCETA PRIME</b> </span></span></span><span><span><span>da <b>SOUZA CRUZ</b>,
      </span><span>localizado pelo
        endereço eletrônico </span>http://www.conectaprime.com.br, <span>e seus subdomínios.</span></span></span></p>

<span></span>

<p><span><b><span>Senha: </span></b></span><span><span>Conjunto de caracteres que
      serve como prova de identidade digital do </span></span><span><b><span>USUÁRIO</span></b></span><span><span>, cujo
      conhecimento deve ser
      exclusivo e único.<b></b></span></span></p>

<p><span><b><span>Site: </span></b></span><span><span>Denominação para página de
      Internet.<b></b></span></span></p>

<p><span><b><span>USUÁRIOS</span></b></span><span><span>:
    </span></span><span><span>Pessoa
      Jurídica (varejista) que celebrou Contrato de Parceria Comercial com a <b>Souza Cruz</b>, e utiliza as
      funcionalidades do <b>PORTAL CONECTA PRIME</b>.</span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>13.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>LEI APLICÁVEL E JURISDIÇÃO</span></b></span></p>

<p><span><span><span><span>13.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></span><span>Os presentes </span></span><span><b><span>Termos
        de Uso</span></b></span><span><span> e Privacidade serão
      interpretados segundo a legislação brasileira, no idioma português, </span><span>sendo eleito o Foro Central da
      Comarca do Rio de Janeiro
      nas relações mantidas com </span></span><span><b><span>USUÁRIOS</span></b></span><span><span><span> para dirimir
      </span></span></span><span><span><span>qualquer litígio ou controvérsia envolvendo o presente
        documento, salvo ressalva específica de competência pessoal, territorial ou
        funcional pela legislação aplicável.</span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><b><span>ANEXO I – TERMOS DE PRIVACIDADE </span></b></span></p>

<p><span><b><span>DO PORTAL CONECTA PRIME</span></b></span></p>

<p><span><span>Os presentes <b>Termos</b><b> de Privacidade</b> tem por finalidade demonstrar o compromisso da<span> 
      </span></span></span></p>

<table>
  <tr>
    <td>
      <p><span><b><span>SOUZA
              CRUZ LTDA</span></b></span><span><span>., pessoa jurídica de direito privado, inscrita no
            CNPJ sob o nº 33.009.911/0001-39, com sede na Avenida República do Chile, nº 330,
            30º andar, Centro, Rio de Janeiro, RJ, CEP 20.031-170, (“<b>SOUZA CRUZ</b>”)</span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span>com a privacidade e a proteção dos dados pessoais
      coletados de seus <b>USUÁRIOS,</b>
      estabelecendo as regras sobre a coleta, registro, armazenamento, uso,
      compartilhamento, enriquecimento e eliminação dos dados coletados dos <b>USUÁRIOS</b>,
    </span></span><span><span>dentro do escopo dos
      serviços e funcionalidades do <b><span>PORTAL CONECTA prime</span></b>, de acordo com
      as leis em vigor, com transparência e clareza junto ao <b>USUÁRIO</b> e ao mercado em geral.</span></span></p>

<p><span><span>Como condição para acesso e uso das funcionalidades
      exclusivas </span></span><span><span>do <b><span>PORTAL CONECTA PRIME</span></b></span></span><span><span>, o
      <b>USUÁRIO</b>
      declara que fez a leitura completa e atenta dos presentes <b>Termos d</b><b>e Privacidade</b>, estando plenamente
      ciente, conferindo, assim, sua livre e expressa concordância com os termos aqui
      estipulados.</span></span></p>

<p><span><b><span><span>1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>COLETA DE DADOS E REGISTRO DE
        ATIVIDADES</span></b></span></p>

<p><span><span><span>1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>USUÁRIO VAREJISTA</b> fornecerá previamente os dados cadastrais à <b>SOUZA
        CRUZ</b>, que irá inseri-los n</span></span><span><span>o <b><span>PORTAL CONECTA
          PRIME</span></b></span></span><span><span>
      para que o <b>USUÁRIO REPRESENTANTE</b>
      autorizado possa criar uma conta de acesso.</span></span></p>

<p><span><span><span>1.1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>USUÁRIO VAREJISTA </b>irá indicar seu <b>USUÁRIO REPRESENTANTE</b> à <b>SOUZA
        CRUZ</b> e informar seus dados pessoais, sendo certo que o <b>USUÁRIO REPRESENTANTE </b><span>não
        pode ser menor de 18 (dezoito) anos de idade</span>.</span></span></p>

<p><span><span><span>1.1.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caberá ao <b>USUÁRIO VAREJISTA</b> a responsabilidade em
      coletar o consentimento do seu <b>USUÁRIO
        REPRESENTANTE</b> para o fornecimento à <b>SOUZA
        CRUZ</b> das informações pessoais abaixo elencadas:</span></span></p>

<table>
  <tr>
    <td>
      <p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>CPF;</span></span></p>
      <p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>NOME;</span></span></p>
      <p><span><span><span>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>E-MAIL;</span></span></p>
      <p><span><span><span>d)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>DATA
            DE NASCIMENTO;</span></span></p>
      <p><span><span><span>e)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>SEXO;</span></span></p>
      <p><span><span><span>f)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>TELEFONE;</span></span></p>
      <p><span><span><span>g)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>CELULAR.</span></span><span><span></span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span><span>1.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O</span></span><span><span> <b><span>PORTAL CONECTA
          PRIME</span></b></span></span><span><span> também registrará todas as
      atividades efetuadas pelo <b>USUÁRIO</b>
      por meio de logs e os armazenará por período igual ou superior a 6 (seis)
      meses.</span></span><span><span></span></span></p>

<p><span><span><span>1.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Não somos responsáveis
      pela precisão, veracidade ou falta dela nas informações prestadas pelo nosso <b>USUÁRIO</b> ou pela sua
      desatualização,
      quando é de sua responsabilidade prestá-las com exatidão ou atualizá-las.<span> </span></span></span></p>

<p><span><span><span>1.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>As atividades que </span></span><span><span>o <b><span>PORTAL CONECTA
          PRIME</span></b></span></span><span><span> poderá registrar incluem:</span></span></p>

<table>
  <tr>
    <td>
      <p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Endereço </span></span><span><i><span>IP,</span></i></span><span><span> Porta
            Lógica de Origem dos<b>
              USUÁRIOS </b>e geolocalização;</span></span><span><span></span></span></p>
      <p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Ações efetuadas
            pelos <b>USUÁRIOS</b> no <b><span>PORTAL
                CONECTA PRIME</span></b>; </span></span><span><span></span></span></p>
      <p><span><span><span>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Telas acessadas
            pelos <b>USUÁRIOS</b>;</span></span><span><span></span></span></p>
      <p><span><span><span>d)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Datas e horários de
            cada atividade efetuada, bem como, o acesso do <b>USUÁRIO</b> ao <b><span>PORTAL CONECTA
                PRIME</span></b>;</span></span><span><span></span></span></p>
      <p><span><span><span>e)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Dados sobre o
            dispositivo utilizado pelo <b>USUÁRIO</b>,
            em especial: versão de sistema operacional, navegador, geolocalização e outros
            aplicativos instalados, se necessário;</span></span><span><span></span></span></p>
      <p><span><span><span>f)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><i><span>Session ID</span></i><span> dos <b>USUÁRIOS</b>, quando
            disponíveis;</span></span><span><span></span></span></p>
      <p><span><span><span>g)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Histórico de
            operações realizadas; e,</span></span><span><span></span></span></p>
      <p><span><span><span>h)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Comportamentos
            identificados nas interações com o <b><span>PORTAL CONECTA
                PRIME</span></b>.</span></span><span><span></span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span><span>1.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b><span>PORTAL CONECTA PRIME</span></b></span></span><span><span> também pode
      fazer uso de
      tecnologias como:</span></span></p>

<p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><b><i><span>Cookies</span></i></b><span>, cabendo ao <b>USUÁRIO</b>
      configurar o seu navegador de Internet caso deseje bloqueá-los. Nesta hipótese,
      algumas funcionalidades do <b><span>PORTAL CONECTA PRIME</span></b> poderão ser
      limitadas;</span></span></p>

<p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><b><i><span>Web Beacons</span></i></b><span> para coletar dados de comportamento do
      <b>USUÁRIO</b> no acesso às páginas, onde a
      instalação de arquivos nos equipamentos poderá não ser necessária;</span></span></p>

<p><span><span><span>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><b><i><span>Beacons </span></i></b><span>que emitem sinais, a partir de tecnologia
      Bluetooth Low
      Energy (BLE), que podem ser captados por recursos tecnológicos que permitem a
      interação entre o <b>USUÁRIO</b> e um
      recurso tecnológicos;</span></span></p>

<p><span><span><span>d)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Outras tecnologias
      para a obtenção de dados de navegação pelo <b>USUÁRIO.</b></span></span></p>

<p><span><span>1.5.1.<span>            </span>O <b>PORTAL CONECTA PRIME</b> utiliza a ferramenta <i>Google
        Analytics</i>, que coleta informações do <b>USUÁRIO </b>como o endereço de IP, navegador utilizado,
      configurações
      de idioma, páginas acessadas, por meio de “<i>cookies</i>”.
      As informações são coletadas de forma anônima e para efeitos analíticos. </span></span></p>

<p><span><span>1.5.2.<span>            </span>O <b>USUÁRIO</b> poderá saber mais detalhes por meio das informações de
      privacidade do <i>Google Analytics</i> em
      &quot;<i>Como o Google utiliza os dados
        quando o usuário usa sites ou aplicativos dos nossos parceiros</i>&quot;,
      localizado em &lt;www.google.com/policies/privacy/partners/&gt;. </span></span></p>

<p><span><span>1.5.3.<span>            </span>Caso o <b>USUÁRIO</b> não concorde com a coleta de dados pelo <i>Google
        Analytics</i> poderá desativá-lo por
      meio do Add-on do seu navegador, disponível no link
      &lt;https://tools.google.com/dlpage/gaoptout?hl=pt-BR&gt;.</span></span></p>

<p><span><span><span>1.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Todas as tecnologias utilizadas respeitarão sempre a
      legislação vigente e os termos destes <b>Termos de</b><b> Privacidade.</b></span></span></p>

<p><span><span><span>1.7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>É importante destacar
      que, uma vez no</span></span><span><span> </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span>,<b> </b>o <b>USUÁRIO</b> poderá ser conduzido,
      via <i>link</i>, conteúdos ou serviços, a
      outros portais ou plataformas, que poderão coletar suas informações e ter sua
      própria Política de Privacidade.</span></span></p>

<p><span><span><span>1.7.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Cabe ao <b>USUÁRIO </b>ler a Política de Privacidade
      de tais plataformas fora do ambiente d</span></span><span><span>o</span></span><span><span> <b><span>PORTAL
          CONECTA PRIME</span></b></span></span><span><span>,
      sendo de responsabilidade do <b>USUÁRIO </b>aceitá-la
      ou rejeitá-la. A <b>SOUZA CRUZ </b>não é responsável pela Política de
      Privacidade nem pelo conteúdo de quaisquer websites, conteúdos ou serviços
      ligados ao</span></span><span><span> <b><span>PORTAL CONECTA PRIME</span></b></span></span><span><span>,<b>
      </b>inclusive
      por meio de <i>links</i>.</span></span></p>

<p><span><b><span><span>2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>USO DOS DADOS</span></b></span></p>

<p><span><span><span>2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os dados coletados dos
      nossos <b>USUÁRIOS</b> e os registros das
      atividades serão utilizados, única e exclusivamente, para as seguintes
      finalidades:</span></span></p>

<table>
  <tr>
    <td>
      <p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Identificá-los
            e autenticá-los adequadamente, além de processar movimentações na <b>Conta de Acesso</b>;</span></span></p>
      <p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Para
            que o varejista receba notificações por e-mail ou SMS;</span></span></p>
      <p><span><span><span>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Administrar,
            prestar os serviços e cumprir as obrigações decorrentes do uso de nossos
            serviços;</span></span></p>
      <p><span><span><span>d)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Atender
            adequadamente às suas solicitações e dúvidas;<span> </span></span></span></p>
      <p><span><span><span>e)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Aperfeiçoar
            seu uso e experiência n</span></span><span><span>o</span></span><span><span>
            <b><span>PORTAL
                CONECTA PRIME</span></b></span></span><span><span>;</span></span></p>
      <p><span><span><span>f)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Efetuar
            estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades de
            seus comportamentos ao utilizarem </span></span><span><span>o</span></span><span><span> <b><span>PORTAL
                CONECTA PRIME</span></b></span></span><span><span>;</span></span></p>
      <p><span><span><span>g)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Promover
            os nossos serviços e produtos;</span></span></p>
      <p><span><span><span>h)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Informar
            sobre novidades, funcionalidades, conteúdos, notícias e demais eventos
            relevantes para a manutenção do relacionamento com os nossos <b>USUÁRIOS, </b><span>através de e-mail,
              telefone, SMS, WhatsApp, carta ou qualquer outro
              meio de comunicação estabelecido entre o </span><b>USUÁRIO</b><span> e a </span><b>SOUZA
              CRUZ</b>;</span></span></p>
      <p><span><span><span>i)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Nos
            resguardar de direitos e obrigações relacionadas ao uso do </span></span><span><b><span>PORTAL CONECTA
              PRIME</span></b></span><span><span>;</span></span></p>
      <p><span><span><span>j)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Como</span></span><span><span> meio probatório em casos de
            atos ilícitos ou contrários a estes <b>Termos de</b><b> Privacidade</b> ou qualquer outro documento legal
            disponibilizado
            pela <b>SOUZA CRUZ</b>; em caso de
            alterações indevidas em seus sistemas e cadastros ou ações que possam colocar
            em risco </span></span><span><span>o</span></span><span><span>
            <b><span>PORTAL
                CONECTA PRIME</span></b> e seus <b>USUÁRIOS</b>;</span></span><span><span>
          </span></span><span><span></span></span></p>
      <p><span><span><span>k)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Para enriquecer a base
            de dados da <b>SOUZA CRUZ </b>e evitar a
            ocorrência de fraudes e riscos associados.</span></span><span><span>
          </span></span><span><span></span></span></p>
      <p><span><span><span>l)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Compartilhamento
            dos dados, de forma anonimizada, com parceiros para manutenção do <b>PORTAL
              CONECTA PRIME</b>.</span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span><span>2.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O consentimento</span></span><span><span> fornecido pelo <b>USUÁRIO</b> é coletado de
      forma individual,
      livre, clara, específica e legitima.</span></span></p>

<p><span><span><span>2.2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso</span></span><span><span> o <b>USUÁRIO</b> não conceda seu consentimento para as
      finalidades ligadas
      diretamente à prestação de serviços pela <b>SOUZA
        CRUZ</b>, os serviços não lhe serão disponibilizados.</span></span></p>

<p><span><span><span>2.2.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso o <b>USUÁRIO</b>
      não conceda seu consentimento para as finalidades facultativas, a prestação de
      serviços pela <b>SOUZA CRUZ</b> continuará
      sendo feita regularmente.</span></span></p>

<p><span><span><span>2.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>USUÁRIO</b>
      poderá alterar suas concessões de consentimento por meio dos canais de
      atendimento disponibilizados pela <b>SOUZA
        CRUZ</b>, conforme indicado no item 5.3., podendo conceder novas permissões ou
      retirar seu consentimento para as permissões atuais, sendo avisado das
      consequências que a retirada de consentimento poderá causar.</span></span></p>

<p><span><span><span>2.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os dados coletados e as atividades registradas também
      poderão ser compartilhados:</span></span><span><span></span></span></p>

<p><span><span><span>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Com autoridades
      judiciais, administrativas ou governamentais competentes, sempre que houver
      requerimento, requisição ou ordem judicial;</span></span></p>

<p><span><span><span>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>De forma automática em
      caso de movimentações societárias, como fusão, aquisição e incorporação.</span></span></p>

<p><span><span><span>2.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>A base de dados
      formada por meio da coleta de dados no <b><span>PORTAL CONECTA PRIME</span></b> é de nossa
      propriedade e responsabilidade, sendo que seu uso, acesso e compartilhamento,
      quando necessários, serão feitos dentro dos limites e propósitos dos negócios
      da</span></span><span><span> </span></span><span><b><span>SOUZA CRUZ</span></b></span><span><span>, podendo, neste
      sentido, os dados serem
      disponibilizados para consulta e cedidos a fornecedores e autoridades, desde
      que obedecido ao disposto nos presentes <b>Termos de</b><b> Privacidade.</b></span></span></p>

<p><span><span><span>2.5.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O nosso <b>USUÁRIO</b> é corresponsável pelo sigilo de
      seus dados pessoais. O compartilhamento de senhas e dados de acesso viola estes
      Termos e Condições de Uso e Privacidade do </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span>
      e serviços.</span></span></p>

<p><span><span><span>2.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Internamente, os dados
      de nossos<b> USUÁRIOS </b>são acessados
      somente por profissionais devidamente autorizados pela <b>SOUZA CRUZ</b>, respeitando os princípios de
      proporcionalidade, ética,
      boa-fé na condução dos negócios, necessidade e relevância para os objetivos da <b>SOUZA CRUZ</b>, além do
      compromisso de
      confidencialidade e preservação da privacidade nos termos do presente
      documento.</span></span></p>

<p><span><b><span><span>3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>ARMAZENAMENTO DOS DADOS E REGISTROS</span></b></span></p>

<p><span><span><span>3.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os dados coletados e
      os registros de atividades estarão armazenados em ambiente seguro e controlado
      pelo prazo mínimo de 6 (seis) meses, nos termos do Marco Civil da Internet,
      observado o estado da técnica disponível. Todavia, considerando que nenhum
      sistema de segurança é infalível, a <b>SOUZA
        CRUZ</b> se exime de quaisquer responsabilidades por eventuais danos e/ou
      prejuízos decorrentes de falhas, vírus ou invasões do banco de dados da <b>SOUZA CRUZ</b>, salvo nos casos em que
      tiver dolo ou culpa.</span></span></p>

<p><span><span><span>3.1.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Os dados poderão ser
      apagados antes desse prazo, caso haja solicitação da empresa varejista
      (contratante dos serviços da <b>SOUZA CRUZ</b>).</span></span></p>

<table>
  <tr>
    <td>
      <p><span><span><span>3.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>Os dados coletados serão armazenados em servidores
            de prestadores de serviços </span></span><span><span>dedicados</span></span><span><span>
            à <b>SOUZA CRUZ</b> localizados no Brasil
            e em outros países que proporcionam grais de proteção de dados pessoais
            adequado ao previsto na legislação brasileira, bem como em cloud, podendo
            haver qualquer transferência ou processamento para países terceiros neste
            caso.</span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span>
      
    </span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>EXIBIÇÃO, RETIFICAÇÃO, LIMITAÇÃO,
        OPOSIÇÃO E EXCLUSÃO DE DADOS</span></b></span><span><b><span></span></b></span></p>

<p><span><span><span>4.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Nosso <b>USUÁRIO</b> pode </span></span><span><span><span>consultar
      </span></span></span><span><span>e/ou
      retificar seus dados pessoais, por meio de sua <b>Conta de Acesso </b>ou das ferramentas de atendimento
      disponibilizadas
      por nós.</span></span></p>

<p><span><span><span>4.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Pelas mesmas
      ferramentas de atendimento o <b>USUÁRIO </b>pode
      requerer a limitação do uso de seus dados pessoais, pode manifestar sua
      oposição ao uso de seus dados pessoais ou a exclusão de seus dados pessoais
      coletados e registrados pela <b>SOUZA CRUZ</b>,
      desde que o contrato entre o <b>USUÁRIO</b>
      comprador (contratante dos serviços da <b>SOUZA
        CRUZ</b>)<b> </b>e a <b>SOUZA CRUZ</b> tenha terminado, eventuais <b>Contas de Acesso</b> canceladas e decorrido
      o prazo legal mínimo.</span></span></p>

<p><span><span><span>4.2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso o <b>USUÁRIO </b>retire seu consentimento para
      finalidades fundamentais ao regular funcionamento do </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span>,
      os serviços e funcionalidades do </span></span><span><b><span>PORTAL
        CONECTA PRIME</span></b></span><span><span> poderão ficar indisponíveis para esse <b>USUÁRIO</b>.</span></span>
</p>

<p><span><span><span>4.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Podemos, para fins de
      auditoria, segurança, controle de fraudes e preservação de direitos, permanecer
      com o histórico de registro dos dados de nossos <b>USUÁRIOS</b> por prazo maior nas hipóteses que a lei ou norma
      regulatória assim estabelecer ou para preservação de direitos, sendo assegurada
      a faculdade da SOUZA CRUZ de excluí-los definitivamente a qualquer tempo
      segundo sua conveniência. Na hipótese de solicitação de exclusão dos dados
      pessoais pela empresa contratante dos serviços da <b>SOUZA CRUZ</b>, a
      solicitação será atendida desde que a manutenção dos dados não seja determinada
      pela legislação brasileira.</span></span></p>

<p><span><span>
      
    </span></span></p>

<p><span><b><span><span>5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>NOTIFICAÇÕES</span></b></span></p>

<p><span><span><span>5.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>As notificações enviadas pela </span></span><span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, por qualquer meio de comunicação, nunca solicitam
      a confirmação de dados, senhas ou cadastros pessoais, além disso, as
      notificações encaminhadas não possuem nenhum tipo de download ou anexos
      executáveis (arquivos com extensões .exe, .com, .scr, .bat).</span></span></p>

<p><span><span><span>5.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Todas as notificações e comunicados enviados pela </span></span><span><b><span>SOUZA
        CRUZ</span></b></span><span><span>, quando enviados por e-mail, têm como remetente o
      endereço </span></span><a
    href="mailto:sav@interactioncenter.com.br"><span><span>sav@interactioncenter.com.br</span></span></a><span><span> e
      seus links levam diretamente para o </span></span><span><b><span>PORTAL CONECTA
        PRIME.</span></b></span><span><span></span></span></p>

<p><span><b><span><span>6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>DISPOSIÇÕES GERAIS</span></b></span></p>

<table>
  <tr>
    <td>
      <p><span><span><span>6.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span></span><span>O<b> <span>PORTAL CONECTA PRIME</span></b></span></span><span><span>
            <u>não</u> utiliza nenhum tipo de decisão automatizada que impacte o <b>USUÁRIO</b>, devendo este se
            responsabilizar por toda e qualquer ação executada, servindo o <i>log</i> e qualquer outro registro
            eletrônico como meio de prova inconteste das atividades aqui realizadas.</span></span></p>
    </td>
    <span></span>
  </tr>
</table>

<p><span><span><span>6.2.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Podemos alterar o teor
      destes <b>Termos de</b> <b>Uso e </b><b>Privacidade</b>
      a qualquer momento, conforme a finalidade ou necessidade, tal qual para
      adequação e conformidade legal de disposição de lei ou norma que tenha força
      jurídica equivalente, cabendo ao nosso <b>USUÁRIO</b>
      verificá-la sempre que efetuar o acesso ao </span></span><span><b><span>PORTAL CONECTA
        PRIME</span></b></span><span><span>
      da <b>SOUZA CRUZ</b>.</span></span></p>

<p><span><span><span>6.2.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Ocorrendo atualizações neste
      documento, a <b><span>SOUZA
          CRUZ</span></b> notificará o <b>USUÁRIO</b>
      pelos meios de contato por ele fornecidos, podendo haver nova coleta de
      consentimento.</span></span></p>

<p><span><span><span>6.3.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Em caso de qualquer
      dúvida com relação às disposições constantes destes <b>Termos de Uso e </b><b>Privacidade</b>, o <b>USUÁRIO</b>
      poderá entrar em contato com a <b>SOUZA CRUZ </b>por meio dos canais de atendimento apontados a seguir,
      cujo horário de funcionamento é segunda à sexta, das 9h às 18h, exceto feriados
      nacionais:</span></span></p>

<p><span><span><span><span>&nbsp;
        </span>iii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Telefone
      : 0800 723 2221, escolha a opção “1” – “varejista”, insera seu CPF/CNPJ e
      selecione a opção “7” – “Conecta Prime”;</span></span></p>

<p><span><span><span><span>&nbsp;
        </span>iv.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>E-mail:
    </span>conectaprime@interactioncenter.com.br</span><span><span></span></span></p>

<p><span><span><span>6.4.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso empresas
      terceirizadas realizem o processamento de quaisquer dados coletados pela <b>SOUZA CRUZ</b>, deverão respeitar as
      condições aqui estipuladas e as normas de Segurança da Informação da <b>SOUZA CRUZ</b>,
      obrigatoriamente.</span></span></p>

<p><span><span><span>6.5.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Caso alguma disposição
      destes <b>Termos de</b> <b>Uso e </b><b>Privacidade</b>
      seja considerada ilegal ou ilegítima por autoridade da localidade em que o
      nosso <b>USUÁRIO</b> resida ou da sua
      conexão à Internet, as demais condições permanecerão em pleno vigor e efeito.</span></span></p>

<p><span><span><span>6.6.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>O <b>USUÁRIO</b>
      reconhece que toda comunicação realizada por e-mail (aos endereços informados
      no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra
      forma digital, virtual e digital também são válidas, eficazes e suficiente para
      a divulgação de qualquer assunto que se refira aos serviços prestados pela <b>SOUZA CRUZ</b>, bem como às
      condições de
      sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as
      disposições expressamente diversas previstas nestes <b>Termos de Uso e Privacidade.</b></span></span></p>

<p><span><b><span><span>7.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span></span></b><b><span>GLOSSÁRIO</span></b></span></p>

<p><span><span><span>7.1.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span>Para os fins deste documento,
      devem se considerar as seguintes definições e descrições para seu melhor
      entendimento:<b></b></span></span></p>

<p><span><b><i><span>Beacons</span></i></b></span><span><span>: São pequenos dispositivos que utilizam tecnologia
      Bluetooth Low
      Energy (BLE), que emitem sinais que podem ser captados por recursos
      tecnológicos, possibilitando a interação entre o <b>USUÁRIO</b> e o recurso tecnológico. Os <b>Web Beacons</b> são
      programações que tem como finalidade monitorar a
      navegação de um <b>USUÁRIO </b>em uma
      página na Internet.</span></span></p>

<p><span><b><i><span>Cloud Computing</span></i></b></span><span><span>: Ou computação em nuvem, é tecnologia de
      virtualização de
      serviços construída a partir da interligação de mais de um servidor por meio de
      uma rede de informação comum (p.ex. a Internet), com objetivo de reduzir custos
      e aumentar a disponibilidade dos serviços sustentados.</span></span></p>

<p><span><b><i>Cookies</i>: </b>Arquivos enviados pelo
    servidor do <b><span>PORTAL CONECTA PRIME</span></b> para o computador dos
    <b><span>USUÁRIOS</span></b><span>, com a finalidade de identificar o computador e obter
      dados de acesso, como páginas navegadas ou <i>links</i>
      acessados, permitindo, desta forma, personalizar a navegação dos <b>USUÁRIOS</b> no <b><span>PORTAL CONECTA
          PRIME</span></b>,
      de acordo com o seu perfil.</span></span></p>

<p><span><b><span>Conta
        de Acesso</span></b></span><span><i><span>:
      </span></i></span><span><span>Credencial de um <b>USUÁRIO</b>
      que pode ser necessária para utilizar ou acessar área restrita e
      funcionalidades exclusivas do </span></span><span><b><span>PORTAL
        CONECTA PRIME</span></b></span><span><b><span> </span></b></span><span><span>oferecido
      pela <b>SOUZA CRUZ.</b></span></span></p>

<p><span><b><span>IP</span></b></span><span><span>:
      Abreviatura de <i>Internet Protocol</i>. É
      conjunto alfanumérico que identifica os dispositivos dos <b>USUÁRIOS</b> na Internet.</span></span></p>

<p><span><b><i><span>LOGS</span></i></b></span><span><span>: Registros de atividades dos <b>USUÁRIOS</b> efetuadas no<b>
      </b></span></span><span><b><span>PORTAL CONECTA PRIME</span></b></span><span><span>
      da <b>SOUZA CRUZ.</b></span></span></p>

<p><span><b><span>PORTAL CONECTA PRIME</span><span>:</span></b><span> Plataforma de
      Internet dos Programa de Relacionamento </span></span><span><span>da <b>SOUZA CRUZ</b>, </span><span>localizado
      pelo endereço eletrônico </span></span><a
    href="http://www.conectaprime.com.br"><span>http://www.conectaprime.com.br</span></a><span>, <span>e seus
      subdomínios.</span></span></p>

<p><span><b><i><span>Session ID</span></i></b></span><span><b><span>:</span></b></span><span><span> Identificação da
      sessão do <b>USUÁRIOS</b> no processo de compra de
      serviços ou </span></span><span><span>quando é efetuado o acesso à área restrita.</span></span></p>

<p><span><b><span>USUÁRIO
        REPRESENTANTE:</span></b></span><span><span> Preposto do <b><span>USUÁRIO VAREJISTA</span></b> que possui
      autorização para
      utilizar os serviços do <b><span>PORTAL CONECTA PRIME.</span></b></span></span></p>

<p><span><b><span>USUÁRIO
        VAREJISTA:</span></b></span><span><span> </span></span><span><span>Pessoa Jurídica que celebrou Termo de
      Parceria Comercial com a <b>SOUZA CRUZ</b>, e utiliza as
      funcionalidades do <b><span>PORTAL CONECTA PRIME</span></b>. </span></span></p>

<p><span><b><span>USUÁRIOS:
      </span></b></span><span><b><span>USUÁRIO REPRESENTANTE </span></b></span><span><span>e <b><span>USUÁRIO
          VAREJISTA</span></b><span> em conjunto.</span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><span>Agradecemos por sua
        atenção, e seja bem-vindo(a) ao <b>PORTAL CONECTA
          PRIME</b>!</span></span></span></p>

<p><span><span><span>
        
      </span></span></span></p>

<p><span><span><b><span>Atualização</span></b></span></span><span><span><span>: 08 de Maio de 2020.
      </span></span></span><span><span></span></span></p>
                   
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;