import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { loadRegulationSuccess, loadRegulationFailure } from './actions';


export function* loadRegulation(){
    try{
        const {data} = yield call(api.get, '/regulamento');
        yield put(loadRegulationSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadRegulationFailure());
    }
}