import React from 'react';
import StepWizard from 'react-step-wizard';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';

import '../src/style.min.css';

const ProgressStep = props => {
    let custom = {
        enterRight: 'none',
        enterLeft: 'none',
        exitRight: 'none',
        exitLeft: 'none'
    }
    return (
        <>
            <StepWizard transitions={custom}>
                {
                    props.faixas[0]
                    && (
                        <StepOne {...props} />)
                }
                {
                    props.faixas[1]
                    && (
                        <StepTwo {...props} />)
                }
                {
                    props.faixas[2]
                    && (
                        <StepThree {...props} />)
                }
                {
                    props.faixas[3]
                    && (
                        <StepFour {...props} />)
                }
            </StepWizard>
        </>
    );
}
export default ProgressStep;