import api from '@/common/api';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { LoadDeliveryPayBanksFailure, LoadDeliveryPayBanksSuccess } from './actions';

export function* getDeliveryPayBanks() {
  try {
    const { data } = yield call(
      api.get,
      '/deliverypay/Bancos',
    );
    yield put(LoadDeliveryPayBanksSuccess(data.data));
  } catch (error) {
    toast.error('Falha ao obter código do banco!');
    yield put(LoadDeliveryPayBanksFailure());
  }
}

