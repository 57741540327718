import React, { Fragment } from 'react'
import { UserMenu } from '@/deliverySystem/components/menus'
import '@/deliverySystem/assets/style/main.min.css'

const SystemDeliveryLayout = ({ children }) => {
    return (
        <div className='app-delivery-system'>
            <div className="wr-delivery-system">
                <div className='container'>
                    <UserMenu />
                    {children}
                </div>
            </div>
        </div>
    );
}

export default SystemDeliveryLayout;