import bannerDescontosImg from "@/assets/img/simulator/v5/descontos-exclusivos-desk.jpg";
import bannerDescontosImgMobile from "@/assets/img/simulator/v5/descontos-exclusivos-mobile.jpg";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as SimulatorActions from "../../../store/simulator/actions";

const BannerDescontos = ({
  steps,
  navigation,
  index
}) => {
  const handleStep = () => {
    navigation.next();
  };

  return (
    <section className="bx bx-section-bannerDescontos">
      <div className="content">
        <div className="banner-descontos">
          <img
            src={window.innerWidth > 500 ? bannerDescontosImg : bannerDescontosImgMobile}
            className="fullWidthImg"
            title="Banner Descontos Exclusivos"
            alt="Banner Descontos Exclusivos"
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={() => handleStep()}
        className="btn btn-main ic-go"
      >
        Próximo
      </button>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerDescontos);
