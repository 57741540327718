import { AsideContainer } from "@/components/containers";
import { Tab, TabsBenefit } from "@/components/customTabs";
import { Link } from "react-router-dom";
import { IconArrowToLeft } from "@/components/icons";
import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as MyBenefitsActions from "../../store/clubBenefits/actions";
import AuthHelper from "./../../Utils/AlertHelper";
import { DefaultContainer } from "@/components/containers";
import Swal from "sweetalert2";

import "./src/benefitsDetails.min.css";

const BenefitsDetails = ({
  loading,
  myBenefitsDetailsRequest,
  benefitDetail,
  clubBenefitsLinkUrlRequest,
  clubBenefitsSuccess,
  clubBenefitsError,
  clubBenefitsMsgError,
  tokenClubBenefit,
  clubBenefitsLoading,
  inadimplente,
  inadimplenteVencimentos,
  readOnly,
  matriz,
  cobrancaCentralizada
}) => {
  const { id } = useParams();
  const [clubBenefitsClick, setClubBenefitsClick] = useState(false);

  useEffect(() => {
    const redirectToHome = true;

    if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos(redirectToHome);
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(redirectToHome, matriz, cobrancaCentralizada);
      return;
    }
  }, [inadimplente, inadimplenteVencimentos]);

  useEffect(() => {
    if (benefitDetail != null && clubBenefitsClick) {
      if (benefitDetail.id === 1) {
        if (
          clubBenefitsSuccess == false &&
          clubBenefitsError &&
          clubBenefitsMsgError.length > 0 &&
          clubBenefitsError
        ) {
          var msgError = clubBenefitsMsgError[0];
          ShowErrorUrlClubBenefitsAlert(msgError);
        }
  
        if (clubBenefitsSuccess && tokenClubBenefit) {
          setClubBenefitsClick(false);
          window.open(tokenClubBenefit.data, "_blank");
        }
      } else {
        setClubBenefitsClick(false);
        window.open(benefitDetail.urlRedirect, "_blank");
      }
    } 
  }, [clubBenefitsSuccess, tokenClubBenefit, benefitDetail, clubBenefitsClick]);

  const redirectToBenefitClub = (e) => {
    e.preventDefault();

    if (clubBenefitsLoading === true) return;

    if (readOnly === true) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (benefitDetail != null && benefitDetail.id === 1) {
      clubBenefitsLinkUrlRequest();
    }

    setClubBenefitsClick(true);
  };

  const ShowErrorUrlClubBenefitsAlert = (msgError) => {
    Swal.fire({
      icon: "warning",
      title: "Atenção!",
      text:
        "Ops, não foi possível acessar, atualize seus dados e tente novamente. Dados inválidos: " +
        msgError,
    });
  };

  useEffect(() => {
    myBenefitsDetailsRequest(id);
  }, []);

  return (
    <section className="pg pg-benefits-details">
      <DefaultContainer>
        {benefitDetail && (
          <>
            <main className="container-section">
              <Link to="/beneficios-prime" className="btn btn-link">
                <IconArrowToLeft
                  fill="#8f23d6"
                  className="ic ic-arrow-to-left ic-left"
                />
                Voltar
              </Link>
              <SectionTitle title={benefitDetail.titulo} />
              <img
                className="banner-benefit-header"
                src={
                  window.innerWidth > 500
                    ? benefitDetail.imagemBanner
                    : benefitDetail.imagemBannerMobile
                }
                alt=""
              />
              <div className="bx bx-details">
                <AsideContainer>
                  <TabsBenefit benefitDetail={benefitDetail}>
                    <Tab label={"Como funciona"} className={"tab-custom-class"}>
                      <div className="tab-Communication">
                        <div className="tab-Communication-description-container">
                          <div
                            className="tab-Communication-description"
                            dangerouslySetInnerHTML={{
                              __html: benefitDetail.comoFunciona,
                            }}
                          ></div>
                          {window.innerWidth > 500 && (
                            <div className="btn-benefit-redirect-desktop">
                              <a
                                href={benefitDetail.urlRedirect}
                                onClick={(e) => {
                                  redirectToBenefitClub(e);
                                }}
                                target="_blank"
                                className="btn ic-go"
                              >
                                {clubBenefitsLoading ? 'Aguarde...' : 'ACESSE E APROVEITE'}
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="tab-Communication-img">
                          <img src={benefitDetail.imagemDetalhe} alt="" />
                        </div>

                        {window.innerWidth <= 500 && (
                          <div className="btn-benefit-redirect-mobile">
                            <a
                              href={benefitDetail.urlRedirect}
                              onClick={(e) => {
                                redirectToBenefitClub(e);
                              }}
                              target="_blank"
                              className="btn ic-go"
                            >
                              {clubBenefitsLoading ? 'Aguarde...' : 'ACESSE E APROVEITE'}
                            </a>
                          </div>
                        )}
                      </div>
                    </Tab>
                    <Tab
                      label={"Dúvidas frequentes"}
                      className={"tab-custom-class"}
                      nextButton={true}
                    >
                      {benefitDetail.duvidasFrequentes && (
                        <div
                          className="tab-Communication-description"
                          dangerouslySetInnerHTML={{
                            __html: benefitDetail.duvidasFrequentes,
                          }}
                        ></div>
                      )}
                    </Tab>
                    <Tab label={"Suporte"} className={"tab-custom-class"}>
                      <div>
                        {benefitDetail.suporte && (
                          <div
                            className="tab-Communication-description"
                            dangerouslySetInnerHTML={{
                              __html: benefitDetail.suporte,
                            }}
                          ></div>
                        )}
                      </div>
                    </Tab>
                  </TabsBenefit>
                </AsideContainer>
              </div>
            </main>
          </>
        )}
      </DefaultContainer>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    myBenefitsDetailsRequest: (id) => {
      dispatch(MyBenefitsActions.myBenefitsDetailsRequest(id));
    },
    clubBenefitsLinkUrlRequest: () => {
      dispatch(MyBenefitsActions.clubBenefitsRequest());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    benefitDetail: state.clubBenefits.benefitDetail,
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    tokenClubBenefit: state.clubBenefits.tokenClubBenefit,
    clubBenefitsSuccess: state.clubBenefits.success,
    clubBenefitsLoading: state.clubBenefits.loading,
    clubBenefitsError: state.clubBenefits.error,
    clubBenefitsMsgError: state.clubBenefits.errorList,
    readOnly: state.auth.userInfo.readOnly,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsDetails);