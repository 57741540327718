import decode from 'jwt-decode';
import { useSelector } from 'react-redux';

export default class AuthHelper {
    static isValid(token) {
        try {
            if (token !== null) {
                const decoded = decode(token);
                if (decoded && decoded.exp > Date.now() / 1000) {
                    return true;
                }
            }
            localStorage.clear();
            return false;
        } catch (err) {
            return false;
        }
    }
    static isAutenticed() {
        try {
            const token = useSelector(state => state.auth.cognitoAccessToken);
            if (token !== null) {
                const decoded = decode(token);
                if (decoded && decoded.exp > Date.now() / 1000) {
                    return true;
                }
            }
            localStorage.clear();
            return false;
        } catch (err) {
            return false;
        }
    }
    static getUserName() {
        try {
            const token = useSelector(state => state.auth.accessToken);
            if (token !== null) {
                const decoded = decode(token);
                if (decoded && decoded.exp > Date.now() / 1000) {
                    return decoded.Nome;
                }
            }
            localStorage.clear();
            return '';
        } catch (err) {
            return '';
        }
    }
}