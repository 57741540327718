import { action } from 'typesafe-actions';
import { CentralizedBillingTypes } from './types';

export const acceptanceCentralizedBilling = () => action(CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_REQUEST);

export const acceptanceCentralizedBillingSuccess = (data) => action(CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_SUCCESS, { data });

export const acceptanceCentralizedBillingFailure = () => action(CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_FAILURE);

export const acceptanceCentralizedBillingReset = () => action(CentralizedBillingTypes.ACCEPTANCE_CENTRALIZED_BILLING_RESET);


export const centralizedBillingHistory = () => action(CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_REQUEST);

export const centralizedBillingHistorySuccess = (data) => action(CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_SUCCESS, { data });

export const centralizedBillingHistoryFailure = (error) => action(CentralizedBillingTypes.CENTRALIZED_BILLING_HISTORY_FAILURE, {error});