import api from '@/common/api';
import { call, put } from 'redux-saga/effects';
import { participantSimulatorFailure, participantSimulatorSuccess } from './actions';

export function* getParticipantSimulator() {
  try {
    const { data } = yield call(
      api.get,
      '/participante/Simulador',
    );

    yield put(participantSimulatorSuccess(data.data));
  } catch (error) {
    yield put(participantSimulatorFailure());
  }
}

