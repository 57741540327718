import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as AwardsActions from "../../../store/awardsSelection/actions";
import "../src/style.min.css";

const StepTwo = ({
  percent,
  setPercent,
  nextStep,
  faixas,
  setMedalha3,
  readOnly,
  premioEscolhido,
  hideModal,
  ShowSwal,
  choiceAwards,
  errorChoice,
  successChoice,
  loading,
  faixaAtual,
}) => {
  const {
    idCampanha,
    idFaixa,
    imagemBannerPrimeiroPremio,
    imagemBannerSegundoPremio,
    qtdePacotes,
    descricaoFaixa,
    opcaoPrimerioPremio,
    opcaoSegundoPremio,
  } = faixas[1];

  useEffect(() => {
    if (errorChoice && faixaAtual === idFaixa) {
      hideModal();
      ShowSwal("error", "Ops, ocorreu um erro na seleção, tente novamente!");
    }
  }, [errorChoice, faixaAtual]);

  useEffect(() => {
    if (successChoice && faixaAtual === idFaixa) {
      next();
    }
  }, [successChoice, faixaAtual]);

  function next() {
    if (faixas.length === 2) {
      hideModal();
      ShowSwal("success", "Obrigado, escolhas gravadas com sucesso!");
    } else {
      nextStep();
      setPercent(percent + 100 / faixas.length);
      setMedalha3(true);
    }
  }

  function SaveAndNext(opcaoEscolhida, descricaoFaixa) {
    if (readOnly === true) return;

    const disruption = premioEscolhido;
    disruption.idCampanha = idCampanha;
    disruption.idFaixa = idFaixa;
    disruption.opcaoEscolhida = opcaoEscolhida;
    disruption.descricaoFaixa = descricaoFaixa;
    choiceAwards(disruption);
  }

  return (
    <>
      Comprando <strong className="goal">{qtdePacotes} pacotes</strong> de
      produtos BAT, você ganha:
      <div className="awards-list">
        <ul>
          <li className="card-award">
            {imagemBannerPrimeiroPremio && opcaoPrimerioPremio && (
              <>
                <img src={imagemBannerPrimeiroPremio} />
                <p> {opcaoPrimerioPremio}</p>
                {loading ? (
                  <button disabled={true} className="btn btn-main">
                    {"Quero Este"}
                  </button>
                ) : (
                  <a
                    className="btn btn-main"
                    disabled={loading || readOnly === true}
                    onClick={() =>
                      SaveAndNext(opcaoPrimerioPremio, descricaoFaixa)
                    }
                  >
                    Quero Este
                  </a>
                )}
              </>
            )}
          </li>
          <li className="card-award">
            {imagemBannerSegundoPremio && opcaoSegundoPremio && (
              <>
                <img src={imagemBannerSegundoPremio} />
                <p>{opcaoSegundoPremio}</p>
                {loading ? (
                  <button disabled={true} className="btn btn-main">
                    {"Quero Este"}
                  </button>
                ) : (
                  <a
                    className="btn btn-main"
                    disabled={loading || readOnly === true}
                    onClick={() =>
                      SaveAndNext(opcaoSegundoPremio, descricaoFaixa)
                    }
                  >
                    Quero Este
                  </a>
                )}
              </>
            )}
          </li>
        </ul>
      </div>
      <small>*Você pode mudar a sua recompensa a cada 90 dias.</small>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    acceptanceTerm: state.myData.data.aceiteTermos,
    afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
    afiliadoConectaPayPendente: state.myData.data.afiliadoConectaPayPendente,
    primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
    idCampanha: state.regulation.idCampanha,
    readOnly: state.auth.userInfo.readOnly,
    errorChoice: state.awardsSelection.errorChoice,
    successChoice: state.awardsSelection.successChoice,
    faixaAtual: state.awardsSelection.atual,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    choiceAwards: (data) => {
      dispatch(AwardsActions.choiceAwards(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
