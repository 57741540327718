import { cnpj as cnpjValid, cpf as cpfValid } from 'cpf-cnpj-validator';

const cpf = (value) => {
  const validation = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g
  return validation.test(value) && cpfValid.isValid(value);
}

const cnpj = value => cnpjValid.isValid(value)

export default {
  cpf,
  cnpj
}
