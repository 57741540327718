import { CustomLoading } from "@/components/customLoading";
import JwtDecode from "@/Utils/jwtDecode";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as AuthOnActions from "store/auth/actions";
import * as SsoOnActions from "store/singleSignOn/actions";
import { AppMessages } from "../../../common/AppMessages";
import { Wrapper } from "./style";

const Callback = ({
  signInRequest,
  signOut,
  ssoResetParams,
  returnUrlResetParam,
  loading,
  location,
  accessToken,
  returnUrl,
  success,
  ...rest
}) => {
  const signed = JwtDecode.isAutenticed();
  // If a Cognito auth code is in the URL (could be a hash or query component), init the new session
  useEffect(() => {
    const stuff = {};

    ssoResetParams();

    if (location.hash) {
      location.hash
        .substring(1)
        .split("&")
        .reduce((obj, str, index) => {
          const strParts = str.split("=");
          if (strParts[0] && strParts[1]) {
            stuff[strParts[0].replace(/\s+/g, "")] = strParts[1].trim();
          }
          return obj;
        }, {});

      if (
        !stuff.id_token ||
        !stuff.access_token ||
        !JwtDecode.isValid(stuff.id_token) ||
        !JwtDecode.isValid(stuff.access_token)
      ) {
        signOut(AppMessages.EXPIRED_SESSION);
      }

      signInRequest({
        idToken: stuff.id_token,
        accessToken: stuff.access_token,
      });
    }
    // If there's no auth code in the URL or we're now logged into, redirect to the root page
    else if (!location.hash || signed) {
      window.location.assign("/");
    }
    // eslint-disable-next-line
  }, []);

  if (!loading && success) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <CustomLoading show={true} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    returnUrl: state.auth.returnUrl,
    loading: state.auth.loading,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInRequest: (params) => {
      dispatch(AuthOnActions.signInRequest(params));
    },
    signOut: (params) => {
      dispatch(AuthOnActions.signOut(params));
    },
    ssoResetParams: () => {
      dispatch(SsoOnActions.ssoResetParams());
    },
    returnUrlResetParam: () => {
      dispatch(AuthOnActions.returnUrlResetParam());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
