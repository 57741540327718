import { CampaignCard } from "@/components/cards";
import { DefaultContainer } from "@/components/containers";
import { CustomLoading } from "@/components/customLoading";
import { Filter } from "@/components/filter";
import { SectionTitle } from "@/components/sectionTitle";
import { SegmentPopup } from "@/components/segmentPopup";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as CampaignsActions from "../../store/campaigns/actions";
import "./src/campaigns-home.min.css";

//HOME DAS CAMPANHAS... lISTAGEM DE TODAS AS CAMPANHAS COM STATUS ATIVA
//FILTRO DE CAMAPNAHAS ATIVAS E CAMPANHAS ENCERRADAS
const MyCampaigns = ({ campaigns, loading, loadCampaign }) => {
  const [campaignView, setCampaignView] = useState("Campanhas ativas");
  const [viewCampaignbyStatus, setViewCampaignbyStatus] = useState(1);
  const filterOptions = ["Campanhas ativas", "Campanhas encerradas"];

  const toggleFilter = (event) => {
    setCampaignView(event.target.value);
    setViewCampaignbyStatus((viewCampaignbyStatus) => !viewCampaignbyStatus);
  };


  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    loadCampaign();
  }, []);

  return (
    <section className="pg pg-campaign-home">
      <DefaultContainer>
        <SectionTitle title="Campanhas">
          <Filter
            label="Exibindo: "
            options={filterOptions}
            inputProps={{
              name: "filter",
              id: "filter",
              value: campaignView,
              onChange: toggleFilter,
            }}
          />
        </SectionTitle>
        <p>
          Nesse espaço você pode comprar diversos produtos da BAT e de
          grandes parceiros com condições exclusivas. Todas essas ofertas geram
          pontos para trocar por prêmios no Conecta Prime. Confira abaixo as
          oportunidades disponíveis para você e o seu negócio:
        </p>
        <div className="bx-campaign-list">
          <ul>
            {!loading &&
              campaigns &&
              campaigns.map(
                (campaign, i) =>
                  viewCampaignbyStatus == campaign.vigente && (
                    <li key={`campaign-${i}`}>
                      <CampaignCard
                        cardType="campaign"
                        campaignId={campaign.id}
                        accept={
                          campaign.participando ||
                          !campaign.temRegulamentoAceite
                        }
                        status={campaign.vigente}
                        startDate={campaign.vigenciaDeSrt}
                        endDate={campaign.vigenciaAteSrt}
                        image={campaign.bannerUrl}
                        title={campaign.nome}
                        url={campaign.slug}
                        subtitle={campaign.subtitulo}
                        description={campaign.nome}
                      />
                    </li>
                  )
              )}
          </ul>
        </div>
      </DefaultContainer>
      <CustomLoading show={loading} />
      <SegmentPopup type={"campaign"} />
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCampaign: (pageSize) => {
      dispatch(CampaignsActions.loadCampaign(pageSize));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    campaigns: state.campaigns.data,
    loading: state.campaigns.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCampaigns);
