
import React from 'react';
import InputMask from 'react-input-mask';
import './src/fields.min.css';

const InputDateMask = ({ error, label, inputProps }) => {

  return (
    <div className={`fm-field text-field ${error ? 'filled-error' : ''}`}>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      {
        <InputMask {...inputProps} mask="99/99/9999" />
      }
      {error && <span className='error-label'>{error.message}</span>}
    </div>
  )
};

export default InputDateMask;
