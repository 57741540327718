import React from "react";
import { RadioButton } from "@/components/formFields";
import { connect } from "react-redux";
import { USER_DECISION } from "../notificationModaReentryDebtor";
import PropTypes from "prop-types";

import "../src/notificationModaReentryDebtor.min.css";

const ChoiseStep = (props) => {
  return (
    <>
      {props.withPromo && (
        <div className="reentry-reward">
          <h3> Você foi incluso em uma incrível promoção!</h3>
          <div className="reward">
            <ul>
              {!!props.reentryHistoricData.descricaoPromocaoAcao.length &&
                props.reentryHistoricData.descricaoPromocaoAcao.map((param) => {
                  return <li key={param.id}>- {param.texto}</li>;
                })}
            </ul>
            <span className="equal">=</span>
            <ul>
              {!!props.reentryHistoricData.descricaoPromocaoIncentivo.length &&
                props.reentryHistoricData.descricaoPromocaoIncentivo.map(
                  (param) => {
                    return <li key={param.id}>+ {param.texto}</li>;
                  }
                )}
            </ul>
          </div>
        </div>
      )}

      <div className="radio-box">
        <RadioButton
          label="Quero negociar os meus débitos e receber minhas pontuações e
        bonificações."
          checked={props.userDecision === USER_DECISION.NEGOCIAR}
          inputProps={{
            id: "0",
            name: "debtChoise",
            value: USER_DECISION.NEGOCIAR,
            onChange: (e) => props.setUserDecision(e.target.value),
          }}
        />

        <RadioButton
          label="Não quero negociar os meus débitos antigos e iniciar o programa, não
        tendo direito as minhas pontuações e bonificações."
          checked={props.userDecision === USER_DECISION.NAO_NEGOCIAR}
          inputProps={{
            id: "1",
            name: "debtChoise",
            value: USER_DECISION.NAO_NEGOCIAR,
            onChange: (e) => props.setUserDecision(e.target.value),
          }}
        />

        {props.withPromo && (
          <RadioButton
            label="Não quero decidir agora."
            checked={props.userDecision === USER_DECISION.VER_DEPOIS}
            inputProps={{
              id: "2",
              name: "debtChoise",
              value: USER_DECISION.VER_DEPOIS,
              onChange: (e) => props.setUserDecision(e.target.value),
            }}
          />
        )}
      </div>
      <button
        className="btn"
        disabled={props.chooseReentryStatus.loading}
        onClick={props.handleReentryDecision}
      >
        {props.chooseReentryStatus.loading
          ? "Enviando escolha..."
          : "Prosseguir"}
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    chooseReentryStatus: state.auth.chooseReentryStatus,
    reentryHistoricData: state.auth.reentryHistoric.data,
  };
};

export default connect(mapStateToProps, null)(ChoiseStep);

ChoiseStep.propTypes = {
  withPromo: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setUserDecision: PropTypes.func.isRequired,
  userDecision: PropTypes.string.isRequired,
  handleReentryDecision: PropTypes.func.isRequired,
  chooseReentryStatus: PropTypes.exact({
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  reentryHistoricData: PropTypes.shape({
    reentryHistoricData: PropTypes.array,
  }),
};
