import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  .loader {
    border: 16px solid #e6e7e8; /* Light grey */
    border-top: 16px solid #381cad; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
