import React from 'react';

const IconEllipsisY = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 15">
            <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                stroke='none'
                fill={props.fill} />
            <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(0 6)"
                stroke='none'
                fill={props.fill} />
            <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(0 12)"
                stroke='none'
                fill={props.fill} />
        </svg>

    );
};

export default IconEllipsisY;