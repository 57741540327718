import {AccountStatementTypes} from './types';

const INITIAL_STATE = {
    data: {},
    loading: false,
    error: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case AccountStatementTypes.ACCOUNT_STATEMENT_REQUEST:
        return {...state, loading: true};
      case AccountStatementTypes.ACCOUNT_STATEMENT_SUCCESS: 
       return {
            data: action.payload.data.data,
            loading: false,
            error: false
        }
      case AccountStatementTypes.ACCOUNT_STATEMENT_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
