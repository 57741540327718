import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';
import { detailsCampaign as reload } from './actions';
import { loadCampaignSuccess, loadCampaignFailure, detailsCampaignSuccess, detailsCampaignFailure, acceptCampaignSuccess, acceptCampaignFailure } from './actions';

export function* loadCampaign(action) {
    try {
        const endPoint = action.payload.pageSize ? `/campanha/filtro/?page=1&page_size=${action.payload.pageSize}` : '/campanha/filtro/';
        const { data } = yield call(api.get, endPoint);

        let dataFormated = data.map((item) => {return {...item, slug: item.slug.replace(/[!@#$%^&*]/g, "")}} )

        yield put(loadCampaignSuccess(dataFormated));
    } catch (error) {
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadCampaignFailure());
    }
}



export function* getCampaignDetails(action) {
    try {
        const { data } = yield call(api.get, '/campanha/' + action.payload.id);

        yield put(detailsCampaignSuccess(data));
    } catch (error) {
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(detailsCampaignFailure());
    }
}



export function* acceptCampaignRegulation(action) {
    try {
        const { data } = yield call(api.post, `/regulamento/${action.payload.id}/aceitar`);
        yield put(acceptCampaignSuccess(data));
        yield put(showToastr({ message: 'Você já esta participam de desta campanha!', type: 'success', open: true }));
        yield put(reload(action.payload.id));
    } catch (error) {
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(acceptCampaignFailure());
    }
} 