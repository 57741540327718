import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import './src/fields.min.css';

// COMPONENTES DE INPUTS PADRAO (TEXT, PASSWORD, EMAIL)
const InputCurrency = ({ error, label, inputProps, onChange}) => {

  return (
    <div className={`fm-field text-field ${error ? 'filled-error' : ''}`}>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      <CurrencyInput {...inputProps} groupSeparator="." decimalSeparator="," decimalScale={2} onValueChange={(value) => onChange(value)}/>
      {error ? <span className='error-label'>{error.message} </span> : ""}
    </div>
  )
};

export default InputCurrency;