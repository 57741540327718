import React from "react";
import { connect } from "react-redux";
import guiaBolsoImg from "./../../../assets/img/simulator/v5/guia-bolso-internet-desk.jpg";
import guiaBolsoImgMobile from "./../../../assets/img/simulator/v5/guia-bolso-internet-mobile.jpg";

const EmprestimoGuiaBolsoBanner = ({
  steps,
  navigation,
  index,
}) => {
  const handleStep = () => {
    navigation.next();
  };

  return (
    <section className="bx bx-section-guiaBolso">
      <div className="content">
        <div className="banner-guiaBolso">
          <img
            src={window.innerWidth > 500 ? guiaBolsoImg : guiaBolsoImgMobile}
            className="fullWidthImg"
            title="Guia Bolso"
            alt="Guia Bolso Banner"
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={() => handleStep()}
        className="btn btn-main ic-go"
      >
        Próximo
      </button>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmprestimoGuiaBolsoBanner);
