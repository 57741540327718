import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import DefaultContainer from '@/components/containers/defaultContainer'
import logo from '@/assets/img/logo-conecta-prime.png'
import './src/style.min.css'
const NotFaund = () => {
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);
    return (
        <section className='page-not-found'>
            <DefaultContainer>
                <Link to='/' className='logo-conecta-prime' title='Ir para home'>
                    <img src={logo} className='logo-conecta-prime' alt='Conect | Prime' />
                </Link>
                <h1>OPS...</h1>
                <p title='Desculpe, pagina não encontrada'>Desculpe, página não encontrada!</p>
                <Link to='/' title='Ir para home' className='btn btn-main ic-go btn-home'>Volte para home e continue navegando</Link>
            </DefaultContainer>
        </section>
    )
}
export default NotFaund 