export const CampaignTypes = {
    LOAD_CAMPAIGN_REQUEST: '@auth/LOAD_CAMPAIGN_REQUEST',
    LOAD_CAMPAIGN_SUCCESS: '@auth/LOAD_CAMPAIGN_SUCCESS',
    LOAD_CAMPAIGN_FAILURE: '@auth/LOAD_CAMPAIGN_FAILURE',

    DETAILS_CAMPAIGN_REQUEST: '@auth/DETAILS_CAMPAIGN_REQUEST',
    DETAILS_CAMPAIGN_SUCCESS: '@auth/DETAILS_CAMPAIGN_SUCCESS',
    DETAILS_CAMPAIGN_FAILURE: '@auth/DETAILS_CAMPAIGN_FAILURE',


    ACCEPT_CAMPAIGN_REQUEST: '@auth/ACCEPT_CAMPAIGN_REQUEST',
    ACCEPT_CAMPAIGN_SUCCESS: '@auth/ACCEPT_CAMPAIGN_SUCCESS',
    ACCEPT_CAMPAIGN_FAILURE: '@auth/ACCEPT_CAMPAIGN_FAILURE'
}