import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Input } from "@/components/formFields";
import { SectionTitle } from "@/components/sectionTitle";
import * as SimulatorActions from "@/store/simulator/actions";

// STEP 01
const Purchasing = ({
  navigation,
  current_state,
  simulator_state,
  simulatorRequest,
  setSteps,
  mostrarBeneficioEnergia
}) => {
  const [purchase, setPurchase] = useState(current_state.qtdePacotes);

  useEffect(() => {
    // Segmentação de UF para exibição de telas do Benefício Stella
    if (!mostrarBeneficioEnergia) {
      setSteps( arr => [...arr, arr[4].progressBar = false])
    }
  }, []);

  useEffect(() => {
    //Updating state
    simulator_state("qtdePacotes", parseInt(purchase));
    simulator_state("vendasConectaPay", false);
  }, [purchase]);

  const ValidationRules = () => {
    return yup.object().shape({
      purchase: yup
        .string("Insira uma quantidade médio de compras")
        .required(
          "A Quantidade das compras é obrigatório dar continuidade a simulação"
        ),
    });
  };

  const { register, errors, reset, getValues, triggerValidation } = useForm({
    validationSchema: ValidationRules(),
    mode: "onChange",
  });

  const sendForm = async (event) => {
    event.preventDefault();

    const isValid = await triggerValidation();

    if (isValid) {
      //SendingRequest
      simulatorRequest(current_state);

      navigation.next();
    }
  };

  return (
    <section className="bx bx-section-Purchasing">
      <SectionTitle title="Você costuma <br />comprar" />
      <form>
        <Input
          label="Quantidade de pacotes por mês?"
          error={errors.purchase}
          inputProps={{
            ref: register,
            name: "purchase",
            type: "number",
            id: "purchase",
            defaultValue: purchase,
            onChange: (e) => setPurchase(e.target.value),
          }}
        />
        <div className="disclaimer">
          <span>
            *O número usado não será considerado como sua meta de venda,{" "}
            <strong>SERÁ USADO APENAS PARA FINS DE SIMULAÇÃO</strong>
          </span>
        </div>
        <div className="bx-buttons">
          <button
            type="submit"
            onClick={sendForm}
            className="btn btn-main ic-go"
          >
            Próximo
          </button>
        </div>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    mostrarBeneficioEnergia: state.simulator.data.mostrarBeneficioEnergia
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchasing);
