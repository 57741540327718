import React from "react";

const IconFlag = (props) => {

  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      viewBox="0 0 20.043 25.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Atom_Icone_Bandeira" transform="translate(1.1 1.1)">
        <path
          id="Caminho_2401"
          d="M0,0H7.632L5.247,7.492l2.385,8.175H0Z"
          transform="translate(10.211 2.886)"
          fill="none"
           stroke={props.fill}
          strokeLinejoin="round"
          strokeWidth="2.2"
        />
        <line
          id="Linha_150"
          y2="23.5"
          fill="none"
          stroke={props.fill}
          strokeLinecap="round"
          strokeWidth="2.2"
        />
        <rect
          id="Retângulo_2191"
          width="9.802"
          height="15.667"
          transform="translate(0.408 0.412)"
          strokeWidth="2.2"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default IconFlag;
