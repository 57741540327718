import Swal from "sweetalert2";
import { AppMessages } from './../common/AppMessages';

export default class AuthHelper {
  static alertReadOnly() {
    Swal.fire({
      icon: "warning",
      title: "Atenção",
      text: AppMessages.READONLY_SESSION,
    });
  }
  static alertInadimplente(redirectToHome, matriz, cobrancaCentralizada) {
    Swal.fire({
      icon: "warning",
      title: "Essa operação não pode ser realizada",
      text: !matriz && cobrancaCentralizada ? AppMessages.INADIMPLENTE_COBRANCA_CENTRALIZADA_FILIAL : AppMessages.INADIMPLENTE_SESSION,
    }).then(() => {
      if (redirectToHome) {
        // Redirect para home caso seja necessário, do contrário, somente exibe popout.
        window.location.replace('/')
      }
    });
  }

  static validateUpdateDateReentry(date) {
    if (date) {
      let todayDate = new Date()
      let conclusionDateReentry = new Date(date)

      // Sum more 5 days
      let conclusionDateMore5Days = new Date(conclusionDateReentry.setDate(conclusionDateReentry.getDate() + 5))

      // If conclusionDateTimeMore5Days is a weekend sum days to the next workday
      if (conclusionDateMore5Days.getDay() === 6 || conclusionDateMore5Days.getDay() === 0) {
        for (let i = 1; conclusionDateMore5Days.getDay() === 0 || conclusionDateMore5Days.getDay() === 6; i++) {
          conclusionDateMore5Days = new Date(conclusionDateMore5Days.setDate(conclusionDateMore5Days.getDate() + 1));
        }
      }

      if (todayDate.getTime() <= conclusionDateMore5Days.getTime()) {
        return true
      } else {
        return false
      }
    }
  }


  static alertReentry(redirectToHome = false) {
    Swal.fire({
      icon: "warning",
      title: "Aguarde!",
      text: `Sua pontuação está sendo atualizada. Em breve você poderá acessar essa página.`,
    }).then(() => {
      if (redirectToHome) {
        // Redirect para home caso seja necessário, do contrário, somente exibe popout.
        window.location.replace('/')
      }
    });
  }

  static alertInadimplenteVencimentos(redirectToHome) {
    Swal.fire({
      icon: "warning",
      title: "Essa operação não pode ser realizada",
      text: AppMessages.INADIMPLENTE_VENCIMENTOS,
    }).then(() => {
      if (redirectToHome) {
        // Redirect para home caso seja necessário, do contrário, somente exibe popout.
        window.location.replace('/')
      }
    });
  }
}

