import { AsideContainer } from "@/components/containers";
import { AsideMenu } from "@/components/menus";
import JwtDecode from "@/Utils/jwtDecode";
import {
  IconBankSlip,
  IconClose,
  IconCoin,
  IconConectaPay,
  IconDocument,
  IconBonus,
  IconPerson,
  IconStore,
} from "components/icons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "./src/myPersonalData.min.css";
import * as PaymentActions from "store/payments/actions";
import TipoPagamentoHelper from "./../../Utils/TipoPagamentoHelper";

const Index = ({
  matriz,
  conectaPayAtiva,
  afiliadoConectaPay,
  readOnly,
  inadimplente,
  mostrarMenuBoletos,
  participantPaymentMethodRequest,
}) => {
  const signed = JwtDecode.isAutenticed();

  const navigation = [
    {
      id: 1,
      label: "Meus dados",
      codigo: "MEUS_DADOS",
      classItem: "mn-meus-dados",
      url: "meus-dados",
      isAtive: true,
      icon: <IconPerson className="ic ic-user" />,
    },
    {
      id: 3,
      label: "Extrato",
      codigo: "EXTRATO",
      classItem: "mn-extrato",
      url: "/extrato",
      isAtive: true,
      icon: <IconDocument className="ic ic-extrato" />,
      target: "_self",
    },
    {
      id: 4,
      label: "Bonificação",
      codigo: "BONIFICACAO",
      classItem: "mn-bonificacao",
      url: "/bonificacao",
      isAtive: true,
      icon: <IconBonus className="ic ic-bonificacao" />,
    },
    {
      id: 6,
      label: "Conecta pay",
      codigo: "CONECTA_PAY",
      classItem: "mn-conecta-pay",
      url: "/conecta-pay",
      isAtive:
        TipoPagamentoHelper.GetAllPaymentsStatusActive().DEBITO_RECEBIVEIS &&
        conectaPayAtiva &&
        afiliadoConectaPay,
      icon: <IconConectaPay className="ic ic-conecta-pay" />,
    },
    {
      id: 8,
      label: "Meus pagamentos",
      codigo: "MEUS_PAGAMENTOS",
      classItem: "mn-payments",
      url: "/meus-pagamentos",
      isAtive: true,
      icon: <IconCoin className="ic ic-payments" />,
    },
    {
      id: 2,
      label: "Meus Boletos",
      codigo: "MEUS_BOLETOS",
      classItem: "mn-meus-pagamentos",
      url: "/bank-slip",
      isAtive: mostrarMenuBoletos,
      icon: <IconBankSlip className="ic ic-payments" />,
    },
    {
      id: 10,
      label: "Boleto Bancário",
      codigo: "BOLETO_BANCARIO",
      classItem: "mn-payments",
      url: "/bank-slip",
      isAtive: false,
      icon: <IconBankSlip className="ic ic-payments" />,
    },
    {
      id: 7,
      label: "Minha rede de varejos",
      codigo: "MINHA_REDE_VAREJOS",
      classItem: "mn-retail-chain",
      url: "/minha-rede-de-varejos",
      isAtive: true,
      icon: <IconStore className="ic ic-retail" />,
    },
    {
      id: 9,
      label: "Cancelar adesão",
      codigo: "CANCELAR_ADESAO",
      classItem: "mn-cancelamento",
      url: "cancel",
      //url: isCancel(),
      isAtive: false,
      icon: <IconClose className="ic ic-close" />,
      //cancelled: true,
    },
  ];

  useEffect(() => {
    participantPaymentMethodRequest();
  }, []);

  return (
    <Router>
      <section className="pg pg-help">
        <AsideContainer>
          <aside className="container-aside">
            <AsideMenu
              signed={signed}
              title="Minha conta"
              menuItens={navigation}
            />
          </aside>
          <main className="container-section">
            <Routes />
          </main>
        </AsideContainer>
      </section>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    participantPaymentMethodRequest: () => {
      dispatch(PaymentActions.participantPaymentMethodRequest());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    matriz: state.myData.data.matriz,
    conectaPayAtiva: state.myData.data.conectaPayAtiva,
    afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
    inadimplente: state.myData.data.inadimplente,
    readOnly: state.auth.userInfo.readOnly,
    mostrarMenuBoletos: state.myData.data.mostrarMenuBoletos,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
