import React from 'react'
import createPiecesImg from "@/deliverySystem/assets/images/home/create-pieces-img.png"
import cellphoneImg from "@/deliverySystem/assets/images/home/cellphone-img.png"
import './src/projectCreationSteps.min.css'

const SelectProjectType = ({ navigation }) => {
    return (
        <section className='select-project-type'>
            <div className="title">
                <h2>CRIE UM NOVO PROJETO</h2>
                <span>
                    Selecione o tipo de projeto que deseja criar. São apenas 3 passos.
                </span>
            </div>
            <div className="img-container">
                <button
                    type="button"
                    className="btn-box box1"
                    onClick={navigation.next}
                >
                    <img src={createPiecesImg} />
                    <div className="text-box">
                        <span>Crie as peças de loja</span>
                        <span className="text-purple">
                            Imã de geladeira, panfleto, cartão de visita e cardápio
                        </span>
                    </div>
                </button>
                <button
                    type="button"
                    className="btn-box box2"
                    onClick={navigation.next}
                >
                    <img src={cellphoneImg} />
                    <div className="text-box">
                        <span>Crie seu cardápio para WhasApp</span>
                        <span className="text-purple">
                            Todos os seus produtos no whatsapp do seu cliente
                        </span>
                    </div>
                </button>
            </div>
        </section>
    );
};

export default SelectProjectType;