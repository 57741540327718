import { AcessStageForm } from "@/components/acessStageForm";
import { AcessStageCreditCard } from "@/components/acessStageCreditCard";
import { ConectaPayForm } from "@/components/conectaPayForm";
import { InputTelAndCellphone } from "@/components/formFields";
import { IconInfo } from "@/components/icons";
import { Tooltip } from "@/components/tooltip";
import DateValidator from "@/Utils/DateValidator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import DadosDePessoais from "./steps/0_dadosPessoais";
import DadosDeContato from "./steps/1_dadosDeContato";
import ConectaPayMaquininha from "./steps/2_conectaPayMaquininha";
import MetodoDePagamento from "./steps/3_metodoDePagamento";
import CadastroFinalizado from "./steps/4_cadastroFinalizado";
import Validation from "@/components/utils/validationFields";

import helperSteps from "@/common/helperSteps";
import { useStep } from "react-hooks-helper";

import * as AuthActions from "store/auth/actions";
import * as FirstAccessActions from "store/firstAccess/actions";
import * as PaymentMethodsActions from "store/payments/actions";
import "./src/dataComplement.min.css";

const DataComplement = ({
  authState,
  conectaPay,
  error,
  firstAccessData,
  firstAccessState,
  hasConnectPay,
  loadFirstAccess,
  loading,
  loadPaymentMethods,
  message_error,
  payments,
  paymentTypeOld,
  progressStep,
  readOnly,
  success,
  successData,
  conectaPayAtiva,
  updateFirstAccessData,
  updateFirstAccessSuccessFlag,
  successFirstAccess,
  successMyData,
  successPayment,
  primeiroAcessoRealizado,
  setCreditCardPaymentRequest,
  creditCardData
}) => {
  const [steps, setSteps] = useState(helperSteps);
  const { index, step, navigation } = useStep({ initialStep: 0, steps });
  const stepsProps = { steps, navigation, setSteps, index };
  const [formData, setFormData] = useState({});
  const [gender, setGender] = useState(0);
  const [preferredContact, setPreferredContact] = useState("");

  const [tel, setTel] = useState("");
  const [date, setDate] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [cellPreferred, setCellPreferred] = useState("");

  const [isValidTel, setIsValidTel] = useState(true); // Não é obrigatorio
  const [isValidRecommendedByValid, setIsValidRecommendedBy] = useState(true); // Não é obrigatorio
  const [isValidCell, setIsValidCell] = useState(null); // obrigatoria
  const [isValidCellPreferred, setIsValidCellPreferred] = useState(null); // obrigatoria
  const [isValidDate, setIsValidDate] = useState(null); // obrigatoria
  const [isValidEmail, setIsValidEmail] = useState(null); // obrigatoria

  const [redirectHome, setRedirectHome] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [recommendedBy, setRecommendedBy] = useState(
    firstAccessData.indicadoPor
  );

  const [showIframeCard, setShowIframeCard] = useState({url: '', status: 0})

  const [clickedBtnSolicitarMaquininha, setClickedBtnSolicitarMaquininha] = useState();
  const [load, setLoad] = useState(false);
  const [dataPayment, setDataPayment] = useState({
    bancoCodigo: "",
    bancoAgencia: "",
    bancoAgenciaDigito: "",
    bancoContaBancaria: "",
    bancoContaBancariaDigito: "",
  });
  const [showBtnSolicitarMaquina, setShowBtnSolicitarMaquina] = useState(null);
  const [showStepConectaPay, setShowStepConectaPay] = useState({
    status: null,
    stage: 0,
  });
  const userProfile = useSelector((state) => state.auth.userInfo);
  window.cloudLoyaltyParticipanteId =
    window.cloudLoyaltyParticipanteId || userProfile.cloudLoyaltyParticipanteId;

  const validationRules = yup.object().shape({
    fullName: yup
      .string("Insira um nome válido!")
      .required("O Nome é obrigatório!"),
    birth: yup
      .string("Uma data de nascimento Válida!")
      .required("Digite sua data de nascimento"),
    genre: yup
      .number("Selecione um gênero válido!")
      .required("Gênero é obrigatório"),
    email: yup
      .string("Digite um telefone válido!")
      .email("Digite um e-mail válido!")
      .required("E-mail é obrigatório"),
    confirmEmail: yup
      .string("Digite um telefone válido!")
      .email("Digite um e-mail válido!")
      .required("Confirmação do e-mail é obrigatório"),
    emailPreferred: yup
      .string("Digite um telefone válido!")
      .email("Digite um e-mail válido!")
      .required("e-mail preferencial é obrigatório"),
    recommendedBy: yup
      .string("Insira um cpf válido!")
      .matches(
        /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g,
        "CPF inválido"
      ),
  });

  const {
    register,
    errors,
    setError,
    clearError,
    getValues,
    triggerValidation,
  } = useForm({
    validationSchema: validationRules,
    mode: "onChange",
  });
  useEffect(() => {
    loadFirstAccess();
    loadPaymentMethods();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Await success request
    if (successFirstAccess && successMyData) {
      setStepsConectaPay();
    }
  }, [successFirstAccess, successMyData]);

  useEffect(() => {
    const form = getValues();
    if (!load || !form.fullName) {
      setFormData(firstAccessData);
      const {
        nascimento,
        indicadoPor,
        celular,
        telefone,
        tipoPagamento,
      } = firstAccessData;

      setRecommendedBy(indicadoPor);
      setCellphone(celular);
      setTel(telefone);
      setGender(
        firstAccessData.genero === null ? 0 : firstAccessData.genero.toString()
      );
      setPaymentMethod(tipoPagamento);
      setPreferredContact('1');
      setLoad(true);
    }
  }, [firstAccessData]);

  useEffect(() => {
    if (Number(paymentMethod) !== 4) clearFormDebit();
  }, [paymentMethod]);

  const dateValidation = (date) => {
    if (!DateValidator.validaDate(date)) {
      setIsValidDate(false);
      return false;
    }

    setIsValidDate(true);
    return true;
  };

  const telValidation = (telefone) => {
    let currentTel = telefone?.replace(/[^\d]+/g, "");

    if (currentTel && currentTel.length < 10) {
      setIsValidTel(false);
      return false;
    }

    setIsValidTel(true);
    return true;
  };

  const cellValidation = (cellPhone) => {
    let currentCel = cellPhone?.replace(/[^\d]+/g, "");

    if (!currentCel || currentCel.length < 11 || cellPhone === "") {
      setIsValidCell(false);
      return false;
    }

    setIsValidCell(true);
    return true;
  };

  const cellPreferredValidation = (cellPhonePreferred) => {
    let currentCelPreferred = cellPhonePreferred?.replace(/[^\d]+/g, "");

    if (!currentCelPreferred || currentCelPreferred.length < 11 || cellPhonePreferred === "") {
      setIsValidCellPreferred(false);
      return false;
    }

    setIsValidCellPreferred(true);
    return true;
  };

  const emailValidation = (email, confirmEmail) => {
    if (email !== confirmEmail) {
      setError("confirmEmail", "validate", "E-mails não coincidem");
      setIsValidEmail(false);
      return false;
    }

    if (email === "no@email.com" || email === "") {
      setError("confirmEmail", "validate", "Insira um E-mail válido!");
      setIsValidEmail(false);
      return false;
    }

    clearError("confirmEmail");
    setIsValidEmail(true);
    return true;
  };

  const recommendedByValidation = (targetRecommendedBy) => {
    if (!Validation.cpf(targetRecommendedBy) && targetRecommendedBy.length == 14) {
      setIsValidRecommendedBy(false);
      return false;
    }
    setIsValidRecommendedBy(true);
    return true;
  };

  const contactPreferredValidation = (type, email) => {
    let isValidContactPreferred = false
    if (type == 1) {
      let cellIsValid = cellPreferredValidation(cellPreferred)
      isValidContactPreferred = cellIsValid
    } else if (type == 2) {
      if (email === "no@email.com" || email === "") {
        setError("emailPreferred", "validate", "Insira um E-mail válido!");
        isValidContactPreferred = false
      } else if (!errors.emailPreferred) {
        isValidContactPreferred = true
      }
    }
    return isValidContactPreferred;
  };

  // STEP 1 DADOS_PESSOAIS
  const validationDadosPessoais = () => {
    const form = getValues();
    if (errors.fullName || !isValidDate) {
      Swal.fire({
        text: "Preencha todos os campos obrigatórios",
        icon: "error",
        confirmButtonText: "Continuar preenchendo",
      });
    } else if (!isValidRecommendedByValid) {
      Swal.fire({
        text: "CPF inválido",
        icon: "error",
        confirmButtonText: "Continuar preenchendo",
      });
    } else {
      return true;
    }
  };

  // STEP 2 DADOS_CONTATO
  const validationDadosContato = () => {
    const form = getValues();

    if (
      !isValidCell ||
      !emailValidation(form.email, form.confirmEmail) ||
      !contactPreferredValidation(form.contatoPreferencial, form.emailPreferred)
    ) {
      Swal.fire({
        text: "Preencha todos os campos obrigatórios",
        icon: "error",
        confirmButtonText: "Continuar preenchendo",
      });
    } else {
      return true;
    }
  };

  // STEP 3 CONECTAPAY_MAQUININHA
  // Logica de passos
  const setStepsConectaPay = () => {
    if (conectaPayAtiva && firstAccessData.afiliadoConectaPay) {
      // Mostra Tela "ConectaPay", Desabilita Botão "Solicitar Maquininha"
      setShowStepConectaPay({ status: true, stage: 1 });
      setShowBtnSolicitarMaquina(false);
    } else if (conectaPayAtiva && !firstAccessData.afiliadoConectaPay) {
      // Mostra Tela "ConectaPay", Habilita Botão "Solicitar Maquininha"
      setShowStepConectaPay({ status: true, stage: 1 });
      setShowBtnSolicitarMaquina(true);
    } else if (!conectaPayAtiva && firstAccessData.afiliadoConectaPay) {
      // Não mostra a tela "ConectaPay", pula para "Método de Pagamento"
      setShowStepConectaPay({ status: false, stage: 0 });
      setShowBtnSolicitarMaquina(false);
    } else if (!conectaPayAtiva && firstAccessData.afiliadoConectaPay) {
      // Não mostra a tela "ConectaPay", pula para "Método de Pagamento"
      setShowStepConectaPay({ status: false, stage: 0 });
      setShowBtnSolicitarMaquina(false);
    }
  };
  // Validação
  const validationConectaPay = () => {
    return true; // Validação ocorre no próprio componente.
  };

  // STEP 4 METODO_DE_PAGAMENTO
  const validationPaymentMethod = () => {
    if (success && !error && !loading) {
      return true
    }
  };

  // ENVIO DO FORMULARIO
  const sendForm = async (event) => {

    if (event !== undefined) {
      event.preventDefault();
    }

    const form = getValues();

    let conectaPayDados = null;

    if (conectaPay.codigoBanco) {
      conectaPayDados = {
        bancoCodigo: conectaPay.codigoBanco,
        bancoAgencia: conectaPay.agencia,
        bancoContaBancaria: conectaPay.conta,
        BancoContaBancariaDigito: conectaPay.digito,
        bancoContaTipo: conectaPay.poupanca ? 1 : 0,
        bancoFavorecidoDocumento: conectaPay.cpfCnpj,
        bancoFavorecidoNome: conectaPay.nomeOuRazao,
      };
    }

    let data = {
      idParticipante: form.idParticipante,
      nome: form.fullName,
      nascimento: date,
      genero: parseInt(gender),
      preferredContact: {
        id: parseInt(preferredContact),
        preferredContact: preferredContact == 1 ? cellPreferred.replace(/[^\d]+/g, "") : form.emailPreferred
      },
      telefone: tel ? tel.replace(/[^\d]+/g, "") : null,
      canalComunicacao: formData.canalComunicacao,
      indicadoPor: recommendedBy ? recommendedBy.replace(/[^\d]+/g, "") : "",
      contatoCpfCnpj: formData.contatoCpfCnpj,
      celular: cellphone ? cellphone.replace(/[^\d]+/g, "") : null,
      email: form.email,
      afiliadoConectaPay: firstAccessData.afiliadoConectaPay,
      tipoPagamento:
        paymentMethod == (0 || null)
          ? parseInt(paymentTypeOld)
          : parseInt(paymentMethod),
      deliveryPay: conectaPayDados,
      accesStage: dataPayment.bancoAgencia != "" ? dataPayment : null,
      cardnumber: paymentMethod == 4 ? dataPayment.cardnumber : null,
    };

    const isValid = await triggerValidation();

    if (
      isValid &&
      cellValidation(cellphone) &&
      dateValidation(date) &&
      emailValidation(form.email, form.confirmEmail) &&
      contactPreferredValidation(form.contatoPreferencial, form.emailPreferred)
    ) {
      firstAccessState("current_state", "conectaPay", {});
      firstAccessState(
        "data",
        "afiliadoConectaPay",
        firstAccessData.afiliadoConectaPay
        );

      if (data.tipoPagamento == 4 && showIframeCard.url == "") {
        setCreditCardPaymentRequest(data)
      } else {
        await updateFirstAccessData(data);
      }
    } else {
      Swal.fire({
        text: "Preencha todos os campos",
        icon: "error",
        confirmButtonText: "Continuar preenchendo",
      });
    }
  };

  useEffect(() => {
  // Aguarda retorno do POST - Cartão de Crédito
    if (creditCardData?.success) {
      let url = creditCardData?.data;
      setShowIframeCard({...showIframeCard, url, status: 1});
    } else if (creditCardData?.error) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Ops, ocorreu algum problema. Por gentileza, tente novamente!",
        timer: 2000,
        timerProgressBar: true,
      });
    }
  },[creditCardData?.success, creditCardData?.error])

  // Aguarda retorno do POST - Primeiro Acesso
  useEffect(() => {
    if (success && !error && !loading) {
      authState("userInfo", "firstAccess", false);
      authState(
        "userInfo",
        "afiliadoConectaPay",
        firstAccessData.afiliadoConectaPay
      );
      nextStep();
    } else if (error && !success && !loading) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: message_error,
        timer: 2000,
        timerProgressBar: true,
      });
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [success, error, message_error]);

  const nextStep = () => {
    if (step == "DADOS_PESSOAIS") {
      if (validationDadosPessoais()) {
        progressStep(index + 1);
        stepsProps.navigation.next();
      }
    }

    if (step == "DADOS_CONTATO") {
      if (validationDadosContato()) {
        if (!conectaPayAtiva) {
          progressStep(index + 2);
          stepsProps.navigation.go(3); // Pular step Conecta Pay
        } else {
          progressStep(index + 1);
          stepsProps.navigation.next();
        }
      }
    }

    if (step == "CONECTAPAY_MAQUININHA") {
      if (validationConectaPay()) {
        progressStep(index + 1);
        stepsProps.navigation.next();
      }
    }

    if (step == "METODO_DE_PAGAMENTO") {
      if (validationPaymentMethod()) {
        progressStep(index + 1);
        stepsProps.navigation.next();
      }
    }
  };

  const previousStep = (e) => {
    if (step == "DADOS_CONTATO") {
      e.preventDefault()
      progressStep(index - 1);
      stepsProps.navigation.previous();
    }

    if (step == "CONECTAPAY_MAQUININHA") {
      e.preventDefault()
      progressStep(index - 1);
      stepsProps.navigation.previous();
    }

    if (step == "METODO_DE_PAGAMENTO") {
      e.preventDefault()
      if (!conectaPayAtiva) {
        progressStep(index - 2);
        stepsProps.navigation.go(1); // Pular step Conecta Pay
      } else {
        progressStep(index - 1);
        stepsProps.navigation.previous();
      }
    }
  };

  if (redirectHome) return <Redirect to="/" />;

  async function clearFormDebit() {
    let paymentData = { ...dataPayment };
    paymentData.bancoAgencia = "";
    paymentData.bancoAgenciaDigito = "";
    paymentData.bancoContaBancaria = "";
    paymentData.bancoContaBancariaDigito = "";
    paymentData.bancoCodigo = "";

    setDataPayment(paymentData);
  }

  return (
    <>
      <form onSubmit={sendForm}>
        <div
          id="userInfo"
          className={step === helperSteps[0] ? "showStep" : "hideStep"}
        >
          <DadosDePessoais
            step={step}
            stepsProps={stepsProps}
            nextStep={nextStep}
            date={date}
            dateValidation={dateValidation}
            formData={formData}
            fullName={errors.fullName}
            gender={gender}
            isValidDate={isValidDate}
            isValidTel={isValidTel}
            recommendedBy={recommendedBy}
            setRecommendedBy={setRecommendedBy}
            recommendedByValidation={recommendedByValidation}
            register={register}
            setDate={setDate}
            setGender={setGender}
            setIsValidTel={setIsValidTel}
            setTel={setTel}
            telValidation={telValidation}
            validationRules={validationRules}
          />
        </div>
        <div className={step === helperSteps[1] ? "showStep" : "hideStep"}>
          <DadosDeContato
            stepsProps={stepsProps}
            step={step}
            nextStep={nextStep}
            previousStep={previousStep}
            errors={errors}
            formData={formData}
            setCellphone={setCellphone}
            cellValidation={cellValidation}
            emailValidation={emailValidation}
            isValidEmail={isValidEmail}
            isValidCell={isValidCell}
            setIsValidCell={setIsValidCell}
            register={register}
            validationRules={validationRules}
            setCellPreferred={setCellPreferred}
            cellPreferredValidation={cellPreferredValidation}
            isValidCellPreferred={isValidCellPreferred}
            setIsValidCellPreferred={setIsValidCellPreferred}
            preferredContact={preferredContact}
            setPreferredContact={setPreferredContact}
          />
        </div>
        <div className={step === helperSteps[2] ? "showStep" : "hideStep"}>
          <ConectaPayMaquininha
            stepsProps={stepsProps}
            nextStep={nextStep}
            previousStep={previousStep}
            primeiroAcessoRealizado={primeiroAcessoRealizado}
            showBtnSolicitarMaquina={showBtnSolicitarMaquina}
            setClickedBtnSolicitarMaquininha={setClickedBtnSolicitarMaquininha}
            showStepConectaPay={showStepConectaPay}
            setShowStepConectaPay={setShowStepConectaPay}
            afiliadoConectaPay={firstAccessData.afiliadoConectaPay}
            conectaPayAtiva={conectaPayAtiva}
            step={step}
            ConectaPayForm={ConectaPayForm}
            successFirstAccess={successFirstAccess}
            successMyData={successMyData}
            readOnly={readOnly}
            setPaymentMethod={setPaymentMethod}
          />
        </div>
        <div className={step === helperSteps[3] ? "showStep" : "hideStep"}>
          <MetodoDePagamento
            stepsProps={stepsProps}
            conectaPayAtiva={conectaPayAtiva}
            firstAccessData={firstAccessData}
            afiliadoConectaPay={firstAccessData.afiliadoConectaPay}
            payments={payments}
            previousStep={previousStep}
            AcessStageForm={AcessStageForm}
            AcessStageCreditCard={AcessStageCreditCard}
            dataPayment={dataPayment}
            clickedBtnSolicitarMaquininha={clickedBtnSolicitarMaquininha}
            loading={loading}
            paymentMethod={paymentMethod}
            paymentTypeOld={paymentTypeOld}
            readOnly={readOnly}
            register={register}
            setDataPayment={setDataPayment}
            setPaymentMethod={setPaymentMethod}
            nextStep={nextStep}
            step={step}
            enviaForm={sendForm}
            showIframeCard={showIframeCard}
            setShowIframeCard={setShowIframeCard}
            creditCardData={creditCardData}
          />
        </div>
        <div className={step === helperSteps[4] ? "showStep" : "hideStep"}>
          <CadastroFinalizado
            stepsProps={stepsProps}
            readOnly={readOnly}
            loading={loading}
            success={success}
            step={step}
          />
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firstAccessData: state.firstAccess.data,
    loading: state.firstAccess.loading,
    success: state.firstAccess.updateData.success,
    successData: state.firstAccess.updateData.data,
    error: state.firstAccess.updateData.error,
    message_error: state.firstAccess.updateData.message_error,
    successFirstAccess: state.firstAccess.success,
    successMyData: state.myData.success,
    successPayment: state.payment.success,
    conectaPayAtiva: state.myData.data.conectaPayAtiva,
    primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
    conectaPay: state.firstAccess.current_state.conectaPay,
    payments: state.payment.data,
    paymentTypeOld: state.auth.userInfo.tipoPagamento,
    readOnly: state.auth.userInfo.readOnly,
    creditCardData: state.payment.creditCardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authState: (node, key, value) => {
      dispatch(AuthActions.authState(node, key, value));
    },
    firstAccessState: (node, key, value) => {
      dispatch(FirstAccessActions.firstAccessState(node, key, value));
    },
    loadFirstAccess: () => {
      dispatch(FirstAccessActions.loadFirstAccess());
    },
    updateFirstAccessData: (data) => {
      dispatch(FirstAccessActions.updateFirstAccess(data));
    },
    updateFirstAccessSuccessFlag: () => {
      dispatch(FirstAccessActions.updateFirstAccessSuccessFlag());
    },
    loadPaymentMethods: () => {
      dispatch(PaymentMethodsActions.loadPaymentMethodsRequest());
    },
    setCreditCardPaymentRequest: (data) => {
      dispatch(PaymentMethodsActions.setCreditCardPayment(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DataComplement);
