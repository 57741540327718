import React, { useState, useEffect } from 'react';
import { Accordion } from '@/components/accordion'
import * as FaqActions from 'store/faq/actions'
import { SectionTitle } from '@/components/sectionTitle'
import { connect } from 'react-redux';

const FAQ = (props) => {
    const [faqList, setFAQ] = useState([]);

    useEffect(() => {
        props.loadFaq();
    }, []);

    useEffect(() => {
        setFAQ(props.faq.faqs);
    }, [props.faq]);

    return (
        <section className='faq'>
            <SectionTitle title='Perguntas frequentes' />
            <div className='bx-accordion'>
                {faqList && faqList.map(faq => {
                    return (
                        <Accordion key={`faq-item-${faq.id}`} title={faq.pergunta}>
                            <div dangerouslySetInnerHTML={{ __html: faq.resposta }}></div>
                        </Accordion>
                    )
                })}
            </div>
        </section>
    )
};


const mapStateToProps = state => {
    return {
        faq: state.faq
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadFaq: () => {
            dispatch(FaqActions.loadFaq());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);