import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { loadFirstAccessSuccess, loadFirstAccessFailure, updateFirstAccessSuccess, updateFirstAccessFailure } from './actions';

export function* loadFirstAccess(){
    try{       
        const { data} = yield call(api.get, '/primeiro-acesso/participante');

        yield put(loadFirstAccessSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadFirstAccessFailure());
    }
} 

export function* updateFirstAccess(action){
    try{
        const { data} = yield call(api.post, '/primeiro-acesso/participante/save' ,action.payload.data);

        yield put(updateFirstAccessSuccess(data));
    }catch(error){
        yield put(updateFirstAccessFailure(error));
    }
} 