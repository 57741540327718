import React from "react";
import "./src/fields.min.css";

// COMPONENTES DE INPUTS PADRAO (TEXT, PASSWORD, EMAIL)
const Input = ({ error, label, inputProps, hasClass }) => {

  return (
    <div
 className={`fm-field text-field ${error ? "filled-error" : ""} ${hasClass ? hasClass : ''}`}
>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      <input {...inputProps} />
      {error ? <span className="error-label">{error.message} </span> : ""}
    </div>
  );
};

export default Input;
