import HomeConnected from '@/pages/homeConnected/';
import HomeDisconnected from '@/pages/homeDisconnected/';
import JwtDecode from '@/Utils/jwtDecode';
import React from 'react';
import { Redirect as RedirectFreezing, withRouter } from 'react-router-dom';


const Redirect = () => {
  const signed = JwtDecode.isAutenticed();
  if (signed)
    return (
      <HomeConnected />
    )
  if (!signed)
    return (
      <RedirectFreezing to="/logout" />
    )
};

export default withRouter(Redirect);




