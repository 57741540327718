import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/footer';

const LayoutDisconnected = ({ children }) => {
    return (
        <div className="app logged-out">
            <div className="wr-site">
                { window.location.pathname !== '/' && <Header />}
                {children}
            </div>
            <Footer />
        </div>
    );
}

export default LayoutDisconnected;