import {BannerTypes} from './types';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case BannerTypes.LOAD_BANNER_REQUEST:
        return {...state, loading: true};
      case BannerTypes.LOAD_BANNER_SUCCESS: 
       return {
          loading: false,
          data: action.payload.data.data
        }
      case BannerTypes.LOAD_BANNER_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
