import Api from '../../common/api'
import { AlertMessage } from '@/components/notification'

export async function LoadCentralizationOfPoints() {
    try {
      const result = await Api.get('/participante/CentralizarPontuacao')
      AlertMessage('Os pontos da sua rede de filiais serão creditados para o seu usuário', 'success')
      return result
    } catch (error) {
      AlertMessage('Ocorreu um erro com sua solicitação, por favor tente novamente!', 'error')
    }
  }
