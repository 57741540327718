import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { AsideContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import { Input, InputCpfCnpj } from "@/components/formFields";
import logo from "@/assets/img/logo-conecta-prime.png";
import { Sanitizer } from "@/components/utils";
import Validation from "@/components/utils/validationFields";
import * as QueryBankSlipActions from "store/queryBankSlip/actions";
import { NavLink } from "react-router-dom";

import "./src/bankSlipIdentifier.min.css";

const BankSlipIdentifier = ({ participantBankSlipHistoryRequest, success }) => {
  const [isPersonalDocValid, setPersonalDocValid] = useState();
  const [personalDoc, setPersonalDoc] = useState("");
  const [codigoSap, setCodigoSap] = useState();
  const [isSapCodeValid, setIsSapCodeValid] = useState();

  function getParticipantBankSlipHistoric() {
    if (isPersonalDocValid) {
      participantBankSlipHistoryRequest(personalDoc);
    } else if (isSapCodeValid) {
      participantBankSlipHistoryRequest(codigoSap.toString());
    }
  }

  const sendForm = () => {
    getParticipantBankSlipHistoric();
    localStorage.setItem("document", isPersonalDocValid ? personalDoc : isSapCodeValid ? codigoSap.toString() : '' );
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (personalDoc !== "") {
      setPersonalDocValid(
        Validation.cpf(personalDoc) || Validation.cnpj(personalDoc)
      );
    }

    if (codigoSap) {
      setIsSapCodeValid(codigoSap.length == 8);
    }
  }, [personalDoc, codigoSap]);

  return (
    <section>
      <AsideContainer>
        <main className="container-section">
          <div className="bx bx-identifier">
            <Link
              to="/consulta-boleto"
              className="logo-conecta-prime"
              title="Ir para home"
            >
              <img
                src={logo}
                className="logo-conecta-prime"
                alt="Conect | Prime"
              />
            </Link>
            <form>
              <SectionTitle title="Visualize o seu Boleto do Conecta Prime" />
              <InputCpfCnpj
                label="Insira o CNPJ ou CPF cadastrados na plataforma"
                error={
                  !isPersonalDocValid && isPersonalDocValid != undefined
                    ? { message: "Documento obrigatório e válido" }
                    : ""
                }
                getValue={setPersonalDoc}
                inputProps={{
                  id: "cpf-cnpj",
                  name: "cpfCnpj",
                  onChange: (e) =>
                    setPersonalDoc(Sanitizer.cpfCnpj(e.target.value)),
                  value: personalDoc,
                }}
              />

              <Input
                label="Ou consulte via Código SAP"
                inputProps={{
                  id: "codigo-sap",
                  maxLength: 8,
                  name: "codigo-sap",
                  onChange: (e) => setCodigoSap(e.target.value),
                  value: codigoSap,
                }}
              />
              <div className="bx-buttons">
                <NavLink
                  className="btn btn-main ic-go"
                  disabled={!(isPersonalDocValid || isSapCodeValid)}
                  onClick={sendForm}
                  to={
                    isPersonalDocValid || isSapCodeValid
                      ? "/boletos"
                      : "/consulta-boleto"
                  }
                >
                  Consultar
                </NavLink>
              </div>
            </form>
          </div>
        </main>
      </AsideContainer>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    participantBankSlipHistoryRequest: (document) => {
      dispatch(
        QueryBankSlipActions.participantBankSlipHistoryRequest(document)
      );
    },
  };
};

export default connect(null, mapDispatchToProps)(BankSlipIdentifier);
