import { action } from 'typesafe-actions';
import { DeliveryPayBanksTypes } from './types';

export const getDeliveryPayBanks = () => action(DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_REQUEST)

export const LoadDeliveryPayBanksSuccess = (data) =>
  action(DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_SUCCESS, { data });

export const LoadDeliveryPayBanksFailure = () =>
  action(DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_FAILURE);
