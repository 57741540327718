import React from 'react'

const IconConectaPay = ({ width, height, className, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 32">
      <g transform="translate(1 1)">
        <path d="M9502,1670h-14a3,3,0,0,1-3-3v-20a3,3,0,0,1,3-3h1v3h12v-3h1a3,3,0,0,1,3,3v20A3,3,0,0,1,9502,1670Z"
          transform="translate(-9485.001 -1640)"
          fill="none"
          stroke={fill}
          strokeWidth="2" />
        <rect
          width='55%'
          height="21%"
          transform="translate(3.999)"
          fill="none"
          stroke={fill}
          strokeWidth="2" />
        <line x2="10.587"
          transform="translate(4.912 13.5)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(4.912 19)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(9.391 19)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(13.87 19)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(4.912 24)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(9.391 24)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
        <line x2="1.587"
          transform="translate(13.87 24)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2" />
      </g>
    </svg>

  )
}
export default IconConectaPay