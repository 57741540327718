import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SectionTitle } from "@/components/sectionTitle";
import { IconEllipsisY, IconArrowToRight } from "@/components/icons";
import { ShowUnderPermission } from "@/components/showUnderPermission";
import "./src/asideMenu.min.css";

const AsideMenu = ({ title, menuItens, signed, type, ...props }) => {
  const [mobileMenuStatus, setMobileMenuStatus] = useState();
  const showMobileMenu = () => {
    setMobileMenuStatus((mobileMenuStatus) => !mobileMenuStatus);
  };
  useEffect(() => {
    setMobileMenuStatus(false);
  }, []);
  return (
    <div
      className={`bx-menu ${mobileMenuStatus ? "open-menu" : "closed-menu"}`}
    >
      <a className="btn-mobile-menu mobile" onClick={showMobileMenu}>
        {mobileMenuStatus ? (
          <IconArrowToRight
            className="ic ic-arrow-to-right"
            width="20px"
            height="20px"
            fill="#8f23d6"
          />
        ) : (
          <IconEllipsisY
            className="ic ic-ellipsisY"
            width="5px"
            heigth="15px"
            fill="#8f23d6"
          />
        )}
      </a>
      <div className="aside-menu">
        <SectionTitle title={title} />
        <nav>
          <ul>
            {!!menuItens.length &&
              menuItens.map((nav) => {
                if (nav.isAtive) {
                  return (
                    <ShowUnderPermission codePermission={nav.codigo}>
                      <li
                        key={`aside-menu-${nav.id}`}
                        className={nav.classItem}
                      >
                        <NavLink
                          to={nav.url}
                          target={nav.target}
                          className="menu-item"
                          onClick={(e) => {
                            showMobileMenu(e);
                          }}
                        >
                          {nav.icon && nav.icon}
                          {nav.label}
                        </NavLink>
                      </li>
                    </ShowUnderPermission>
                  );
                }
              })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AsideMenu;
