import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as AccountStatementActions from "store/accountStatement/actions";
import AuthHelper from "./../../../Utils/AlertHelper";
import { Extract } from "@/pages/myAccount/accountStatement";
import { CustomLoading } from "@/components/customLoading";

import "./src/accountStatement.min.css";

const AccountStatement = ({
  loadAccountStatement,
  inadimplente,
  inadimplenteVencimentos,
  readOnly,
  loading,
  matriz,
  cobrancaCentralizada,
  reativacaoAtual,
}) => {
  useEffect(() => {
    loadAccountStatement();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const onClick = (event) => {
    event.preventDefault();

    if (readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (
      reativacaoAtual &&
      AuthHelper.validateUpdateDateReentry(reativacaoAtual.dataConclusao) &&
      !reativacaoAtual.negociaDebito
    ) {
      AuthHelper.alertReentry(false);
      return;
    } else if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
      return;
    }

    window.open("https://conectaprime.webpremios.digital/extract", "_blank");
  };

  return (
    <div>
      <SectionTitle title="Extrato" />
      <div className="bx bx-accountStatement">
        <SectionTitle
          title="Extrato de Pontuação"
          customClass="extract-subtitle"
        />
        <Extract onClick={onClick} />
        <CustomLoading show={loading} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    inadimplente: state.myData.data.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    readOnly: state.auth.userInfo.readOnly,
    loading: state.accountStatement.loading,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
    reativacaoAtual: state.auth.userInfo.reativacaoAtual,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAccountStatement: () => {
      dispatch(AccountStatementActions.loadAccountStatement());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatement);
