import PropTypes from "prop-types";
import { connect } from "react-redux";

const showUnderPermission = (props) => {

  const couldShow = !!props.userPermissions.length && props.userPermissions.find((item) => {
    return item.codigo === props.codePermission;
  })?.permissao;
  return couldShow === undefined || couldShow ? props.children : null;
};

showUnderPermission.propTypes = {
  codePermission: PropTypes.string.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userPermissions: state.auth.userPermissions,
});

export default connect(mapStateToProps)(showUnderPermission);
