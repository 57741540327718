import { call, put } from 'redux-saga/effects';
import api from '../../common/api';

import { ssoSuccess, ssoFailure } from './actions';


export function* validSSOParameters(action){
    try{
        const {data} = yield call(api.post, `/SingleSignOn?v=${new Date().getTime()}`, action.payload.params);

        yield put(ssoSuccess(data));
    }catch(error){
        yield put(ssoFailure());
    }
}