import { call, put } from 'redux-saga/effects';
import api from '../../common/api';

import { simulatorSuccess, simulatorFailure } from './actions';

export function* simulatorRequest(action){
    try{
        const {data} = yield call(api.post, "/simulacao/calculo", action.payload.params);

        yield put(simulatorSuccess(data));
    }catch(error){
        yield put(simulatorFailure());
    }
}