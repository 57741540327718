import React from 'react';

const IconMenu = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30.36 21.347">
            <g transform="translate(0 -76)">
                <g transform="translate(0 85.488)">
                    <g>
                        <path d="M29.174,236H1.186a1.186,1.186,0,0,0,0,2.372H29.174a1.186,1.186,0,0,0,0-2.372Z" 
                        transform="translate(0 -236)" 
                        stroke='none'
                        fill={props.fill} />
                    </g>
                </g>
                <g transform="translate(0 76)">
                    <g>
                        <path d="M29.174,76H1.186a1.186,1.186,0,0,0,0,2.372H29.174a1.186,1.186,0,1,0,0-2.372Z" 
                        transform="translate(0 -76)" 
                        stroke='none'
                        fill={props.fill} />
                    </g>
                </g>
                <g transform="translate(0 94.975)">
                    <g>
                        <path d="M29.174,396H1.186a1.186,1.186,0,0,0,0,2.372H29.174a1.186,1.186,0,0,0,0-2.372Z" 
                        transform="translate(0 -396)" 
                        stroke='none'
                        fill={props.fill} />
                    </g>
                </g>
            </g>
        </svg>


    );
};

export default IconMenu;