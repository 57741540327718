import {ContactUsTypes} from './types';

const INITIAL_STATE = {
    subjects: [],
    loading: false,
    loadingSubject: false,
    error: false,
    contactSuccess: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ContactUsTypes.CONTACTUS_REQUEST:
        return {...state, loading: true, contactSuccess: false};
      case ContactUsTypes.CONTACTUS_SUCCESS: 
       return {
          ...state,
          loading: false,
          contactSuccess: action.payload.data
        }
      case ContactUsTypes.CONTACTUS_FAILURE: 
        return {...state, loading: false, error: true}
      case ContactUsTypes.SUBJECT_REQUEST:
        return {...state, loadingSubject: true};
      case ContactUsTypes.SUBJECT_SUCCESS: 
         return {
            ...state,
            loadingSubject: false,
            subjects: action.payload.data.data
          }
      case ContactUsTypes.SUBJECT_FAILURE: 
          return {...state, loadingSubject: false, error: true}
      case ContactUsTypes.CONTACTUS_SUCCESS_FLAG: 
          return {...state, contactSuccess: false};
      default:
        return state;
    }
};

export default reducer;
