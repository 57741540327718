import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { AsideContainer } from "@/components/containers";
import { SectionTitle } from "@/components/sectionTitle";
import { Input } from "@/components/formFields";
import logo from "@/assets/img/logo-conecta-prime.png";
import * as SimulatorActions from "../../store/simulator/actions";

import "./src/identifier.min.css";

const Identifier = ({
  simulator_state,
  simulatorStateInitial,
  simulatorRequest,
  current_state,
  loading,
}) => {
  const btn = useRef(null);
  const [codSAP, setCodSAP] = useState();
  const [cpfMask, setCpfMask] = useState();
  const [redirectSimulador, setRedirectSimulador] = useState(false);

  const ValidationRules = () => {
    return yup.object().shape({
      codSAP: yup
        .string("Insira um código do PDV válido!")
        .required("código do PDV é obrigatório"),
    });
  };

  const { register, errors, reset, getValues, triggerValidation } = useForm({
    validationSchema: ValidationRules(),
    mode: "onChange",
  });

  const sendForm = async (event) => {
    event.preventDefault();
    simulator_state("codigoPdv", codSAP);
    simulatorRequest({ ...current_state, codigoPdv: codSAP });
    setRedirectSimulador(true);
  };

  const onPressEnter = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      btn.current.click();
    }
  };

  useEffect(() => {
    if (codSAP && codSAP.length >= 11) {
      setCpfMask("99.999.999/9999-99");
    }

    setCpfMask("999.999.999-99");
  }, [codSAP]);

  if (redirectSimulador && !loading && current_state.codigoPdv) {
    return <Redirect to="/simulador" />;
  }

  return (
    <section className="wr-simulator identifier">
      <AsideContainer>
        <main className="container-section">
          <div className="bx bx-identifier">
            <Link
              to="/simulador-identificacao"
              className="logo-conecta-prime"
              title="Ir para home"
            >
              <img
                src={logo}
                className="logo-conecta-prime"
                alt="Conect | Prime"
              />
            </Link>
            <form>
              <SectionTitle title="Bem-vindo ao Conecta Prime, o programa de benefícios da BAT!" />
              <p>
                Quer descobrir o quanto você pode ganhar com o Conecta Prime?
              </p>
              <Input
                label="Insira o código do PDV"
                error={errors.codSAP}
                inputProps={{
                  ref: register,
                  name: "codSAP",
                  type: "text",
                  id: "codSAP",
                  defaultValue: codSAP,
                  onChange: (e) => setCodSAP(e.target.value),
                  onKeyPress: onPressEnter,
                }}
              />
              <div className="bx-buttons">
                <button
                  ref={btn}
                  type="button"
                  onClick={(e) => {
                    sendForm(e);
                  }}
                  disabled={!codSAP || loading}
                  className="btn btn-main ic-go"
                >
                  {loading ? "Aguarde..." : "Começar Simulador"}
                </button>
              </div>
            </form>
          </div>
        </main>
      </AsideContainer>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    loading: state.simulator.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorStateInitial: () => {
      dispatch(SimulatorActions.simulatorStateInitial());
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Identifier);
