import React from "react";
import { Route, Switch } from "react-router-dom";
import { FAQ } from '@/components/faq';
import { ContactForm } from '@/components/contactForm';
import { PrivacyPolicy } from '@/components/privacyPolicy';
import { Regulation } from '@/components/regulation';

const Routes = () => (
  <Switch>
    <Route path="/ajuda" component={FAQ} />
    <Route path="/faq" component={FAQ} />
    <Route path="/fale-conosco" component={ContactForm} />
    <Route path="/termos" component={PrivacyPolicy} />
    <Route path="/regulamento" component={Regulation} />
  </Switch>
);

export default Routes;
