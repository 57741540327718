import { ClubBenefitsTypes } from './types';

const INITIAL_STATE = {
  benefitDetail: null,
  benefitBannerSuperior: [],
  tokenClubBenefit: {},
  loading: false,
  error: false,
  errorList: [],
  success: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClubBenefitsTypes.CLUBBENEFITS_REQUEST:
      return { ...state, loading: true };
    case ClubBenefitsTypes.CLUBBENEFITS_SUCCESS:
      return {
        ...state,
        tokenClubBenefit: action.payload.data,
        success: action.payload.data.success,
        loading: false,
        error: false,
      }
    case ClubBenefitsTypes.CLUBBENEFITS_FAILURE:
      return { ...state, loading: false, error: true, success: false, errorList: action.payload.error };

    case ClubBenefitsTypes.MYBENEFITS_REQUEST:
      return { ...state, loading: true };

    case ClubBenefitsTypes.MYBENEFITS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        success: action.payload.data.success
      }

    case ClubBenefitsTypes.MYBENEFITS_FAILURE:
      return { ...state, loading: false, error: true, success: false, errorList: action.payload.error };

    case ClubBenefitsTypes.MYBENEFITSDETAILS_REQUEST:
      return { ...state, benefitDetail: null, loading: true, errorList: true };

    case ClubBenefitsTypes.MYBENEFITSDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        benefitDetail: action.payload.data,
      }

    case ClubBenefitsTypes.MYBENEFITSDETAILS_FAILURE:
      return { ...state, loading: false, error: true };

    case ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_REQUEST:
      return { ...state, benefitBannerSuperior: [], loading: true };

    case ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_SUCCESS:
      return {
        ...state,
        loading: false,
        benefitBannerSuperior: action.payload.data,
      }

    case ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_FAILURE:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};

export default reducer;
