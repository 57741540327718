import pdf from "@/assets/files/REGULAMENTO_CONECTA_PAY.pdf";
import regulamentoAssociacao from "@/assets/files/Termo_de_Responsabilidade_de_Redes_de_Varejo_Conecta_Prime.pdf";
import {
  Input,
  InputCpfCnpj,
  Select,
  SelectBank,
} from "@/components/formFields";
import { Sanitizer, ValidationBank } from "@/components/utils";
import Validation from "@/components/utils/validationFields";
import * as DeliveryPayActions from "@/store/deliveryPay/actions";
import * as DeliveryPayBanksActions from "@/store/deliveryPayBanks/actions";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as FirstAccessActions from "../../store/firstAccess/actions";
import "./src/conectaPayForm.min.css";

const ConectaPayForm = ({
  getDeliveryPayBanks,
  saveDeliveryPay,
  banks,
  loading,
  setStep,
  firstAccess,
  nextStep,
  firstAccessState,
  retailChain,
  callBackRetailChain,
  setAcceptAssociationTerms,
  acceptAssociationTerms
}) => {
  useEffect(() => {
    getDeliveryPayBanks();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const bankAccountTypes = [
    {
      id: false,
      value: "Conta corrente",
    },
    {
      id: true,
      value: "Conta poupança",
    },
  ];

  const typesAccount = [
    {
      id: "1",
      value: "Pessoa Jurídica",
    },
    {
      id: "2",
      value: "Pessoa Fisíca",
    },
  ];
  const { register, getValues, errors, triggerValidation } = useForm({
    validationSchema: ValidationBank(),
    mode: "onChange",
  });
  const [personalDoc, setPersonalDoc] = useState("");
  const [isPersonalDocValid, setPersonalDocValid] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [paymentByReceivable, setPaymentByReceivable] = useState(false);
  const [typeAccountSelected, setTypeAccountSelected] = useState(
    "Nome do favorecido"
  );
  const [typeDoc, setTypeDoc] = useState("Numero do CPF/CNPJ");
  useEffect(() => {
    if (personalDoc !== "") {
      setPersonalDocValid(
        Validation.cpf(personalDoc) || Validation.cnpj(personalDoc)
          ? false
          : true
      );
    }
  }, [personalDoc]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (personalDoc === "") {
      setPersonalDocValid(true);
    }

    const isValid = await triggerValidation();

    if (isValid && !isPersonalDocValid) {
      let body = {
        ...getValues(),
        poupanca: JSON.parse(getValues().poupanca),
        cpfCnpj: personalDoc,
      };

      if (!firstAccess) {

        //atualizar state
        firstAccessState("current_state", "conectaPay", {...body});

        //Callback nextStep
        nextStep();
      } else if (retailChain) callBackRetailChain(body);
      else {
        body = { ...body, PagamentoPorRecebiveis: paymentByReceivable };

        saveDeliveryPay(body);
      }
    }
  };

  const handleTypeAccountSelected = (event) => {
    if (event.target.value == 1) {
      setTypeAccountSelected("Razão Social ou Nome da conta");
      setTypeDoc("Numero do CPF/CNPJ");
    } else {
      setTypeAccountSelected("Nome do favorecido");
      setTypeDoc("Numero do CPF/CNPJ");
    }
  };

  return (
    <>
      <section>
        <div className="fields">
          <Select
            label="Tipo Conta"
            error={errors.poupanca}
            options={typesAccount}
            inputProps={{
              id: "typeAccount",
              name: "typeAccount",
              ref: register,
              onChange: (e) => {
                handleTypeAccountSelected(e);
              },
            }}
          />
          <SelectBank
            label="Código do seu banco"
            options={banks}
            error={errors.codigoBanco}
            inputProps={{
              id: "codigo-banco",
              name: "codigoBanco",
              ref: register,
            }}
          />
          <Input
            label="Sua agência bancária"
            error={errors.agencia}
            inputProps={{
              id: "agencia",
              name: "agencia",
              ref: register,
              type: "number",
            }}
          />
          <div className="account">
            <Input
              label="Sua conta bancária"
              error={errors.conta}
              inputProps={{
                id: "conta",
                name: "conta",
                ref: register,
                type: "number",
              }}
            />

            <Input
              label="dígito"
              error={errors.digito}
              inputProps={{
                id: "digito",
                name: "digito",
                ref: register,
                type: "number",
              }}
            />
          </div>
          <Select
            label="É conta poupança ou conta corrente"
            error={errors.poupanca}
            options={bankAccountTypes}
            inputProps={{
              id: "poupanca",
              name: "poupanca",
              ref: register,
            }}
          />

          <Input
            label={typeAccountSelected}
            error={errors.nomeOuRazao}
            inputProps={{
              id: "nome-razao",
              name: "nomeOuRazao",
              ref: register,
              required: true,
            }}
          />
          <InputCpfCnpj
            label={typeDoc}
            error={
              isPersonalDocValid
                ? { message: "Documento obrigatório e válido" }
                : ""
            }
            getValue={setPersonalDoc}
            inputProps={{
              id: "cpf-cnpj",
              name: "cpfCnpj",
              ref: register,
              onChange: (e) =>
                setPersonalDoc(Sanitizer.cpfCnpj(e.target.value)),
              value: personalDoc,
            }}
          />
        </div>

        {firstAccess && !retailChain && (
          <div className="fm-field-checkbox">
            <input
              type="checkbox"
              id="termosAceite"
              name="nTermosAceite"
              onChange={() => {
                setAcceptTerms((acceptTerms) => !acceptTerms);
              }}
            />
            <label htmlFor="termosAceite">
              Li e aceito os termos do regulamento.
            </label>
            <a
              href={pdf}
              target="_blank"
              className="purple"
            >
              Clique aqui para baixar o regulamento
            </a>
          </div>
        )}
        {retailChain && (
          <div className="fm-field-checkbox">
            <input
              type="checkbox"
              id="acceptedRegulation"
              name="acceptedRegulation"
              onChange={() => {
                setAcceptAssociationTerms
                  (acceptAssociationTerms => !acceptAssociationTerms)
              }}
            />
            <label htmlFor="acceptedRegulation">
              Informo que li o{" "}
              <a href={regulamentoAssociacao} download="download">
                Termo de Responsabilidade
              </a>{" "}
              e assumo o aceite do Termo da Campanha em nome do varejo
              associado.
            </label>
          </div>
        )}
        {firstAccess && !retailChain && (
          <div className="fm-field-checkbox recebiveis-conecta">
            <input
              type="checkbox"
              id="pagamentoPorRecebiveis"
              name="nPagamentoPorRecebiveis"
              onChange={() => {
                setPaymentByReceivable(!paymentByReceivable);
              }}
            />
            <label htmlFor="pagamentoPorRecebiveis">
              Aceito efetuar o pagamento da mensalidade com desconto nos meus
              recebíveis da Conecta Pay
            </label>
          </div>
        )}
      </section>
      <div className="fm-button">
        {retailChain ? (
          <button
            onClick={onSubmit}
            className="btn btn-main ic-go"
            disabled={!acceptAssociationTerms}
          >
            solicitar associação
          </button>
        ) : (
          <button
          onClick={onSubmit}
            className="btn btn-main ic-go"
            disabled={loading || (!acceptTerms && firstAccess)}
          >
            {!firstAccess
              ? "Próximo"
              : loading
              ? "ENVIANDO PEDIDO"
              : "CONFIRMAR PEDIDO"}
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banks: state.deliveryPayBanks.data,
  loading: state.deliveryPayBanks.loading || state.deliveryPay.loading,
  firstAccess:state.myData.data.primeiroAcessoRealizado,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...DeliveryPayBanksActions,
      ...DeliveryPayActions,
      ...FirstAccessActions,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConectaPayForm)
);
