import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Accordion } from '@/components/accordion'
import { slugfy } from "@/components/gtm";
import * as BonusActions from "@/store/bonus/actions";
import { IconBonus, IconCheck, IconChevron } from "components/icons";
import { TrackingResumeData } from "@/pages/myAccount/bonus";
import { SectionTitle } from "@/components/sectionTitle";
import './src/trackingMatriz.min.css'

const TrackingMatriz = ({ listOfRetails, matriz, loadBonus, bonusResetFlag, bonusProdutos, ...props }) => {
    const [codigoSap, setCodigoSap] = useState();
    const [toggleAccordionTarget, setToggleAccordionTarget] = useState();
    const [targetAccordion, setTargetAccordion] = useState();
    const alterarExibicaoDetalhe = (id) => {
        let detalheSelecionado = props.showDetailItem;
    
        if (detalheSelecionado == id) {
          detalheSelecionado = "";
        } else {
          detalheSelecionado = id;
        }
    
        props.setShowDetailItem(detalheSelecionado);
      };

    const insertTrackingTarget = (e, codigoSap, index) => {
        if (e.target == toggleAccordionTarget) {
            bonusResetFlag();
            setToggleAccordionTarget()
            setCodigoSap()
            setTargetAccordion(index);
            
        } else {
            bonusResetFlag();
            setToggleAccordionTarget(e.target)
            setCodigoSap(codigoSap)
            setTargetAccordion(index);
        }
    };

    return (
        <section className='tracking-matriz'>
            {props?.retailChain?.length > 0 && props?.retailChain != undefined && (
                <div className='bx-accordion'>
                    <SectionTitle title="Bonificação" />
                    {props.retailChain.map((retail, i) => {
                        return (
                            <Accordion customHeight={"none"} callback={(e) => insertTrackingTarget(e, retail.codigoSap, i)}  expandOneByOneAccordion={toggleAccordionTarget}
                                key={`list-item-${i}`} 
                                className={retail.matriz ? "matriz" : "filial" }
                                title={retail.razaoSocial}>
                                    <span className='status'>
                                        {retail.matriz ? "matriz" : "filial"}
                                    </span>
                                    <span className='user-info'>
                                        Código SAP: {retail.codigoSap} | CPF/CNPJ: {retail.documento}
                                    </span>
                                    <TrackingResumeData 
                                        keyId={i} 
                                        retailChain={props.retailChain} 
                                        trackingStates={props.trackingStates} 
                                        codigoSap={codigoSap} 
                                        matriz={matriz} targetAccordion={targetAccordion} 
                                    />
                                    {bonusProdutos?.bonusProdutos.length > 0 && (
                                        <>
                                            <SectionTitle title="Bônus em produtos" />
                                            <div className="bx bonus bonus-em-produtos">
                                            <table cellPadding="0" cellSpacing="0" border="0">
                                                <thead>
                                                <tr>
                                                    <th>
                                                    <span>Descrição</span>
                                                    </th>
                                                    <th>
                                                    <span>Data</span>
                                                    </th>
                                                    <th>
                                                    <span>Produtos</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bonusProdutos.bonusProdutos.map((item, i) => (
                                                    <tr key={`bonusProduto-${i}`}>
                                                    <td>{item.descritivo}</td>
                                                    <td>{item.dataApuracao.replace(/-/gi, '/')}</td>
                                                    <td className="produtos">{item.pacotes}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </>
                                    )}
                                    <SectionTitle title={`Tracking <span class="disclaimer">*Trackings disponíveis a partir de JAN/2021</span>`} />
                                    {props.trackingStates.loading ? "Carregando..." 
                                        : props.tracking !== undefined && props.tracking.length > 0 ? props.tracking.map((item, i) => {
                                        return (
                                            <div className="bx bonus itemTracking">
                                                <div
                                                    className="titulo"
                                                    onClick={() => alterarExibicaoDetalhe(i + 1)}
                                                >
                                                    <IconBonus
                                                    className={`ic ic-bonificacao ${
                                                        item.cor ? slugfy(item.cor) : ""
                                                    }`}
                                                    />
                                                    <div className="descricao">
                                                    <p>
                                                        <strong>{item.produto}</strong>
                                                    </p>
                                                    <p>{item.data}</p>
                                                    <p>
                                                        {item.quantidade == 1
                                                        ? `${item.quantidade} produto`
                                                        : `${item.quantidade} produtos`}{" "}
                                                    </p>
                                                    </div>
                                                    <div className={`badge ${item.cor ? slugfy(item.cor) : ""}`}>
                                                    {item.status}
                                                    </div>
                                                    <IconChevron className="ic-chevron" height="10px" width="10px" />
                                                </div>
                                                <div
                                                    className={`detalhe ${
                                                    props.showDetailItem == i + 1 ? "active" : ""
                                                    }`}
                                                >
                                                    <div className="tituloDetalhe">
                                                        <strong>TRACKING DETALHADO</strong>
                                                    </div>

                                                    {item?.detalhes?.length > 0 ? (
                                                    <ul className="listaDetalheTrack">
                                                        {item.detalhes.map((track, i) => (
                                                        <li>
                                                            <div className={"data"}>{track.data}</div>
                                                            <div className={`iconeStatusTrack ${slugfy(track.cor)}`}>
                                                                <span>
                                                                    {track.descricao != null &&
                                                                    track.descricao.length >= 1 && (
                                                                        <IconCheck className={`ic ic-check`} />
                                                                    )}
                                                                </span>
                                                                <div
                                                                    className={`ic-next ${
                                                                    i + 1 == item?.detalhes?.length ? "" : "active"
                                                                    }`}
                                                                ></div>
                                                            </div>
                                                            <div>
                                                                <strong>{track.status}</strong>{" "}
                                                                {track.descricao ? ` - ${track.descricao}` : ""}
                                                            </div>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                ) : (
                                                ""
                                                )}
                                                </div>
                                            </div>
                                        )}) : props.trackingStates.success ? <p>Não existem Tracks para a referência informada.</p> 
                                        : <p>Faça a busca de tracking nos filtros acima.</p>
                                    }
                            </Accordion>
                        )
                    })}
                </div>
            )}
        </section>
    )
}

const mapStateToProps = state => {
    return {
        readOnly: state.auth.userInfo.readOnly,
        tracking: state.bonus.data.tracking,
        trackingStates: state.bonus,
        bonusProdutos: state.bonus.data.bonusProdutos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadBonus: (data) => {
            dispatch(BonusActions.loadBonus(data));
        },
        bonusResetFlag: () => {
            dispatch(BonusActions.bonusResetFlag());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingMatriz)