import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import { PaymentHistory } from "@/pages/myAccount/payment";
import "./src/payment.scss";

const PaymentPage = ({ cobrancaCentralizada, matriz, userPermissions }) => {
  useEffect(() => {
    if (!!userPermissions.length) {
      let perm = userPermissions.find((item) => {
        return item.codigo === "MEUS_PAGAMENTOS";
      })?.permissao;

      return perm ? undefined : window.location.replace("/");
    }
  }, []);

  return (
    <section className="payment-history">
      <SectionTitle title="Pagamentos" />

      {!matriz && cobrancaCentralizada ? (
        "Seu pagamento está associado a sua matriz, qualquer dúvida favor entrar em contato com a mesma."
      ) : (
        <PaymentHistory />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
    matriz: state.myData.data.matriz,
    userPermissions: state.auth.userPermissions,
  };
};

export default connect(mapStateToProps)(PaymentPage);
