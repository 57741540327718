import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SectionTitle } from "@/components/sectionTitle";
import { ConectaPayForm } from "@/components/conectaPayForm";
import { MyData } from "@/components/myData";
import ConectaPayMember from "@/pages/myAccount/conectaPay/conectaPayMember";
import BannerConectaPay from "@/assets/img/banners/banner-conecta-pay.png";

import "./src/conectaPay.min.css";

const ConectaPay = ({
  isSaveSuccess,
  afiliadoConectaPay,
  conectaPayAtiva,
}) => {
  const [btnSubscribeConectaPay, setBtnSubscribeConectaPay] = useState(false)

  return (
    <Fragment>
      <SectionTitle title="Conecta Pay" />
      {afiliadoConectaPay && <ConectaPayMember />}
      {isSaveSuccess && (
        <img
          className="banner-conect-pay"
          src={BannerConectaPay}
          alt="imagem de atualização bancária - atualizado com sucesso"
        />
      )}
      {!afiliadoConectaPay && conectaPayAtiva && !isSaveSuccess && !btnSubscribeConectaPay ? (
        <ConectaPayMember setBtnSubscribeConectaPay={setBtnSubscribeConectaPay} />
      ) : !afiliadoConectaPay && conectaPayAtiva && !isSaveSuccess && btnSubscribeConectaPay ? (
        <div className="bx block">
          <section className="block-section">
            <SectionTitle
              title="
            Já sabemos um <br />
            pouco sobre você"
            />
            <MyData />
          </section>
          <section className="block-section">
            <SectionTitle
              title="
            Mas para solicitar a Conecta Pay </br> direto no seu estabelecimento, precisamos que você preencha as informações abaixo."
            />
            <form>
              <ConectaPayForm />
            </form>
          </section>
        </div>
      ) : !conectaPayAtiva && !afiliadoConectaPay && (
        <div className="bx block">
          <h3>Este serviço está temporariamente indisponível</h3>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  isSaveSuccess: state.deliveryPay.isSaveSuccess,
  afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
  conectaPayAtiva: state.myData.data.conectaPayAtiva,
});

export default withRouter(connect(mapStateToProps)(ConectaPay));
