export const ClubBenefitsTypes =  {
    CLUBBENEFITS_REQUEST : '@clubBenefits/CLUBBENEFITS_REQUEST',
    CLUBBENEFITS_SUCCESS : '@clubBenefits/CLUBBENEFITS_SUCCESS',
    CLUBBENEFITS_FAILURE : '@clubBenefits/CLUBBENEFITS_FAILURE',
    MYBENEFITS_REQUEST : '@MyBenefitsTypes/MYBENEFITS_REQUEST',
    MYBENEFITS_SUCCESS : '@MyBenefitsTypes/MYBENEFITS_SUCCESS',
    MYBENEFITS_FAILURE : '@MyBenefitsTypes/MYBENEFITS_FAILURE',
    MYBENEFITSDETAILS_REQUEST : '@MyBenefitsDetailsTypes/MYBENEFITSDETAILS_REQUEST',
    MYBENEFITSDETAILS_SUCCESS : '@MyBenefitsDetailsTypes/MYBENEFITSDETAILS_SUCCESS',
    MYBENEFITSDETAILS_FAILURE : '@MyBenefitsDetailsTypes/MYBENEFITSDETAILS_FAILURE',
    MYBENEFITSBANNERSUPERIOR_REQUEST : '@MyBenefitsDetailsTypes/MYBENEFITSBANNERSUPERIOR_REQUEST',
    MYBENEFITSBANNERSUPERIOR_SUCCESS : '@MyBenefitsDetailsTypes/MYBENEFITSBANNERSUPERIOR_SUCCESS',
    MYBENEFITSBANNERSUPERIOR_FAILURE : '@MyBenefitsDetailsTypes/MYBENEFITSBANNERSUPERIOR_FAILURE',
}