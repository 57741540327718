import React, { cloneElement, useState } from 'react'
import './src/tabsBenefit.min.css'

const TabsBenefit = (props) => {
    const [contentIndex, setContentIndex] = useState({
        currentTab: props.defaultActiveIndex || 0,
        nextTab: 1
    });

    const { children } = props;

    const handleTabClick = (tabIndex) => {
        if (tabIndex !== contentIndex.currentTab) {
            setContentIndex({
                currentTab: tabIndex,
                nextTab: tabIndex < children.length - 1 ? tabIndex + 1 : 0
            });
        }
    }

    const cloneTabElement = (Tab, index) => {
        return (
            cloneElement(Tab, {
                key: `customTab-${index}`,
                onClick: (e) => {handleTabClick(index)},
                tabIndex: index,
                isActive: index === contentIndex.currentTab,
            })
        );
    }

    const renderChildrenTabs = () => {

        if (!Array.isArray(children)) {
            return cloneTabElement(children);
        }
        return children.map(cloneTabElement);
    }

    const renderActiveTabContent = () => {
        if (children[contentIndex.currentTab]) {
            return children[contentIndex.currentTab].props.children
        }
        return children.props.children;
    }
    const { className } = props;

    return (
        <>
            <section className={`tabs-benefit ${className}`}>
                {
                    props.benefitDetail && window.innerWidth <= 500 && (
                        <img className='logo-mobile' src={props.benefitDetail.imagemLogo} alt=""/>
                    )
                }
                <ul className={'tabs-list'}>
                    {props.benefitDetail && window.innerWidth > 500 && (
                        <li className='tabs__tab logo-desktop'><img src={props.benefitDetail.imagemLogo} alt=""/></li>
                    )}
                    {renderChildrenTabs()}
                </ul>
                <hr />
                <div className={'tab-content'}>
                    {renderActiveTabContent()}
                </div>
            </section>
        </>
    );
};
export default TabsBenefit;