import { CampaignCard } from "@/components/cards";
import { AsideContainer } from "@/components/containers";
import { Tab, Tabs } from "@/components/customTabs";
import { Checkbox } from "@/components/formFields";
import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as CampaignsActions from "../../store/campaigns/actions";
import "./src/campaigns-details.min.css";

const CampaignDetails = ({
  campaign,
  loading,
  readOnly,
  acceptCampaign,
  detailsCampaign,
}) => {
  const { id } = useParams();
  const [acceptRegulation, setAcceptRegulation] = useState(false);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    detailsCampaign(id);
  }, []);

  const postAccept = () => {
    acceptCampaign(id);
  };

  const renderAccept = () => {
    return (
      <>
        {campaign &&
          campaign.vigente &&
          !campaign.participando &&
          campaign.regulamentoAceiteObrigatorio && (
            <>
              <Checkbox
                label="Declaro li e aceito o regulamento da campanha."
                isChecked={campaign.participando}
                inputProps={{
                  name: "accept",
                  type: "checkbox",
                  id: "accept",
                  disabled: campaign.participando || readOnly || loading,
                  onChange: (e) =>
                    setAcceptRegulation(
                      (acceptRegulation) => !acceptRegulation
                    ),
                }}
              />
              <div className="bx-button">
                <button
                  disabled={
                    (!acceptRegulation && !campaign.participando) ||
                    readOnly ||
                    loading ||
                    campaign.participando
                  }
                  onClick={postAccept}
                  className="btn btn-main btn-accept ic-go"
                >
                  comece a participar!
                </button>
              </div>
            </>
          )}
      </>
    );
  };

  return (
    <section className="pg pg-campaign-datails">
      <AsideContainer>
        {campaign && (
          <>
            <aside className="container-aside">
              <SectionTitle title={campaign.nome} />
              <CampaignCard
                cardType="campaign"
                accept={
                  campaign.participando ||
                  !campaign.regulamentoAceiteObrigatorio
                }
                status={campaign.vigente}
                startDate={campaign.vigenciaDeSrt}
                endDate={campaign.vigenciaAteSrt}
                image={campaign.bannerUrl}
                title={campaign.nome}
                subtitle={campaign.subtitulo}
                description={campaign.nome}
              />
            </aside>
            <main className="container-section">
              <SectionTitle title="Detalhes da Campanha" />
              <div className="bx bx-datails">
                <Tabs>
                  <Tab
                    label={"O que eu ganho?"}
                    className={"tab-custom-class"}
                    nextButton={true}
                  >
                    <div className="tab-Communication">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: campaign.comunicacao,
                        }}
                      ></div>
                    </div>
                  </Tab>
                  <Tab
                    label={"Até quando?"}
                    className={"tab-custom-class"}
                    nextButton={true}
                  >
                    <div className="tab-duration">
                      <div>
                        <h4>
                          De {campaign.vigenciaDeStr} a{" "}
                          {campaign.vigenciaAteSrt}
                        </h4>
                        {campaign.vigenciaDias > 0 ? (
                          <p>
                            Aproveite! Você ainda tem{" "}
                            <strong>{campaign.vigenciaDias}</strong> dias para
                            participar.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    label={"Como participar?"}
                    className={"tab-custom-class"}
                  >
                    <div className="tab-regulamento campanha">
                      {campaign.regulamentoAceiteObrigatorio && (
                        <h4>Leia e aceite o regulamento</h4>
                      )}
                      <div
                        className="container-scroll"
                        dangerouslySetInnerHTML={{
                          __html: campaign.regulamento,
                        }}
                      ></div>
                      {renderAccept()}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </main>
          </>
        )}
      </AsideContainer>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptCampaign: (id) => {
      dispatch(CampaignsActions.acceptCampaign(id));
    },
    detailsCampaign: (id) => {
      dispatch(CampaignsActions.detailsCampaign(id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    readOnly: state.auth.userInfo.readOnly,
    campaign: state.campaigns.campaign,
    loading: state.campaigns.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
