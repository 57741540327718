import { default as Carousel } from "react-slick";
import { DefaultContainer } from "@/components/containers";
import { CustomLoading } from "@/components/customLoading";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as MyBenefitsActions from "../../store/clubBenefits/actions";
import { BenefitsShowcase } from "@/components/showcase";
import AuthHelper from "./../../Utils/AlertHelper";

import "./src/myBenefits.min.css";
import './src/banner-benefits-home.min.css';

const MyBenefits = ({ loading, myBenefitsRequest, myBenefitsBannerSuperiorRequest , myBenefits, inadimplente, inadimplenteVencimentos, matriz, cobrancaCentralizada }) => {

  const [bannersCarousel, setBannersCarousel] = useState([]);
  
  useEffect(() => {
    const redirectToHome = true

    if (inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos(redirectToHome);
      return;
    } else if (inadimplente) {
      AuthHelper.alertInadimplente(redirectToHome, matriz, cobrancaCentralizada);
      return;
    }
  },[inadimplente, inadimplenteVencimentos])

  useEffect(() => {
    myBenefitsRequest();
  }, []);

  useEffect(() => {
    myBenefitsBannerSuperiorRequest();
  }, []);

  useEffect(() => {
    setBannersCarousel(myBenefits.benefitBannerSuperior.data);
  }, [myBenefits.benefitBannerSuperior]);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="pg pg-benefits">
      <Carousel {...settings}>
          {bannersCarousel && bannersCarousel.map(banner => {
                  return (<div key={banner.id}>
                      {banner.clicavel && banner.url && banner.url !== '#' ?
                          <a href={banner.url} target={banner.janela ? "_blank" : "_self"} >
                              <img src={window.innerWidth > 500 ? banner.imagemUri : banner.imagemMobileUri} alt={banner.url} />
                          </a>
                          :
                          <img src={window.innerWidth > 500 ? banner.imagemUri : banner.imagemMobileUri} alt={banner.url} />
                      }
                  </div>)
              }
          )}
      </Carousel>
      <DefaultContainer>
        <BenefitsShowcase myBenefits={myBenefits} />
      </DefaultContainer>
      <CustomLoading show={loading} />
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    myBenefitsRequest: (data) => {
      dispatch(MyBenefitsActions.myBenefitsRequest(data));
    },
    myBenefitsBannerSuperiorRequest: (data) => {
      dispatch(MyBenefitsActions.myBenefitsBannerSuperiorRequest(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    myBenefits: state.clubBenefits,
    loading: state.clubBenefits.loading,
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBenefits);
