export const DeliveryPayTypes = {
  DELIVERY_PAY_REQUEST: '@deliveryPay/DELIVERY_PAY_REQUEST',
  DELIVERY_PAY_SUCCESS: '@deliveryPay/DELIVERY_PAY_SUCCESS',
  DELIVERY_PAY_FAILURE: '@deliveryPay/DELIVERY_PAY_FAILURE',
  DELIVERY_PAY_ACCEPT_TERM_REQUEST: '@deliveryPay/DELIVERY_PAY_ACCEPT_TERM_REQUEST',
  DELIVERY_PAY_ACCEPT_TERM_SUCCESS: '@deliveryPay/DELIVERY_PAY_ACCEPT_TERM_SUCCESS',
  DELIVERY_PAY_ACCEPT_TERM_FAILURE: '@deliveryPay/DELIVERY_PAY_ACCEPT_TERM_FAILURE',
  GET_CONECTA_PAY_LINK_REQUEST : "@deliveryPay/GET_CONECTA_PAY_LINK_REQUEST",
  GET_CONECTA_PAY_LINK_SUCCESS : "@deliveryPay/GET_CONECTA_PAY_LINK_SUCCESS",
  GET_CONECTA_PAY_LINK_FAILURE : "@deliveryPay/GET_CONECTA_PAY_LINK_FAILURE"
}
