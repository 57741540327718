import { Input, InputTelAndCellphone, RadioButton } from "@/components/formFields";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IconArrowToLeft from "../../icons/iconArrowToLeft";

const DadosDeContato = ({
  cellValidation,
  cellphone,
  setStep,
  errors,
  firstAccessData,
  formData,
  nextStep,
  isValidCell,
  loading,
  readOnly,
  register,
  setCellphone,
  step,
  previousStep,
  stepsProps,
  preferredContact,
  setPreferredContact,
  setCellPreferred,
  isValidCellPreferred,
  cellPreferredValidation,
}) => {
  return (
    <div id="contactInfo">
      <div style={{ marginBottom: "40px" }}>
        <button onClick={(e) => {previousStep(e)}} className="btn btn-link">
          <IconArrowToLeft
            fill="#8f23d6"
            className="ic ic-arrow-to-left ic-left"
          />
          Voltar
        </button>
      </div>
      <InputTelAndCellphone
        label="Celular"
        error={isValidCell != true ? { message: "Celular é obrigatório" } : false}
        inputProps={{
          ref: register,
          id: "cellphone",
          name: "cellphone",
          disabled: readOnly,
          onChange: (e) => cellValidation(e.target.value),
          onBlur: (e) => {
            setCellphone(e.target.value);
          },
        }}
      />
      <Input
        label="E-mail"
        error={errors.email}
        inputProps={{
          ref: register,
          name: "email",
          type: "email",
          id: "email",
          disabled: readOnly,
          defaultValue: formData.email,
        }}
      />
      <Input
        label="Confirmar E-mail"
        error={errors.confirmEmail}
        inputProps={{
          ref: register,
          name: "confirmEmail",
          type: "email",
          id: "confirmEmail",
          disabled: readOnly,
        }}
      />

      <div className="box">
        <span>Contato Preferencial </span>
        <div>
          <RadioButton
            label="Celular"
            checked={preferredContact === "1"}
            inputProps={{
              id: "contatoPreferencialCelular",
              name: "contatoPreferencial",
              ref: register,
              value: "1",
              disabled: readOnly,
              onChange: e => {
                setPreferredContact(e.target.value)
              }
            }}
          />
          <RadioButton
            label="E-mail"
            checked={preferredContact === "2"}
            inputProps={{
              id: "contatoPreferencialEmail",
              name: "contatoPreferencial",
              ref: register,
              value: "2",
              disabled: readOnly,
              onChange: e => {
                setPreferredContact(e.target.value)
                setCellPreferred('')
              }
            }}
          />
        </div>

        <div>
          {preferredContact == "1" &&
            <InputTelAndCellphone
              label=""
              error={isValidCellPreferred != true ? { message: "Celular preferencial é obrigatório" } : false}
              inputProps={{
                ref: register,
                id: "cellPreferred",
                name: "cellPreferred",
                disabled: readOnly,
                onChange: (e) => cellPreferredValidation(e.target.value),
                onBlur: (e) => {
                  setCellPreferred(e.target.value);
                },
              }}
            />
          } 
          
          {preferredContact == "2" &&
            <Input
              label=""
              error={errors.emailPreferred}
              inputProps={{
                ref: register,
                name: "emailPreferred",
                type: "email",
                id: "emailPreferred",
                disabled: readOnly,
              }}
            />
          } 
        </div>
      </div>

      <div className="fm-button">
        <button
          type="button"
          className="btn btn-main ic-go"
          onClick={() => {
            nextStep();
          }}
          disabled={loading || readOnly}
        >
          Próximo
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firstAccessData: state.firstAccess.data,
    success: state.firstAccess.updateData.success,
    error: state.firstAccess.updateData.error,
    message_error: state.firstAccess.updateData.message_error,
    loading: state.firstAccess.loading,
    conectaPay: state.firstAccess.current_state.conectaPay,
    payments: state.payment.data,
    paymentTypeOld: state.auth.userInfo.tipoPagamento,
    readOnly: state.auth.userInfo.readOnly,
  };
};
export default connect(mapStateToProps)(DadosDeContato);
