import { DeliverySystemTypes } from './types';

const INITIAL_STATE = {
    projectList: {
        data: [],
        loading: false,
        success: false,
        error: false,
    },
    categoriesProductsList: {
        data: [],
        loading: false,
        success: false,
        error: false,
    },
    putProductOnMenu: {
        loading: false,
        success: false,
        error: false,
    },
    createNewProduct: {
        loading: false,
        success: false,
        error: false,
    },
    createNewCategory: {
        loading: false,
        success: false,
        error: false,
    },
    updateCategory: {
        loading: false,
        success: false,
        error: false,
    },
    removeProductFromMenu: {
        loading: false,
        success: false,
        error: false,
    },
    removeCategory: {
        loading: false,
        success: false,
        error: false,
    },
    updateProduct: {
        loading: false,
        success: false,
        error: false,
    },
    removeProduct: {
        loading: false,
        success: false,
        error: false,
    },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DeliverySystemTypes.LOAD_PROJECTS_LIST_REQUEST:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    loading: true,
                }
            };
        case DeliverySystemTypes.LOAD_PROJECTS_LIST_SUCCESS:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    success: true,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                }
            };
        case DeliverySystemTypes.LOAD_PROJECTS_LIST_FAILURE:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_REQUEST:
            return {
                ...state,
                categoriesProductsList: {
                    ...state.categoriesProductsList,
                    loading: true,
                }
            };
        case DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_SUCCESS:
            return {
                ...state,
                categoriesProductsList: {
                    ...state.categoriesProductsList,
                    success: true,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                }
            };
        case DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_FAILURE:
            return {
                ...state,
                categoriesProductsList: {
                    ...state.categoriesProductsList,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.LOAD_CATEGORIES_AND_PRODUCTS_RESET_FLAG:
            return {
                ...state,
                categoriesProductsList: {
                    ...state.categoriesProductsList,
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PROJECT_REQUEST:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    loading: true,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PROJECT_SUCCESS:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    response: action.payload.data.data,
                    success: action.payload.data.data.success,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PROJECT_FAILURE:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PROJECT_RESET_FLAG:
            return {
                ...state,
                newProject: {
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                updateProject: {
                    ...state.updateProject,
                    loading: true,
                }
            };
        case DeliverySystemTypes.UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                updateProject: {
                    ...state.updateProject,
                    response: action.payload.data.data,
                    success: action.payload.data.success,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_PROJECT_FAILURE:
            return {
                ...state,
                updateProject: {
                    ...state.updateProject,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.UPDATE_PROJECT_RESET_FLAG:
            return {
                ...state,
                updateProject: {
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PRODUCT_REQUEST:
            return {
                ...state,
                createNewProduct: {
                    ...state.createNewProduct,
                    loading: true,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PRODUCT_SUCCESS:
            return {
                ...state,
                createNewProduct: {
                    ...state.createNewProduct,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PRODUCT_FAILURE:
            return {
                ...state,
                createNewProduct: {
                    ...state.createNewProduct,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_PRODUCT_RESET_FLAG:
            return {
                ...state,
                createNewProduct: {
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProduct: {
                    ...state.updateProduct,
                    loading: true,
                }
            };
        case DeliverySystemTypes.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                updateProduct: {
                    ...state.updateProduct,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                updateProduct: {
                    ...state.updateProduct,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.UPDATE_PRODUCT_RESET_FLAG:
            return {
                ...state,
                updateProduct: {
                    ...state.updateProduct,
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_REQUEST:
            return {
                ...state,
                removeProduct: {
                    ...state.removeProduct,
                    loading: true,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_SUCCESS:
            return {
                ...state,
                removeProduct: {
                    ...state.removeProduct,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_FAILURE:
            return {
                ...state,
                removeProduct: {
                    ...state.removeProduct,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_RESET_FLAG:
            return {
                ...state,
                removeProduct: {
                    ...state.removeProduct,
                    success: false,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.PUT_PRODUCT_ON_MENU_REQUEST:
            return {
                ...state,
                putProductOnMenu: {
                    ...state.putProductOnMenu,
                    loading: true,
                }
            };
        case DeliverySystemTypes.PUT_PRODUCT_ON_MENU_SUCCESS:
            return {
                ...state,
                putProductOnMenu: {
                    ...state.putProductOnMenu,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.PUT_PRODUCT_ON_MENU_FAILURE:
            return {
                ...state,
                putProductOnMenu: {
                    ...state.putProductOnMenu,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.PUT_PRODUCT_ON_MENU_RESET_FLAG:
            return {
                ...state,
                putProductOnMenu: {
                    ...state.putProductOnMenu,
                    success: false,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_CATEGORY_REQUEST:
            return {
                ...state,
                createNewCategory: {
                    ...state.createNewCategory,
                    loading: true,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_CATEGORY_SUCCESS:
            return {
                ...state,
                createNewCategory: {
                    ...state.createNewCategory,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_CATEGORY_FAILURE:
            return {
                ...state,
                createNewCategory: {
                    ...state.createNewCategory,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.CREATE_NEW_CATEGORY_RESET_FLAG:
            return {
                ...state,
                createNewCategory: {
                    ...state.createNewCategory,
                    success: false,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                updateCategory: {
                    ...state.updateCategory,
                    loading: true,
                }
            };
        case DeliverySystemTypes.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                updateCategory: {
                    ...state.updateCategory,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                updateCategory: {
                    ...state.updateCategory,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.UPDATE_CATEGORY_RESET_FLAG:
            return {
                ...state,
                updateCategory: {
                    ...state.updateCategory,
                    success: false,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_REQUEST:
            return {
                ...state,
                removeProductFromMenu: {
                    ...state.removeProductFromMenu,
                    loading: true,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_SUCCESS:
            return {
                ...state,
                removeProductFromMenu: {
                    ...state.removeProductFromMenu,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_FAILURE:
            return {
                ...state,
                removeProductFromMenu: {
                    ...state.removeProductFromMenu,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.REMOVE_PRODUCT_FROM_MENU_RESET_FLAG:
            return {
                ...state,
                removeProductFromMenu: {
                    ...state.removeProductFromMenu,
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_CATEGORY_REQUEST:
            return {
                ...state,
                removeCategory: {
                    ...state.removeCategory,
                    loading: true,
                }
            };
        case DeliverySystemTypes.REMOVE_CATEGORY_SUCCESS:
            return {
                ...state,
                removeCategory: {
                    ...state.removeCategory,
                    success: true,
                    loading: false,
                    error: false,
                }
            };
        case DeliverySystemTypes.REMOVE_CATEGORY_FAILURE:
            return {
                ...state,
                removeCategory: {
                    ...state.removeCategory,
                    loading: false,
                    success: false,
                    error: true,
                    errorMessage: action.payload.data,
                }
            };
        case DeliverySystemTypes.REMOVE_CATEGORY_RESET_FLAG:
            return {
                ...state,
                removeCategory: {
                    ...state.removeCategory,
                    loading: false,
                    success: false,
                    error: false,
                }
            };
        default:
            return state;
    }
};

export default reducer;

