import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Accordion } from '@/components/accordion'
import { loadBilletIC } from '@/store/BilletIC/sagas'

import "./src/billetIC.min.css";

const BilletIC = ({ matriz }) => {

    const [boletoVarejo, setBoletoVarejo] = useState([]);

    useEffect(() => {
        load();
    }, [])

    async function load() {
        let data = await loadBilletIC();
        setBoletoVarejo(data)
    }

    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0].substring(2), // get only two digits
            month = datePart[1], day = datePart[2];

        return day + '/' + month + '/' + year;
    }


    return (
        <section className='payment-history'>

            {boletoVarejo.length > 0
                ? (
                    <div className='bank-slip-container'>
                        <div className='bx-accordion'>
                            {boletoVarejo.map((payment, i) => {

                                return (
                                    <Accordion key={`list-item-${i}`} className={`payment-item Disponivel`} title={`${payment.mesRef}`}>
                                        <span className='status'>
                                            {payment.ativo ? 'Disponivel' : 'Indisponivel'}
                                        </span>
                                        <div className='payment-details'>
                                            <p><span>Boleto Bancario</span></p>
                                            <p className='expiration'>Vence em: <span>{formatDate(payment.dataVencimento)}</span></p>
                                            <div className='fm-button'>
                                                <a type="button"
                                                    className='btn btn-main ic-go'
                                                    href={payment.linkBoletoIC}
                                                    target="_blank"
                                                >
                                                    Visualizar boleto
                                        </a>
                                            </div>
                                        </div>
                                    </Accordion>
                                )
                            })}
                        </div>
                    </div>) : (
                    <div className='bank-slip-container'>
                        <div className='bx'>
                            <h3>Não há boletos disponíveis para visualização.</h3>
                        </div>
                    </div>
                )}

        </section>
    )
}

const mapStateToProps = state => {
    return {
        matriz: state.myData.data.matriz,
    }
}


export default connect(mapStateToProps)(BilletIC)