import React from 'react'
import { Link } from "react-router-dom"
import { IconCheck } from '@/components/icons'

import './src/showcaseCard.min.css'

// CARDS DAS CAPANHAS

const CampaignCard = (props) => {
  const campaignStatus = props.status !== undefined && props.status === false ? 'campaign-inactive' : 'campaign-active'

  const campaignaccept = () => {
    if (props.status === true) {
      if (props.accept === true) {
        return (<label className='associate'><IconCheck width='10px' height='10px' fill='#111111' className='ic-check' />Disponível</label>)
      } else if (props.accept === false) {
        return (<label className='not-associate'>Leia o Regulamento e participe</label>)
      }
    } else {
      return (<label className='not-associate'>encerrada em {props.endDate}</label>)
    }
  }

  return (
    <div>
      <div className={`showcase-card campaign-card ${campaignStatus}`}>
        <img className='card-banner' src={props.image} />

        <div className='card-label'>
          {campaignaccept()}

          <h4 className='description'>{props.description}</h4>
          <strong className='benefits'>{props.subtitle}</strong>

          {props.segment && (
            <strong className='segment'>{props.segment}</strong>
          )}

          {props.campaignId && (
            <Link className="btn ic-go" to={`campanha/${props.campaignId}/${props.url}`} target='_self'>
              veja mais detalhes
        </Link>)}
        </div>
      </div>
    </div>
  )
}

export default CampaignCard
