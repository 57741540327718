import React from 'react';
import './src/containers.min.css'

const AsideContainer = ({ children }) => {
    return (
        <>
            <div className='ct asideContainer'>
                {children}
            </div>
        </>
    )
}

export default AsideContainer;