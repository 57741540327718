import React, { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { Accordion } from "@/deliverySystem/components/accordion";
import { Products } from "@/deliverySystem/components/products";
import * as DeliverySystemActions from "store/deliverySystem/actions";
import Swal from "sweetalert2";
import "./src/categoryListing.min.css";

const CategoryListing = ({
  categoriesProductsList,
  categoriesProductsListState,
  putProductOnMenuState,
  putProductOnMenuResetFlag,
  removeProductFromMenuResetFlag,
  loadCategoriesAndProductsRequest,
  updateProductRequest,
  removeProductRequest,
  setUpdateProductList,
  updateProductList,
  removeProductFromMenuState,
  updateProductResetFlag,
  updateProductState,
  removeProductResetFlag,
  removeProductState,
  createNewProductState,
  createNewProductResetFlag,
  createNewCategoryRequest,
  createNewCategoryState,
  createNewCategoryResetFlag,
  updateCategoryRequest,
  updateCategoryState,
  updateCategoryResetFlag,
  removeCategoryRequest,
  removeCategoryState,
  removeCategoryResetFlag,
}) => {
  const toast = useRef(null);
  const [modalState, setModalState] = useState(false);
  const [editModalTargetProps, setEditModalTargetProps] = useState({});
  const propsModal = {
    setModalState,
    modalState,
    setEditModalTargetProps,
    editModalTargetProps,
  };

  // Reload GET after Create new product
  useEffect(() => {
    if (!createNewProductState.loading) {
        if (createNewProductState.success) {
            toast.current.show({ severity: 'success', summary: 'Novo produto criado com sucesso!', detail: 'Agora você pode adiciona-lo ao seu cardápio.' });
            loadCategoriesAndProductsRequest(categoriesProductsList.id);
            createNewProductResetFlag(); // reset status state flags
        } else if (createNewProductState.error) {
            toast.current.show({ severity: 'error', summary: 'Algo deu errado...', detail: 'Não conseguimos adicionar esse produto. Por favor, tente novamente!' });
            createNewProductResetFlag();
        }
    }
}, [createNewProductState.success, createNewProductState.error])

  // Reload GET after Add product on menu
  useEffect(() => {
    if (!putProductOnMenuState.loading) {
      if (putProductOnMenuState.success) {
        toast.current.show({
          severity: "success",
          summary: "Produto adicionado com sucesso!",
          detail: "O produto foi adicionado ao seu cardápio!",
        });
        setUpdateProductList({ status: 1 });
        putProductOnMenuResetFlag();
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
      } else if (putProductOnMenuState.error) {
        toast.current.show({
          severity: "error",
          summary: "Algo deu errado...",
          detail:
            "Não conseguimos adicionar esse produto ao cardápio. Por favor, tente novamente!",
        });
        putProductOnMenuResetFlag();
      }
    }
  }, [putProductOnMenuState.success, putProductOnMenuState.error]);

  // Reload GET after Remove Product from Menu
  useEffect(() => {
    if (!removeProductFromMenuState.loading) {
      if (removeProductFromMenuState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Produto removido do seu cardápio.",
        });
        setUpdateProductList({ status: 1 });
        removeProductFromMenuResetFlag();
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
      } else if (removeProductFromMenuState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail:
            "Algo deu errado ao remover o produto ao seu cardápio, tente novamente mais tarde.",
        });
        removeProductFromMenuResetFlag();
      }
    }
  }, [removeProductFromMenuState.success, removeProductFromMenuState.error]);

  // Reload GET after Edit Product
  useEffect(() => {
    if (!updateProductState.loading) {
      if (updateProductState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "O produto foi editado.",
        });
        setUpdateProductList({ status: 1 });
        updateProductResetFlag();
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
      } else if (updateProductState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail: "Algo deu errado ao editar o produto, tente novamente.",
        });
        updateProductResetFlag();
      }
    }
  }, [updateProductState.success, updateProductState.error]);

  // Reload GET after Delete Product
  useEffect(() => {
    if (!removeProductState.loading) {
      if (removeProductState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "O produto foi deletado.",
        });
        setUpdateProductList({ status: 1 });
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
        removeProductResetFlag();
      } else if (removeProductState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail: "Algo deu errado ao deletar o produto, tente novamente.",
        });
        removeProductResetFlag();
      }
    }
  }, [removeProductState.success, removeProductState.error]);

  // Reload GET after Add category
  useEffect(() => {
    if (!createNewCategoryState.loading) {
      if (createNewCategoryState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "A categoria foi adicionada.",
        });
        setUpdateProductList({ status: 1 });
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
        createNewCategoryResetFlag();
      } else if (createNewCategoryState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail: "Não conseguimos adicionar essa categoria, tente novamente.",
        });
        createNewCategoryResetFlag();
      }
    }
  }, [createNewCategoryState.success, createNewCategoryState.error]);

  // Reload GET after Edit category
  useEffect(() => {
    if (!updateCategoryState.loading) {
      if (updateCategoryState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "A categoria foi editada.",
        });
        setUpdateProductList({ status: 1 });
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
        updateCategoryResetFlag();
      } else if (updateCategoryState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail: "Não conseguimos editar essa categoria, tente novamente.",
        });
        updateCategoryResetFlag();
      }
    }
  }, [updateCategoryState.success, updateCategoryState.error]);

  // Reload GET after Delete category
  useEffect(() => {
    if (!removeCategoryState.loading) {
      if (removeCategoryState.success) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "A categoria foi excluída.",
        });
        setUpdateProductList({ status: 1 });
        loadCategoriesAndProductsRequest(categoriesProductsList.id);
        removeCategoryResetFlag();
      } else if (removeCategoryState.error) {
        toast.current.show({
          severity: "error",
          summary: "Ops!",
          detail: "Algo deu errado ao deletar a categoria, tente novamente.",
        });
        removeCategoryResetFlag();
      }
    }
  }, [removeCategoryState.success, removeCategoryState.error]);

  useEffect(() => {
    if (propsModal.modalState) {
      // Edit Product
      if (propsModal.editModalTargetProps.mode == "edit") {
        Swal.fire({
          title: "Digite os dados que deseja editar.",
          confirmButtonText: "Confirmar",
          showCloseButton: true,
          customClass: {
            title: "title",
            confirmButton: 'btn-confirm',
          },
          html:
          `<div class="input-container">
            <label for="edit-name">Nome</label>
            <input id="edit-name" class="swal2-input input-data-form" value="${propsModal.editModalTargetProps.nome}" autofocus placeholder="Nome">
          </div>` +
          `${propsModal.editModalTargetProps.incluidoCardapio ? (
            `<div class="input-container">
              <label for="edit-price">Preço (R$)</label>
              <input id="edit-price" maxlength="6" class="swal2-input input-data-form" value="${propsModal.editModalTargetProps.valor}" placeholder="Preço">
            </div>`
          ): ''}`,
          preConfirm: () => {
            return new Promise((resolve) => {
              let data = {
                produtoId: propsModal.editModalTargetProps.id,
                body: {},
              };

              if (true) {
                resolve({
                  ...data,
                  body: {
                    ...data.body,
                    nome: document.getElementById("edit-name").value,
                    valor: propsModal.editModalTargetProps.incluidoCardapio ? Number(document.getElementById("edit-price").value.replace(",", ".")) : 0,
                    cardapioId: categoriesProductsList.id,
                  },
                });
              }
            });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setModalState(false);
            updateProductRequest(result.value.produtoId, result.value.body);
          } else {
            setModalState(false);
          }
        });
      }

      // Delete Product
      if (propsModal.editModalTargetProps.mode == "delete") {
        Swal.fire({
          title: `Realmente deseja excluir o produto "${propsModal.editModalTargetProps.nome}"?`,
          showCancelButton: true,
          customClass: {
            title: "title",
            confirmButton: 'btn-confirm',
            cancelButton: 'btn-cancel'
          },
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            setModalState(false);
            removeProductRequest(propsModal.editModalTargetProps.id);
          } else {
            setModalState(false);
          }
        });
      }

      // Add category
      if (propsModal.editModalTargetProps.mode == "category-add") {
        Swal.fire({
          title: "Digite o nome da categoria que deseja criar.",
          confirmButtonText: "Confirmar",
          showCloseButton: true,
          customClass: {
            title: "title",
            confirmButton: 'btn-confirm',
          },
          html:
          `<div class="input-container">
            <label for="edit-name">Nova categoria</label>
            <input id="edit-name" class="swal2-input input-data-form" value="" autofocus placeholder="Digite o nome da categoria">
          </div>`,
          preConfirm: () => {
            return new Promise((resolve) => {
              let data = {};
              if (true) {
                resolve({
                  ...data,
                  nome: document.getElementById("edit-name").value,
                  cardapioId: categoriesProductsList.id,
                });
              }
            });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setModalState(false);
            createNewCategoryRequest({...result.value});
          } else {
            setModalState(false);
          }
        });
      }
      // Add edit category
      if (propsModal.editModalTargetProps.mode == "category-edit") {
        Swal.fire({
          title: "Digite o novo nome da categoria",
          confirmButtonText: "Confirmar",
          showCloseButton: true,
          customClass: {
            title: "title",
            confirmButton: 'btn-confirm',
          },
          html:
          `<div class="input-container">
            <label for="edit-name">Categoria</label>
            <input id="edit-name" class="swal2-input input-data-form" value="${propsModal.editModalTargetProps.nome}" autofocus placeholder="Digite o nome da categoria">
          </div>`,
          preConfirm: () => {
            return new Promise((resolve) => {
              let data = {
                categoriaId: propsModal.editModalTargetProps.categoriaId,
                body: {},
              };
              
              if (true) {
                resolve({
                  ...data,
                  body: {
                    ...data.body,
                    nome: document.getElementById("edit-name").value,
                    cardapioId: categoriesProductsList.id,
                  },
                });
              }
            });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setModalState(false);
            updateCategoryRequest(result.value.categoriaId, result.value.body);
          } else {
            setModalState(false);
          }
        });
      }
      // Delete category
      if (propsModal.editModalTargetProps.mode == "category-delete") {
        Swal.fire({
          title: `Realmente deseja excluir a categoria "${propsModal.editModalTargetProps.nome}"`,
          showCancelButton: true,
          text: "*Ao deletar todos os produtos cadastrados serão removidos também.",
          customClass: {
            title: "title",
            confirmButton: 'btn-confirm',
            cancelButton: 'btn-cancel'
          },
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            setModalState(false);
            removeCategoryRequest(propsModal.editModalTargetProps.categoriaId, categoriesProductsList.id);
          } else {
            setModalState(false);
          }
        });
      }
    }
  }, [propsModal.modalState]);

  return (
    <>
      <section className="bx-category">
        {categoriesProductsList?.categorias?.length > 0
          ? (categoriesProductsList?.categorias || []).map((item, i) => {
              return (
                <Accordion
                  key={`Category-item-${item.id}`}
                  title={item.nome}
                  className={`${item.nome}`}
                  propsModal={propsModal}
                  categoriaId={item.id}
                  updateProductList={updateProductList}
                >
                  <Products
                    cardapioId={categoriesProductsList.id}
                    setUpdateProductList={setUpdateProductList}
                    updateProductList={updateProductList}
                    categoriaId={item.id}
                    produtos={item.produtos}
                    {...propsModal}
                  />
                </Accordion>
              );
            })
          : categoriesProductsListState.error && (
              <p>Erro ao carregar os produtos</p>
            )}
          <button className='btn-new-project' onClick={() => {
            setModalState(true);
            setEditModalTargetProps({...editModalTargetProps, mode: 'category-add'})
            }}><i className='icon-add' />Crie uma nova categoria
          </button>
      </section>
      <Toast ref={toast} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCategoriesAndProductsRequest: (cardapioId) => {
      dispatch(
        DeliverySystemActions.loadCategoriesAndProductsRequest(cardapioId)
      );
    },
    updateProductRequest: (produtoId, data) => {
      dispatch(DeliverySystemActions.updateProductRequest(produtoId, data));
    },
    removeProductRequest: (produtoId) => {
      dispatch(DeliverySystemActions.removeProductRequest(produtoId));
    },
    putProductOnMenuResetFlag: () => {
      dispatch(DeliverySystemActions.putProductOnMenuResetFlag());
    },
    removeProductFromMenuResetFlag: () => {
      dispatch(DeliverySystemActions.removeProductFromMenuResetFlag());
    },
    updateProductResetFlag: () => {
      dispatch(DeliverySystemActions.updateProductResetFlag());
    },
    removeProductResetFlag: () => {
      dispatch(DeliverySystemActions.removeProductResetFlag());
    },
    createNewProductResetFlag: () => {
      dispatch(DeliverySystemActions.createNewProductResetFlag())
    },
    createNewCategoryRequest: (data) => {
      dispatch(DeliverySystemActions.createNewCategoryRequest(data));
    },
    createNewCategoryResetFlag: () => {
      dispatch(DeliverySystemActions.createNewCategoryResetFlag());
    },
    updateCategoryRequest: (categoriaId, data) => {
      dispatch(DeliverySystemActions.updateCategoryRequest(categoriaId, data));
    },
    updateCategoryResetFlag: () => {
      dispatch(DeliverySystemActions.updateCategoryResetFlag());
    },
    removeCategoryRequest: (categoriaId, cardapioId) => {
      dispatch(DeliverySystemActions.removeCategoryRequest(categoriaId, cardapioId));
    },
    removeCategoryResetFlag: () => {
      dispatch(DeliverySystemActions.removeCategoryResetFlag());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    categoriesProductsListState: state.deliverySystem.categoriesProductsList,
    putProductOnMenuState: state.deliverySystem.putProductOnMenu,
    removeProductFromMenuState: state.deliverySystem.removeProductFromMenu,
    updateProductState: state.deliverySystem.updateProduct,
    removeProductState: state.deliverySystem.removeProduct,
    createNewCategoryState: state.deliverySystem.createNewCategory,
    createNewProductState: state.deliverySystem.createNewProduct,
    updateCategoryState: state.deliverySystem.updateCategory,
    removeCategoryState: state.deliverySystem.removeCategory,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListing);
