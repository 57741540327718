import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Input,
    SelectBank,
} from "@/components/formFields";
import { useForm } from "react-hook-form";
import { ValidationBank } from "@/components/utils";
import "./src/acessStageForm.min.css";
import * as DeliveryPayBanksActions from "@/store/deliveryPayBanks/actions";
import { CustomLoading } from "@/components/customLoading";

const AcessStageForm = ({
    banks,
    getDeliveryPayBanks,
    loading,
    dataPayment,
    setDataPayment,
    setshowConfirmation,
    readOnly,
    cancelado,
    firstAccess
}) => {
    const [bradesco, setBradesco] = useState([])
    const [isValidBankCode, setIsValidBankCode] = useState()

    const { register, errors, triggerValidation } = useForm({
        validationSchema: ValidationBank(),
        mode: "onChange",
    });

    useEffect(() => {
        getDeliveryPayBanks();
    }, []);

    useEffect(() => {
        if (banks != undefined && !loading) {
            setBradesco(banks.filter(e => e.codigoBanco == 237))
        }
    }, [banks]);

    const codeBankValidation = (bankCode) => {
        if (bankCode == null || bankCode == '' || bankCode == "Selecione") {
            setIsValidBankCode(false)
            return false
        }

        setIsValidBankCode(true)
        return true
    }

    async function handleChange(name, value) {
        if (name === undefined) {
            name = null
        } else {
            setDataPayment({ ...dataPayment, [name]: value });
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const isValid = await triggerValidation();

        codeBankValidation(dataPayment.bancoCodigo);
    
        if (isValid && isValidBankCode) {
            setshowConfirmation("open")
        } else {
            return false 
        }
    };

    return (
        <>
            <div className="fields">
                <div className="algo">
                    <SelectBank
                        label="Código do seu banco"
                        options={bradesco}
                        error={isValidBankCode == undefined  || isValidBankCode ? false : { message: "Escolha um banco válido" }}
                        inputProps={{
                            id: "bancoCodigo",
                            name: "bancoCodigo",
                            ref: register,
                            onChange: (e) => {
                                handleChange(e.target.name, e.target.value);
                                codeBankValidation(e.target.value);
                            },
                            value: dataPayment.bancoCodigo !== null ? dataPayment.bancoCodigo : '',
                        }}
                    />
                </div>
                <div className="account-agency">
                    <Input
                        label="Sua agência bancária"
                        error={errors.bancoAgencia}
                        inputProps={{
                            id: "bancoAgencia",
                            name: "bancoAgencia",
                            ref: register,
                            type: "text",
                            onChange: (e) => {
                                handleChange(e.target.name, e.target.value);
                            },
                            defaultValue: dataPayment.bancoAgencia !== null ? `${dataPayment.bancoAgencia}` : '',
                        }}
                    />
                    <Input
                        label='Digito'
                        error={errors.bancoAgenciaDigito}
                        inputProps={{
                            id: "bancoAgenciaDigito",
                            name: "bancoAgenciaDigito",
                            ref: register,
                            type: "text",
                            onChange: (e) => {
                                handleChange(e.target.name, e.target.value);
                            },
                            defaultValue: dataPayment.bancoAgenciaDigito !== null ? `${dataPayment.bancoAgenciaDigito}` : '',
                        }}
                    />
                </div>
                <div className="account">
                    <Input
                        label="Sua conta bancária"
                        error={errors.bancoContaBancaria}
                        inputProps={{
                            id: "bancoContaBancaria",
                            name: "bancoContaBancaria",
                            ref: register,
                            type: "text",
                            onChange: (e) => {
                                handleChange(e.target.name, e.target.value);
                            },
                            defaultValue: dataPayment.bancoContaBancaria !== null ? `${dataPayment.bancoContaBancaria}` : '',
                        }}
                    />

                    <Input
                        label="Dígito"
                        error={errors.bancoContaBancariaDigito}
                        inputProps={{
                            id: "bancoContaBancariaDigito",
                            name: "bancoContaBancariaDigito",
                            ref: register,
                            type: "text",
                            onChange: (e) => {
                                handleChange(e.target.name, e.target.value);
                            },
                            defaultValue: dataPayment.bancoContaBancariaDigito !== null ? `${dataPayment.bancoContaBancariaDigito}` : '',
                        }}
                    />
                </div>
            </div>
            {!firstAccess && (
                <div className="fm-button">
                    <button
                        type="button"
                        className="btn btn-main ic-go"
                        disabled={
                            loading ||
                            readOnly ||
                            cancelado
                        }
                        onClick={onSubmit}
                    >
                        {loading ? "Carregando..." : "Alterar forma de pagamento"
                        }
                    </button>
                </div>
            )}
            <CustomLoading show={loading} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      banks: state.deliveryPayBanks.data,
      loading: state.deliveryPayBanks.loading,
      firstAccess: state.auth.userInfo.firstAccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDeliveryPayBanks: () => {
        dispatch(DeliveryPayBanksActions.getDeliveryPayBanks());
      }
    };
  };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcessStageForm));

