import {FaqTypes} from './types';

const INITIAL_STATE = {
    faqs: [],
    loading: false,
    error: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FaqTypes.FAQ_REQUEST:
        return {...state, loading: true};
      case FaqTypes.FAQ_SUCCESS: 
       return {
            ...state,
            loading: false,
          faqs: action.payload.data.data
        }
      case FaqTypes.FAQ_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
