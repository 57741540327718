import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { IconCoin, IconHome, IconBenefitClub } from "@/components/icons";
import { UserMenu } from "@/components/menus";
import AuthHelper from "./../../Utils/AlertHelper";
import { ShowUnderPermission } from "@/components/showUnderPermission";
import "./src/tabBar.min.css";

const TabBar = (props) => {
  const onClick = (event) => {
    event.preventDefault();

    if (props.readOnly) {
      AuthHelper.alertReadOnly();
      return;
    }

    if (props.inadimplenteVencimentos) {
      AuthHelper.alertInadimplenteVencimentos();
      return;
    } else if (props.inadimplente) {
      AuthHelper.alertInadimplente(
        false,
        props.matriz,
        props.cobrancaCentralizada
      );
      return;
    }

    window.open(process.env.REACT_APP_URL_CATALOG, "_blank");
  };

  return (
    <Fragment>
      <section className="wr-tab-bar">
        <nav className="tab-bar-menu">
          <NavLink
            to="/"
            exact
            activeClassName="active"
            className="tab-bar-item"
          >
            <span>
              <IconHome className="ic ic-home" />
            </span>
            <strong>Home</strong>
          </NavLink>
          <NavLink to="/campanhas" className="tab-bar-item">
            <span>
              <IconCoin className="ic ic-home" />
            </span>
            <strong>Campanhas</strong>
          </NavLink>
          <NavLink to="/beneficios-prime" className="tab-bar-item">
            <span>
              <IconBenefitClub className="ic ic-benefit-club" />
            </span>
            <strong>Benefícios Prime</strong>
          </NavLink>
          <ShowUnderPermission codePermission="COMO_FUNCIONA">
            <NavLink to="/como-funciona" className="tab-bar-item">
              <span>
                <i className="ic ic-conecta-prime"></i>
              </span>
              <strong>Programa</strong>
            </NavLink>
          </ShowUnderPermission>
          <div className="bx bx-user-menu">
            <UserMenu tabBar={true} />
          </div>
        </nav>
      </section>
      <div className="tab-bar-placeholter" />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    inadimplente: state.myData.data.inadimplente,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
  };
};
export default connect(mapStateToProps)(TabBar);
