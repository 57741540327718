import vivoImg from "@/assets/img/simulator/v5/vivo-desk.jpg";
import vivoImgMobile from "@/assets/img/simulator/v5/vivo-mobile.jpg";
import { SectionTitle } from "@/components/sectionTitle";
import React from "react";
import { connect } from "react-redux";

const InternetVivoBanner = ({
  steps,
  navigation,
  index,
  simulator_data,
  mostrarBeneficioEnergia,
}) => {
  const handleStep = () => {
    navigation.next();
  };

  return (
    <section className="bx bx-section-internetVivo">
      <SectionTitle
        title={`${
          mostrarBeneficioEnergia ? "E o Prime" : "O Prime"
        } oferece planos incríveis de internet e telefonia da VIVO para os varejistas cadastrados:`}
      />
      <div className="content">
        <h3>
          <strong>
            Com um dos planos disponíveis você pode economizar: <br />{" "}
            <span className="price">
              R$ {simulator_data.vivoTelefoniaString}
            </span>
          </strong>
        </h3>
        <br />
        <div className="banner-vivo">
          <img
            src={window.innerWidth > 500 ? vivoImg : vivoImgMobile}
            className="fullWidthImg"
            title="Internet Vivo"
            alt="Internet Vivo"
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={() => handleStep()}
        className="btn btn-main ic-go"
      >
        Próximo
      </button>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    mostrarBeneficioEnergia: state.simulator.data.mostrarBeneficioEnergia,
    simulator_data: state.simulator.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InternetVivoBanner);
