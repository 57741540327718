import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InputCurrency } from "@/components/formFields";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import * as SimulatorActions from "../../../store/simulator/actions";

const DescontoEnergia = ({
  steps,
  navigation,
  index,
  current_state,
  simulator_state,
  simulatorRequest,
}) => {
  const [valorContaEnergia, setValorContaEnergia] = useState(current_state.valorContaEnergia ? current_state.valorContaEnergia : 0);

  useEffect(() => {
    simulator_state("step", 6);
  }, []);

  useEffect(() => {
    //Updating state
    if(valorContaEnergia) {
      simulator_state("valorContaEnergia", parseFloat(valorContaEnergia.toString().replace(".", "").replace(",", ".")))
    }
  }, [valorContaEnergia]);

  const ValidationRules = () => {
    return yup.object().shape({
      valorContaEnergia: yup
        .string("Insira uma quantidade médio de compras")
        .required(
          "A Quantidade das compras é obrigatório dar continuidade a simulação"
        ),
    });
  };

  const { register, errors, reset, getValues, triggerValidation } = useForm({
    validationSchema: ValidationRules(),
    mode: "onChange",
  });

  const sendForm = async (event) => {
    event.preventDefault();

    const isValid = await triggerValidation();

    if (isValid) {
      simulatorRequest(current_state);
      navigation.next();
    }
  };

  return (
    <section className="bx bx-section-descontoEnergiaEstela">
      <SectionTitle title="Com o Prime você pode ter também <span>descontos imperdíveis</span> na sua conta de energia:" />
      <div className="content">
        <InputCurrency
          label="Qual a sua média mensal de gasto em energia?"
          error={errors.valorContaEnergia}
          onChange={(value) => setValorContaEnergia(value)}
          inputProps={{
            ref: register,
            type: 'text',
            name: "valorContaEnergia",
            id: "valorContaEnergia",
            value: valorContaEnergia,
            prefix: "R$ "
          }}
        />
      </div>
      <div className="bx-buttons">
        <button type="submit" onClick={sendForm} className="btn btn-main ic-go">
          Próximo
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DescontoEnergia);
