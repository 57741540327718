import React from "react";

const iconLetter = () => (
  <svg width={30} height={22} viewBox="0 0 30 22">
    <defs>
      <style>{".a{fill:#fff;fill-rule:evenodd;}"}</style>
    </defs>
    <path
      className="a"
      d="M28.751,0H1.249A1.264,1.264,0,0,0,0,1.284V20.716A1.264,1.264,0,0,0,1.249,22h27.5A1.264,1.264,0,0,0,30,20.716V1.284A1.264,1.264,0,0,0,28.751,0ZM27.5,17.587l-7.518-7.27L27.5,4.123Zm-13.188-3.9a1.216,1.216,0,0,0,1.567,0l2.131-1.756,7.739,7.491H4.249l7.837-7.579ZM25.39,2.568,15.1,11.041,4.849,2.568ZM10.114,10.223,2.5,17.587V3.928Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default iconLetter;
