import React from 'react';

import './src/modal.min.css';

const ModalLayout = ({ title, children, modalState, setModalState, callback, customClass, closeButton }) => {
    const closeModal = () => {
        setModalState('close');
        if (callback) {
            callback();
        }
    }
    return (
        <div className={`overlay ${modalState} ${customClass ? customClass : ''}`}>
            <section className='modalContainer'>
                <div className='bx-modal'>
                    {title &&
                        <div className='modal-header'>
                            <h2>{title}</h2>
                        </div>
                    }
                    {closeButton && <a onClick={closeModal} className='btn-close-modal'><i className='icon icon-icon_close'></i></a>}
                    <div className='modal-content'>
                        {children}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ModalLayout;