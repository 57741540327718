import logoStella from "@/assets/img/simulator/v5/logo_stella.png";
import { SectionTitle } from "@/components/sectionTitle";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as SimulatorActions from "../../../store/simulator/actions";

const DescontoEnergiaBannerStella = ({
  navigation,
  simulator_data,
  bannersBeneficios
}) => {
  const handleStep = () => {
    !!bannersBeneficios.length ? navigation.next() : navigation.go(7);
  };

  return (
    <section className="bx bx-section-descontoEnergiaEstela">
      <SectionTitle title="Com o Prime você pode ter também <span>descontos imperdíveis</span> na sua conta de energia:" />
      <div className="content">
        <p>
          Com a parceria Stella você tem acesso a <b>energia mais barata</b>,
          100% <b>renovável</b> e com <b>zero investimento.</b>
        </p>
        <br />
        <h3>
          <strong>
            Aproveite até <span className='discount'>{simulator_data.percentualStella}%</span> de desconto, podendo reduzir sua
            conta para: <br /> <span className='price'>R$ {simulator_data.valorContaEnergiaString}</span>
          </strong>
        </h3>
        <br />
        <span>
          * Valor para simulação.<br />
          Atualmente disponível nas UFs: <b>MG, DF, ES e SC</b>
        </span>
        <br />
        <div className='banner-stella'>
          <img
            src={logoStella}
            className="fullWidthImg"
            title="Stella"
            alt="Stella"
          />
        </div>
      </div>
      <div className="bx-buttons">
        <button
          type="submit"
          onClick={() => handleStep()}
          className="btn btn-main ic-go"
        >
          Próximo
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    simulator_data: state.simulator.data,
    bannersBeneficios: state.simulator.data.banners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DescontoEnergiaBannerStella);
