import React from 'react'
import './src/button.min.css'

const Button = ({ id, callBack, icon, btnClass, labelButton, disabled }) => {
    const handleClick = event => {
        event.preventDefault()
        callBack()
    }

    return (
        <div className={`fm-Button`}>
            <button id={id} disabled={disabled} onClick={handleClick} className={`btn btn--${btnClass}`}>
                {icon ? <i className={`icon ${icon}`}></i> : ''}
                <span>{labelButton}</span>
            </button>
        </div>
    );
};

export default Button;