import { action } from 'typesafe-actions';
import { PerformanceTypes } from './types';

export const loadPerformance = (params) => action(PerformanceTypes.PERFORMANCE_REQUEST, {params})

export const loadPerformanceSuccess = (data) => action(PerformanceTypes.PERFORMANCE_SUCCESS, { data });

export const loadPerformanceFailure = () => action(PerformanceTypes.PERFORMANCE_FAILURE)

export const selectMonthPerformance = () => action(PerformanceTypes.SELECT_MONTH_PERFORMANCE_REQUEST)

export const selectMonthPerformanceSuccess = (data) => action(PerformanceTypes.SELECT_MONTH_PERFORMANCE_SUCCESS, { data });

export const selectMonthPerformanceFailure = () => action(PerformanceTypes.SELECT_MONTH_PERFORMANCE_FAILURE)
