import { AuthTypes } from './types';

// accessToken, cognitoAccessToken, cognitoIdToken, userInfo

const INITIAL_STATE = {
  loading: false,
  success: false,
  accessToken: '',
  cognitoAccessToken: '',
  cognitoIdToken: '',
  userInfo: {},
  returnUrl: '',
  userPermissions: [],
  chooseReentryStatus: {
    loading: false,
    success: false,
    error: false,
  },
  reentryHistoric: {
    data: {},
    loading: false,
    success: false,
    error: false,
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.AUTH_STATE:
      return { ...state, [action.payload.node]: { ...state[action.payload.node], [action.payload.key]: action.payload.value } }
    case AuthTypes.SIGN_IN_REQUEST:
      return { ...state, loading: true, success: false, userInfo: {} };
    case AuthTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        accessToken: action.payload.accessToken,
        cognitoIdToken: action.payload.cognitoIdToken,
        cognitoAccessToken: action.payload.cognitoAccessToken,
        returnUrl: action.payload.returnUrl,
        loading: false,
        success: true
      }
    case AuthTypes.SIGN_IN_FAILURE:
      return { ...state, loading: false, success: false, userInfo: {} }
    case AuthTypes.RETURN_URL_RESET:
      return { ...state, returnUrl: null }
    case AuthTypes.LOAD_PERMISSIONS_REQUEST:
      return { ...state };
    case AuthTypes.LOAD_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userPermissions: action.payload.data.data,
      }
    case AuthTypes.REENTRY_NEGOCIATION_REQUEST:
      return { ...state, chooseReentryStatus: { ...state.chooseReentryStatus, loading: true, success: false, error: false } };
    case AuthTypes.REENTRY_NEGOCIATION_SUCCESS:
      return { ...state, chooseReentryStatus: { ...state.chooseReentryStatus, loading: false, success: true, error: false } };
    case AuthTypes.REENTRY_NEGOCIATION_FAILURE:
      return { ...state, chooseReentryStatus: { ...state.chooseReentryStatus, loading: false, success: false, error: true } };
    case AuthTypes.REMOVE_ALL_DEBTOR_STATE:
      return {
        ...state, userInfo: {
          ...state.userInfo, inadimplenteVencimentos: false, inadimplente: false,
          reativacaoAtual: {
            ...state.userInfo.reativacaoAtual,
            concluido: true,
          }
        }
      }
    case AuthTypes.REMOVE_DEBTOR_90_STATE:
      return {
        ...state, userInfo: {
          ...state.userInfo, inadimplenteVencimentos: false,
          reativacaoAtual: {
            ...state.userInfo.reativacaoAtual,
            concluido: true,
          }
        }
      }
    case AuthTypes.REENTRY_HISTORIC_REQUEST:
      return { ...state, reentryHistoric: { ...state.reentryHistoric, loading: true, success: false, error: false } };
    case AuthTypes.REENTRY_HISTORIC_SUCCESS:
      return { ...state, reentryHistoric: { ...state.reentryHistoric, data: action.payload.data, loading: false, success: true, error: false } };
    case AuthTypes.REENTRY_HISTORIC_FAILURE:
      return { ...state, reentryHistoric: { ...state.reentryHistoric, data: INITIAL_STATE.reentryHistoric.data, loading: false, success: false, error: true } };
    default:
      return state;
  }
};

export default reducer;
