export const PopupTypes =  {

    LOAD_POPUP_HOME_REQUEST : '@auth/LOAD_POPUP_HOME_REQUEST',
    LOAD_POPUP_HOME_SUCCESS : '@auth/LOAD_POPUP_HOME_SUCCESS',
    LOAD_POPUP_HOME_FAILURE : '@auth/LOAD_POPUP_HOME_FAILURE',
    
    LOAD_POPUP_CAMPAIGNS_REQUEST : '@auth/LOAD_POPUP_CAMPAIGNS_REQUEST',
    LOAD_POPUP_CAMPAIGNS_SUCCESS : '@auth/LOAD_POPUP_CAMPAIGNS_SUCCESS',
    LOAD_POPUP_CAMPAIGNS_FAILURE : '@auth/LOAD_POPUP_CAMPAIGNS_FAILURE',

    LOAD_POPUP_CONFIRM_REQUEST : '@auth/LOAD_POPUP_CONFIRM_REQUEST',
    LOAD_POPUP_CONFIRM_SUCCESS : '@auth/LOAD_POPUP_CONFIRM_SUCCESS',
    LOAD_POPUP_CONFIRM_FAILURE : '@auth/LOAD_POPUP_CONFIRM_FAILURE'
}