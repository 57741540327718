import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import { IconCheck } from "@/components/icons";

// rever
const Progress = ({ steps, stepCurrent, soma, current_values, loading }) => {
  const setProgressStatus = (key) => {
    const statusProgressBar =
      stepCurrent === key ? `current` : stepCurrent > key ? "filled" : "empty";
    return statusProgressBar;
  };

  useEffect(() => {
    if (steps[stepCurrent].progressBar) {
      const item = document.getElementsByClassName(`current`);
      const container = document.getElementById("progress-bar");
      if (container && container.scrollTo) {
        container.scrollTo({
          left: item[0].offsetLeft - item[0].offsetWidth,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line
  }, [stepCurrent]);

  return (
    <section className="bx bx-section-Progress">
      <SectionTitle title="Vamos somar tudo o que você pode ganhar com o Conecta Prime?" />
      <ul className="progress-bar" id="progress-bar">
        {steps &&
          steps.map((step, key) => {
            if (step.progressBar) {
              return (
                <li
                  key={`progress-bar-${key}`}
                  className={setProgressStatus(key)}
                >
                  <span className="bx-icon">
                    <IconCheck className="ic ic-check" fill="#21346C" />
                  </span>
                  <span className="step-title">{step.title}</span>
                  <strong>
                    {!current_values[step.state_key] || stepCurrent < key
                      ? `...`
                      : `R$ ${current_values[step.state_key]}`}
                  </strong>
                </li>
              );
            }
          })}
      </ul>
      <div className="bx-total-bonus">
        <span>No total, você pode ganhar até</span>
        <strong>R$ {!loading ? soma : "..."}</strong>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    soma: state.simulator.data.somaString,
    current_values: state.simulator.data,
    loading: state.simulator.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
