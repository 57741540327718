import {RegulationTypes} from './types';

const INITIAL_STATE = {
    regulation: "",
    idCampanha: 0,
    loading: false,
    error: false,
    success: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case RegulationTypes.LOAD_REGULATION_REQUEST:
        return {...state, loading: true};
      case RegulationTypes.LOAD_REGULATION_SUCCESS: 
       return {
          ...state,
          loading: false,
          regulation: action.payload.data.data.regulamento,
          idCampanha: action.payload.data.data.idCampanha
        }
      case RegulationTypes.LOAD_REGULATION_FAILURE: 
        return {...state, loading: false, error: true}
      default:
        return state;
    }
};

export default reducer;
