import { DefaultContainer } from "@/components/containers";
import React from "react";
import "./src/header.min.css";
import { Link } from "react-router-dom";

// HEADER
const HeaderDisconnected = () => {
  return (
    <header className="wr wr-header">
      <DefaultContainer>
        <Link className="logo-programa" to="/simulador-identificacao"></Link>
      </DefaultContainer>
    </header>
  );
};

export default HeaderDisconnected;
