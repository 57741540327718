import React from 'react';

const ConectaPayRegulation = () => {
    return (
        <div className='container-scroll'>
        <p>
        <strong>TERMO DE ADESÃO AOS SERVIÇOS DE PAGAMENTO (“CONECTA PAY”)</strong>
<br/><br/>
O presente termo para Contratação de Serviços de Pagamento (“Contrato”) é
celebrado por e entre as seguintes partes (em conjunto, as “Partes” e,
individualmente, “Parte”):<br/>
I. DELIVERY PAY SOLUÇÕES EM PAGAMENTO S.A., sociedade anônima,
inscrita no CNPJ/MF sob o nº 30.612.886/0001-95, com sede na Alameda Mamoré,
nº 687, 5º andar, conjunto 503, Sala 05-124, Alphaville Centro Industrial e
Empresarial, na Cidade de Barueri, Estado de São Paulo, CEP 06454-040 (“Delivery
Pay”);e<br/>
II. VAREJO, é a pessoa física ou jurídica, qualificado conforme os dados
especificados e validados na aba “Meus Dados” no Portal Conecta Prime, que revende
produtos fabricados e/ou comercializados pela Souza Cruz. O Varejo integra a Rede
Souza Cruz e anuiu, previamente, ao Regulamento e ao Termo de Adesão ao
Programa Conecta Prime, o que permite à Delivery Pay habilitar o Varejo para
utilização das Soluções de Pagamento da Rede de Captura, conforme definido no
Contrato.
E, ainda, com a interveniência e anuência de:<br/>
III. SOUZA CRUZ LTDA., sociedade empresária limitada, inscrita no CNPJ/MF
sob o nº 33.009.911/0001-39, com sede na Avenida República do Chile, nº 330,
Centro, na Cidade do Rio de Janeiro, Estado do Rio de Janeiro, CEP 20.031-170
(“Souza Cruz” ou “Interveniente-Anuente”).
CONSIDERANDOS:
(a) O Varejo integra a rede de estabelecimentos comerciais clientes ou conveniados
da Souza Cruz (“Rede Souza Cruz”), por meio do Portal Conecta Prime, tendo aderido
e anuído ao Termo de Adesão ao Programa Conecta Prime (“Termo de Adesão”);
(b) O Varejo tem interesse em aderir ao benefício Conecta Pay que facilitará a
venda de produtos Souza Cruz ou de demais fabricantes com pagamento em cartão
de crédito ou débito, por meio da concessão de pontos de fidelidade às vendas de
Produtos Souza Cruz, conforme definido no Regulamento que constitui o Anexo I
deste Termo, quando forem pagas por meio de cartões de débito e crédito com as
Soluções de Pagamento oferecidas pela Delivery Pay;
(b) a Delivery Pay é uma subcredenciadora, participante do sistema de arranjos de
pagamento, a quem compete habilitar os Varejos da Rede Souza Cruz, nos termos
do Programa Conecta Prime, para a aceitação de instrumentos de pagamento
emitidos por instituições de pagamento ou financeiras participantes de arranjos de
pagamento, realizando a liquidação de Transações de pagamento aos Varejos.<br/>
1. DAS DEFINIÇÕES<br/>
Para o perfeito entendimento e interpretação deste CONTRATO, são adotadas as
seguintes definições, grafadas em caixa alta, utilizadas no singular ou no plural: 

AGENDA FINANCEIRA é a relação de débitos e créditos do Varejo relativo às
Transações.
ARRANJO DE PAGAMENTO é o conjunto de regras e procedimento instituídos pela
Bandeira que permite a prestação de serviços de pagamento ao público em geral por
meio da relação entre Emissores, Varejo, Instituições Domicílio e Subcredenciadores.
BANCO CENTRAL é o Banco Central do Brasil.
BANDEIRAS significam as empresas instituidoras de arranjos de pagamento, que
estabelecem o conjunto de regras e procedimentos gerais de organização e
funcionamento do sistema de cartões e/ou meios de pagamento, detentoras dos
direitos de propriedade e franqueadoras de suas marcas e logotipos à Credenciadora
e emissores de cartões, para aceitação na Rede de Captura.
CANCELAMENTO DA TRANSAÇÃO é o processo em que o Varejo solicita à Delivery
Pay o cancelamento de uma Transação já processada.
“CARTÃO” ou “CARTÕES” são os instrumentos de pagamento, físicos ou virtuais,
disponibilizados pelos Emissores, para uso pessoal e intransferível dos Portadores e
aceitos pelos Varejos da Rede Souza Cruz habilitados pela Delivery Pay.
CENTRAL DE ATENDIMENTO são canais disponibilizados pela Souza Cruz, para
atendimentos de 1º nível, e pela Delivery Pay, para atendimentos de 2º e 3º níveis,
aos Varejos para atendimento de dúvidas, pesquisas operacionais, solicitação de
manutenção e contratação de Produtos, Serviços e Equipamentos, conforme as
definições deste Termo.
CHIP é o microprocessador introduzido nos Cartões contendo programação e
memória de dados do Portador, cuja leitura é realizada nos Equipamentos com uso
de senha ou assinatura do Portador.
CÓDIGO DE AUTORIZAÇÃO é um conjunto de caracteres numéricos e/ou
alfanuméricos, gerados pelos Emissores.
COMPROVANTE DE TRANSAÇÃO é o documento que poderá ser emitido pelos
Equipamentos e/ou sistemas da Delivery Pay ou de terceiros por ela autorizados
comprovando a realização de uma Transação.
CONTESTAÇÃO (“CHARGEBACK”) é o processo de contestação de uma Transação,
parcial ou total, por parte do Portador, da Bandeira ou do Emissor, de acordo com as
regras e prazos definidos pelas Bandeiras, o que poderá resultar na não realização
de Repasse ou de estorno do crédito ao Varejo, nos termos deste Contrato.
DIAS ÚTEIS ou DIA ÚTIL é qualquer dia que não seja sábado, domingo ou feriado
declarado nacional.
DELIVERY PAY é participante de arranjos de pagamento, subadquirente de diversas
Bandeiras, provedora de Rede de Captura, processamento, liquidação de
pagamentos, antecipação de recebíveis, roteamento de transações eletrônicas,
locação de equipamentos, integração de sistemas e conexão de Emissores com
outros sistemas de captura entre outros serviços de tecnologia.

DOMICÍLIO BANCÁRIO é a conta de livre movimentação, de titularidade do Varejo
ou por ele autorizado, mantida junto a uma instituição financeira, onde serão
efetuados os créditos e/ou débitos decorrentes da realização das Transações e
serviços previstos neste Contrato.
EMISSOR é a instituição financeira e/ou de pagamento, nacional ou estrangeira,
autorizada pelo Banco Central, responsável pela emissão de Instrumentos de
Pagamento com validade no Brasil e pela liberação do pagamento ao Varejo. É quem
oferece crédito ao Portador e define limites e parâmetros para processamento e
autorização das transações sob sua responsabilidade.
EMPRESA(S) PARCEIRA(S) são empresas com as quais a Delivery Pay mantém
algum tipo de parceria para representá-las perante o Varejo, mediante prévia
anuência da Souza Cruz e nos termos do Regulamento do Programa Conecta Prime,
fornecendo soluções de pagamento e detendo autorização para realizar descontos
nos recebíveis do Varejo oriundos de programas, produtos e/ou serviços aderidos
e/ou adquiridos pelo Varejo da Empresa Parceira.
EQUIPAMENTOS são quaisquer aparelhos, independente da tecnologia e
propriedade, incluindo, mas não se limitando a Pin Pad e Terminal POS, fornecidos
e/ou instalados no Varejo para a realização de TRANSAÇÕES.
EXTRATOS DE VENDA são os documentos padronizados, fornecidos pela Delivery
Pay, gerados eletronicamente pelos Equipamentos, para registrar a quantidade e o
valor total das Transações realizadas no respectivo dia.
INFORMAÇÃO CONFIDENCIAL ou INFORMAÇÕES CONFIDENCIAIS é toda e
qualquer informação, seja verbal, escrita, impressa ou eletrônica, de qualquer
natureza, que poderá ser entendida como confidencial, sejam elas classificadas como
confidenciais ou não, incluindo, mas não limitado a, qualquer informação a respeito
de transações efetuadas, quaisquer informações ou condições decorrentes das
transações ou gerados a partir de tais transações ou estabelecidas no Contrato,
tecnologia utilizada pelas Partes, assim como os procedimentos técnicos, os
processos de negócios, incluindo as estratégias financeiras e as políticas de
segurança de informação das Partes, que podem ter a forma de documentos,
especificações técnicas, know-how, patentes, dados, desenhos, planos, fluxogramas
de processo, fotografias, base de dados, hardware, software, além de descrições,
apresentações e observações efetuadas oralmente.
MANUAL OPERACIONAL é o documento para orientação e esclarecimento de todas
as rotinas pertinentes a utilização da Rede de Captura e/ou Equipamentos fornecidos
ao Varejo para aceitação dos meios de pagamento autorizados pela Delivery Pay.
MATERIAL é todo e qualquer suprimento fornecido ao Varejo, pela Delivery Pay
e/ou pela Souza Cruz, a título oneroso ou gratuito, tais como: bobinas, adesivos,
display´s, materiais promocionais, etc.
MEIOS DE IDENTIFICAÇÃO são os dispositivos de identificação, apresentados sob
a forma de Cartão plástico, Smartphones, QR code’s, Token’s, Smart Watches ou
outros meios, sejam físicos ou não, capazes de identificar um portador e possibilitar
várias funções de compras de bens ou serviços além de transações eletrônicas para
envio ou consulta de informações.
MEIOS DE PAGAMENTO são os instrumentos físicos ou eletrônicos com funções de
pagamento (múltiplas ou não), aceitos ou que venham a ser aceitos na Rede De 

Captura, disponibilizados e autorizados pela Delivery Pay, e que sejam aprovados
pela Souza Cruz, por escrito, para uso no âmbito da Parceria.
PERDA são todas e quaisquer perdas, obrigações, demandas, passivos, exigências,
constrições, danos, multas, penalidades, prejuízos, ônus, desembolsos, custos ou
despesas, incluindo danos diretos, danos indiretos, danos emergentes, danos morais
e/ou lucros cessantes, honorários advocatícios e de outros especialistas, de verbas
de sucumbência, bem como custas judiciais ou quaisquer juros, sejam eles já
materializados ou futuros, incorridos pela Delivery Pay e/ou terceiros, decorrentes,
direta ou indiretamente, do objeto do Contrato e/ou Anexo, bem como do
descumprimento de obrigações assumidas pelo Varejo perante terceiros ou no âmbito
de sua atividade, inclusive após o decurso do prazo do Contrato e/ou Anexo,
incluindo, sem limitação, aquelas decorrentes de multas, penalidades, reclamações,
processos administrativos, extrajudiciais e judiciais ajuizados por terceiros e/ou
ocasionadas por ato oriundo do Varejo, independente de culpa ou dolo.
PORTADOR é a pessoa física, jurídica e/ou preposto de pessoa jurídica, detentora
e/ou portadora de Meio de Pagamento, autorizado a realizar Transações.
PORTAL CONECTA PRIME é a página na Internet (www.conectaprime.com.br),
com área de acesso seguro mediante senha e usuário, onde o usuário poderá aderir
ao serviço da Delivery Pay e que oferece ao Varejo a possibilidade de consulta a
informações e acesso a serviços on-line prestados no âmbito da Parceria, sejam estes
gratuitos ou onerosos.
PRODUTOS ADICIONAIS podem ser considerados como produtos e/ou serviços,
além daqueles comercializados entre Varejo e Portador, aqueles disponibilizados para
o Varejo e descritos na cláusula 6.25.1 deste Contrato, através da Rede de Captura,
Portal Delivery Pay ou através de outros meios disponibilizados diretamente pela
Delivery Pay ou terceiros por ela autorizados, de forma gratuita ou onerosa, após
autorização prévia e por escrito da Souza Cruz.
PRODUTOS SOUZA CRUZ significam os produtos que sejam ou venham a ser
fabricados e/ou comercializados pela Souza Cruz, incluindo produtos fabricados por
parceiros, atuais ou futuros, da Souza Cruz.
REDE DE CAPTURA é o conjunto de serviços composto por sistemas de informática,
equipamentos e outras tecnologias para captura, processamento, tratamento de
dados, canais de comunicação, direcionamento e integração de dados, de
propriedade da Delivery Pay ou de terceiros por ela autorizados, utilizados para
transporte de transações eletrônicas entre os sistemas necessários para
processamento e autorização.
REGULAMENTO é o instrumento parte integrante deste Contrato, identificando e
qualificando o Varejo para utilização da Rede de Captura, conforme os dados
fornecidos pelo Varejo à Souza Cruz no Termo de Adesão ao Programa Conecta
Prime. O Regulamento descreverá as condições comerciais acordadas entre a
Delivery Pay e o Varejo. O domicílio bancário para débitos de valores decorrentes dos
serviços previstos neste Contrato será o informado nos dados cadastrais do Programa
Conecta Prime.
REPASSE é a transferência de valores recebidos de terceiros ao Varejo efetuado pelo
Emissor dos Valores Líquidos originados pelas Transações na Rede de Captura
administrada ou que venha a ser pela Delivery Pay, no âmbito da Parceria. 

SENHA é o número de identificação pessoal que pode ser gerado de forma
automática pelos sistemas da Delivery Pay ou dos Emissores, com a finalidade de
identificar um Portador e lhe prover acesso a determinados sistemas e/ou serviços
no âmbito da Parceria, descritos neste Contrato, com opção ou não de poder ser
alterado pelo Portador .
TARIFA POR TRANSAÇÃO é a remuneração por Transação realizada no âmbito da
Parceria, com valor fixo ou variável, estipulada no Regulamento (Anexo I), paga pelo
Varejo à Delivery Pay por débito em seu Domicílio Bancário ou cobrança bancária, ou
indireta através de desconto nos seus recebíveis oriundos das transações efetuadas
com meios de pagamento dos Emissores autorizados a utilizar a Rede de Captura.
TOKEN é um dispositivo eletrônico gerador de Senhas, números de identificação ou
números de validação.
TRANSAÇÕES são todas e quaisquer transações eletrônicas que caracterizam um
processo de aquisição de bens e/ou serviços, operações de transferências de fundos
ou saques em espécie realizados no âmbito do Contrato, mediante a utilização de
meio de identificação e pagamento na Rede de Captura, caracterizando uma relação
comercial e fiscal entre o Varejo e o Portador. Podem ser consideradas como
Transação também operações de consultas de saldo, cancelamentos, aquisição de
serviços de terceiros e qualquer outra comunicação entre os equipamentos de
captura e os sistemas da Delivery Pay no âmbito do Contrato.
URA - Unidade de Resposta Audível, sistema que possibilita atendimento automático
nos contatos telefônicos.
VALOR BRUTO é o valor total das Transações realizadas no Varejo antes da dedução
de qualquer taxa ou tarifa.
VALOR LÍQUIDO é o valor a ser creditado ao Varejo correspondente ao Valor Bruto,
após a dedução da Tarifa por Transação e quaisquer descontos devidos à Delivery
Pay prevista neste Contrato e no Regulamento, no âmbito do benefício do Conecta
Pay, bem como descontos devidos em decorrência de programas, produtos e/ou
serviços aderidos e/ou adquiridos de Empresas Parceiras da Delivery Pay, mediante
prévia anuência da Souza Cruz e nos termos do Regulamento do Programa Conecta
Prime.
VAREJO é a pessoa física ou jurídica, que revende produtos fabricados e/ou
comercializados pela Souza Cruz. O Estabelecimento Comercial integra a Rede Souza
Cruz e anuiu, nesta data, ao Regulamento e ao Termo de Adesão ao Programa
Conecta Prime, o que permite à Delivery Pay habilitar o Estabelecimento Comercial
para utilização das Soluções de Pagamento da Rede de Captura, conforme definido
no Contrato.<br/>
2. DO OBJETO<br/>
2.1. O Objeto deste Contrato é a prestação de serviços que abrangem a captura,
roteamento, transmissão e processamento das Transações no âmbito da Parceria,
mediante credenciamento do Varejo da Rede Souza Cruz, habilitando-o a aceitar os
Meios De Pagamento de Emissores habilitados na Rede de Captura e autorizados pela
Delivery Pay, após autorização prévia e por escrito da Souza Cruz, .<br/>
2.2. Constituem atividades relacionadas ao objeto deste Contrato:<br/>

a) O fornecimento de Equipamentos através de comodato, Portal Conecta Prime,
canal de atendimento telefônico, agente autorizado, aplicativos Mobile ou
qualquer outra tecnologia que venha a ser adotada pela Delivery Pay ou pela
Souza Cruz durante a vigência do Contrato que possibilite a captura,
processamento, autorização e a liquidação de Transações;
b) A manutenção adequada da operacionalidade da Rede de Captura e a devida
identificação visual do Estabelecimento Comercial através do fornecimento do
KIT de sinalização, conforme estabelecido na cláusula 3.14;
c) O cadastramento pela Delivery Pay do Regulamento (Anexo 1), aceito pelo
Varejo, para sere utilizado nos casos de aceitação dos Meios de Pagamento no
âmbito da Parceria;
d) Regular e operacionalizar o repasse de valores entre o Delivery Pay e o Varejo
para a liquidação de Transações, conforme definido no Contrato.
<br/>2.3. Os serviços e atividades de que trata essa Cláusula são prestados e/ou
propiciados aos Varejos diretamente pela Delivery Pay ou por intermédio de empresa
autorizada, cabendo a cada um deles a execução do serviço, conforme acordado em
CONTRATOS próprios com suas respectivas condições comerciais e operacionais ou,
conforme o caso, de outras formas de remuneração previstas neste Contrato ou
estabelecidas através de Termo Aditivo.
<br/>2.4. As partes reconhecem que a Souza Cruz não é uma instituição de pagamento,
nem subcredenciadora. Da mesma forma, a Souza Cruz não é prestadora dos
serviços objeto deste Contrato, não tendo qualquer responsabilidade por obrigações
em relação ao presente Contrato, nem mesmo por eventuais vícios e/ou defeitos na
prestação dos serviços, comprometendo-se as partes a manterem a Souza Cruz
isenta para todos os fins.
<br/>3. DO CREDENCIAMENTO
<br/>3.1. O Credenciamento e a adesão do Varejo à Rede de Captura serão feitos com o
aceite deste Contrato e a aceitação do Regulamento do Conecta Pay (Anexo I), tendo
o Varejo, nesta data, aderido e anuído ao Regulamento do Conecta Prime.
<br/>3.2. O credenciamento e a adesão do Varejo à Rede de Captura estão condicionados
à autorização e à liberação da Delivery Pay, mediante análise dos dados fornecidos
pelo Varejo à Souza Cruz no Termo de Adesão ao Conecta Prime e dos requisitos
operacionais definidos através de critérios estabelecidos pela Delivery Pay no
Regulamento. Tal liberação não constitui condição permanente, podendo esta ser
suspensa a qualquer tempo em função de ajustes e/ou regras estabelecidas ou
alteradas pela Delivery Pay em cumprimento das regulamentações e regras dos
reguladores da indústria de cartões.
<br/>3.3. O credenciamento do Varejo está condicionado exclusivamente à prévia adesão
ao Programa Conecta Prime, exceto nas hipótese da cláusula 3.4.
<br/>3.4. Determinados Varejos não serão credenciados ou serão descredenciados pela
Delivery Pay caso seja constatado qualquer indício de atividades consideradas ilegais,
indesejáveis, que possam trazer qualquer tipo de prejuízo para qualquer empresa ou
usuário dos serviços da Delivery Pay, por qualquer tipo de pendência financeira junto
à Delivery Pay ou por alterações nas regras de credenciamento que podem ser feitas
de forma unilateral pela Delivery Pay. 

<br/>3.5. A Delivery Pay definirá os tipos de Transações que o Estabelecimento Comercial
poderá aceitar, assim como as formas de captura de Transações que ele estará
autorizado a realizar, à luz do Regulamento e do Termo de Adesão ao Conecta Prime
celebrado entre o Estabelecimento Comercial e a Souza Cruz.
<br/>3.6. O credenciamento do Varejo pela Delivery Pay se refere ao serviço relacionado
à Rede de Captura para o Programa Conecta Prime. O Regulamento deste Contrato
conterá especificação expressa sobre as condições do Estabelecimento Comercial em
relação ao prazo de repasse e taxas sobre às Transações, as quais serão
estabelecidas como parâmetro para os Emissores que participam ou que vierem a
participar da Rede de Captura durante a vigência do Contrato, a fim de auxiliar na
relação do Varejo com os Emissores que fazem ou farão parte de tal Rede de Captura
no âmbito da Parceria.
<br/>3.7. A Delivery Pay poderá, a qualquer tempo, efetuar nova análise e optar por
manter ou não o Estabelecimento Comercial credenciado, respeitados os itens 3.3 e
<br/>3.4.
<br/>3.8. Após a aceitação e inclusão do Estabelecimento Comercial na Rede de Captura,
o que ocorre quando o Varejo da Rede Souza Cruz, tendo anuído ao Termo de Adesão
ao Conecta Prime nesta data, celebra este Instrumento de Contratação de Serviços
de Pagamento com a aceitação do Regulamento de credenciamento, de forma
eletrônica, serão geradas:
a) Ordem de instalação, envio ou homologação do Equipamento contratado.
c) A disponibilização, por meio eletrônico, via e-mail ou Portal Conecta Prime, dos
dados cadastrais, dados do Domicílio Bancário, Produtos disponibilizados e/ou
contratados e prazos de pagamento, conforme definido no Regulamento (Anexo
I). Tais informações deverão ser conferidas pelo Varejo. Em caso de divergência,
o Estabelecimento deverá entrar em contato pela Central de 1º Nível da Souza
Cruz por meio do número 0800 7 23 22 21 solicitando a regularização.
<br/>3.8.1. O Varejo acessará as informações acerca da Parceria com a Delivery Pay por
meio do Portal Conecta Prime. O Varejo é integralmente responsável por seu login e
senha junto à Souza Cruz, devendo mantê-los em absoluto sigilo e reconhece que
seu login e senha são pessoais e intransferíveis, respondendo, perante a Souza Cruz
e a Delivery Pay e terceiros, por quaisquer prejuízos ocasionados em razão do
compartilhamento de tais dados.
<br/>3.9. A ocorrência dos eventos acima (cláusula 3.8) implica a concordância do Varejo
com todos os termos e condições deste Contrato.
<br/>3.10. O Varejo poderá vincular um ou mais Equipamentos, sob sua guarda e
responsabilidade, ao seu cadastro na Rede de Captura. A Delivery Pay avaliará o
pedido conforme seus próprios critérios, podendo aprová-lo ou recusá-lo.
<br/>3.11. O Varejo se obriga a informar imediatamente qualquer alteração cadastral à
Souza Cruz, por meio do Portal Conecta Prime, que se comprometerá a informar tal
alteração à Delivery Pay a fim de manter seu cadastro sempre atualizado para a
prestação dos serviços do Contrato.
<br/>3.11.1. O Varejo reconhece e concorda que deverá realizar Transações de acordo
com as atividades e informações concedidas à Souza Cruz e à DELIVERY PAY no
momento do credenciamento, fornecidas por meio do Termo de Adesão ao Conecta
Prime. Caso haja alteração de atividade do Varejo, a Delivery Pay se reserva o direito
de rescindir imediatamente o Contrato.

<br/>3.12. Os Produtos Adicionais e serviços previstos na cláusula 6.25.1 deste
Contrato, ou outros que eventualmente serão criados pela Delivery Pay durante a
vigência do Contrato, podem ser oferecidos Varejo de forma remota, mediante
aprovação prévia e por escrito da Souza Cruz, com formalização através de ligação
telefônica gravada, sendo que a adesão do Estabelecimento se ratificará quando da
realização de qualquer Transação do Produto que, inclusive, resultará na aceitação
de todos os termos e condições do referido Produto, registrado em anexo específico
que, assim que disponibilizado, seja através de registro em cartório, meio eletrônico
ou qualquer outro adequado, passa a fazer parte deste Contrato.
<br/>3.13. O presente Termo de Adesão passa a vigorar, com aceite pleno de todas as
suas cláusulas e condições, no momento em que o Varejo anuir com este Termo de
Adesão no Portal Conecta Prime.
<br/>3.14. O Varejo compromete-se a seguir todas as regras e exigências determinadas
pela Delivery Pay e pela legislação.
<br/>3.15. O Varejo autoriza a Delivery Pay, sempre que esta julgar necessário, após
comunicação prévia à Souza Cruz por escrito, a vistoriar durante o horário comercial,
diretamente ou por terceiros autorizados, os seguintes itens:
a) Regularidade e permanência de suas atividades.
b) Adequação da sinalização de uso obrigatório.
c) Regularidade na realização das Transações.
d) Funcionamento e conservação dos Equipamentos.
e) Adequada guarda, consumo e abastecimento de todo e qualquer material
operacional.
<br/>4. DO EQUIPAMENTO
<br/>4.1. A DELIVERY PAY fornecerá Equipamentos ao Estabelecimento, mediante
comodato, bem como pode também homologar Equipamentos de outras empresas
para integração à Rede de Captura que, assim que devidamente instalados, passam
a estar sob a guarda e responsabilidade do Estabelecimento.
<br/>4.2. Os Equipamentos são fornecidos ao Varejo com as devidas orientações para
manuseio. Em caso de dúvidas, o Varejo tem à disposição o Portal Conecta Prime,
ou ainda poderá entrar em contato com a Central de Atendimento.
<br/>4.3. A instalação e desinstalação e/ou manutenção do Equipamento pode ser
realizada pela Delivery Pay ou por terceiros por ela indicados e autorizados.
<br/>4.4. O Varejo deve se responsabilizar pelo tipo de Equipamento que seja obrigado
a utilizar em virtude da legislação específica, bem como pelo pagamento de todos os
tributos e contribuições decorrentes da utilização do Equipamento, não se
responsabilizando a Delivery Pay por qualquer situação que venha a ser imposta em
função da escolha e utilização do Equipamento pelo Varejo.
<br/>4.5. No caso de fornecimento de equipamentos pela Delivery Pay, não será cobrado
nenhum valor do Varejo pelo fornecimento.
<br/>4.6. São partes integrantes do equipamento cedidos ao Varejo os periféricos /
acessórios tais como: (i) tampa traseira; ii) bateria; (iii) fonte de energia entre outros
que possam existir em função de alteração ou lançamento de novos modelos de
equipamentos.

<br/>4.7. Em relação aos Equipamentos, o Varejo obriga-se a:
a) Quando aplicável, ter disponível acesso à internet para a instalação e uso dos
Equipamentos, arcando com as respectivas despesas de funcionamento;
b) No caso de Terminais POS, portáteis ou não, manter os mesmos sempre
prontos para realizar transações, arcando com as despesas de funcionamento
relativas ao consumo de energia elétrica.
c) Conferir, no momento da instalação ou manutenção do Equipamento, os dados
impressos no Comprovante de Venda (SLIP) emitido pelo Equipamento, incluindo,
mas não se limitando, ao seu nome fantasia e CNPJ. O Varejo reconhece e
concorda, de forma expressa e irrevogável, que a Delivery Pay e a Souza Cruz não
são responsáveis perante o Varejo, em caso de utilização de Equipamento de
terceiro, incluindo as Transações efetuadas por meio do respectivo Equipamento,
pelo Varejo, em qualquer hipótese, ainda que de forma culposa ou dolosa;
d) Usar os Equipamentos corretamente, respeitando o Manual Operacional,
respondendo pelos custos de instalação, conserto e manutenção dos
Equipamentos de sua propriedade, bem como dos Equipamentos locados ou
cedidos em comodato, na hipótese de sua quebra ou falha, decorrentes de uso
e/ou instalação e/ou manuseio indevidos por seus empregados, prepostos ou
terceiros, incluindo neste manuseio indevido o rompimento dos lacres da bateria
ou do chip GSM.
e) Manter os Equipamentos no local de instalação ou em outro local autorizado
expressamente pela Delivery Pay, não podendo ceder, sublocar, transferir ou
alienar, total ou parcialmente, nenhum Equipamento.
f) não transportar os Equipamentos de propriedade da Delivery Pay para local
diverso do endereço informado pelo Varejo para a instalação (inclusive para outra
Unidade Comercial que o Varejo tenha registrado no Termo de Adesão celebrado
com a Souza Cruz e fornecido à Delivery Pay), ou utilizar Equipamento de outro
Varejo, responsabilizando-se, ainda, por quaisquer penalidades e Perdas
ocasionadas à Delivery Pay nesse sentido.
g) Adotar todas as providências e cautelas necessárias para manter a guarda, a
integridade e a perfeita conservação e funcionamento dos Equipamentos, sendo
vedado ao Varejo realizar qualquer reparação ou modificação em tais
Equipamentos, comprometendo-se a comunicar imediatamente à Central de
Atendimento da Souza Cruz qualquer intervenção ou violação por terceiros de
quaisquer dos seus deveres relativamente ao Equipamento.
h) Reconhecer e concordar que os softwares aplicativos cedidos ou inseridos nos
Equipamentos, de forma gratuita ou onerosa pela Delivery Pay, são de titularidade
da Delivery Pay e incorporam a propriedade intelectual da mesma, podendo o
Varejo apenas fazer uso deles, comprometendo-se a não ceder, copiar, alterar,
modificar, adaptar, manipular, adicionar, decompilar, decompor ou efetuar
qualquer conversão dos softwares, sendo vedado também o uso de engenharia
reversa ou utilização para fins diversos dos previstos no presente Contrato, sob
pena de imediata rescisão do Contrato, sem prejuízo do ressarcimento por
eventuais perdas e danos acarretados.
i) Assumir a responsabilidade pelo pagamento do valor do Equipamento, nos
seguintes casos: furto, roubo, perda total ou parcial, incêndio, destruição total ou
parcial, falta de solicitação de assistência técnica, descuido no manuseio, retenção
ou qualquer outro fato ou evento que impossibilite, dificulte ou prejudique o direito
de propriedade sobre os Equipamentos, além da responsabilidade pela apreensão,
remoção, bloqueio, lacre, confisco ou leilão dos Equipamentos por quaisquer
órgãos ou autoridades, desde que tenha dado causa a tais eventos, e pelo custo
de reparo, substituição ou liberação, bem como eventuais multas e penalidades
impostas. Nesse caso, as partes acordam que deverá ser pago o valor de
R$1.200,00 (mil e duzentos reais) por equipamento.

j) Em casos de furto ou roubo, incêndio ou destruição total ou parcial, além de
arcar com os custos dos equipamentos, o Varejo deverá apresentar à Delivery Pay
o respectivo Boletim de Ocorrência ou laudo específico, onde deve constar,
obrigatoriamente, dados que identifiquem o Equipamento.
k) Comunicar imediatamente à Central de Atendimento da Souza Cruz caso haja
suspeita de fraude ou fraude confirmada no Equipamento ou, ainda, casos que
tome conhecimento de vazamento de dados ou informações de Portadores,
indicando todas as características do Equipamento.
<br/>4.8. A Delivery Pay providenciará a correção dos defeitos de funcionamento dos
Equipamentos ou a troca dos mesmos, se houver necessidade, exceto nos casos
comprovados de mau uso pelo Varejo.
<br/>4.9. Os custos para reparos de Equipamentos ou periféricos, em decorrência de mau
uso pelo Varejo, serão integralmente suportados pelo Varejo que, desde já, autoriza
a Delivery Pay a determinar que os Emissores descontem os valores diretamente
sobre o REPASSE a que o Varejo fizer jus perante qualquer Emissor que utiliza a Rede
de Captura, podendo a Delivery Pay, ainda, , cobrar estes valores através de débito
direto no domicilio bancário do Varejo ou emitir boleto contra o Varejo para cobrança
de tais custos.
<br/>4.9.1. A determinação de condição de “mau uso” será feita pela própria Delivery
Pay em seus laboratórios próprios, em laboratórios dos fabricantes, ou laboratórios
de terceiros homologados pela Delivery Pay, os quais deverão emitir laudo com o
detalhamento da identificação da condição.
<br/>4.9.2. Em todos os casos, será sempre disponibilizado ao Varejo, através de
sistemas WEB ou envio por meio eletrônico, uma cópia do laudo anteriormente
mencionado.
<br/>4.10. O Varejo se compromete a realizar todas as atualizações necessárias nos
equipamentos, que estejam sob sua responsabilide, para permitir a adequada
execução deste Contrato e oferecer maior segurança às Transações realizadas na
Rede de Captura, nos prazos estabelecidos pela Delivery Pay.
<br/>4.11. O Varejo deve cumprir e manter-se aderente às regras deste Contrato e
Regulamento Anexo, às regras do mercado de meios de pagamento.
<br/>4.12. A Delivery Pay providenciará, através de empresa autorizada, a manutenção
ou a troca do Equipamento, se houver necessidade. A manutenção preventiva e
corretiva só será realizada pela Delivery Pay.
<br/>4.13. A manutenção pode ser realizada remotamente e, havendo necessidade de
reparo físico, o Equipamento deverá ser enviado para a Delivery Pay através de
retirada no local da instalação por empresa autorizada pela Delivery Pay. No caso de
identificação de mau uso, retirada prematura, débitos pendentes, ou qualquer outro
motivo que cause desequilíbrio financeiro gerando custos para a Delivery Pay, todas
as despesas da manutenção, inclusive os custos de retirada e nova instalação,
deverão ser suportados pelo Varejo e serão cobrados nas formas já tratadas nesse
instrumento.
<br/>4.14. O Varejo deve facilitar o acesso dos técnicos aos locais de instalação dos
Equipamentos. Caso seja solicitada pelo Varejo a prestação de quaisquer dos serviços
previstos nesta Cláusula fora do horário comercial, fica a exclusivo critério da Delivery
Pay sua execução. 

<br/>4.15. O Varejo reconhece o direito da Delivery Pay de efetuar interrupções no
fornecimento dos serviços e desde já declara que tem conhecimento pleno de que os
serviços poderão, eventualmente, ser afetados, ou temporariamente interrompidos
por motivos técnicos, em razão de reparo, manutenção ou troca de Equipamento,
razão pela qual a Delivery Pay não garante a continuidade ininterrupta da prestação
de serviços, bem como não garante que seus serviços estarão livres de falhas ou
defeitos.
<br/>4.16. A Delivery Pay não se responsabiliza por eventuais falhas, atrasos ou
interrupções na prestação de serviço decorrente de caso fortuito ou motivos de força
maior, bem como por limitações impostas por parte do Poder Público ou da atuação
de Operadoras de serviço de telecomunicações interconectadas à rede do Varejo, por
qualquer falha ou indisponibilidade dos Emissores, ou, ainda, por má utilização do
serviço pelo Varejo ou por qualquer outro fato alheio à Delivery Pay.
<br/>4.17. O Varejo pode solicitar a qualquer momento o cancelamento do seu cadastro
e a desinstalação dos Equipamentos, observadas as condições previstas no
Regulamento Anexo e neste Termo de Adesão. Em qualquer hipótese de rescisão do
Contrato, o Varejo compromete-se a devolver o(s) Equipamento(s) que não sejam
próprios do Estabelecimento, através de retirada no local por empresa autorizada da
Delivery Pay, no prazo de 05 (cinco) dias úteis contados da data da comunicação da
rescisão, com seus respectivos Periféricos, no mesmo estado em que os recebeu,
salvo desgaste natural, sendo, após análise laboratorial feita pela Delivery Pay ou
terceiros por ela autorizada, sendo detectado desgaste ou dano por mau uso, todos
os custos serão cobrados do Varejo.
<br/>4.17.1. Na hipótese de não devolução ou perda do Equipamento, o Varejo será
constituído em mora ex re para todos os fins de direito, independentemente de
qualquer comunicação da Delivery Pay neste sentido, obrigando-se a reembolsar a
Delivery Pay no valor integral do Equipamento, além de toda e qualquer Perda que a
Delivery Pay venha a incorrer, sendo permitido à Delivery Pay cobrar ou proceder à
retenção e/ou compensação de valores devidos ao Varejo, sem prejuízo da cobrança
da respectiva Remuneração, até que a Delivery Pay seja devidamente restituída.
<br/>4.18. Sem prejuízo das condições acordadas pelas Partes, conforme estipulado nas
cláusulas seguintes, o Estabelecimento adere, nesta data, sem restrições, a todos os
termos e condições previstos neste Contrato, bem como outras disposições gerais
editadas pela Delivery Pay durante a vigência do Contrato e comunicadas ao Varejo,
que digam respeito às transações online ou contratação de serviços através de
subadquirentes.
<br/>4.19. O Varejo, assumindo total e exclusiva responsabilidade por consumidores e
por usuários de seus serviços, inclusive com relação a eventuais reclamações,
processos administrativos e judiciais ajuizados em face da Delivery Pay, ficando o
Varejo responsável por indenizar as demais Partes e arcar com todos e quaisquer
custos incorridos por esta, incluindo, sem se limitar a, condenações, multas,
penalidades, custas, honorários, dentre outros.
<br/>5. REALIZAÇÃO DAS TRANSAÇÕES
<br/>5.1. No momento da realização da Transação, o Varejo deve, minimamente e
obrigatoriamente:
a) Verificar se o Cartão não apresenta sinais de adulteração.
b) Tipos de meio de identificação para as Transações na Rede de Captura:
i. Cartão com Chip e Senha;

ii. Cartão com tarja magnética e senha;
c) Cumprir todos os procedimentos, padrões e normas exigidas neste Contrato,
sendo que a Delivery Pay não se responsabilizará pelas Transações em desacordo
com o aqui disposto ou qualquer outra regra existente ou que venha a ser criada
dos Emissores.
<br/>5.2. Nas Transações em que não houver digitação de Senha, o Varejo sempre
colherá a assinatura do Portador na via do Comprovante de Venda que ficará com o
Varejo e a conferirá com as constantes do Cartão e documento de identificação
pessoal. Obriga-se, ainda, o Varejo, a:

a) Verificar as características de segurança do Cartão e data de validade;
b) Confrontar se os dados impressos no Comprovante de Transação conferem com
os dados do Cartão utilizado, como, exemplificativamente, nome do Portador e
número do CARTÃO, que podem aparecer transcritas integral ou parcialmente;
c) Entregar ao Portador a "via do cliente" do Comprovante de Transação.
<br/>5.3. O Varejo está impedido de impor condições e/ou restrições ao pleno uso e à
aceitação de Cartão e Meios de Pagamento, sendo-lhe expressamente vedado efetuar
qualquer discriminação relativamente a quaisquer meios de pagamento autorizados
pela Delivery Pay para utilizar a Rede de Captura.
<br/>5.4. A realização de qualquer Transação deverá dar-se segundo o previsto neste
Contrato e demais condições e regras operacionais e de segurança fixadas e em vigor
pela Delivery Pay, quando editadas durante a vigência do Contrato e comunicadas
ao Varejo.
<br/>5.6. Estarão sujeitas ao não processamento as Transações irregularmente
realizadas pelo Varejo, sob quaisquer modalidades, de forma conivente ou não, em
circunstâncias que caracterizem indícios ou suspeita de fraude que objetivem a
obtenção de vantagens ilícitas ou estejam em desacordo com este Contrato.
<br/>5.7. O Varejo está ciente que será descredenciado, após comunicação pela Delivery
Pay por escrito à Souza Cruz, caso suspeitas ou irregulares conforme definição e
regras de monitoramento de fraude da Delivery Pay, exceto se a Delivery Pay
entender que é possível reverter a situação, arcando sempre o Varejo com os
prejuízos causados e assumindo determinadas obrigações, a serem formalizadas em
documento próprio. Nesta hipótese, a recusa do Varejo em assinar documento que
regularize a situação configurará em rescisão do presente Contrato.
<br/>5.8. O Varejo está ciente e concorda com os métodos legais que a Delivery Pay vier
a adotar para identificar e prevenir fraudes e práticas ilícitas, comprometendo-se o
Varejo a monitorar e orientar seus funcionários, bem como cooperar e colaborar,
principalmente no fornecimento das informações solicitadas, sob pena de
ressarcimento, pelo Varejo, nos termos deste Contrato e rescisão do mesmo.
<br/>5.9. O Varejo, na consecução de suas atividades e realização de Transações, não
poderá utilizar recursos tecnológicos, hardware, software ou qualquer outra
tecnologia não homologada ou não autorizada pela Delivery Pay e/ou que venha
trazer riscos de fraude ou segurança e que estejam em desacordo com as normas e
padrões da indústria de Cartões e Meios de Pagamento.
<br/>5.10. O descumprimento desta Cláusula pelo Varejo autorizará a Delivery Pay a
rescindir este Contrato por justa causa, após comunicação por escrito à Souza Cruz,
sem prejuízo do ressarcimento pelo Varejo, das perdas e danos resultantes para a 

Delivery Pay bem como eventuais custos com manutenção, perda ou roubo de
equipamento.
<br/>5.11. O Varejo é o exclusivo responsável por solucionar, diretamente com os
Portadores e Contratantes, toda e qualquer eventual controvérsia sobre as
características, qualidade, quantidade, propriedades, origem, preço, funcionamento,
garantias, defeitos e/ou avarias dos bens e produtos adquiridos e/ou serviços
prestados, incluindo casos de defeito ou devolução, problemas de entrega etc. A
Delivery Pay e a Souza Cruz são isentas de quaisquer responsabilidades
convencionais ou legais em relação aos fatos mencionados nesta Cláusula, inclusive
com relação ao Código de Proteção e Defesa do Consumidor.
<br/>5.12. O Varejo está ciente e concorda expressamente que a responsabilidade da
Delivery Pay limita-se à execução das obrigações descritas neste Contrato como
subcredenciadora participante do arranjo de pagamento, sendo certo que quaisquer
obrigações ou ônus decorrentes, direta ou indiretamente, de quaisquer
procedimentos administrativos ou judiciais, resultantes de eventual descumprimento
de qualquer obrigação principal ou acessória por parte do Varejo e promovida por
qualquer órgão federal, estadual ou municipal competente, deverão ser suportados
integralmente pelo Varejo.
<br/>5.13. Na hipótese de a Delivery Pay despender quaisquer valores em razão do
disposto no item 5.12 acima será aplicado o procedimento de cobrança ao Varejo em
quaisquer das formas previstas neste Contrato.
<br/>5.14. O Varejo autoriza expressamente a Delivery Pay, desde já, a lhe repassar
quaisquer despesas para o cumprimento de ordem de terceiro com relação ao Varejo,
incluindo, sem limitar-se, ao atendimento de ofícios judiciais.
<br/>5.15. O Varejo é responsável pela guarda, em local seguro e protegido de
desgastes, dos documentos relacionados às Transações efetivadas, assim como é o
responsável único pelas Transações eventualmente não reconhecidas pelos
Portadores.
<br/>5.16. Dentre os documentos relacionados às Transações, conforme disposto no
item anterior, deve o Varejo guardar as notas fiscais e documentação que comprove
a entrega dos bens adquiridos ou dos serviços prestados pelo prazo de 12 (doze)
meses a contar da data da Transação, cabendo-lhe fazer a entrega de todos esses
documentos ou de qualquer deles à Delivery Pay e/ou ao Emissor em até 5 (cinco)
dias úteis a contar da solicitação, ciente o Varejo de que no caso de não os entregar
no prazo acima fixado, ou de os entregar estando ilegíveis ou incorretos, ele estará
sujeito ao estorno do valor da Transação.
<br/>5.17. O Varejo poderá requerer o cancelamento de Transações, ciente de que o
modo e o processo de cancelamento serão determinados exclusivamente pelo
Emissor.
<br/>6. REPASSE E LIQUIDAÇÃO DAS TRANSAÇÕES
<br/>6.1. O Varejo está ciente que a adesão ao presente Contrato implica a contratação
da Delivery Pay para prover a Rede de Captura, processamento e roteamento de
transações eletrônicas no âmbito do Programa Conecta Prime. O Repasse do Valor
Líquido das Transações, na forma e prazos definidos no Regulamento e respectivas
alterações, é de responsabilidade e se dará pelo Emissor, mediante crédito do
respectivo valor no Domicílio Bancário indicado pelo Varejo, bem como por qualquer
outro meio de pagamento admitido por este Contrato ou acordado entre as Partes

mediante autorização da Souza Cruz, aí incluída a compensação, desde que a
Transação tenha sido realizada de acordo com o previsto neste Contrato.
<br/>6.2. O Varejo reconhece que só poderá realizar questionamento em relação a
qualquer repasse do Valor Líquido, diretamente ao Emissor e mediante comprovação
da efetivação da transação, o que se dará através de relatório e/ou informações
geradas no Portal Conecta Prime ou no Portal do Emissor.
<br/>6.3. O prazo de repasse será contado a partir da data transação de fechamento das
transações conforme especificado no Termo de Adesão de cada Emissor, observados
os prazos para repasse previstos na cláusula 6.13.
<br/>6.4. Questões relacionadas à regularização do Domicílio Bancário, data de
pagamentos e/ou repasses, cancelamento e/ou estornos de transações que não
puderem ser feitas diretamente pelos terminais de captura, deverão ser tratadas
diretamente pelo Estabelecimento Comercial com cada Emissor, apenas comunicando
à Souza Cruz por meio do Portal Conecta Prime ou da Central de Atendimento da
Souza Cruz, a quem caberá informar a Delivery Pay as eventuais alterações.
<br/>6.5. Mediante a adesão do Varejo a este Contrato, o Varejo expressamente autoriza,
de forma irrevogável e irretratável, que o banco de seu Domicílio Bancário efetue por
ordem da Delivery Pay ou de qualquer um dos Emissores que utilizam a Rede de
Captura, em seu Domicílio Bancário, lançamentos a crédito, débito, estorno de
valores e outros previstos neste Contrato, independentemente de prévia consulta ao
Varejo ou de qualquer outro ato ou formalidade legal ou documental.
<br/>6.6. Na hipótese de o mercado de meios de pagamento implementar em conjunto
novo modelo de manutenção de Domicílios Bancários, o Varejo desde já concorda
que a Delivery Pay e os Emissores poderão enviar as informações necessárias para a
entidade independente que as centralize, nos termos da legislação e regulamentação
vigentes.
<br/>6.8. Afim de auxiliar o Varejo na relação com os Emissores, a Delivery Pay poderá
disponibilizar ao Varejo o extrato das Transações realizadas através da Rede de
Captura, mediante acesso ao Portal Conecta Pay ou através do terminal de captura.
<br/>6.9. O Varejo ainda reconhece e aceita que é responsável pelos estornos, débitos,
cancelamento e/ou chargebacks das Transações, nos termos do Contrato,
independentemente da culpa e/ou dolo, bem como pelas multas impostas pelas
Bandeiras em razão de suas condutas.
<br/>6.10. O Varejo deverá solucionar diretamente com seus usuários toda e qualquer
controvérsia sobre os bens e serviços fornecidos, incluindo casos de defeito ou
devolução, problemas na entrega, etc., isentando a Delivery Pay e a Souza Cruz de
qualquer responsabilidade relativa a esses bens e serviços, inclusive com relação ao
Código de Proteção e Defesa do Consumidor e demais legislações aplicáveis, bem
como a indenizando em caso de imputação de responsabilidade pelas situações aqui
previstas.
<br/>6.11. O Varejo é responsável por quaisquer problemas de aceitação, quantidade,
qualidade, garantia, preço ou não atendimento dos produtos e/ou serviços oferecidos
em sua loja, inclusive em caso de arrependimento por parte do Usuário e devolução
do pagamento.
<br/>6.12. É de responsabilidade exclusiva do Varejo a confirmação do despacho e
entrega do produto e/ou execução do serviço adquirido(s) pelo Usuário. O Varejo
comunicará o Usuário em caso de não atendimento da TRANSAÇÃO. 

<br/>6.13. Com relação aos repasses dos valores das Transações, cabe à Delivery Pay
destinar os valores ao Estabelecimento nos prazos de 1 (um) dia nas transações da
modalidade débito e 30 (trinta) dias nas transações da modalidade crédito.
<br/>6.13.1. O prazo de pagamento será contado a partir da data da captura da
Transação, de acordo com os termos e condições previstos neste Contrato, bem como
quaisquer regras aplicáveis pelas Bandeiras ou autoridades reguladoras.
<br/>6.13.2. Caso a data prevista para o pagamento do Valor Líquido da Transação
coincida com feriado ou dia de não funcionamento bancário, o pagamento será
realizado no primeiro Dia Útil subsequente.
<br/>6.13.3. Nas hipóteses de falha técnica e/ou operacional nos sistemas e/ou quebra
de Equipamentos, a Delivery Pay poderá, a seu único e exclusivo critério, e sem
incorrer em qualquer ônus ou penalidade, exceder o prazo estabelecido para efetuar
o pagamento relativo a qualquer tipo de Valor Líquido.
<br/>6.13.4. O Varejo deverá zelar pela regularidade do domicílio bancário,
responsabilizando-se pela correção e suficiência das informações prestadas à
Delivery Pay.
<br/>6.13.5. O Varejo terá o prazo de até 90 (noventa) dias a contar da data prevista
para a realização do pagamento pela Delivery Pay, para apontar qualquer divergência
em relação aos valores pagos. Findo tal prazo, não caberá qualquer reclamação por
parte do Estabelecimento e ocorrerá a quitação automática, irrevogável, irretratável
e definitiva quanto aos referidos valores.
<br/>6.14. Se o Varejo não cumprir com todas as suas obrigações constantes do Contrato,
ainda que a Transação tenha recebido um Código de Autorização, o seu valor não
será repassado ou, se já tiver sido repassado, ficará sujeito a estorno. Essa regra
também será aplicada nas seguintes situações:
(i) se a Transação for cancelada pelo Varejo ou pela Delivery Pay a pedido do
Varejo;
(ii) se a controvérsia sobre os bens e serviços fornecidos, incluindo, mas não
se limitando a serviços não prestados, mercadoria não entregue ou ainda
casos de defeito ou devolução, não for solucionada entre o Varejo e o Usuário
ou se o Usuário não reconhecer ou discordar da Transação;
(iii) se houver erro de processamento da Transação, incluindo, mas não se
limitando, a digitação de número do Cartão incorreto, valor incorreto,
duplicidade de submissão ou de Código de Autorização de uma mesma
Transação, processamento de moeda incorreto, etc.;
(iv) se o Varejo não apresentar a Transação para a Delivery Pay em um prazo
máximo de 5 (cinco) dias a contar da data do fornecimento do Código de
Autorização;
(v) se dentro do prazo previsto a Transação não for comprovada pela exibição
do Comprovante de Venda e/ou do respectivo comprovante de entrega de
mercadoria ou serviço e/ou dos outros documentos que venham a ser exigidos
pela Delivery Pay conforme o Meio de Pagamento utilizado e/ou Transação
realizada;
(vi) se o Comprovante de Venda estiver ilegível, rasurado, adulterado ou 

danificado, ou ainda, se os seus campos não estiverem corretamente
preenchidos;
(vii) se for Comprovante de Venda duplicado, falsificado ou copiado de outro;
(viii) se houver ordem de autoridade legítima impedindo o repasse ou
determinando o bloqueio, penhora, arresto, custódia ou depósito dos créditos
do Varejo;
(ix) se houver erro no processo de obtenção de Código de Autorização da
Transação, ou em caso de Código de Autorização negado, ou se a TRANSAÇÃO
não tiver um Código de Autorização válido na data da venda;
(x) se o Usuário não reconhecer a Transação, ainda que tenha sido conferido
a ela um Código de Autorização;
(xi) se o Varejo realizar Transação suspeita ou irregular ou ainda atingir ou
exceder o percentual de Transações suspeitas ou irregulares de acordo com
as escalas pré-definidas pela Bandeira;
<br/>6.15. Em caso de contestação ou não reconhecimento da Transação pelo Usuário
quanto à não conformidade da Transação, incluindo, mas sem se limitar, a entrega
incompleta ou apresentação de defeito, ou se o Varejo não cumprir com qualquer
das condições previstas, a Delivery Pay deixará de creditar o valor do repasse ao
Varejo, ou caso já o tenha creditado, procederá ao cancelamento da Transação e/ou
estorno do respectivo valor nos termos do Contrato.
<br/>6.16. Em caso de cancelamento e/ou estorno em favor da Delivery Pay, o valor da
Transação cancelada ou estornada, que deverá ser restituído pelo Varejo, deverá ser
atualizado pelo IGP-M/FGV (ou índice que o substitua) desde a data de repasse, mais
juros de 1% (um por cento) ao mês ou fração pro-rata, acrescido dos encargos
operacionais e perdas e danos incorridos.
<br/>6.17. O Varejo deverá monitorar os seus funcionários, contratados e prepostos,
sendo responsável por todos os atos praticados por estes, e realizar a manutenção
sistemas para fins de aplicar os métodos de detecção e prevenção de fraudes.
<br/>6.18. Além do cumprimento de todas as obrigações previstas no Contrato e no
Regulamento (Anexo I), o Varejo aceita, concorda e se compromete ainda a:
i. cumprir a legislação em vigor, incluindo, sem se limitar a, legislação relativa
à prevenção dos crimes de lavagem de dinheiro e ao financiamento de
terrorismo;
ii. monitorar todas as Transações realizadas de forma a identificar eventuais
Transações fraudulentas e/ou irregulares;
iii. Acessar e guardar durante toda a vigência deste Contrato e dos contratos com
os usuários e Varejo final e por mais 5 (cinco) anos após o término destes,
todos os documentos e informações relacionadas às Transações realizadas;
iv. Colaborar de forma efetiva com a Delivery Pay, com as Bandeiras, com as
autoridades, órgãos de regulação e/ou fiscalização, no fornecimento de dados
e informações relativas às Transações, incluindo, mas sem se limitar, ao que
se refere à prevenção e combate aos crimes de lavagem de dinheiro e
ocultação de bem e aos crimes contra crianças e adolescentes. 

<br/>6.18.1. Fica estabelecido que a Delivery Pay poderá auditar o cumprimento das
obrigações acima e as demais obrigações previstas no Contrato e no Regulamento
por parte do Varejo, após comunicação à Souza Cruz.
<br/>6.19. O presente Contrato poderá ser rescindido pela Delivery Pay, mediante prévia
e expressa autorização pela Souza Cruz, caso identifique que Transações realizadas
no Varejo foram fraudulentas, ficando ainda o Varejo sujeito a penalidades previstas
neste Contrato e demais medidas judiciais.
<br/>6.20. Cada uma das partes se obriga, sob pena de indenização por perdas e danos
e aplicação de multa, a manter em absoluto sigilo e confidencialidade, usando
somente para os fins deste Contrato, todas as informações, dados ou especificações
a que tiver acesso ou que porventura venha a conhecer ou ter ciência sobre as
Transações, Usuários, dados de Cartões e Meios de Pagamento e condições
comerciais deste Contrato, sem prejuízo das obrigações de revelação ou dos reportes
exigidos em lei ou por ordem judicial.
<br/>6.21. Neste ato, o Varejo expressamente autoriza que a Delivery Pay preste às
autoridades competentes como, por exemplo, Banco Central do Brasil, Receita
Federal, Secretarias das Fazendas Estaduais, Secretarias de Arrecadação Municipais,
Conselho de Controle de Atividades Financeiras, Polícia Federal etc., todas as
informações que forem solicitadas com relação ao Varejo e operações por ele
executadas sob este Contrato, bem como informações a entidades que se destinem
a controlar garantias que envolvam recebíveis, quando a legislação e a
regulamentação setorial exigirem.
<br/>6.22. Salvo se disposto de forma diversa neste Contrato ou em lei, cada uma das
partes se compromete a manter, conservar e guardar todas as informações,
equipamentos e materiais que lhe sejam entregues ou a que tenha acesso da outra
parte em decorrência do presente Contrato, em local absolutamente seguro e com
acesso permitido somente a pessoas autorizadas, que também se obriguem a mantêlos em sigilo, nos termos aqui previstos.
<br/>6.23. O Varejo obriga-se a cumprir todos os requerimentos de segurança da
informação definidos pela Delivery Pay e/ou pela Bandeira, conforme versão mais
atualizada disponível. Nesse sentido, o Varejo deverá armazenar somente aqueles
dados de Transações, de Usuários e de Cartões que venham a ser autorizados pela
Delivery Pay. Essa obrigação de sigilo se manterá válida inclusive quando do término
por qualquer motivo do Contrato.
<br/>6.24. Sem prejuízo das demais cláusulas e condições constantes de Contrato, a
Delivery Pay e a Souza Cruz não se responsabilizarão por infração e/ou
descumprimento de qualquer legislação aplicável ao Varejo em suas operações ou
atividades, sendo que na hipótese de a Delivery Pay ou a Souza Cruz virem a sofrer
ou suportar qualquer perda e/ou prejuízo, por culpa ou dolo do Varejo, ficará o Varejo
obrigado a proceder ao reembolso, à Delivery Pay, de tais valores, incluindo, mas
sem se limitar, despesas relacionadas à custas administrativas e/ou judiciais, taxas,
emolumentos e honorários advocatícios, devidamente atualizados acordo com a
variação do índice IGP-M/FGV.
<br/>6.25. O Varejo declara ter ciência e concorda que a Delivery Pay mantém contratos
com diversas Empresas Parceiras, podendo, em nome delas, realizar eventuais
descontos diretamente dos valores a serem recebidos pelo Varejo, desde que, após
autorização escrita pela Souza Cruz, o Varejo tenha aderido à algum programa e/ou
adquirido algum produto ou serviço destas Empresas Parceiras.

<br/>6.25.1. A Delivery Pay poderá, mediante aprovação prévia e por escrito da Souza
Cruz, explorar outros produtos financeiros/de pagamento dos Varejos credenciados,
inclusive por meio das Empresas Parceiras, compreendidos como Produtos
Adicionais, a exemplo de: (a) antecipação de recebíveis; (b) cartões; e (c) venda de
produtos e serviços financeiros.
<br/>6.25.2. Estes valores descontados serão repassados diretamente às Empresas
Parceiras, servindo o comprovante de desconto nos recebíveis do Varejo como
comprovante de pagamento relacionado ao programa, serviço e/ou produto aderido
e/ou adquirido da Empresa Parceira.
<br/>6.25.2.1. O Varejo desde já autoriza a Delivery Pay a descontar dos valores a
serem recebidos o valor de R$ 120,00 (cento e vinte reais) por mês, referente ao
valor da mensalidade do Conecta Prime, e repassar para a Souza Cruz, caso o
Varejista opte por essa forma de pagamento.
<br/>6.25.3. A Delivery Pay se isenta de qualquer responsabilidade quanto a realização
de cobranças realizadas em duplicidade pelas Empresas Parceiras, devendo o Varejo,
caso isto ocorra, contatar diretamente referida Empresa Parceira, salvo os casos em
que a Delivery Pay, por sua culpa exclusiva, tenha dado causa comprovada à
cobrança em duplicidade e/ou desconto indevido do valor.
<br/>6.25.4. Ocorrendo a culpa exclusiva da Delivery Pay, esta se compromete a
solucionar o impasse no menor tempo possível e realizar, em até 30 (trinta) dias, a
devolução do valor descontado e/ou cobrado.
6.26. Na hipótese de eventuais divergências existente entre este Contrato e demais
termos vigentes, emitidos pela Delivery Pay, as Partes acordam que prevalecem as
disposições do Regulamento e do Termo de Adesão ao Conecta Prime celebrado entre
os Varejos e a Souza Cruz.
<br/>7. REAJUSTE DE TARIFAS E TAXAS
<br/>7.1. As taxas, preços e tarifas mencionadas neste Contrato, no Regulamento e seus
anexos ou aditivos serão reajustadas anualmente, com data base em janeiro de cada
ano, de acordo com a variação do IPC-A/IBGE, independentemente da data de
assinatura do presente contrato
<br/>8. CONFIDENCIALIDADE E SEGURANÇA DAS INFORMAÇÕES
<br/>8.1. O Varejo compromete-se a manter em sigilo os dados ou especificações a que
tiver acesso ou que venha a ter sobre Transações, Portadores, CONTRATANTES,
Emissores, Bandeiras e condições estabelecidas neste Contrato.
<br/>8.2. A Delivery Pay poderá comunicar ao COAF – Conselho de Controle de Atividades
Financeiras, as Transações que possam estar configuradas na Lei 9.613/98 e demais
normas relativas à lavagem de dinheiro.
<br/>9. COMPARTILHAMENTO DE INFORMAÇÕES 

<br/>9.1. O Varejo autoriza a DELIVERY PAY a verificar a exatidão dos dados pessoais e
informações cadastrais constantes do presente contrato, anexos e aditivos, bem
como, por qualquer meio idôneo, quer pessoalmente ou por empresas especializadas.
<br/>9.2. O Varejo autoriza expressamente a Delivery Pay a compartilhar com todos os
Emissores participantes da Rede de Captura as seguintes informações:
a) CNPJ do Varejo;
b) Dados cadastrais do Varejo;
c) Tipo de Estabelecimento;
d) Dados de contato do Varejo;
e) Condições definidas pelo Varejo em relação ao prazo de repasse e taxas sobre
Transações, estabelecidas neste Contrato firmado entre o Varejo e a Delivery Pay,
bem como no Regulamento anexo.
<br/>9.3. O Varejo, ao efetuar o preenchimento de informações no Termo de Adesão ao
Programa Conecta Prime e a adesão ao Benefício Conecta Pay, com a consequente
contratação de serviços de pagamento previstos neste Contrato, concorda com o
fornecimento de seus dados cadastrais e pessoais, bem como a aceitação de cookies
de navegação, para identificação do IP do usuário e controle interno de navegação.
<br/>9.4. O Varejo declara ciência e expressamente autoriza a Delivery Pay, nos termos
da Lei 12.965 de 23 de abril de 2014 a proceder à coleta, armazenamento, guarda e
tratamento de registros de conexão, acessos, transações, dados cadastrais, pessoais
ou de comunicações (observadas a preservação da intimidade, vida privada, honra e
imagem, bem como o sigilo das comunicações privadas nos termos da lei), os quais
serão fornecidos e/ou utilizados pela Delivery Pay, desde que com prévia autorização
da Souza Cruz, para finalidades como:
a) Comunicar o Varejo a respeito de novos produtos, promoções, notícias e
pesquisas da Delivery Pay e/ou da Souza Cruz;
b) Ações de marketing da Delivery Pay e/ou de seus clientes e parceiros;
c) Produtos e serviços da Delivery Pay e/ou Emissores parceiros, voltados para
captura, roteamento, transmissão, processamento e autorização das transações
de meios de pagamento;
d) Habilitação de Transações de novos Emissores que venham a participar da Rede
de Captura da Delivery Pay.
e) Oferta de serviços e produtos que possam ser utilizados aproveitando os
serviços de captura.
<br/>9.5. O Varejo poderá, a qualquer tempo, se opor ao compartilhamento de
informações que a Delivery Pay detenha sobre ele, com Emissores participantes da
Rede de Captura ou terceiros. Caso o Varejo revogue seu consentimento para as
atividades ligadas diretamente aos serviços objeto deste Contrato, os serviços não
lhe serão disponibilizados.
<br/>10. TRANSAÇÕES – NOVOS EMISSORES
<br/>10.1. O Varejo concorda que a Delivery Pay proporcione a entrada de novos
Emissores para utilização da Rede de Captura, bem como, com o compartilhamento
de informações cadastrais e avaliações do Varejo, conforme indicado na cláusula 10.2
acima, após comunicação prévia e por escrito da Souza Cruz, com todos os novos
Emissores que vierem a participar da Rede de Captura, desde que resguardada
segurança e confidencialidade.
<br/>10.2. Todos os novos Emissores que passarem a utilizar a Rede de Captura
assumirão as condições e regras estabelecidas pelo Varejo em termo de adesão 

firmado entre a Delivery Pay e o Varejo, após autorização prévia e por regras próprias
definidas entre o Varejo e o EMISSOR, para o repasse e liquidação das TRANSAÇÕES
realizadas no Varejo.
<br/>10.3. Todas as condições e regras para o repasse e liquidação das Transações
realizadas no Varejo por meios de identificação de novos Emissores poderão ser
negociadas e formalizadas através de Termo Aditivo entre o novo Emissor e o Varejo,
o que deverá ser formalmente comunicado à Delivery Pay através do Portal Delivery
Pay ou Central de Atendimento.
<br/>10.4. A falta de comunicação do Varejo à Delivery Pay sobre a alteração das regras
estabelecidas entre o Varejo e o Emissor, nos termos deste Contrato, acarretará a
manutenção dos parâmetros pré-estabelecidos anteriormente.
<br/>10.5. O Varejo, mesmo já credenciado pela Delivery Pay poderá optar pela não
aceitação de outras condições do Emissor, o que acarretará o descredenciamento e
não aceitação do Varejo em relação apenas às Transações para tal Emissor.
<br/>11. TÉRMINO E/OU RESCISÃO DO CONTRATO
<br/>11.1. O Varejo poderá, a qualquer tempo, sem motivação alguma, rescindir o
Contrato por meio da Plataforma Conecta Prime. Tal rescisão ocorrerá livre de direitos
indenizatórios, ônus, encargos ou penalidades, ressalvadas as obrigações contratuais
pendentes; e a SOUZA CRUZ será responsável por sinalizar à Delivery Pay acerca da
rescisão do Contrato.
<br/>11.2. Este Contrato poderá ser imediatamente rescindido, por justa causa, por
qualquer das Partes, nos seguintes casos:
a) Infração pela parte faltosa de qualquer das Cláusulas, termos ou condições
deste Contrato;
b) Fraude ou suspeita de fraude;
c) Decretação de falência, deferimento de pedido de recuperação judicial ou
proposição de recuperação extrajudicial ou declaração de insolvência de qualquer
das Partes;
d) Não aceitação pelo Varejo, de eventuais alterações efetuadas pela Delivery Pay
no presente Contrato ou inclusão de Aditivos;
e) Caso qualquer das Partes incorra em qualquer das hipóteses acima, a Parte que
rescindir este Contrato deverá encaminhar comunicação por escrito à outra, com
cópia para a Souza Cruz, a fim de que a Parte tome ciência inequívoca da rescisão,
o que produzirá seus efeitos desde o momento da sua ocorrência, contanto que a
prévia comunicação à Souza Cruz seja efetuada..
<br/>11.3. Constituirá igualmente justa causa de rescisão imediata, a exclusivo critério
da Delivery Pay, sem prejuízo do ressarcimento das perdas e danos eventualmente
acarretados, o não cumprimento pelo Varejo das obrigações estipuladas no Contrato,
especialmente se o Varejo praticar ou sofrer medidas, tais como, mas não limitadas
às que seguem:
a) Realizar Transações irregulares, ou em desacordo parcial ou total com os
termos e condições deste Contrato ou Contratos com Emissores;
b) Adotar ou permitir práticas que resultem, parcial ou totalmente, em preferência
por qualquer outro instrumento de pagamento, exclusão, condicionamento ou
limitação da utilização dos Cartões e Meios de Pagamento autorizados pela
Delivery Pay;

c) Ceder, transferir, emprestar, dar em caução ou garantia, entregar a terceiros,
sem autorização da Delivery Pay, os Equipamentos, aparelhos, software e
Materiais operacionais, bem como quaisquer direitos e obrigações decorrentes
deste Contrato;
d) Tornar-se inativo ou manter-se inativo, considerando-se inativo o Varejo que,
após 3 (três) meses com o terminal instalado, não realizar Transações mensais
que atinjam o valor de R$ 9.000 (nove mil reais). Neste caso, com o
consentimento do Varejo, a Delivery Pay poderá cobrá-lo, a título de aluguel
mensal, o valor de R$ 40,00 (quarenta reais).
<br/>11.4. O término ou rescisão do Contrato não exonera as Partes do cumprimento
pleno e irrestrito de todas as obrigações decorrentes deste Contrato, tais como, mas
não limitada, do Varejo pagar à Delivery Pay os valores eventualmente devidos na
forma deste Contrato e permitir a imediata retirada e/ou entrega dos Equipamentos,
software e todos os Materiais operacionais.
<br/>11.5. Obriga-se o Varejo, em qualquer hipótese de término ou rescisão deste
Contrato, a não mais utilizar, sob qualquer pretexto ou justificativa, as marcas e/ou
logotipos de propriedade das empresas descritas neste Contrato, bem como devolver
os Equipamentos, aparelhos, software e Materiais cedidos.
<br/>12. PROTEÇÃO DE DADOS
<br/>12.1. Caso o Varejo trafegue, processe ou armazene dados do Portador em seu
ambiente, seja em mídia física ou digital, este compromete-se a cumprir e manterse aderente às regras, incluindo, mas não se limitando àquelas emanadas pelo PCI
(Payment Card Industry) Security Standards Council ou qualquer norma posterior
que venha a regular a segurança de dados do portador de cartão no mercado de
Meios de Pagamento local e internacional, durante a vigência deste Contrato,
conforme prazos e condições definidas pela Delivery Pay.
<br/>12.1.1. A obrigatoriedade acima se estende a qualquer fornecedor contratado pelo
Varejo cuja atividade seja passível de tráfego, processamento ou armazenamento
dos dados do Portador.
<br/>12.1.2. O Varejo deve comunicar imediatamente a Delivery Pay e a Souza Cruz, no
prazo máximo de 24 (vinte e quatro) horas, caso tome conhecimento de vazamento
dos dados do Portador.
<br/>12.2. O Varejo se declara ciente de que a Delivery Pay não possui responsabilidade
quanto a criação e segurança do ambiente virtual do Varejo, nem mesmo pela forma
como se dá o acesso de clientes do Varejo a tal ambiente.
<br/>12.3. O Varejo é exclusivamente responsável por instalar e manter atualizados
sistemas e/ou dispositivos, bem como outros itens necessários a evitar a violação do
equipamento que terá acesso às soluções e serviços disponibilizados pela Delivery
Pay.
<br/>12.4. Além disso, o Varejo deve se certificar de que a configuração dos equipamentos
por ele utilizados, próprios ou de terceiros, atende aos requisitos mínimos de
segurança para uso das soluções e serviços disponibilizados pela Delivery Pay, de
modo que a Delivery Pay ficará isenta de qualquer responsabilidade referente à essa
questão.

<br/>12.5. O Varejo declara que leu e está ciente do conteúdo das normas de segurança
e privacidade da Delivery Pay, prevista no Portal Delivery Pay.
<br/>13. ALTERAÇÕES DO CONTRATO
<br/>13.1. A Delivery Pay, por qualquer documento, físico ou eletrônico, enviado ao
Varejo, por qualquer meio de transmissão ou comunicação ou ainda por
disponibilização no Portal Conecta Prime, poderá alterar ou aditar Cláusulas ou
condições deste Contrato ou incluir novos anexos, sempre em observância do
Regulamento e do Termo de Adesão ao Conecta Prime.
<br/>13.2. São partes e documentos integrantes deste Contrato e de seu objeto os
Anexos, o Regulamento do Conecta Pay e Termos Aditivos, em caso de conflito entre
tais documentos e este Contrato, prevalecerá o Contrato.
<br/>13.3. Fazem parte integrante do presente contrato, para todos os fins, sempre em
observância do Regulamento e do Termo de Adesão ao Conecta Prime:
a) Todos os Anexos e formulários mencionados no corpo deste instrumento e,
inclusive, aqueles não expressamente mencionados, porém editados na vigência
do Contrato, comunicados ao Varejo e disponibilizados pela Delivery Pay por meio
da Internet no endereço https://www.conectaprime.com.br, preenchidos e
enviados à Delivery Pay pelo Varejo também por meio da Internet;
b) As normas de segurança e privacidade adotadas pela Delivery Pay e que se
encontram no endereço https:// www.conectaprime.com.br.
<br/>13.5. Ao marcar a opção "Li e estou de acordo com os termos do contrato da
Delivery Pay” constante dos formulários fornecidos pela Souza Cruz no Portal Conecta
Prime, o Varejo está declarando ter lido e aceito, sem reservas, todas as cláusulas e
condições do presente Contrato.
<br/>14.5.1. Caso o VAREJO possua VAREJOS ASSOCIADOS no portal Conecta Prime,
esse contrato aplicar-se-á a todos os Varejos Associados.
<br/>13.6. Se o Varejo não concordar com as alterações comunicadas na forma acima,
deve contatar a Souza Cruz por meio do Canal de Atendimento da Souza Cruz e,
após o atendimento realizado pela Souza Cruz, poderá rescindir este Contrato, no
prazo de 10 (dez) dias, contados da conclusão do atendimento realizado pela Souza
Cruz. Caso o Varejo não se manifeste em tal prazo, comprovadamente, em contrário
a tais alterações, tal fato implicará na sua aceitação plena e irrestrita, sem prejuízo
da possibilidade de rescindir o Contrato, a qualquer tempo.
<br/>13.7. Durante a vigência deste Contrato, o Varejo poderá receber mensagens
eletrônicas da Delivery Pay referentes ao Programa Conecta Prime, de modo a
assegurar a execução contratual e pós-contratual referentes ao presente Contrato,
tais como avisos relacionados a alterações contratuais, atualização de tecnologia,
entre outros.
<br/>14. DISPOSIÇÕES GERAIS
<br/>14.1. Este Contrato vigorará por prazo indeterminado.
<br/>14.2. As Partes não poderão negociar condições comerciais especiais sem a
interveniência e anuência da Souza Cruz.

<br/>14.3. O Varejo, com relação aos nomes e às marcas, obriga-se a utilizá-las nos
estritos termos deste Contrato, nas formas, cores e modelos indicados e aprovados
previamente pela Souza Cruz, não podendo alterá-las, registrá-las ou usá-las de
forma indevida ou infringindo os direitos de propriedade.
<br/>14.4. As Partes não serão responsáveis por quaisquer falhas, interrupções ou
atrasos no cumprimento de suas obrigações, quando decorrentes de caso fortuito ou
de força maior, sendo estes excludentes de responsabilidade nos termos do artigo
393 do Código Civil Brasileiro, incluindo, entre outros, atos governamentais,
limitações impostas por parte do Poder Público, interrupção na prestação de serviços
sob licença, autorização, permissão ou concessão governamental (fornecimento de
energia elétrica e serviços de telefonia, atuação de operadoras de serviço de
telecomunicações interconectadas à rede do Varejo, entre outros), catástrofes,
greves, perturbações da ordem pública e demais eventos da mesma natureza.
<br/>14.5. A eventual tolerância ou transigência das Partes em exigir o integral
cumprimento das obrigações contratuais não constituirá novação, renúncia ou
modificação do acordado, tratando-se de mera liberalidade, podendo a respectiva
Parte exigir, a qualquer tempo, o cumprimento integral de todas as obrigações
previstas neste Contrato.
<br/>14.6. Este Contrato obriga as Partes, bem como seus respectivos sucessores a
qualquer título.
<br/>14.7. O Varejo deverá comunicar à Souza Cruz, em até 15 (quinze) dias a contar
da data da ocorrência, quaisquer alterações relativas às informações prestadas à
Souza Cruz, especialmente aquelas alterações referentes à composição societária,
denominação social, objeto social, endereços comerciais e eletrônicos, endereços de
correspondência ou números de telefone.
<br/>14.8. A nulidade ou invalidade de qualquer das disposições deste Contrato não
implicará na nulidade ou invalidade das demais, que permanecerão válidas,
produzindo plenos efeitos de direito.
<br/>14.9. Ocorrendo fatos não previstos pela Delivery Pay que possam prejudicar o
equilíbrio econômico-financeiro, afetando a adequada manutenção da
operacionalidade da Rede de Captura, as taxas e as tarifas referidas neste Contrato
poderão sofrer alterações, a serem prévia e expressamente comunicadas ao Varejo,
de forma a restaurar o equilíbrio contratual entre as Partes.
<br/>14.10. Este Contrato não estabelece vínculo trabalhista, previdenciário ou
societário entre as partes.
<br/>14.11. A Delivery Pay não poderá ceder ou transferir, total ou parcialmente, os
seus direitos e obrigações decorrentes deste Contrato para suas coligadas,
controladas, controladores e terceiros, sem a prévia e expressa autorização da Souza
Cruz. É vedado ao Varejo, a que título for, a cessão deste Contrato e dos direitos
dele decorrentes, salvo se por anuência expressa e formal da Delivery Pay.
<br/>14.12. Fica desde já certo e esclarecido que somente constituem como fato gerador
de base de cálculo para tributos e impostos de qualquer espécie ou competência, de
acordo com seu tipo e característica individual, apenas os valores descontados do
Valor Bruto do Repasse e que forem resultantes de fornecimento de produtos e/ou
serviços, sendo o Valor Liquido apenas o Repasse de valores recebidos de terceiros. 

<br/>14.13. As partes elegem o foro da Comarca de Santana de Parnaíba/SP para dirimir
eventuais dúvidas oriundas deste Contrato, renunciando a qualquer outro, por mais
privilegiado que seja.
<br/>14.14. As Partes estão de acordo e aceitam que documentos eletrônicos
sejam utilizados como prova em caso de qualquer demanda judicial oriunda
da presente relação contratual, nos termos do Marco Civil da Internet e dos
artigos 439, 440 e 441 do Código Processual Civil. As Partes acordam que
essa disposição não constitui cerceamento ao direito à ampla defesa e ao
contraditório.
<br/>14.15. Fica estabelecido que a abstenção pelas Partes do exercício de
quaisquer direitos ou faculdades que lhe assistam não constituirão novação
e nem afetarão tais direitos, que poderão ser exercidos a qualquer tempo.
<br/>14.16. Não se cria, por força deste Termo, nenhum tipo de vínculo
trabalhista ou societário, bem como nenhum tipo de representação ou
solidariedade entre as Partes aqui contratantes.
E por assim estarem justas e contratadas as partes firmam o presente instrumento
através de aceite pelo Varejo através de assinatura eletrônica, ao Contrato e
Regulamento.
<br/><br/><strong>ANEXO I- REGULAMENTO DO BENEFÍCIO CONECTA PAY</strong><br/><br/>
O Conecta Pay é um benefício oferecido pela SOUZA CRUZ LTDA., pessoa jurídica
de direito privado, inscrita no CNPJ sob o nº 33.009.911/0001-39, com sede na
Avenida República do Chile, nº 330, Centro, Rio de Janeiro, RJ, CEP 20.091-900,
(“SOUZA CRUZ”), em parceria com a DELIVERY PAY sociedade anônima, inscrita
no CNPJ/MF sob o nº 30.612.886/0001-95, com sede na Alameda Mamoré, nº 687,
5º andar, conjunto 503, Sala 05-124, Alphaville Centro Industrial e Empresarial, na
Cidade de Barueri, Estado de São Paulo, CEP 06454-040 (“Delivery Pay”).
<br/>1. DAS DEFINIÇÕES
“CASHBACK” significa a pontuação que os Varejos receberão de acordo com
o valor pago correspondente as taxas cobradas pela Delivery Pay nas
Transações com as Soluções de Pagamento especificamente para aquisição
de Produtos Souza Cruz, que podem ser resgatados no âmbito do Programa
Conecta Prime. Não haverá possibilidade de conversão total ou parcial em
dinheiro pelos Varejos, seja em crédito em conta e/ou em pecúnia
“CONECTA PAY” benefício oferecido pela Souza Cruz aos Varejos
participantes do Programa Conecta Prime da Souza Cruz, por meio do qual é
o oferecida Solução de Pagamento.

“CONECTA PRIME” Programa de Relacionamento dedicado exclusivamente
aos clientes VAREJISTAS da SOUZA CRUZ, pelo qual serão fornecidos
pontos aos VAREJISTAS que atenderem aos critérios de avaliação indicados
pela SOUZA CRUZ, que poderão ser trocados por produtos da SOUZA CRUZ
ou produtos disponibilizados na Plataforma, por meio do PORTAL CONECTA
PRIME
“PORTAL CONECTA PRIME”: plataforma de Internet do programa de
relacionamento Conecta Prime da SOUZA CRUZ, localizado pelo endereço
eletrônico http://www.conectaprime.com.br, e seus subdomínios;
“PRODUTOS SOUZA CRUZ”: no singular ou no plural, respectivamente, um
ou todos os produtos, derivados de tabaco ou não, e seus acessórios,
fabricados e/ou comercializados pela SOUZA CRUZ ou seus distribuidores
autorizados.
<br/>2. DO BENEFÍCIO CONECTA PAY
<br/>2.1. O benefício Conecta PAY tem como objetivo viabilizar a venda de
produtos SOUZA CRUZ por meio de cartões de pagamento, físicos ou
virtuais.
<br/>2.2. A SOUZA CRUZ, por meio do benefício Conecta Pay, irá conceder
pontos aos VAREJISTAS, que poderão ser resgatados na plataforma
Conecta Prime, correspondente ao valor pago pelas taxas cobradas
pela Delivery Pay nas transações com as Soluções de Pagamento
especificamente para a aquisição de produtos Souza Cruz.
<br/>2.2.1. O valor pago pelas taxas cobradas não poderá ser
convertido, total ou parcialmente, em dinheiro pelos
Varejistas.
<br/>2.3. Este benefício é destinado exclusivamente
aos VAREJISTAS que celebraram o Termo de Parceria Comercial com
a SOUZA CRUZ, através do Portal Conecta Prime, excluindo, portanto,
qualquer VAREJISTA que não seja participante do Programa Conecta
Prime e/ou de consumidor final.
<br/>2.4. Os VAREJISTAS somente serão elegíveis enquanto mantiverem
vínculo contratual com a SOUZA CRUZ, por meio do Prograam
Conecta Prime, e com a DELIVERY PAY, através do Termo de Adesão
aos Serviços de Pagamento, perdendo direito aos pontos e serviços do
CONECTA PAY imediatamente, na forma deste Regulamento, em caso
de descredenciamento por qualquer motivo.
<br/>3. DO PRAZO DE DURAÇÃO DO BENEFÍCIO E INÍCIO DA CONTAGEM
DOS PONTOS
<br/>3.1. O benefício Conecta Pay terá início no momento em que o
VAREJISTA der o aceite eletrônico no PORTAL CONECTA PRIME e terá
duração enquanto a relação comercial estiver vigorando.

<br/>3.2. A adesão poderá ser realizada pelo participante elegível a qualquer
momento, sendo certo que a contagem dos pontos acumulados
ocorrerá a partir da primeira transação qualificada com Produtos Souza
Cruz.
<br/>3.3. A duração do benefício poderá ser prorrogada ou interrompida, a
qualquer tempo, de acordo com os resultados obtidos no seu decorrer.
<br/>4. DA ADESÃO
<br/>4.1. A adesão ao benefício Conecta Pay será efetuada mediante o
conhecimento e o aceite expresso de todos os termos e condições
estabelecidos neste Regulamento, bem como no Termo de Adesão aos
Serviços de Pagamento, todos disponíveis no PORTAL CONECTA
PRIME.
<br/>4.2. Somente VAREJOS participantes do Programa Conecta Prime
poderão aderir ao benefício CONECTA PAY.
<br/>4.3. Após manifestar seu consentimento às regras do benefício Conecta
Pay e de seu Termo de Adesão ao Serviço de Pagamento,
o VAREJISTA receberá os equipamentos para realizar as transações
no prazo de 5 dias úteis.
<br/>4.4. SOUZA CRUZ possui a faculdade de se recusar a conceder,
suspender ou cancelar a adesão do VAREJISTA que violar ou tentar
violar qualquer obrigação imposta neste Regulamento, no Termo de
Adesão aos Serviços de Pagamento ou em qualquer documento legal
da SOUZA CRUZ disponibilizado no PORTAL CONECTA PRIME.
<br/>5. DA VALIDADE DOS PONTOS
<br/>5.1. A validade dos pontos, adquiridos em virtude do cahsback, será de
1 (um) ano.
<br/>6. DAS REGRAS DE BONIFICAÇÃO
<br/>6.1. O valor do cashback, referente ao valor da taxa da máqina, conforme definido
na cláusula 8, sobre a venda de produtos Souza Cruz será convertido em
pontos no PORTAL CONECTA PRIME, tendo como base a seguinte concersão,
que não poderá ser alterada durante a vigência deste Regulamento:
R$ 1,00 (um real) = 10 (dez) pontos
<br/>6.2. O saldo e o extrato de pontos do VAREJISTA poderão ser consultados a
qualquer momento, por meio do PORTAL CONECTA PRIME . Após cada
resgate, os pontos não resgatados permanecem no extrato
do VAREJISTA para trocas futuras limitado ao prazo de expiração dos pontos
disponível no PORTAL CONECTA PRIME.

<br/>6.3. Os pontos adquiridos mensalmente serão divulgados em até 7 (sete) dias
úteis contados do encerramento de cada semana, estando, a partir desta
data, disponível para resgate.
<br/>6.4. Caso o valor dos pontos de cada semana não seja atribuído corretamente
e/ou divulgado dentro do prazo estipulado, o VAREJISTA poderá comunicar
o fato por meio do telefone da Central de Serviço de Atendimento ao
Varejista 0800 7 23 22 21 (opção 1; inserir o CNPJ/CPF e selecionar a opção
<br/>7), no prazo máximo de 60 (sessenta) dias contados da data em que
deveriam ter sido divulgados. Findo este prazo, tais pontos não poderão ser
mais resgatados pelo VAREJISTA bem como não haverá qualquer tipo de
ressarcimento em razão do não resgate.
<br/>7. DAS TARIFAS POR TRANSAÇÃO E DO REPASSE
<br/>7.1. A taxa cobrada, salvo em caso de negociação específica entre as partes,
pela captura de cada transação realizada pelo VAREJO por meio da solução
de pagamento será 1,5% para transações no Débito e 2,5% para transações
no Crédito.
<br/>7.2. Caso as taxas sejam alteradas, a Souza Cruz sinalizará ao varejo as
novas taxas no PORTAL CONECTA PRIME.
<br/>7.3. O repasse do valor líquido das transações será feito pelo Emissor,
mediante crédito do respectivo valor no domicílio bancário indicado pelo
VAREJO no PORTAL CONECTA PRIME, no prazo de 1 dia para transações no
Débito e 30 dias para transações no Crédito.
<br/>7.4. O VAREJO poderá optar por realizar o pagamento da mensalidade do
Programa CONECTA PRIME, conforme estabelecido no regulamento do
Programa, mediante desconto nos recebíveis da Solução de Pagamento
Conecta Pay.
<br/>7.4. O VAREJO se responsabiliza pelo cadastro das informações corretas no
PORTAL CONECTA PRIME e declara estar ciente de que as informações de
cadastro que constam no registro do Portal da Souza Cruz irão prevalecer
para o cumprimento das disposições do presente Regulamento e do Termo de
Adesão ao Serviço de Pagamento.
<br/>8. DO RESGATE DE PONTOS
<br/>8.1. Os pontos adquiridos pelo VAREJISTA poderão ser trocados em
produtos fabricados e/ou comercializados pela SOUZA CRUZ e/ou por
produtos disponíveis no PORTAL CONECTA PRIME.
<br/>8.2. Uma vez solicitado o resgate do benefício, o VAREJISTA terá o
número de protocolo de resgate disponível na Plataforma e também
poderá receber via e-mail, WhatsApp ou SMS e os pontos necessários
para o resgate serão automaticamente debitados de seu extrato de
pontos no PORTAL CONECTA PRIME. Depois de gerado este número
de protocolo, a operação de resgate não poderá ser cancelada, assim
como os pontos referentes ao resgate não retornarão para conta de
acesso do VAREJISTA.

<br/>8.3. Os pontos não poderão ser convertidos em dinheiro ou em qualquer
outro produto que não esteja disponível no PORTAL CONECTA
PRIME.
<br/>8.4. Também não será permitido complementar a quantia faltante de
pontos em dinheiro, ou qualquer outro meio de pagamento, caso a
quantia de pontos seja insuficiente para efetuar o resgate .
<br/>8.5. Os cigarros resgatados serão entregues no estabelecimento
comercial do VAREJISTA cadastrado junto à SOUZA CRUZ, já os
outrso produtos disponibilizados na plataforma serão entregues no
enedereço indicado pelo VAREJISTA, de acordo com as marcas e com
a quantidade solicitada pelo VAREJISTA no momento do resgate.
<br/>8.6. Caso algum produto resgatado apresente defeito de fabricação ou
esteja em desacordo com o pedido, o VAREJISTA deverá comunicar
a SOUZA CRUZ por meio do telefone da Central de Serviço de
Atendimento ao Varejista 0800 7 23 22 21 (opção 1; o varejista deve
indicar o CNPJ/CPF e selecionar a Opção 7).
<br/>8.7. No resgate de produtos fabricados e/ou comercializados
pela SOUZA CRUZ, o somatório destes resgates estará limitado a uma
quantidade de 30% (trinta por cento) do faturamento projetado
do VAREJISTA com cigarros no período de vigência do Contrato.
<br/>8.8. Será considerado como preço máximo para resgate o preço corrente
de venda dos produtos da SOUZA CRUZ a seus VAREJISTAS.
<br/>8.9. Após o término da relação comercial, o resgate poderá ser efetuado
até a data de expiração dos pontos, informada no PORTAL DO
CONECTA PRIME.
<br/>9. DAS DISPOSIÇÕES GERAIS
<br/>9.1. Serão automaticamente excluídos os participantes elegíveis que
tentarem burlar ou fraudar as regras estabelecidas neste Regulamento,
no Termo de Adesão ao Serviço de Pagamento, no Regulamento do
Conecta Prime, nos Termos de Parceria Comercial, em qualquer outro
documento disponibilizado no PORTAL CONECTA PRIME ou, ainda, que
cometerem qualquer tipo de suspeita de fraude, incluindo, mas não se
limitando à criação, benefício e utilização de ferramentas automatizadas,
ficando, ainda, sujeitos à responsabilização penal e civil.
<br/>9.2. O USUÁRIO declara que não emprega e/ou utiliza mão-de-obra
infantil no exercício de suas atividades, bem como não contrata e/ou
mantém relações com quaisquer outras empresas que lhe prestem
serviços (parceiros, fornecedores e/ou subcontratados) que utilizem,
explorem e/ou por qualquer meio ou forma empreguem o trabalho infantil,
nos termos previstos no ECA - Estatuto da Criança e do Adolescente, Lei
n.º 8.069/90 e demais normas legais em vigor.
<br/>9.3. O USUÁRIO declara que não comercializa e/ou promove a
comercialização produtos ilegais, ou seja, produtos não registrados
perante aos órgão fiscalizadores, bem como o USUÁRIO declara que está
ciente que poderá ter sua conta bloqueada ou suspensa em caso de 

comercialização e/ou promoção de produtos ilegais além da aplicação das
penalidades previstas em lei
<br/>9.4. A SOUZA CRUZ se reserva o direito de efetuar, a qualquer tempo,
alteração na quantidade de pontos necessária para resgate dos produtos
e disponíveis em decorrência de motivos econômicos e financeiros,
durante o período de vigência do Termo de Parceria Comercial com
o VAREJISTA. A SOUZA CRUZ se reserva, ainda, o direito de substituir
integralmente a lista de produtos disponíveis para resgate, bem como,
incluir itens sazonais entre aqueles a serem resgatados, com prazos e
quantidades limitados.
<br/>9.5. A SOUZA CRUZ se reserva ainda o direito de adequar os termos deste
regulamento a qualquer momento, de forma a assegurar o funcionamento
do programa em conformidade com as diretrizes legais e pró competitivas.
<br/>9.6. A simples adesão, com a efetivação da inscrição no
benefício CONECTA PAY implica na aceitação e no inteiro conhecimento
de todas as condições de direitos e de obrigações deste Regulamento.
        </p>
        </div>

    );
};

export default ConectaPayRegulation;