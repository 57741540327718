import { IconChevron } from '@/components/icons';
import React, { useState } from 'react';
import './src/fields.min.css';

const SelectBank = ({ error, label, options, inputProps }) => {
  const [selectedValue] = useState('Selecione');

  return (
    <div className={`fm-field fm-select ${error ? 'filled-error' : ''}`}>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      <div className='custom-select'>
        <select {...inputProps}>
          <option value={selectedValue}>Selecione</option>
          {options.map(option => {
            return (<option key={option.id} value={option.codigoBanco}>{`${option.codigoBanco} - ${option.nomeDoBanco}`}</option>)
          })}
        </select>
        <IconChevron className='ic-chevron' height='10px' width='10px' fill='#21346C' />
      </div>
      {error && <span className='error-label'>{error.message}</span>}
    </div>
  );
};

export default SelectBank;
