import { ToastrTypes } from './types';

const INITIAL_STATE = {
    open: false,
    message: '',
    type: undefined,
  };
  
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ToastrTypes.SHOW:
        return { open: true, message: action.payload.message, type: action.payload.type };
      case ToastrTypes.HIDE:
        return { ...state, open: false };
      default:
        return state;
    }
  };
  
  export default reducer;
  