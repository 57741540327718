import React from "react";

const arraySteps = [
  {
    id: "Purchasing",
    step: "01",
    title: "Bonificação",
    state_key: "bonificacaoSelecionadaString",
    purchase: "...",
    progressBar: true,
  },
  {
    id: "BonusOnPurchasing",
    step: "02",
    title: "Bonificação",
    state_key: "bonificacaoSelecionadaString",
    progressBar: false,
  },
  {
    id: "OnLineShopping",
    step: "03",
    title: "Compras online",
    state_key: "comprasOnlineString",
    progressBar: true,
  },
  {
    id: "Promotion",
    step: "04",
    title: "Promoções",
    state_key: "promocoesString",
    progressBar: true,
  },
  {
    id: "DescontoEnergia",
    step: "05",
    title: "Desconto de Energia",
    state_key: "descontoEnergiaString",
    progressBar: true,
  },
  {
    id: "DescontoEnergiaBannerStella",
    step: "06",
    title: "Parceria Stella",
    state_key: "DescontoEnergiaBannerStella",
    progressBar: false,
  },
  // {
  //   id: "InternetVivo",
  //   step: "07",
  //   title: "Internet Vivo",
  //   state_key: "vivoTelefoniaString",
  //   progressBar: true,
  // },
  // {
  //   id: "InternetVivoBanner",
  //   step: "08",
  //   title: "Internet Vivo Banner",
  //   state_key: "InternetVivoBanner",
  //   progressBar: false,
  // },
  {
    id: "BannerBeneficios",
    step: "07",
    title: "Banner",
    state_key: "BannerBeneficios",
    progressBar: false,
  },
  {
    id: "Total",
    step: "08",
    title: "Total",
    state_key: "...",
    progressBar: false,
  },
];

export default arraySteps;
