
import React, {Fragment, useEffect, useState} from 'react';
import './src/fields.min.css';

const RadioButton = ({ error, label, inputProps, checked, disabled, tooltip, customClass }) => {

      return (
        <>
          <div className={`fm-field-radioButton ${customClass ? customClass : ''}`}>
            <input type="radio" {...inputProps} checked={checked}  disabled={disabled} />
            <label htmlFor={inputProps.id}>
              {label}
            </label>
            { tooltip }
          </div>
          {error && <span className='error-label'>{error.message}</span>}
        </>
      )
};

export default RadioButton;
