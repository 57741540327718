
import validation from '@/components/utils/validationFields';
import { Sanitizer } from 'components/utils';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import './src/fields.min.css';

const InputCpfCnpj = ({ error, label, inputProps }) => {
  const [mask, setMask] = useState("");

  useEffect(() => {
    const sanitizerString = Sanitizer.cpfCnpj(inputProps.value);
    if (validation.cpf(sanitizerString)) {
      return setMask("999.999.999-99")
    }

    setMask("99.999.999/9999-99")
  }, [inputProps.value])

  return (
    <div className={`fm-field text-field ${error ? 'filled-error' : ''}`}>
      <label htmlFor={inputProps.id}>
        {label}
      </label>
      <InputMask {...inputProps} value={inputProps.value} mask={mask} />
      {error && <span className='error-label'>{error.message}</span>}
    </div>
  )
};

export default InputCpfCnpj;
