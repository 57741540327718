import React from "react";
import { Route, Switch } from "react-router-dom";
import { BankSlipIdentifier } from "@/pages/queryBankSlip/bankSlipIdentifier";
import { ResultBankSlipHistoric } from "@/pages/queryBankSlip/resultBankSlipHistoric";

const Routes = () => (
  <Switch>
    {/* <Route path="/consulta-boleto" exact component={BankSlipIdentifier} /> */}
    <Route
      path="/consulta-boleto"
      component={() => {
        window.location.href =
          "https://petra3.force.com/aio/s/login?ec=302&startURL=%2faio%2fs%2f";
        return "Aguarde, estamos redirecionando...";
      }}
    />
    {/* <Route path="/boletos" exact component={ResultBankSlipHistoric} /> */}
    <Route
      path="/boletos"
      exact
      component={() => {
        window.location.href =
          "https://petra3.force.com/aio/s/login?ec=302&startURL=%2faio%2fs%2f";
        return "Aguarde, estamos redirecionando...";
      }}
    />
  </Switch>
);

export default Routes;
