import React from 'react';

const IconArrowToRight = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            className={props.className}
            viewBox="0 0 21.414 12.142">
            <g transform="translate(1 1.414)">
                <path
                    d="M4.657,0,0,4.657,4.657,9.313"
                    transform="translate(19 9.313) rotate(180)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="round"
                    strokeWidth="2" />
                <line
                    x1="18"
                    transform="translate(0 4.606)"
                    fill="none"
                    stroke={props.fill}
                    strokeLinecap="round"
                    strokeWidth="2" />
            </g>
        </svg>

    );
};

export default IconArrowToRight;