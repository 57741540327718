import React from 'react';

const IconConversation = (props) => {
    return (
        <svg
            className={props.className}
            fill={props.fill}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 37.09 36.821">
            <g transform="translate(-1791.97 -3351.948)">
                <path
                    d="M9.574,19.583A9.793,9.793,0,0,1,9.793,0h4.9a9.795,9.795,0,0,1,3.264,19.028v7.632Z"
                    transform="translate(1803.579 3361.108)"
                    stroke="#fff"
                    fill='none'
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M10.92,19.361H9.68A9.68,9.68,0,1,1,9.68,0h5.531a9.682,9.682,0,0,1,9.546,8.067H20.467A9.689,9.689,0,0,0,10.92,19.36Z"
                    transform="translate(1792.97 3352.948)"
                    stroke="#fff"
                    fill='none'
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>

    );
};

export default IconConversation;