import React, { useState, useEffect } from "react";
import { SectionTitle } from "@/components/sectionTitle";
import ModalCancel from "@/components/modalCancel/modalCancel";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import * as CancelActions from "store/cancel/actions";
import { AppMessages } from "../../../common/AppMessages";
import "./src/cancel.min.css";

const Cancel = ({
  updateCancelRequest,
  error,
  success,
  loading,
  aceiteTermos,
  readOnly,
  cancelado,
  dataCancelamentoStr,
  errorList,
}) => {
  const [showConfirmation, setshowConfirmation] = useState("close");
  const [motivoCancel, setMotivoCancel] = useState("");
  const [cancelClick, setCancelClick] = useState(false);

  const propsModal = {
    setModalState: setshowConfirmation,
    modalState: showConfirmation,
  };

  const handleConfirmCancel = () => {
    postCancel();
  };

  const postCancel = () => {
    setCancelClick(true);
    updateCancelRequest({
      motivo: motivoCancel,
    });
  };

  useEffect(() => {
    if (cancelClick && !loading && success && !error) {
      setshowConfirmation("close");
      setCancelClick(false);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: AppMessages.CANCEL_SUCCESS,
      });
    } else if (cancelClick && !loading && !success && error) {
      setshowConfirmation("close");
      setCancelClick(false);
      const erroText =
        errorList && errorList.length > 0
          ? errorList[0]
          : "Opa! Alguma coisa deu errado, entre em contato com o administrador";
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: erroText,
      });
    }
    // eslint-disable-next-line
  }, [loading, success, error, cancelClick]);

  return (
    <>
      <SectionTitle title="Cancelamento" />

      <div id="box-cancel" className="bx bx-cancel">
        {cancelado === false && aceiteTermos && (
          <div>
            <p className="label-filter">Motivo do cancelamento</p>
            <select
              className="filter-field"
              onChange={(e) => {
                setMotivoCancel(e.target.value);
              }}
            >
              <option selected value="">
                Selecione
              </option>
              <option value="Mensalidade Prime">Mensalidade Prime</option>
              <option value="Maquininha">Maquininha</option>
              <option value="Não concordo com o programa">
                Não concordo com o programa
              </option>
              <option value="Não aceitei o programa">
                Não aceitei o programa
              </option>
              <option value="Pontos">Pontos</option>
              <option value="Meta">Meta</option>
              <option value="Meu varejo encerrou as atividades">
                Meu varejo encerrou as atividades
              </option>
              <option value="Não vendo produtos BAT">
                Não vendo produtos BAT
              </option>
              <option value="Fui cadastrado por engano">
                Fui cadastrado por engano
              </option>
              <option value="Nota fiscal">Nota fiscal</option>
              <option value="Outros">Outros</option>
            </select>
            <div className="fm-button">
              <button
                type="button"
                id="btn-cancel"
                className="btn btn-main ic-go"
                disabled={
                  readOnly === true ||
                  loading === true ||
                  !motivoCancel ||
                  cancelado === true
                }
                onClick={() => setshowConfirmation("open")}
              >
                Continuar cancelamento
              </button>
            </div>
          </div>
        )}
        {cancelado === true && (
          <div>
            <p>
              O seu cancelamento foi solicitado no dia {dataCancelamentoStr}.
            </p>
          </div>
        )}
        {!aceiteTermos && (
          <div>
            <p>Você não aceitou os termos.</p>
          </div>
        )}

        <ModalCancel {...propsModal}>
          <SectionTitle title="Cancelamento de adesão." />
          <br />
          <p>
            <strong>Realmente deseja cancelar o Conecta Prime?</strong>
          </p>
          <br />
          <p>
            Você perderá todos os seus benefícios, bonificações e não terá mais
            o volume apurado. Caso possua Conecta Pay, a mesma será recolhida.
          </p>
          <br />
          <div className="fm-button">
            <a
              onClick={() => {
                const isblock =
                  readOnly === true || loading === true || cancelado === true;
                if (!isblock) {
                  setshowConfirmation("close");
                }
              }}
              disabled={
                readOnly === true || loading === true || cancelado === true
              }
              className="btn btn-inverted ic-go"
            >
              Cancelar
            </a>
            <a
              onClick={() => {
                const isblock =
                  readOnly === true || loading === true || cancelado === true;
                if (!isblock) {
                  handleConfirmCancel();
                }
              }}
              disabled={
                readOnly === true || loading === true || cancelado === true
              }
              className="btn btn-main ic-go"
            >
              {loading === true ? "Aguarde..." : "Confirmar"}
            </a>
          </div>
        </ModalCancel>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.cancel.loading,
  error: state.cancel.error,
  errorList: state.cancel.errorList,
  success: state.cancel.success,
  readOnly: state.auth.userInfo.readOnly,
  aceiteTermos: state.myData.data.aceiteTermos,
  cancelado: state.myData.data.cancelado,
  dataCancelamentoStr: state.myData.data.dataCancelamentoStr,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateCancelRequest: (data) => {
      dispatch(CancelActions.updateCancelRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cancel);
