import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';
import {  loadPopupHome as loadHome, loadCampaignsHome as loadCamp } from './actions';

import { loadPopupHomeSuccess, loadPopupHomeFailure, loadCampaignsHomeSuccess, loadCampaignsHomeFailure, loadConfirmPopupSuccess, loadConfirmPopupFailure } from './actions';

export function* loadPopupHome(){
    try{
        const {data} = yield call(api.get, '/popup/meus-popups/home');

        yield put(loadPopupHomeSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadPopupHomeFailure());
    }
} 

export function* loadPopupCampaigns(){
    try{
        const {data} = yield call(api.get, 'popup/meus-popups/campanha');

        yield put(loadCampaignsHomeSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadCampaignsHomeFailure());
    }
} 

export function* marcarVisualizacao(action){
    try{
        const {data} = yield call(api.post, `popup/marcar-visualizacao/${action.payload.data.id}/${action.payload.data.login}`);

        yield put(loadConfirmPopupSuccess(data));
        yield put(action.payload.data.type === 'home' ? loadHome() : loadCamp());
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadConfirmPopupFailure());
    }
} 