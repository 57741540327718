import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RadioButton } from "@/components/formFields";
import { IconArrowToLeft } from "@/components/icons";
import { SectionTitle } from "@/components/sectionTitle";
import Modal from "@/components/modal/modal";
import "./src/payment-methods.min.css";
import * as PaymentActions from "store/payments/actions";
import Swal from "sweetalert2";
import { AcessStageForm } from "@/components/acessStageForm";
import { AcessStageCreditCard } from "@/components/acessStageCreditCard";

const PaymentMethods = ({
  loading,
  paymentState,
  success,
  error,
  errorList,
  participantPaymentMethodRequest,
  participantPaymentMethod,
  participantPaymentChangeRequest,
  changedPayment,
  accesstageDadosBancarios,
  cancelado,
  readOnly,
  creditCardData
}) => {
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [showConfirmation, setshowConfirmation] = useState("close");
  const [showIframeCard, setShowIframeCard] = useState({url: '', status: 0})

  const [dataPayment, setDataPayment] = useState({
    tipoPagamento: paymentMethod,
    bancoCodigo: accesstageDadosBancarios.bancoCodigo,
    bancoAgencia: accesstageDadosBancarios.bancoAgencia,
    bancoAgenciaDigito: accesstageDadosBancarios.bancoAgenciaDigito,
    bancoContaBancaria: accesstageDadosBancarios.bancoContaBancaria,
    bancoContaBancariaDigito: accesstageDadosBancarios.bancoContaBancariaDigito,
  });

  useEffect(() => {
    participantPaymentMethodRequest();
  }, []);

  useEffect(() => {
    // Aguarda retorno do POST - Cartão de Crédito
      if (creditCardData?.success) {
        let url = creditCardData?.data;
        setShowIframeCard({...showIframeCard, url, status: 1});
      } else if (creditCardData?.error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Ops, ocorreu algum problema. Por gentileza, tente novamente!",
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },[creditCardData?.success, creditCardData?.error])

  useEffect(() => {
    if (paymentMethod == 3)
      setDataPayment({ ...dataPayment, tipoPagamento: paymentMethod });
  }, [paymentMethod]);

  useEffect(() => {
    let newPayments = participantPaymentMethod

    newPayments.map((payment) => {
      if (payment.tipoPagamentoAtual) {
        setPaymentMethod(payment.id);
      }
    });
  }, [participantPaymentMethod]);

  useEffect(() => {
    if (changedPayment && success && !error) {
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Método de pagamento alterado com sucesso",
        timer: 2000,
        timerProgressBar: true,
      });

      participantPaymentMethodRequest();
    } else if (!changedPayment && !success && error) {
      Swal.fire({
        icon: errorList ? "warning" : "error",
        title: errorList ? "Alerta!" : "Erro!",
        text: errorList
          ? errorList[0]
          : "Ops! Alguma coisa deu errado, contate o administrador",
        timer: 2000,
        timerProgressBar: true,
      });
    }

    paymentState("", "", {
      changedPayment: false,
      success: false,
      error: false,
    });
  }, [changedPayment, success, error]);

  const propsModal = {
    setModalState: setshowConfirmation,
    modalState: showConfirmation,
  };

  const handleSaveRetailChain = async () => {
    if (paymentMethod !== 3) clearFormDebit();

    setShowIframeCard({url: '', status: 0});
    setshowConfirmation("close");
    participantPaymentChangeRequest({ ...dataPayment, tipoPagamento: paymentMethod });
  };

  async function clearFormDebit() {
    let paymentData = { ...dataPayment };
    paymentData.tipoPagamento = paymentMethod;
    paymentData.bancoAgencia = "";
    paymentData.bancoAgenciaDigito = "";
    paymentData.bancoContaBancaria = "";
    paymentData.bancoContaBancariaDigito = "";
    paymentData.bancoCodigo = "";

    setDataPayment(paymentData);
  }

  return (
    <section className="payment-methods">
      <SectionTitle title={`Forma de pagamento: `}>
        {participantPaymentMethod.map((data) => {
          return (
            data.tipoPagamentoAtual && (
              <div className="actualPayment"> {data.nome}</div>
            )
          );
        })}
        <Link 
          to="/meus-pagamentos" 
          className="btn btn-link"
        >
          <IconArrowToLeft
            fill="#8f23d6"
            className="ic ic-arrow-to-left ic-left"
          />
          Voltar
        </Link>
      </SectionTitle>
      <div className="bx bx-payment-methods">
        {participantPaymentMethod.map((payment) => {
          return (
            <RadioButton
              key={payment.id}
              label={payment.nome}
              // error={errors.fullName}
              checked={Number(paymentMethod) === payment.id}
              inputProps={{
                disabled:
                  loading ||
                  participantPaymentMethod.length <= 1 ||
                  readOnly ||
                  cancelado,
                id: `paymentMethod-${payment.id}`,
                name: "paymentMethod",
                value: `${payment.id}`,
                onChange: (e) => {
                  setPaymentMethod(Number(e.target.value));
                },
              }}
            />
          );
        })}
        {participantPaymentMethod.map(({ id }) => {
          return paymentMethod === 3 && paymentMethod === id && !loading ? (
            <AcessStageForm
              dataPayment={dataPayment}
              setDataPayment={setDataPayment}
              setshowConfirmation={setshowConfirmation}
              readOnly={readOnly}
              cancelado={cancelado}
            />
          ) : null;
        })}

        {participantPaymentMethod.map(({ id }) => {
          return paymentMethod === 4 && paymentMethod === id && !loading ? (
            <AcessStageCreditCard
              dataPayment={dataPayment}
              setDataPayment={setDataPayment}
              handleSaveRetailChain={handleSaveRetailChain}
              paymentState={paymentState}
              success={success}
              error={error}
              errorList={errorList}
              showIframeCard={showIframeCard}
              setShowIframeCard={setShowIframeCard}
            />
          ) : null;
        })}

      {(paymentMethod == 1 || paymentMethod == 2 || (paymentMethod == 4 && showIframeCard.status == 1)) &&
        <div className="fm-button">
          <button
            type="button"
            disabled={
              loading ||
              participantPaymentMethod.length <= 1 ||
              readOnly ||
              cancelado
            }
            className="btn btn-main ic-go"
            onClick={() => {
              setshowConfirmation("open")
            }}
          >
            {loading ? "Carregando..." : "Alterar forma de pagamento"
            }
          </button>
        </div>
      }
      </div>
      <Modal {...propsModal}>
        <SectionTitle title="Troca de método de <br /> pagamento." />
        <p>Você deseja confirmar a troca do seu método de pagamento?</p>
        <p>
          <strong>
            A alteração será aplicada na mensalidade do mês seguinte
          </strong>
        </p>
        <div className="fm-button">
          <a
            disabled={
              loading ||
              participantPaymentMethod.length <= 1 ||
              readOnly ||
              cancelado
            }
            onClick={() => {
              setshowConfirmation("close")
            }}
            className="btn btn-inverted ic-go"
          >
            cancelar
          </a>
          <a
            disabled={
              loading ||
              participantPaymentMethod.length <= 1 ||
              readOnly ||
              cancelado
            }
            onClick={() => {
              handleSaveRetailChain()
            }}
            className="btn btn-main ic-go"
          >
            confirmar
          </a>
        </div>
      </Modal>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    participantPaymentMethod: state.payment.participantPaymentMethod,
    changedPayment: state.payment.changedPayment,
    loading: state.payment.loading,
    success: state.payment.success,
    error: state.payment.error,
    errorList: state.payment.errorList,
    accesstageDadosBancarios: state.myData.data.accesstageDadosBancarios,
    cancelado: state.myData.data.cancelado,
    readOnly: state.auth.userInfo.readOnly,
    creditCardData: state.payment.creditCardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paymentState: (node, key, value) => {
      dispatch(PaymentActions.paymentState(node, key, value));
    },
    participantPaymentMethodRequest: () => {
      dispatch(PaymentActions.participantPaymentMethodRequest());
    },
    participantPaymentChangeRequest: (data) => {
      dispatch(PaymentActions.participantPaymentChangeRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
