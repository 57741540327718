import React from 'react'

const IconCoin = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 21.448 21.207'>
      <g transform='translate(0.75 0.75)'>
        <g transform='translate(0 8.34)'>
          <path
            d='M6.56,5.3h0C2.881,5.245,0,4.081,0,2.653V0C0,.707.712,1.372,2,1.873a13.83,13.83,0,0,0,4.833.778c3.771,0,6.838-1.189,6.838-2.651V2.653a1.24,1.24,0,0,1-.288.763l-.277,0c-3,0-5.688.776-6.55,1.888Z'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
        </g>
        <g
          transform='translate(0 5.496)'>
          <path
            d='M3605.336,5196c0,1.465-3.061,2.651-6.837,2.651s-6.838-1.187-6.838-2.651v2.652c0,1.464,3.062,2.65,6.838,2.65s6.837-1.187,6.837-2.65Z'
            transform='translate(-3591.661 -5195.997)'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
        </g>
        <g>
          <path
            d='M3591.661,5188.45c0-1.464,3.062-2.651,6.838-2.651s6.837,1.187,6.837,2.651'
            transform='translate(-3591.661 -5185.799)'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
          <path
            d='M3605.336,5196c0,1.465-3.061,2.651-6.837,2.651s-6.838-1.187-6.838-2.651v2.652c0,1.464,3.062,2.65,6.838,2.65s6.837-1.187,6.837-2.65Z'
            transform='translate(-3591.661 -5193.347)'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
        </g>
        <g transform='translate(6.273 11.754)'>
          <path
            d='M3591.661,5188.45c0-1.464,3.062-2.651,6.838-2.651s6.837,1.187,6.837,2.651'
            transform='translate(-3591.661 -5185.799)'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
          <path
            d='M3605.336,5196c0,1.465-3.061,2.651-6.837,2.651s-6.838-1.187-6.838-2.651v2.652c0,1.464,3.062,2.65,6.838,2.65s6.837-1.187,6.837-2.65Z'
            transform='translate(-3591.661 -5193.347)'
            fill='none'
            stroke={props.fill}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='7%' />
        </g>
      </g>
    </svg>
  )
}

export default IconCoin
