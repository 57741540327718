export const PaymentTypes =  {
    PAYMENT_STATE: "@payment/PAYMENT_STATE",
    LOAD_PAYMENT_METHODS_REQUEST: "@payment/LOAD_PAYMENT_METHODS_REQUEST",
    LOAD_PAYMENT_METHODS_SUCCESS: "@payment/LOAD_PAYMENT_METHODS_SUCCESS",
    LOAD_PAYMENT_METHODS_FAILURE: "@payment/LOAD_PAYMENT_METHODS_FAILURE",
    PAYMENT_HISTORY_REQUEST : "@payment/PAYMENT_HISTORY_REQUEST",
    PAYMENT_HISTORY_SUCCESS : "@payment/PAYMENT_HISTORY_SUCCESS",
    PAYMENT_HISTORY_FAILURE : "@payment/PAYMENT_HISTORY_FAILURE",
    PARTICIPANT_PAYMENT_METHOD_REQUEST : "@payment/PARTICIPANT_PAYMENT_METHOD_REQUEST",
    PARTICIPANT_PAYMENT_METHOD_SUCCESS : "@payment/PARTICIPANT_PAYMENT_METHOD_SUCCESS",
    PARTICIPANT_PAYMENT_METHOD_FAILURE : "@payment/PARTICIPANT_PAYMENT_METHOD_FAILURE",
    CHANGE_PAYMENT_METHOD_REQUEST : "@payment/CHANGE_PAYMENT_METHOD_REQUEST",
    CHANGE_PAYMENT_METHOD_SUCCESS : "@payment/CHANGE_PAYMENT_METHOD_SUCCESS",
    CHANGE_PAYMENT_METHOD_FAILURE : "@payment/CHANGE_PAYMENT_METHOD_FAILURE",
    COPY_OF_THE_BANK_SLIP_REQUEST: "@payment/COPY_OF_THE_BANK_SLIP_REQUEST",
    COPY_OF_THE_BANK_SLIP_SUCCESS: "@payment/COPY_OF_THE_BANK_SLIP_SUCCESS",
    COPY_OF_THE_BANK_SLIP_FAILURE: "@payment/COPY_OF_THE_BANK_SLIP_FAILURE",
    CHANGE_FLAG_PAYMENT_REQUEST: "@payment/CHANGE_FLAG_PAYMENT_REQUEST",
    CHANGE_FLAG_PAYMENT_SUCCESS: "@payment/CHANGE_FLAG_PAYMENT_SUCCESS",
    CHANGE_FLAG_PAYMENT_FAILURE: "@payment/CHANGE_FLAG_PAYMENT_FAILURE",
    SET_CREDIT_CARD_PAYMENT_REQUEST: "@payment/SET_CREDIT_CARD_PAYMENT_REQUEST",
    SET_CREDIT_CARD_PAYMENT_SUCCESS: "@payment/SET_CREDIT_CARD_PAYMENT_SUCCESS",
    SET_CREDIT_CARD_PAYMENT_FAILURE: "@payment/SET_CREDIT_CARD_PAYMENT_FAILURE",
    SET_CHANGE_CREDIT_CARD_PAYMENT_REQUEST: "@payment/SET_CHANGE_CREDIT_CARD_PAYMENT_REQUEST",
    SET_CHANGE_CREDIT_CARD_PAYMENT_SUCCESS: "@payment/SET_CHANGE_CREDIT_CARD_PAYMENT_SUCCESS",
    SET_CHANGE_CREDIT_CARD_PAYMENT_FAILURE: "@payment/SET_CHANGE_CREDIT_CARD_PAYMENT_FAILURE",
    SPORADIC_BANK_SLIP_REQUEST: "@payment/SPORADIC_BANK_SLIP_REQUEST",
    SPORADIC_BANK_SLIP_SUCCESS: "@payment/SPORADIC_BANK_SLIP_SUCCESS",
    SPORADIC_BANK_SLIP_FAILURE: "@payment/SPORADIC_BANK_SLIP_FAILURE",
    SPORADIC_BANK_SLIP_RESET_FLAG: "@payment/SPORADIC_BANK_SLIP_RESET_FLAG",
}