import React from "react";
import { IconClose } from "@/components/icons";

import "./src/modal.min.css";

const Modal = ({
  title,
  children,
  modalState,
  setModalState,
  callback,
  customClass,
  closeBtn = true,
}) => {
  const closeModal = () => {
    setModalState("close");
    if (callback) {
      callback();
    }
  };
  return (
    <div className={`overlay ${modalState} ${customClass ? customClass : ""}`}>
      <section className="modalContainer">
        <div className="bx-modal">
          {title ? (
            <div className="modal-header">
              <h2>{title}</h2>
              {closeBtn && (
                <a onClick={closeModal} className="btn-close-modal">
                  <IconClose
                    width="20px"
                    height="20px"
                    className="ic-close"
                    fill="#20346C"
                  />
                </a>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="modal-content">{children}</div>
        </div>
      </section>
    </div>
  );
};

export default Modal;
