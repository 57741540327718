import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import * as DeliverySystemActions from './actions';


// GET lista de projetos - Home
export function* loadProjectsListRequest() {
    try {
        const { data } = yield call(api.get, '/delivery/projetos');
        yield put(DeliverySystemActions.loadProjectsListSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.loadProjectsListFailure());
    }
}

// GET Lista de categorias e Produtos - Criar cardápio
export function* loadCategoriesAndProductsRequest(action) {
    try {
        const { data } = yield call(api.get, `/delivery/categoria/${action.payload.cardapioId}/produtos`);
        yield put(DeliverySystemActions.loadCategoriesAndProductsSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.loadCategoriesAndProductsFailure());
    }
}

// POST Criar novo projeto - Home
export function* createNewProjectRequest(action) {
    try {
        const { data } = yield call(api.post, `/delivery/projetos`, action.payload.formData, { params: action.payload.data });
        yield put(DeliverySystemActions.createNewProjectSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.createNewProjectFailure(error));
    }
}

// POST Adicionar Produto ao cardapio por projeto
export function* putProductOnMenuRequest(action) {
    try {
        const { data } = yield call(api.post, `/delivery/cardapio`, { ...action.payload.data });
        yield put(DeliverySystemActions.putProductOnMenuSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.putProductOnMenuFailure(error));
    }
}

// POST Nova categoria de produtos
export function* createNewCategoryRequest(action) {
    try {
        const { data } = yield call(api.post, `delivery/categoria`, { ...action.payload.data });
        yield put(DeliverySystemActions.createNewCategorySuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.createNewCategoryFailure(error));
    }
}

// PATCH editar projeto
export function* updateProjectRequest(action) {
    try {
        const { data } = yield call(api.patch, `/delivery/projeto/${action.payload.cardapioId}`, action.payload.formData, { params: action.payload.data });
        yield put(DeliverySystemActions.updateProjectSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.updateProjectFailure(error));
    }
}

// PATCH Editar categoria
export function* updateCategoryRequest(action) {
    try {
        const { data } = yield call(api.patch, `/delivery/categoria/${action.payload.categoriaId}`, { ...action.payload.data });
        yield put(DeliverySystemActions.updateCategorySuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.updateCategoryFailure(error));
    }
}

// POST Cadastrar novo produto por cardapioId
export function* createNewProductRequest(action) {
    try {
        const { data } = yield call(api.post, `/delivery/produto`, { ...action.payload.data });
        yield put(DeliverySystemActions.createNewProductSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.createNewProductFailure(error));
    }
}

// PATCH Editar um produto
export function* updateProductRequest(action) {
    try {
        const { data } = yield call(api.patch, `/delivery/produto/${action.payload.produtoId}`, { ...action.payload.data });
        yield put(DeliverySystemActions.updateProductSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.updateProductFailure(error));
    }
}

// DELETE Deletar um produto
export function* removeProductRequest(action) {
    try {
        const { data } = yield call(api.delete, `/delivery/produto/${action.payload.produtoId}`);
        yield put(DeliverySystemActions.removeProductSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.removeProductFailure(error));
    }
}

// DELETE projetos por cardapioId
export async function deleteProject(cardapioId) {
    try {
        const result = await api.delete(`/delivery/projeto/${cardapioId}`)
        return result
    } catch (error) {
        return error
    }
}

// DELETE Remover produto do cardápio 
export function* removeProductFromMenuRequest(action) {
    try {
        const { data } = yield call(api.delete, `/delivery/cardapio/${action.payload.cardapioId}/produto/${action.payload.produtoId}`);
        yield put(DeliverySystemActions.removeProductFromMenuSuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.removeProductFromMenuFailure(error));
    }
}

// DELETE Deletar uma categoria de um cardapio
export function* removeCategoryRequest(action) {
    try {
        const { data } = yield call(api.delete, `/delivery/categoria/${action.payload.categoriaId}/cardapio/${action.payload.cardapioId}`);
        yield put(DeliverySystemActions.removeCategorySuccess(data));
    } catch (error) {
        yield put(DeliverySystemActions.removeCategoryFailure(error));
    }
}