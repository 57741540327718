import React from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import { connect } from 'react-redux'
import Routes from "./routes"
import { AsideContainer } from '@/components/containers'
import Spinner from "@/components/spinner";

import './src/queryBankSlip.min.css';

const Index = ({ loading, success }) => {

    return (
        <Router>
            <section className='wr-simulator identifier'>
                <AsideContainer>
                    {loading && !success ?
                        <Spinner />
                        :
                        <Routes />
                    }
                </AsideContainer>
            </section>
        </Router>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.queryBankSlip.loading,
        success: state.queryBankSlip.success
    }
};
export default connect(mapStateToProps)(Index)
