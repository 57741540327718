import Immutable from 'seamless-immutable';
import { DeliveryPayBanksTypes } from './types';


const INITIAL_STATE = Immutable({
  data: [
    {
      id: 0,
      codigoBanco: "",
      nomeDoBanco: "",
      ordem: 0,
      ativo: 0
    }
  ],
  loading: true
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_REQUEST:
      return { ...state, loading: true };
    case DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }
    case DeliveryPayBanksTypes.DELIVERY_PAY_BANKS_FAILURE:
      return { ...state, loading: false }
    default:
      return state;
  }
};

export default reducer;
