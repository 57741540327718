import {CancelTypes} from './types';

const INITIAL_STATE = {
    motivo: "",
    loading: false,
    error: false,
    errorList: [],
    success: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CancelTypes.UPDATE_CANCEL_REQUEST:
        return { ...state, loading: true, errorList: [] };
      case CancelTypes.UPDATE_CANCEL_SUCCESS:
        return {
          ...state,
          loading: false,
          success: action.payload.data.success,
          error: false,
          errorList: [],
          motivo: action.payload.data.data
        }
      case CancelTypes.UPDATE_CANCEL_FAILURE:
        return { ...state, loading: false, error: true, success: false, errorList: action.payload.error };
  
      default:
        return state;
    }
};

export default reducer;
