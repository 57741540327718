import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SectionTitle } from "@/components/sectionTitle";
import { Tabs, Tab } from "@/components/customTabs";
import { TransferHistory } from "@/pages/myAccount/pointsTransfer";
import {
  MyRetailNetwork,
  RetailChainEmbedded,
  RetailChainCentralization,
} from "@/pages/myAccount/retailChain";
import { CentralizationOfPoints } from "@/components/centralizationOfPoints";

import "./src/manage-retail.min.css";

const ManageRetail = ({ matriz, userPermissions }) => {
  const [hasApprovedRetailsChain, setHasApprovedRetailsChain] = useState(false);

  useEffect(() => {
    if (!!userPermissions.length) {
      let perm = userPermissions.find((item) => {
        return item.codigo === "MINHA_REDE_VAREJOS";
      })?.permissao;

      return perm ? undefined : window.location.replace("/");
    }
  }, []);

  return (
    <>
      <SectionTitle title="Minha rede de varejos" />
      {matriz ? (
        <>
          <CentralizationOfPoints />
          <div className="bx bx-manage-retail">
            <Tabs>
              <Tab
                label={"Varejos associados"}
                className={"tab-custom-class"}
                nextButton={false}
              >
                <MyRetailNetwork
                  setHasApprovedRetailsChain={setHasApprovedRetailsChain}
                />
              </Tab>
              <Tab
                label={"Transferência de pontos"}
                className={"tab-custom-class"}
                nextButton={false}
              >
                <TransferHistory />
              </Tab>
              <Tab
                label={"Extrato"}
                className={"tab-custom-class"}
                nextButton={false}
              >
                <RetailChainEmbedded />
              </Tab>
              <Tab
                label={"Pagamentos"}
                className={`tab-custom-class ${
                  hasApprovedRetailsChain
                    ? "payments-active"
                    : "payments-inactive"
                }`}
                nextButton={false}
              >
                <RetailChainCentralization />
              </Tab>
            </Tabs>
          </div>
        </>
      ) : (
        <MyRetailNetwork
          setHasApprovedRetailsChain={setHasApprovedRetailsChain}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    matriz: state.myData.data.matriz,
    userPermissions: state.auth.userPermissions,
  };
};

export default connect(mapStateToProps)(ManageRetail);
