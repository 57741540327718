import "@/assets/css/main.min.css";
import { AsideContainer } from "@/components/containers";
import { IconArrowToLeft } from "@/components/icons";
import {
  arraySteps,
  Purchasing,
  BonusOnPurchasing,
  OnLineShopping,
  Promotion,
  DescontoEnergia,
  DescontoEnergiaBannerStella,
  // InternetVivo,
  // InternetVivoBanner,
  EmprestimoGuiaBolsoBanner,
  BannerDescontos,
  BannerBeneficios,
  Progress,
  Total,
} from "@/pages/simulator";
import * as SimulatorActions from "@/store/simulator/actions";
import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import { connect } from "react-redux";
import HeaderDisconnected from "../../components/header/headerDisconnected";
import "./src/simulator.min.css";

const Simulator = ({
  simulator_state,
  simulatorRequest,
  current_state,
  loading,
  simulatorStateInitial,
  mostrarBeneficioEnergia,
  mostrarBannerBeneficio
}) => {
  var array = arraySteps;
  if(!mostrarBannerBeneficio){
    var arrayIndex = array.map((e) => { return e.step; }).indexOf("11");
    if(arrayIndex != -1){
      array.splice(arrayIndex, 1);
    }
  }
  const [steps, setSteps] = useState(array);
  const { index, step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const props = { steps, navigation, setSteps, index };
  const handleClick = () => {
    if (index <= 11) {
      simulator_state("vendasConectaPay", false);
      if (index == 1) {
        simulator_state("vendasConectaPay", false);
        simulatorRequest({ ...current_state, step: 0 });
      } else if (index == 2) {
        simulatorRequest({ ...current_state, step: 1 });
      } else if (index == 3) {
        simulatorRequest({ ...current_state, step: 4 });
      } else if (index == 4) {
        simulatorRequest({ ...current_state, step: 5 });
      } else if (index == 5) {
        simulatorRequest({ ...current_state, step: 6 });
      } else if (index == 6) {
        simulatorRequest({ ...current_state, step: 7 });
      } else if (index == 7) {
        simulatorRequest({ ...current_state, step: 8 });
      } else if (index == 8) {
        simulatorRequest({ ...current_state, step: 9 });
      } else if (index == 9) {
        simulatorRequest({ ...current_state, step: 10 });
      } else if (index == 10) {
        simulatorRequest({ ...current_state, step: 11 });
      } else if (index == 11) {
        simulatorRequest({ ...current_state, step: 12 });
      } else if (index == 12) {
        simulatorRequest({ ...current_state, step: 13 });
      }
    }

    // Segmentação de UF para retorno de telas pulando o Benefício Stella.
    if (mostrarBeneficioEnergia) {
      navigation.previous();
    } else if (index == 6) {
      navigation.go(3);
    } else {
      navigation.previous();
    }
  };

  const redirectSimuladorIdentificacao = async (e) => {
    e.preventDefault();
    await simulatorStateInitial();

    window.location.assign("/simulador-identificacao");
  };

  return (
    <section className="wr-simulator">
      <HeaderDisconnected />
      <AsideContainer>
        <main className="container-section">
          <div className="bx-steps">
            {index === 0 ? (
              <a className="btn btn-link" onClick={redirectSimuladorIdentificacao}>
                <IconArrowToLeft
                  fill="#8f23d6"
                  className="ic ic-arrow-to-left ic-left"
                />
                Voltar
              </a>
            ) : (
              <a className="btn btn-link" onClick={handleClick}>
                <IconArrowToLeft
                  fill="#8f23d6"
                  className="ic ic-arrow-to-left ic-left"
                />
                Voltar
              </a>
            )}
            {
              {
                Purchasing: <Purchasing {...props} />,
                BonusOnPurchasing: <BonusOnPurchasing {...props} />,
                Promotion: <Promotion {...props} />,
                OnLineShopping: <OnLineShopping {...props} />,
                DescontoEnergia: <DescontoEnergia {...props} />,
                DescontoEnergiaBannerStella: (
                  <DescontoEnergiaBannerStella {...props} />
                ),
                // InternetVivo: <InternetVivo {...props} />,
                // InternetVivoBanner: <InternetVivoBanner {...props} />,
                EmprestimoGuiaBolsoBanner: <EmprestimoGuiaBolsoBanner {...props} />,
                BannerDescontos: <BannerDescontos {...props} />,
                BannerBeneficios: <BannerBeneficios {...props} />,
                Total: <Total {...props} />,
              }[id]
            }
          </div>
        </main>
        <aside className="container-aside">
          <Progress steps={steps} stepCurrent={index} />
        </aside>
      </AsideContainer>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    loading: state.simulator.loading,
    mostrarBeneficioEnergia: state.simulator.data.mostrarBeneficioEnergia,
    mostrarBannerBeneficio: state.simulator.data.mostrarBannerBeneficio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
    simulatorStateInitial: () => {
      dispatch(SimulatorActions.simulatorStateInitial());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Simulator);
