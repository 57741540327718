// ** Desativado devido a Parceiro Métrica efetuar eventos via o próprio GTM **


// export const gtmSendEvent = (eventCategory, eventAction, eventLabel) => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     'event': 'event',
//     'eventCategory': eventCategory,
//     'eventAction': eventAction,
//     'eventLabel': eventLabel,
//   });
// }

// export const gtmSendConversionEvent = (eventCategory, eventAction, eventLabel) => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     'event': 'conversion',
//     'eventCategory': eventCategory,
//     'eventAction': eventAction,
//     'eventLabel': eventLabel
//   });
// }

// export const gtmSendCallback = (eventCategory, eventAction, eventLabel, dimension8, dimension1) => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     'event': 'event',
//     'eventCategory': eventCategory,
//     'eventAction': eventAction,
//     'eventLabel': eventLabel,
//     'dimension8': dimension8,
//     'dimension1': dimension1
//   });
// }

// export const gtmSendGlobal = (dimension1 = undefined) => {
//   window.dataLayer = window.dataLayer || []
//   window.dataLayer.push({
//     'event': 'global',
//     'dimension1': dimension1,
//   })
// }

// export const gtmGetProductWithAvailability = (metric2 = undefined, name, id, list, price, category = undefined, position) => {
//   window.dataLayerTransition = window.dataLayerTransition || {}
//   const dataLayerTransition = window.dataLayerTransition
//   const nameProps = slugfy(list)

//   const arrayTransition = [{
//       'name': slugfy(name),
//       'id': id,
//       'price': price ? price : undefined,
//       'metric2  ': metric2 ? metric2 : undefined,
//       'position': position,
//       'category': category ? slugfy(category) : undefined,
//       'list': slugfy(list)
//   }]
//   if (dataLayerTransition[nameProps]) {
//       var item = dataLayerTransition[nameProps].find(item => item.id === id)
//       if (!item) {
//         window.dataLayerTransition = {
//           [nameProps]: dataLayerTransition[nameProps] ? dataLayerTransition[nameProps].concat(arrayTransition) : dataLayerTransition[nameProps]
//         }
//       }
//     } else {
//       window.dataLayerTransition = {
//         [nameProps]: arrayTransition
//       }
//     }
// }

// export const gtmSendProductImpression = (arrayProduct) => {
//   if (window.dataLayerTransition) {
//       window.dataLayer = window.dataLayer || [];
//       window.dataLayerTransition = window.dataLayerTransition || undefined
//       if (window.dataLayerTransition) {
//           for (var [key, item] of Object.entries(window.dataLayerTransition)) {
//               if (item.length >= arrayProduct.length) {
//                   window.dataLayer.push({
//                       'event': 'productImpressions',
//                       'eventCategory': 'enhanced-ecommerce',
//                       'eventAction': 'productImpression',
//                       'noInteraction': '1',
//                       'ecommerce': {
//                       'impressions': item
//                       }
//                   })
//               }
//           }
//       }
//   }
// }

// export const gtmSendProductClick = (metric2 = undefined, name, id, list, price, category = undefined, position) => {
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//       'event': 'productClick',
//       'eventCategory': 'enhanced-ecommerce',
//       'eventAction': 'productClick',
//       'ecommerce': {
//         'click': {
//           'actionField': { 'list': slugfy(list) },
//           'products': [{
//               'name': slugfy(name),
//               'id': id,
//               'price': price ? price : undefined,
//               'metric2  ': metric2 ? metric2 : undefined,
//               'position': position,
//               'category': category ? slugfy(category) : undefined
//           }]
//         }
//       }
//     })
// }
// export const gtmGetBanners = (name, position, list) => {
// window.dataLayerBanners = window.dataLayerBanners || {}
// const dataLayerBanners = window.dataLayerBanners
// const nameProps = slugfy(list)

// const arrayTransition = [{
//     'name': slugfy(name),
//     'id': position,
//     'list': nameProps
// }]
// if (dataLayerBanners[nameProps]) {
//     var item = dataLayerBanners[nameProps].find(item => item.id === position)
//     if (!item) {
//       window.dataLayerBanners = {
//         [nameProps]: dataLayerBanners[nameProps] ? dataLayerBanners[nameProps].concat(arrayTransition) : dataLayerBanners[nameProps]
//       }
//     }
//   } else {
//     window.dataLayerBanners = {
//       [nameProps]: arrayTransition
//     }
//   }
// }

// export const gtmSendBannerImpression = () => {
// if (window.dataLayerBanners) {
//     window.dataLayer = window.dataLayer || [];
//     window.dataLayerBanners = window.dataLayerBanners || undefined
//     if (window.dataLayerBanners) {
//         for (var [key, item] of Object.entries(window.dataLayerBanners)) {
//           window.dataLayer.push({
//               'event': 'promotionImpression',
//               'eventCategory': 'enhanced-ecommerce',
//               'eventAction': 'promotionImpression',
//               'noInteraction': '1',
//               'ecommerce': {
//               'promotions': item
//               }
//           })
//         }
//     }
// }
// }
// export const gtmSendBannerClick = (name, position, list) => {
// window.dataLayer = window.dataLayer || [];
// window.dataLayer.push({
//   'event': 'promotionClick',
//   'eventCategory': 'enhanced-ecommerce',
//   'eventAction': 'promotionClick',
//     'ecommerce': {
//       'promoClick': {
//         'promotions': [{
//           'name': slugfy(name),
//           'id': position,
//           'list': slugfy(list)
//         }]
//       }
//     }
//   })
// }
export const slugfy = (str) => {
str = str.replace(/^\s+|\s+$/g, ''); // trim
str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

return str;
}