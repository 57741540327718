import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as AwardsActions from "../../../store/awardsSelection/actions";
import "../src/style.min.css";

const StepOne = ({
  percent,
  setPercent,
  nextStep,
  faixas,
  setMedalha2,
  readOnly,
  premioEscolhido,
  hideModal,
  ShowSwal,
  choiceAwards,
  errorChoice,
  successChoice,
  loading,
  faixaAtual,
  transferencia,
  quantidadeEscolhaProdutos,
}) => {
  const {
    idCampanha,
    idFaixa,
    imagemBannerPrimeiroPremio,
    imagemBannerSegundoPremio,
    qtdePacotes,
    descricaoFaixa,
    opcaoPrimerioPremio,
    opcaoSegundoPremio,
    pacotes,
    permiteSelecionar,
    idSku1,
    idSku2,
  } = faixas[0];

  function next() {
    if (faixas.length === 1) {
      hideModal();
      ShowSwal("success", "Obrigado, escolhas gravadas com sucesso!");
    } else {
      nextStep();
      setMedalha2(true);
      setPercent(percent + 100 / faixas.length);
    }
  }

  function SaveAndNext(choiceType, descricaoFaixa) {
    if (readOnly === true) return;
    const disruption = premioEscolhido;

    if (choiceType == "single") {
      // Sending 2x idSku1
      disruption.idCampanha = idCampanha;
      disruption.idFaixa = idFaixa;
      disruption.opcaoEscolhida = "";
      disruption.descricaoFaixa = descricaoFaixa;
      disruption.idSku1 = idSku1;
      disruption.idSku2 = null;
      return choiceAwards(disruption);
    }

    if (choiceType == "combo") {
      // Sending idSku1 and idSku2
      disruption.idCampanha = idCampanha;
      disruption.idFaixa = idFaixa;
      disruption.opcaoEscolhida = "";
      disruption.descricaoFaixa = descricaoFaixa;
      disruption.idSku1 = idSku1;
      disruption.idSku2 = idSku2;
      return choiceAwards(disruption);
    }
  }

  useEffect(() => {
    if (errorChoice && faixaAtual === idFaixa) {
      hideModal();
      ShowSwal("error", "Ops, ocorreu um erro na seleção, tente novamente!");
    }
    // eslint-disable-next-line
  }, [errorChoice, faixaAtual]);

  useEffect(() => {
    if (successChoice && faixaAtual === idFaixa) {
      next();
    }
    // eslint-disable-next-line
  }, [successChoice, faixaAtual]);

  const itemAwards1 = (selected, isPossibleMultiple) => {
    return (
      <li className={`card-award ` + (selected ? 'selecionado' : '')}>
          <div className="image-container">
            <img alt="" src={imagemBannerPrimeiroPremio} /> {/* sku 1 */}
            <span className="sum-icon">+</span>
            <img alt="" src={imagemBannerPrimeiroPremio} /> {/* sku 1 */}
          </div>
          <p className="award-desc">{opcaoPrimerioPremio}</p>
          {loading ? (
            <button disabled={true} className="btn btn-main">
              {(isPossibleMultiple) ? 'Quero Este' : 'Receber prêmio'}
            </button>
          ) : (
            <a
              className="btn btn-main"
              disabled={loading || readOnly === true}
              onClick={() => SaveAndNext("single", descricaoFaixa)}
            >
              {(isPossibleMultiple) ? 'Quero Este' : 'Receber prêmio'}
            </a>
          )}
        </li>
    )
  };


  const itemAwards2 = (selected, isPossibleMultiple) => {
    return (
      <li className={`card-award ` + (selected ? 'selecionado' : '')}>
        <div className="image-container">
          <img src={imagemBannerPrimeiroPremio} /> {/* sku 1 */}
          <span className="sum-icon">+</span>
          <img src={imagemBannerSegundoPremio} /> {/* sku 2 */}
        </div>
        <p className="award-desc">{opcaoSegundoPremio}</p>
        {loading ? (
          <button disabled={true} className="btn btn-main">
            {(isPossibleMultiple) ? 'Quero Este' : 'Receber prêmio'}
          </button>
        ) : (
          <a
            className="btn btn-main"
            disabled={loading || readOnly === true}
            onClick={() => SaveAndNext("combo", descricaoFaixa)}
          >
            {(isPossibleMultiple) ? 'Quero Este' : 'Receber prêmio'}
          </a>
        )}
      </li>
    );
  };

  const viewSelectedAwards = () => {
    return (quantidadeEscolhaProdutos == 1) ? itemAwards1(true) : itemAwards2(true);
  };

  const viewSelectionAwards = () => {
    return (permiteSelecionar) ? <ul>{itemAwards1(false, true)}{itemAwards2(false, true)}</ul> : <ul>{itemAwards2(true)}</ul>
  };

  return (
    <>
      Comprando <strong className="goal">{qtdePacotes} pacotes</strong> de
      produtos BAT, você ganha:
      <div
        className={`awards-list ${
          transferencia
            ? "notSelectionAwards"
            : "selectionAwards"
        }`}
      >
        {transferencia
          ? viewSelectedAwards()
          : viewSelectionAwards()}
      </div>
      <small>*Você pode mudar a sua recompensa a cada 90 dias.</small>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    acceptanceTerm: state.myData.data.aceiteTermos,
    afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
    afiliadoConectaPayPendente: state.myData.data.afiliadoConectaPayPendente,
    primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
    idCampanha: state.regulation.idCampanha,
    readOnly: state.auth.userInfo.readOnly,
    errorChoice: state.awardsSelection.errorChoice,
    successChoice: state.awardsSelection.successChoice,
    faixaAtual: state.awardsSelection.atual,
    transferencia: state.awardsSelection.data.transferencia,
    quantidadeEscolhaProdutos:
      state.awardsSelection.data.quantidadeEscolhaProdutos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    choiceAwards: (data) => {
      dispatch(AwardsActions.choiceAwards(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
