import React, { useEffect, useState } from "react";
import { default as Carousel } from "react-slick";
import { connect } from "react-redux";
import * as BannerActions from "store/banner/actions";
import "./src/banner.min.css";
import AuthHelper from "./../../Utils/AlertHelper";

// BANNER PRINCIPAL NA AREA DE DESTAQUE
const MainBanner = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [bannersCarousel, setBannersCarousel] = useState([]);
  const markeplaceUrl = new RegExp(/conectaprime\.webpremios\.digital\/.*/m);

  useEffect(() => {
    props.loadBanner();
  }, []);

  const marketplaceUrlRedirect = (url, event) => {
    let isMarketplaceUrl = markeplaceUrl.test(url);

    if (isMarketplaceUrl) {
      if (
        props.reativacaoAtual &&
        AuthHelper.validateUpdateDateReentry(
          props.reativacaoAtual.dataConclusao
        ) &&
        !props.reativacaoAtual.negociaDebito
      ) {
        AuthHelper.alertReentry(false);
        return;
      } else if (props.inadimplenteVencimentos) {
        event.preventDefault();
        AuthHelper.alertInadimplenteVencimentos();
        return;
      } else if (props.inadimplente) {
        event.preventDefault();
        AuthHelper.alertInadimplente(
          false,
          props.matriz,
          props.cobrancaCentralizada
        );
        return;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setBannersCarousel(props.banner.data);
  }, [props.banner]);

  return (
    <section className="main-banner">
      <Carousel {...settings}>
        {bannersCarousel &&
          bannersCarousel.map((banner) => {
            return (
              <div key={banner.id}>
                {banner.clicavel && banner.url && banner.url !== "#" ? (
                  <a
                    href={
                      markeplaceUrl.test(banner.url) &&
                      (props.inadimplente || props.inadimplenteVencimentos)
                        ? ""
                        : banner.url
                    }
                    target={banner.janela ? "_blank" : "_self"}
                    onClick={(e) => {
                      marketplaceUrlRedirect(banner.url, e);
                    }}
                  >
                    <img
                      src={
                        window.innerWidth > 768
                          ? banner.imagemUri
                          : banner.imagemMobileUri
                      }
                      alt={
                        markeplaceUrl.test(banner.url) &&
                        (props.inadimplente || props.inadimplenteVencimentos)
                          ? ""
                          : banner.url
                      }
                    />
                  </a>
                ) : (
                  <img
                    src={
                      window.innerWidth > 768
                        ? banner.imagemUri
                        : banner.imagemMobileUri
                    }
                    alt={
                      markeplaceUrl.test(banner.url) &&
                      (props.inadimplente || props.inadimplenteVencimentos)
                        ? ""
                        : banner.url
                    }
                  />
                )}
              </div>
            );
          })}
      </Carousel>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    banner: state.banner,
    inadimplente: state.auth.userInfo.inadimplente,
    inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
    matriz: state.myData.data.matriz,
    cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
    reativacaoAtual: state.auth.userInfo.reativacaoAtual,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBanner: () => {
      dispatch(BannerActions.loadBanner());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBanner);
