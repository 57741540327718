import * as yup from "yup";

const ValidationBank = () => {
  return yup.object().shape({
    codigoBanco: yup
      .string()
      .max(3, "Selecione um banco ")
      .required("O Banco é obrigatório!"),
    agencia: yup
      .string()
      .typeError("Insira um número válido")
      .required("O Agência é obrigatória!")
      .matches(/\d{4}/g, "Agência inválida")
      .matches(/^(\d)(?!\1+$)\d*$/g, "Os números não podem ser sequenciais")
      .min(1, "Agência deve conter no mínimo 1 digito")
      .max(4, "A conta deve ter no máximo 4 digitos"),
    bancoAgencia: yup
      .string()
      .typeError("Insira um número válido")
      .required("O Agência é obrigatória!")
      .matches(/\d{4}/g, "Agência inválida")
      .matches(/^(\d)(?!\1+$)\d*$/g, "Os números não podem ser sequenciais")
      .min(1, "Agência deve conter no mínimo 1 digito")
      .max(4, "A conta deve ter no máximo 4 digitos"),
    conta: yup
      .string()
      .typeError("Insira um número válido")
      .required("A conta é obrigatória")
      // .matches(/\d{12}/, "Conta inválida")
      .matches(/^(\d)(?!\1+$)\d*$/g, "Números não podem ser sequenciais")
      .min(1, "A conta deve ter no mínimo 1 digito")
      .max(12, "A conta deve ter no máximo 12 digitos"),
    bancoContaBancaria: yup
      .string()
      .typeError("Insira um número válido")
      .required("A conta é obrigatória")
      // .matches(/\d{12}/, "Conta inválida")
      .matches(/^(\d)(?!\1+$)\d*$/g, "Números não podem ser sequenciais")
      .min(1, "A conta deve ter no mínimo 1 digito")
      .max(12, "A conta deve ter no máximo 12 digitos"),
    digito: yup
      .string()
      .typeError("Insira digitos validos!")
      .required("O digito é obrigatório")
      .max(2, "A conta deve ter no máximo 2 digitos"),
    // bancoAgenciaDigito: yup
    //   .string()
    //   .typeError("Insira digitos validos!")
    //   .required("O digito é obrigatório")
    //   .max(2, "A conta deve ter no máximo 2 digitos"),
    bancoContaBancariaDigito: yup
      .string()
      .typeError("Insira digitos validos!")
      .required("O digito é obrigatório")
      .max(2, "A conta deve ter no máximo 2 digitos"),
    poupanca: yup
      .bool()
      .typeError("Escolha o tipo de conta")
      .required("Selecione o tipo de conta"),
    nomeOuRazao: yup
      .string()
      .required("Nome do favorecido é obrigatório")
      .matches(
        /^[A-Za-z ]+$/,
        "O nome do favorecido deve conter somente letras"
      )
      .min(2, "")
      .max(100, "Nome do favorecido até 100 caracteres"),
    cpfCnpj: yup.string().required("Documento é obrigatório"),
  });
};

export default ValidationBank;
