import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// import { CentralizationOfPoints } from '@/components/centralizationOfPoints';
import { IconAlert } from '@/components/icons';
import { Filter } from '@/components/filter';
import { SectionTitle } from '@/components/sectionTitle';
import * as MigrationOfPointsActions from '../../../store/migrationOfPoints/actions';
import AuthHelper from "@/Utils/AlertHelper";
import './src/transfer-history.min.css';

const TransferHistory = ({ migrationPointsHistoryRequest, migrationOfPointsHistory, inadimplente, inadimplenteVencimentos, readOnly, cancelado, matriz, cobrancaCentralizada }) => {
    const [filter, setFilter] = useState('todos');
    const filterOptions = ['todos', 'APROVADO', 'REPROVADO', 'AGUARDANDO'];

    useEffect(() => {
        migrationPointsHistoryRequest();
    }, []);

    const isInadimplente = (event) => {
        if (inadimplenteVencimentos) {
            event.preventDefault()
            AuthHelper.alertInadimplenteVencimentos();
            return;
        } else if (inadimplente) {
            event.preventDefault()
            AuthHelper.alertInadimplente(false, matriz, cobrancaCentralizada);
            return;
        }
    }

    const restrict = () => {
        if(!readOnly && !cancelado && !inadimplente && !inadimplenteVencimentos) {
            return '/transferir-pontos';
        }
    }

    return (
        <section className='transfer-history'>

            {/* <CentralizationOfPoints /> */}

            <div className='fm-button'>
                <Link to={restrict} className='btn btn-main btn-new ic-go' disabled={readOnly || cancelado} onClick={isInadimplente}>Transferir pontos</Link>
                {/* <Link to='/associar-novo-varejo' className='btn btn-main btn-new ic-go'>solicitar nova associação</Link> */}
            </div>
            <div className='bx-alert'>
                <p><IconAlert className='ic ic-alert' />As transferências só podem ser solicitadas uma vez por mês e após o dia 20 de cada mês.</p>
            </div>

            <SectionTitle title='Histórico de transferências'>
                {migrationOfPointsHistory.length > 0 && (
                    <Filter
                        label='Exibindo: '
                        options={filterOptions}
                        inputProps={{
                            name: 'filter',
                            id: 'filter',
                            value: filter,
                            onChange: (e) => setFilter(e.target.value),
                        }}
                    />)}
            </SectionTitle>

            <div className='bx-transfer-list'>
                {migrationOfPointsHistory.length > 0 ? (
                    <ul>
                        {migrationOfPointsHistory && migrationOfPointsHistory.map(transfer => {
                            if (filter === 'todos' || transfer.codigo === filter)
                                return (
                                    <li key={transfer.id} className={`transferencia status--${transfer.codigo.toLowerCase()}`}>
                                        <span className='date'>{transfer.dataSolicitacaoStr}</span>
                                        <span className='points'>{transfer.totalPontos} pontos</span>
                                        <span className='status'>{transfer.codigo}</span>
                                    </li>
                                )
                        })}
                    </ul>
                ) : (
                        <div className='bx'>
                            <h3>Não encontramos registros de transferências de pontos realizadas entre a sua rede de varejos.</h3>
                        </div>
                    )}
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        migrationOfPointsHistory: state.migrationOfPoints.migrationOfPointsHistory,
        inadimplente: state.myData.data.inadimplente,
        inadimplenteVencimentos: state.auth.userInfo.inadimplenteVencimentos,
        readOnly: state.auth.userInfo.readOnly,
        cancelado: state.myData.data.cancelado,
        matriz: state.myData.data.matriz,
        cobrancaCentralizada: state.myData.data.cobrancaCentralizada,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        migrationPointsHistoryRequest: () => {
            dispatch(MigrationOfPointsActions.migrationPointsHistoryRequest());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferHistory);