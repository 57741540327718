import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ModalPopup } from "@/components/modalPopup";
import Swal from "sweetalert2";
import * as CentralizedBillingActions from "store/centralizedBilling/actions";
import { Checkbox } from "@/components/formFields";
import { Accordion } from "@/components/accordion";
import { TimelineCentralization } from "@/pages/myAccount/retailChain/timelineCentralization";

import "./src/retailChainCentralization.min.css";

const RetailChainCentralization = ({
  acceptanceCentralizedBilling,
  acceptanceCentralizedBillingReset,
  centralizedBillingHistory,
  loadingSolicitation,
  successSolicitation,
  errorSolicitation,
  successHistory,
  loadingHistory,
  matriz,
  listOfRetails,
  matrizPlanValue,
  solicitation,
}) => {
  const [aprovCentralization, setAprovCentralization] = useState(false);
  const [modalState, setModalState] = useState("close");
  const [retailChain, setRetailChain] = useState([]);
  const [totalRetailsPlanValue, setTotalRetailsPlanValue] = useState(0);

  useEffect(() => {
    setRetailChain(
      listOfRetails.filter((item) => {
        return item.statusCodigo === "APROVADO";
      })
    );
  }, [listOfRetails]);

  const sumRetailsValue = () => {
    retailChain.forEach((item) => {
      setTotalRetailsPlanValue(
        (matrizPlanValue += item.planoMensalidadePrime.valor)
      );
    });
  };

  useEffect(() => {
    if (successSolicitation && !errorSolicitation && !loadingSolicitation) {
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: 'Solicitação concluída com sucesso, acompanhe as próximas emissões de cobrança no menu "Meus Pagamentos"',
      });
      setModalState("close");
      acceptanceCentralizedBillingReset();
      centralizedBillingHistory();
    } else if (
      errorSolicitation &&
      !successSolicitation &&
      !loadingSolicitation
    ) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `${
          solicitation ||
          "Opa! Alguma coisa deu errado, entre em contato com o administrador`"
        }`,
      });
      setModalState("close");
      acceptanceCentralizedBillingReset();
    }
  }, [successSolicitation, errorSolicitation, loadingSolicitation]);

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    title: "Atenção!",
    iconAlert: true,
    btnClose: true,
    mediaTag: false,
    className: "centralization-modal",
  };

  const modalConfirmation = () => {
    return (
      <ModalPopup {...propsModal} size={2}>
        <div className="inform">
          <p>
            Ao aceitar a centralização de pagamentos para as{" "}
            <strong>
              {!!retailChain.length && retailChain.length} filiais aprovadas
            </strong>{" "}
            você receberá mensalmente um boleto único disponível em Meus
            Pagamentos.
          </p>
          <p>
            <strong>Lembrando, este processo é IRREVERSÍVEL.</strong> Caso
            ocorra alguma alteração na quantidade de filiais aprovadas, o valor
            do boleto único também será alterado automaticamente.
          </p>

          <p className="value">
            *Valor total:{" "}
            <strong>
              {totalRetailsPlanValue.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </p>
          <p>
            *Inclui sua mensalidade atual somada as mensalidades de suas
            filiais.
          </p>
        </div>

        <div className="buttons">
          <button
            className="btn"
            disabled={!aprovCentralization || loadingSolicitation}
            onClick={() => {
              acceptanceCentralizedBilling();
            }}
          >
            Centralizar Pagamento
          </button>

          <button
            className="btn btn-close"
            onClick={() => {
              setModalState("close");
              setAprovCentralization(false);
            }}
          >
            Fechar
          </button>
        </div>
      </ModalPopup>
    );
  };

  return (
    <div className="retail-centralization">
      {!successHistory && !loadingHistory && (
        <div>
          <div>
            Atualmente possui{" "}
            <strong>
              {!!retailChain.length && retailChain.length} filiais aprovadas
            </strong>{" "}
            para associação de centralização de pagamento.
          </div>
          <div>
            <span>
              <strong>Atenção:</strong> Ao associar os varejos você irá gerar
              uma única fatura.
            </span>
          </div>

          <Checkbox
            label={"Desejo associar pagamento centralizado"}
            className="check-centralization"
            inputProps={{
              id: "aprov-centralization",
              name: "aprov-centralization",
              checked: aprovCentralization,
              onChange: () => {
                setAprovCentralization(!aprovCentralization);
              },
            }}
          />

          <div className="buttons">
            <button
              onClick={() => {
                setModalState("open");
                sumRetailsValue();
              }}
              disabled={!aprovCentralization || loadingSolicitation}
              className="btn btn-main ic-go"
            >
              {window.innerWidth > 400
                ? "solicitar centralização de pagamento"
                : "solicitar"}
            </button>
          </div>
        </div>
      )}

      <TimelineCentralization className="timeline" retailChain={retailChain} />

      <div className="bx-accordion">
        {retailChain.map((retail, i) => {
          return (
            <Accordion
              key={`list-item-${i}`}
              className={
                matriz
                  ? retail.statusNome.toLowerCase()
                  : retail.tipoVarejo.toLowerCase()
              }
              title={retail.razaoSocial}
            >
              <span className="status">
                {matriz ? retail.statusNome : retail.tipoVarejo}
              </span>
              <p>
                <strong>CPF/CNPJ:</strong> {retail.documento}
              </p>
              <p>
                <strong>Código SAP:</strong> {retail.codigoSap}
              </p>
              <p>
                <strong>Data da solicitação:</strong> {retail.dataSolicitacao}
              </p>
              <p>
                <strong>Dados do varejo:</strong> {retail.endereco}
              </p>
              {retail.statusNome &&
                (retail.statusNome.toLowerCase() === "reprovado" ||
                  retail.statusNome.toLowerCase() === "cancelado") && (
                  <div className="bx-message bx-message--error">
                    <i className="icon-icon_qarning"></i>
                    <strong>{retail.motivo}</strong>
                  </div>
                )}
            </Accordion>
          );
        })}
      </div>

      {modalConfirmation()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingSolicitation: state.centralizedBilling.solicitation.loading,
    successSolicitation: state.centralizedBilling.solicitation.success,
    errorSolicitation: state.centralizedBilling.solicitation.error,
    solicitation: state.centralizedBilling.solicitation,
    loadingHistory: state.centralizedBilling.history.loading,
    successHistory: state.centralizedBilling.history.success,
    matriz: state.myData.data.matriz,
    listOfRetails: state.retailChain.data,
    matrizPlanValue: state.myData.data.planoMensalidadePrime.valor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptanceCentralizedBilling: () => {
      dispatch(CentralizedBillingActions.acceptanceCentralizedBilling());
    },
    acceptanceCentralizedBillingReset: () => {
      dispatch(CentralizedBillingActions.acceptanceCentralizedBillingReset());
    },
    centralizedBillingHistory: () => {
      dispatch(CentralizedBillingActions.centralizedBillingHistory());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailChainCentralization);
