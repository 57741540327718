import React, { useState,useEffect } from 'react';
import { connect } from "react-redux";
import Modal from '../modal/modal';
import { ConectaPayRegulation } from '@/components/conectaPayForm';
import SectionTitle from '@/components/sectionTitle/sectionTitle';
import * as DeliveryPayActions from 'store/deliveryPay/actions';
import * as MyDataActions from 'store/my-data/actions';

const AcceptanceConectaPay = ({
    aceiteTermos,
    acceptanceTerm,
    afiliadoConectaPay,
    afiliadoConectaPayPendente,
    primeiroAcessoRealizado,
    deliveryPayAcceptTermsRequest,
    termAccepted,
    myDataState,
    idCampanha,
    loading
}) => {
    const [modalState, setModalState] = useState('close');
    const [regulationAccepted, setRegulationAccepted] = useState(null);

    const propsModal = {
        setModalState: setModalState,
        modalState: modalState,
    };

    useEffect(() => {
        const camp = ((aceiteTermos || acceptanceTerm) && idCampanha > 0);
        const showModal =
        camp &&
        afiliadoConectaPay &&
        afiliadoConectaPayPendente && 
        !termAccepted &&
        primeiroAcessoRealizado;
        
        setModalState(showModal ? "open" : "close");
      }, [afiliadoConectaPayPendente, acceptanceTerm, idCampanha]);

    useEffect(()=>{
      myDataState("data", "afiliadoConectaPayPendente", false);

      if(!regulationAccepted)
        myDataState("data", "afiliadoConectaPay", false);

    }, [termAccepted]);

    useEffect(() =>{
      if(regulationAccepted === true || regulationAccepted === false){
        deliveryPayAcceptTermsRequest(regulationAccepted);
      }
    }, [regulationAccepted]);

    return (
        <Modal {...propsModal}>
            <SectionTitle title='Regulamento de adesão à Conecta Pay' />
            <ConectaPayRegulation />
            <div className="bx-button">
                <a disabled={loading? true: false} onClick={() => { 
                    setRegulationAccepted(false);
                }} className='btn btn-inverted ic-go'>Não aceito</a>
                <a disabled={loading? true: false} onClick={() => { 
                    setRegulationAccepted(true);
                }} className='btn btn-main ic-go'>Aceito</a>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
      aceiteTermos: state.auth.userInfo.aceiteTermos,
      acceptanceTerm: state.acceptanceTerm.aceiteTermos,
      afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
      afiliadoConectaPayPendente: state.myData.data.afiliadoConectaPayPendente,
      primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
      termAccepted: state.deliveryPay.termAccepted,
      idCampanha: state.regulation.idCampanha,
      loading: state.deliveryPay.loading
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      myDataState: (node, key, value) =>{
        dispatch(MyDataActions.myDataState(node, key, value));
      },
      deliveryPayAcceptTermsRequest: (accepted) => {
        dispatch(DeliveryPayActions.deliveryPayAcceptTermsRequest(accepted));
      }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceConectaPay);