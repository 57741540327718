import { PopupTypes } from './types';

const INITIAL_STATE = {
  home: [],
  campaigns: [],
  loading: false,
  error: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PopupTypes.LOAD_POPUP_HOME_REQUEST:
      return { ...state, loading: true };
    case PopupTypes.LOAD_POPUP_HOME_SUCCESS:
      return {
        loading: false,
        home: action.payload.data.data
      }
    case PopupTypes.LOAD_POPUP_HOME_FAILURE:
      return { ...state, loading: false, error: true }

      case PopupTypes.LOAD_POPUP_CAMPAIGNS_REQUEST:
        return { ...state, loading: true };
      case PopupTypes.LOAD_POPUP_CAMPAIGNS_SUCCESS:
        return {
          loading: false,
          campaigns: action.payload.data.data
        }
      case PopupTypes.LOAD_POPUP_CAMPAIGNS_FAILURE:
        return { ...state, loading: false, error: true }


    case PopupTypes.LOAD_POPUP_CONFIRM_REQUEST:
      return { ...state, loading: true };
    case PopupTypes.LOAD_POPUP_CONFIRM_SUCCESS:
      return {
        loading: false,
        data: action.payload.data.data
      }
    case PopupTypes.LOAD_POPUP_CONFIRM_FAILURE:
      return { ...state, loading: false, error: true }
    default:
      return state;
  }
};

export default reducer;
