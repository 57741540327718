import React, { useEffect, useState } from "react";
import HeaderDisconnected from '../../components/header/headerDisconnected';
import DefaultContainer from "@/components/containers/defaultContainer";
import { SectionTitle } from '@/components/sectionTitle'
import { ModalPopup } from '@/components/modalPopup'
import './src/documentation.min.css';

import exampleVideo from '@/assets/video/video-example.ogg'

const Documentation = () => {
  const [modalState, setModalState] = useState("close");

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    title: "Teste  - Modal Popout",
    iconAlert: true, // Ícone de alerta no topo do modal
    btnClose: true,
    mediaTag: true, // Imagem OU vídeo (Os dois não) -- Ativar essa flag caso deseje utilizar.
  };

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <section className="page-docs-example">
      <HeaderDisconnected />
      <DefaultContainer>
        <SectionTitle title='Documentações Conecta Prime - Exemplos' />

        <div>
          <h3>Modal Popout</h3>
          <button className='btn' onClick={() => setModalState("open")}>Abrir Modal popout</button>
          <p>*Nota: Há possibilidade de haver divergências na estilização devido a possibilidade de sobreposição de CSS em comparação desta page ao componente no qual deseja implementar.</p>
          <ModalPopup {...propsModal}>
            
            {/* Caso deseje Vídeo OU imagem (Os dois não) */}
            <video controls>
              <source src={exampleVideo} type="video/ogg"/>
            </video>
            {/* <img src={exampleImg} alt=""/> */}

            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
            </p>

            {/* Caso deseje algum botão */}
            <button className='btn' onClick={() => setModalState("close")}>Fechar</button> 
          </ModalPopup>
        </div>
      </DefaultContainer>
    </section>
  );
};
export default Documentation;
