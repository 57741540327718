export const MyDataTypes =  {
    MY_DATA_STATE: '@myData/MY_DATA_STATE',
    MY_DATA_UPDATE: '@myData/MY_DATA_UPDATE',
    MY_DATA_UPDATE_SUCCESS: '@myData/MY_DATA_UPDATE_SUCCESS',
    MY_DATA_REQUEST: '@myData/MY_DATA_REQUEST',
    MY_DATA_SUCCESS: '@myData/MY_DATA_SUCCESS',
    MY_DATA_FAILURE: '@myData/MY_DATA_FAILURE',
    RESET_LOADING_FLAG: '@myData/RESET_LOADING_FLAG',
    REMOVE_DEBTOR_STATE: '@myData/REMOVE_DEBTOR_STATE'
}
