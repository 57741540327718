export const RetailChainTypes = {
    RETAIL_CHAIN_STATE : "@retailChain/RETAIL_CHAIN_STATE",
    RETAIL_CHAIN_LIST_REQUEST : "@retailChain/RETAIL_CHAIN_LIST_REQUEST",
    RETAIL_CHAIN_LIST_SUCCESS : "@retailChain/RETAIL_CHAIN_LIST_SUCCESS",
    RETAIL_CHAIN_LIST_FAILURE : "@retailChain/RETAIL_CHAIN_LIST_FAILURE",
    FACILITY_IDENTIFICATION_REQUEST : "@retailChain/FACILITY_IDENTIFICATION_REQUEST",
    FACILITY_IDENTIFICATION_SUCCESS : "@retailChain/FACILITY_IDENTIFICATION_SUCCESS",
    FACILITY_IDENTIFICATION_FAILURE : "@retailChain/FACILITY_IDENTIFICATION_FAILURE",
    RETAIL_CHAIN_SOLICITATION_REQUEST: "@retailChain/RETAIL_CHAIN_SOLICITATION_REQUEST",
    RETAIL_CHAIN_SOLICITATION_SUCCESS: "@retailChain/RETAIL_CHAIN_SOLICITATION_SUCCESS",
    RETAIL_CHAIN_SOLICITATION_FAILURE: "@retailChain/RETAIL_CHAIN_SOLICITATION_FAILURE",
    RETAIL_CHAIN_REPORT_REQUEST: "@retailChain/RETAIL_CHAIN_REPORT_REQUEST",
    RETAIL_CHAIN_REPORT_SUCCESS: "@retailChain/RETAIL_CHAIN_REPORT_SUCCESS",
    RETAIL_CHAIN_REPORT_FAILURE: "@retailChain/RETAIL_CHAIN_REPORT_FAILURE",
}