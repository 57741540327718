import React from 'react';

const IconHome = (props) => {
    return (
        <div>
            <svg
                width={props.width}
                height={props.height}
                className={props.className}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30">
                <g transform="translate(0 0)">
                    <path
                        d="M29.97,13.51,16.351,1.128l-.13-.118a1.252,1.252,0,0,0-.291-.2L15.915.805a1.251,1.251,0,0,0-1.071,0L14.83.811a1.251,1.251,0,0,0-.291.2l-.128.117L.789,13.51A1.25,1.25,0,1,0,2.47,15.36l.409-.372V29.435a1.25,1.25,0,0,0,1.25,1.25h22.5a1.25,1.25,0,0,0,1.25-1.25V14.988l.409.372a1.25,1.25,0,1,0,1.682-1.85ZM12.88,28.185v-7.5a2.5,2.5,0,1,1,5,0v7.5Zm12.5,0h-5v-7.5a5,5,0,1,0-10,0v7.5h-5V12.715l10-9.091,10,9.091Z"
                        transform="translate(-0.38 -0.685)"
                        fill={props.fill}
                        stroke='none'
                        fillRule="evenodd" />
                </g>
            </svg>

        </div>
    );
};

export default IconHome;