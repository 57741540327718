import React from "react";

const IconCopyPaste = (props) => {
  return (
    <svg
      height={props.height}
      viewBox="0 0 91 91"
      width={props.with}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={props.fill}>
        <rect fill={props.fill} height={props.height * 0.037 < 4 ? 4 : props.height * 0.037 + 'px'} width={props.width * 0.19 < 15 ? 15 : props.width * 0.19 + 'px'} x="42.4" y="42.018" />
        <rect fill={props.fill} height={props.height * 0.037 < 4 ? 4 : props.height * 0.037 + 'px'} width={props.width * 0.056 < 15 ? 5 : props.width * 0.056 + 'px'} x="42.4" y="48.924" />
        <rect fill={props.fill} height={props.height * 0.037 < 4 ? 4 : props.height * 0.037 + 'px'} width={props.width * 0.056 < 15 ? 5 : props.width * 0.056 + 'px'} x="50.488" y="48.924" />
        <path d="M24.411,74.468h29.841c3.152,0,5.717-2.564,5.717-5.716v-2.775h3.729c3.151,0,5.715-2.564,5.715-5.717V33.801   c0-0.482-0.205-0.912-0.527-1.222l0.005-0.005l-12.513-12c-0.141-0.135-0.302-0.233-0.473-0.311c-0.049-0.022-0.1-0.033-0.15-0.051   c-0.146-0.051-0.293-0.081-0.447-0.09c-0.037-0.002-0.068-0.021-0.106-0.021H33.855c-0.939,0-1.699,0.761-1.699,1.7v6.792h-7.745   c-0.938,0-1.7,0.761-1.7,1.7v42.475C22.711,73.707,23.473,74.468,24.411,74.468z M56.9,25.787l6.584,6.314h-4.268   c-1.277,0-2.316-1.039-2.316-2.316V25.787z M35.556,23.501H53.5v6.284c0,3.152,2.564,5.716,5.717,5.716h6.796V60.26   c0,1.277-1.039,2.316-2.315,2.316H35.556V23.501z M26.111,31.993h6.045v32.283c0,0.939,0.76,1.701,1.699,1.701h22.713v2.775   c0,1.276-1.039,2.316-2.316,2.316H26.111V31.993z" />
      </g>
    </svg>
  );
};

export default IconCopyPaste;