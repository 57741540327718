import React, { useState, useRef } from 'react';
import { connect } from "react-redux"
import * as DeliverySystemActions from "store/deliverySystem/actions"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DefaultCompanyIcon from '@/deliverySystem/assets/images/home/defaultCompanyRectangle.png'
import { formatDate } from "@/deliverySystem/common/globalFunctions";
import { deleteProject } from "store/deliverySystem/sagas";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { formatToCellphone } from "@/deliverySystem/common/globalFunctions";

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import './src/dataTable.min.css';

const DataTableFilter = ({ deliverySystem , setModalState, inputValue, setInputValue, stepsProps, loadProjectsListRequest, toastShow, propsModalDownload }) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);

    const editFunction = (rowData) => {
        setInputValue({ ...inputValue, ...rowData, editMode: true });
        stepsProps.navigation.go(1);
        setModalState("open");
    }

    const deleteProjectFunction = async (rowData) => {
        const response = await deleteProject(rowData.id);

        if (response?.data?.data?.success) {
            toastShow('success', 'Sucesso', `${response.data.data.message}`, 3500);
            loadProjectsListRequest();
        }
    }

    const handleDownloadPdf = async (rowData) => {
        propsModalDownload.setDownloadTargetProps({...rowData, whatsApp: formatToCellphone(rowData.whatsApp)})
        propsModalDownload.setModalState("open");
    }

    const deleteModalConfirmation = (rowData) => {
        Swal.fire({
            title: `Realmente deseja excluir o projeto "${rowData.nomeRevenda}"?`,
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            customClass: {
                title: "title",
                confirmButton: 'btn-confirm',
              },
          }).then((result) => {
            if (result.isConfirmed) {
                return deleteProjectFunction(rowData);
            }
          })

    }

    const header = (
        <div className="table-header">
            <button className='btn-new-project' onClick={() => setModalState('open')}><i className='icon-add' />Crie um novo projeto</button>
            {deliverySystem?.projectList?.data.length > 0 &&
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar projetos" />
                </span>
            }
        </div>
    );

    const companyBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Empresa</span>
                <div className="companyIcon-container">
                    <img alt="flag" src={rowData?.logoUrl} onError={(e) => e.target.src=`${DefaultCompanyIcon}`} className={`companyIcon`} />
                </div>
                <span className="image-text">{rowData?.nomeRevenda}</span>
            </React.Fragment>
        );
    }

    const createdInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Criado em</span>
                <span>{formatDate(rowData.dataInclusao)}</span>
            </React.Fragment>
        );
    }

    const buttonsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button onClick={() => editFunction(rowData)} className='btn-hyperlink'>Editar</Button>
                <Button onClick={() => deleteModalConfirmation(rowData)} className='btn-hyperlink'>Excluir</Button>
            </React.Fragment>
        );
    }

    const DownloadPecasBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className='btn-purple' onClick={() => handleDownloadPdf(rowData)}>
                    {window.innerWidth > 960 ? <>Download</> : <>Download das<br /> Peças</>}
                </Button>
            </React.Fragment>
        );
    }

    const DownloadCardapioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className='btn-purple'>
                    <Link to={{pathname: "/seu-delivery/criar-cardapio", state: {cardapioId: rowData.id}}} target="_self">
                        {window.innerWidth > 960 ? <>Download</> : <>Download do<br /> Cardápio</>}
                    </Link>
                </Button>
            </React.Fragment>
        );
    }

    return (
        <div className="datatable-filter">
            <div className="card">
                {deliverySystem?.projectList?.data.length > 0 ?
                    <DataTable ref={dt} value={deliverySystem?.projectList?.data} paginator rows={5}
                        header={header} className="p-datatable-customers"
                        globalFilter={globalFilter} emptyMessage="Nenhum projeto encontrado.">
                            <Column className={'company-container'} field="nomeRevenda" filterField="nomeRevenda" header="Empresa" body={companyBodyTemplate} />
                            <Column field="dataInclusao" header="Criado em" body={createdInBodyTemplate} />
                            <Column className={'config-container'} body={buttonsBodyTemplate} />
                            <Column header="Peças" body={DownloadPecasBodyTemplate} style={{width: '15%', textAlign: 'center'}} />
                            <Column header="Cardápio" body={DownloadCardapioBodyTemplate} style={{width: '15%', textAlign: 'center'}} />
                    </DataTable> :
    
                    <DataTable ref={dt} value={deliverySystem?.projectList?.data} rows={5}
                        header={header} className="p-datatable-customers"
                        globalFilter={globalFilter} emptyMessage="Você não possui nenhum projeto ainda. :(">
                            <Column className={'company-container'} field="nomeRevenda" filterField="nomeRevenda" body={companyBodyTemplate} />
                            <Column field="dataInclusao" body={createdInBodyTemplate} />
                            <Column body={buttonsBodyTemplate} />
                            <Column className={'pecas'} body={DownloadPecasBodyTemplate} style={{width: '15%', textAlign: 'center'}} />
                            <Column className={'cardapio'} body={DownloadCardapioBodyTemplate} style={{width: '15%', textAlign: 'center'}} />
                    </DataTable>
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadProjectsListRequest: () => {
        dispatch(DeliverySystemActions.loadProjectsListRequest())
        },
    }
}

export default connect(null, mapDispatchToProps)(DataTableFilter)
