import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import * as MigrationOfPointsActions from './actions';

export function* migrationPointsSubsidiaryRequest(){
    try {
        const {data} = yield call(api.get, "/SolicitacaoPontos/Filiais");

        yield put(MigrationOfPointsActions.migrationPointsSubsidiarySuccess(data.data));
    } catch (error) {
        yield put(MigrationOfPointsActions.migrationPointsSubsidiaryFailure());
    }
}

export function* migrationPointsRequest(action){
    try {
        const {data} = yield call(api.post, "/SolicitacaoPontos/Solicitar", action.payload.data);

        yield put(MigrationOfPointsActions.migrationPointsSuccess(data));
    } catch (error) {
        yield put(MigrationOfPointsActions.migrationPointsFailure(error));
    }
}

export function* migrationPointsHistoryRequest(){
    try {
        const {data} = yield call(api.get, "/SolicitacaoPontos/ListarSolicitacoes");

        yield put(MigrationOfPointsActions.migrationPointsHistorySuccess(data.data));
    } catch (error) {
        yield put(MigrationOfPointsActions.migrationPointsHistoryFailure());
    }
}