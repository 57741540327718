import { call, put } from 'redux-saga/effects';
import api from '../../common/api';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages';

import { contactUsSuccess, contactUsFailure, loadSubjectsSuccess, loadSubjectsFailure } from './actions';

export function* contactUs(action){
    try{
        const {data} = yield call(api.post, '/faleconosco', action.payload.data);

        yield put(contactUsSuccess(data.success));

        if(data.success)
            yield put(showToastr({ message: AppMessages.CONTACTUS_MESSAGE_SENT, type: 'success', open: true }));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(contactUsFailure());
    }
} 

export function* loadSubjects(){
    try{
        const {data} = yield call(api.get, '/faleconosco');

        yield put(loadSubjectsSuccess(data));
    }catch(error){
        yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
        yield put(loadSubjectsFailure());
    }
}