import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import './src/inputs.min.css'

const InputTelAndCellphone = ({ error, labelText, inputProps, type, callBack, ...props }) => {
    const [fieldStatus, SetFieldStatus] = useState(!props.value ? 0 : 1)

    useEffect(() => {
        if (inputProps.value) SetFieldStatus(1)
    },[inputProps?.value])

    return (
        <div className={`field-ct ${fieldStatus ? 'filled' : 'empty'}`}>
        <label htmlFor={inputProps.id}>
            <span>{labelText}</span>
        </label>
        {
            type === 'tel' ?
            <InputMask {...inputProps} mask="(99) 9999-9999" /> :
            <InputMask {...inputProps} mask="(99) 99999-9999" />
        }
        </div>
    )
};

export default InputTelAndCellphone;