import React from "react";
import { connect } from "react-redux";
import { ConectaPayForm } from "../../conectaPayForm";
import { IconInfo } from "@/components/icons";
import { Tooltip } from "@/components/tooltip";
import IconArrowToLeft from "../../icons/iconArrowToLeft";

const ConectaPayMaquininha = ({
  previousStep,
  nextStep,
  showBtnSolicitarMaquina,
  setClickedBtnSolicitarMaquininha,
  showStepConectaPay,
  setShowStepConectaPay,
  primeiroAcessoRealizado,
  setStep,
  step,
  readOnly,
  stepsProps,
}) => {
  return (
    <div id="formConectaPay">
      {showStepConectaPay.status && showStepConectaPay.stage == 1 && (
        <section className="bx-get-conecta-pay">
          <div style={{ marginBottom: "40px" }}>
            <button onClick={(e) => {previousStep(e)}} className="btn btn-link">
              <IconArrowToLeft
                fill="#8f23d6"
                className="ic ic-arrow-to-left ic-left"
              />
              Voltar
            </button>
          </div>
          <h2>
            Quero vender com a Conecta Pay
            <span className="bx-info">
              <Tooltip
                width="300px"
                message="<strong>Conecta Pay</strong> é um terminal de pagamentos exclusivo para varejos Conecta Prime.
                    Com ele, você poderá passar todas suas vendas com taxas competitivas e terá retorno de 100% do valor das taxas de produtos BAT
                    identificados em pontos no Conecta Prime"
              >
                <IconInfo className="ic ic-info" fill="#00CAFB" />
              </Tooltip>
            </span>
          </h2>
          <div className="fm-button">
            <button
              type="button"
              onClick={() => {
                setClickedBtnSolicitarMaquininha(false);
                stepsProps.navigation.next();
              }}
              className="btn btn-inverted ic-go"
              disabled={readOnly}
            >
              {!showBtnSolicitarMaquina ? "já tenho conecta pay, obrigado" : "não, obrigado!"}
            </button>
            <button
              type="button"
              onClick={() => {
                setShowStepConectaPay({ status: true, stage: 2 });
                setClickedBtnSolicitarMaquininha(true);
              }}
              className="btn btn-main ic-go"
              disabled={!showBtnSolicitarMaquina}
            >
              solicitar maquininha
            </button>
          </div>
        </section>
      )}
      {showStepConectaPay.status && showStepConectaPay.stage == 2 && (
        <section className="bx-form">
          <div style={{ marginBottom: "40px" }}>
            <button
              onClick={() => {setShowStepConectaPay({ status: true, stage: 1 });}}
              className="btn btn-link"
            >
              <IconArrowToLeft
                fill="#8f23d6"
                className="ic ic-arrow-to-left ic-left"
              />
              Voltar
            </button>
          </div>
          <h2>Informe seus dados bancários</h2>
          <ConectaPayForm
            firstAccess={primeiroAcessoRealizado}
            stepsProps={stepsProps}
            step={step}
            nextStep={nextStep}
            step={step}
          />
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ConectaPayMaquininha);
