import { IconMedal, IconClose } from "@/components/icons";
import { Modal } from "@/components/modal";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import * as AwardsActions from "../../store/awardsSelection/actions";
import SectionTitle from "../sectionTitle/sectionTitle";
import "./src/style.min.css";
import ProgressStep from "./steps/progressStep";

const AwardsSelection = ({
  aceiteTermos,
  acceptanceTerm,
  afiliadoConectaPay,
  afiliadoConectaPayPendente,
  primeiroAcessoRealizado,
  acceptanceConectaPay,
  idCampanha,
  readOnly,
  loadAwards,
  choiceAwards,
  mostrarEscolhas,
  faixas,
  loading,
  transferencia,
}) => {
  const [showModal, setShowModal] = useState({
    status: false,
    classStatus: "close",
  });
  const [percent, setPercent] = useState(0);
  const [medalha1, setMedalha1] = useState(true);
  const [medalha2, setMedalha2] = useState(false);
  const [medalha3, setMedalha3] = useState(false);
  const [medalha4, setMedalha4] = useState(false);

  const [premioEscolhido, setPremioEscolhido] = useState({
    idCampanha: idCampanha,
    idFaixa: 0,
    opcaoEscolhida: "",
    descricaoFaixa: "",
    idSku1: 0,
    idSku2: 0,
  });

  useEffect(() => {
    if (idCampanha > 0 && acceptanceTerm && !afiliadoConectaPayPendente) {
      loadAwards();
    }
  }, [idCampanha, acceptanceTerm, afiliadoConectaPayPendente]);

  useEffect(() => {
    if (
      mostrarEscolhas &&
      idCampanha > 0 &&
      acceptanceTerm &&
      !afiliadoConectaPayPendente
    ) {
      setShowModal({ status: true, classStatus: "open" });
    }
  }, [mostrarEscolhas, idCampanha, acceptanceTerm, afiliadoConectaPayPendente]);

  function hideModal() {
    setShowModal({ status: false, classStatus: "close" });
  }

  function ShowSwal(icon, text) {
    let timerInterval;

    Swal.fire({
      icon: icon,
      text: text,
      timer: 2000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location.reload();
      }
    });
  }

  const faixaOne = (faixas ?? [])[0];
  const faixaTwo = (faixas ?? [])[1];
  const faixaThree = (faixas ?? [])[2];
  const faixaFour = (faixas ?? [])[3];

  return (
    <>
      {faixas && faixas.length > 0 && showModal.status && (
        <Modal modalState={showModal.classStatus} customClass={"customClass"}>
          <section className="awards-selection">
            <span className="wrap-close" onClick={hideModal}>
              <IconClose
                className="ic-close"
                fill="var(--grayScale-a)"
                width="14px"
                height="14px"
              />
            </span>
            {transferencia ? (
              <>
                <SectionTitle title="Sua nova recompensa mensal." />
                <p>
                  Devido sua participação na nova campanha do Conecta Prime,
                  segue abaixo a sua nova recompensa mensal*:
                </p>
              </>
            ) : (
              <>
                <SectionTitle title="Escolha as suas recompensas mensais." />
                <p>
                  No Conecta Prime, quanto mais produtos BAT você compra,
                  mais produtos extra você ganha! Escolha quais produtos você
                  quer ganhar ao atingir seus potenciais de compra*:
                </p>
              </>
            )}
            <div className="container-scroll">
              <div className="porogress-range">
                <ul>
                  {faixaOne && (
                    <li className={medalha1 ? "active" : ""}>
                      <span>
                        <IconMedal className="ic ic-medal" />
                      </span>
                    </li>
                  )}
                  {faixaTwo && (
                    <li className={medalha2 ? "active" : ""}>
                      <span>
                        <IconMedal className="ic ic-medal" />
                      </span>
                    </li>
                  )}
                  {faixaThree && (
                    <li className={medalha3 ? "active" : ""}>
                      <span>
                        <IconMedal className="ic ic-medal" />
                      </span>
                    </li>
                  )}
                  {faixaFour && (
                    <li className={medalha4 ? "active" : ""}>
                      <span>
                        <IconMedal className="ic ic-medal" />
                      </span>
                    </li>
                  )}
                </ul>
                <span className="porogress">
                  <span
                    className="bar"
                    style={{
                      width: percent + "%",
                    }}
                  ></span>
                </span>
              </div>

              <ProgressStep
                setPercent={setPercent}
                percent={percent}
                faixas={faixas}
                premioEscolhido={premioEscolhido}
                hideModal={hideModal}
                setMedalha2={setMedalha2}
                setMedalha3={setMedalha3}
                setMedalha4={setMedalha4}
                ShowSwal={ShowSwal}
                readOnly={readOnly}
                loading={loading}
              />
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    acceptanceTerm: state.myData.data.aceiteTermos,
    afiliadoConectaPay: state.myData.data.afiliadoConectaPay,
    afiliadoConectaPayPendente: state.myData.data.afiliadoConectaPayPendente,
    primeiroAcessoRealizado: state.myData.data.primeiroAcessoRealizado,
    idCampanha: state.regulation.idCampanha,
    readOnly: state.auth.userInfo.readOnly,
    mostrarEscolhas: state.awardsSelection.data.mostrarEscolhas,
    faixas: state.awardsSelection.data.faixas,
    loading: state.awardsSelection.data.loading,
    transferencia: state.awardsSelection.data.transferencia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadAwards: () => {
      dispatch(AwardsActions.loadAwards());
    },
    choiceAwards: (data) => {
      dispatch(AwardsActions.choiceAwards(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardsSelection);
