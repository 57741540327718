import { action } from 'typesafe-actions';
import { ClubBenefitsTypes } from './types';


export const clubBenefitsRequest = () => action(ClubBenefitsTypes.CLUBBENEFITS_REQUEST);

export const urlClubBenefitsSuccess = (data) => action(ClubBenefitsTypes.CLUBBENEFITS_SUCCESS, {data});

export const urlClubBenefitsFailure = (error) => action(ClubBenefitsTypes.CLUBBENEFITS_FAILURE, {error});

// Página Home Benefícios Prime

export const myBenefitsRequest = (data) => action(ClubBenefitsTypes.MYBENEFITS_REQUEST, {data});

export const myBenefitsSuccess = data => action(ClubBenefitsTypes.MYBENEFITS_SUCCESS, {data});

export const myBenefitsFailure = (error) => action(ClubBenefitsTypes.MYBENEFITS_FAILURE, {error});

// Página Detalhes Benefícios Prime

export const myBenefitsDetailsRequest = (id) => action(ClubBenefitsTypes.MYBENEFITSDETAILS_REQUEST, { id });

export const myBenefitsDetailsSuccess = (data) => action(ClubBenefitsTypes.MYBENEFITSDETAILS_SUCCESS, {data});

export const myBenefitsDetailsFailure = (error) => action(ClubBenefitsTypes.MYBENEFITSDETAILS_FAILURE, {error});

// Banner Superior
export const myBenefitsBannerSuperiorRequest = () => action(ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_REQUEST);

export const myBenefitsBannerSuperiorSuccess = (data) => action(ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_SUCCESS, {data});

export const myBenefitsBannerSuperiorFailure = (error) => action(ClubBenefitsTypes.MYBENEFITSBANNERSUPERIOR_FAILURE, {error});