export const formatDate = (date) => {
  function format(number) {
    return ("0" + number).slice(-2);
  }

  var getMonth = new Date(date).getMonth() + 1;
  var getDay = new Date(date).getDate();
  var getYear = new Date(date).getFullYear();
  return format(getDay) + "/" + format(getMonth).slice(-2) + "/" + getYear;
};

export const formatToCellphone = (phone) => {
  if (phone != undefined) {
    // normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, "");
  
    // check if number length equals to 11
    if (phone.length == 11) {
      //reformat and return phone number
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
  }
};

export const slugfy = (str) => {
  if (str != undefined) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }
  
    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes
  
    return str;
  }
};
