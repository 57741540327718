import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale, subDays } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import "./src/fields.min.css";


const InputMonthYear = ({ error, label, inputProps, controlState, change }) => {
  const [startDate, setStartDate] = useState(new Date(inputProps.value));
  registerLocale('ptBR', ptBR);

  
  const setMinDate = () => {
    let today = new Date()
    let year = today.getFullYear();
    let month = today.getMonth();
    
    let minDate = today
    minDate.setMonth(month - 1)
    minDate.setFullYear(year - 1)
    return minDate
  }

  useEffect(() => {
    setStartDate(new Date(inputProps.value));
  }, [inputProps.value]);
  
  return (
    <div className={`fm-field text-field ${error ? "filled-error" : ""}`}>
      <label htmlFor={inputProps.id}>{label}</label>
      <DatePicker
        className="datepicker"
        selected={startDate === new Date() ? null : startDate}
        onChange={(date) => { 
          setStartDate(date); 
          controlState(date);
        }}
        showMonthYearPicker
        id={inputProps.id}
        disabled={inputProps.disabled}
        regional={['pt-BR']}
        locale='ptBR'
        dateFormat="MM/yyyy"
        placeholderText="Selecione o Mês desejado"
        maxDate={new Date()}
        minDate={setMinDate()}
      />
      {error ? <span className="error-label">{error.message} </span> : ""}
    </div>
  );
};

export default InputMonthYear;
