import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InputCurrency } from "@/deliverySystem/components/inputs";
import imgLoading from "@/deliverySystem/assets/images/loading-btn.svg";
import imgEditIcon from "@/deliverySystem/assets/images/editar.svg";
import * as DeliverySystemActions from "store/deliverySystem/actions";

import "./src/productItem.min.css";

const ProductItem = ({
  produto,
  putProductOnMenuRequest,
  removeProductFromMenuRequest,
  cardapioId,
  putProductOnMenuState,
  removeProductFromMenuState,
  setUpdateProductList,
  updateProductList,
  ...propsModal
}) => {
  const [updatePrice, setUpdatePrice] = useState(false);
  const [productPrice, setProductPrice] = useState();

  useEffect(() => {
    if (updateProductList.status == 3) {
      // Reset hooks after reload
      setUpdatePrice(false);
      setProductPrice();
      setUpdateProductList({ status: 0 });
    }
  }, [updateProductList]);

  const handleSaveProductSelected = (produtoId) => {
    let data = {};

    putProductOnMenuRequest({
      ...data,
      cardapioId,
      valor: Number(productPrice.replace(",", ".")),
      produtoId,
    });
  };

  const deleteTargetProduct = (produto) => {
    propsModal.setModalState(true);
    propsModal.setEditModalTargetProps({ mode: "delete", ...produto });
  };

  const editTargetProduct = (produto) => {
    propsModal.setModalState(true);
    propsModal.setEditModalTargetProps({ mode: "edit", ...produto });
  };

  return (
    <>
      <div className="product-item">
        <span>{produto.nome}</span>
        <section className="config-icons">
          <button
            onClick={() => deleteTargetProduct({ ...produto })}
            className="icons delete-icon"
          >
            <i className="icon icon-icon_close_circle" />
          </button>
          <button
            onClick={() => editTargetProduct({ ...produto })}
            className="icons edit-icon"
          >
            <img src={imgEditIcon} />
          </button>
        </section>
        {produto.incluidoCardapio ? (
          <button
            disabled={removeProductFromMenuState.loading}
            className="btn--remove"
            onClick={() => removeProductFromMenuRequest(cardapioId, produto.id)}
          >
            REMOVER
          </button>
        ) : !updatePrice ? (
          <button className="btn--add" onClick={() => setUpdatePrice(true)}>
            ADICIONAR
          </button>
        ) : (
          <div className="bx-field bx-set-price">
            <InputCurrency
              label="R$"
              onChange={(value) => setProductPrice(value)}
              inputProps={{
                name: "price",
                type: "text",
                id: "price",
                value: productPrice,
                maxLength: "6",
              }}
            />
            <button
              disabled={!productPrice || putProductOnMenuState.loading}
              className="btn-save-product"
              onClick={() => handleSaveProductSelected(produto.id)}
            >
              {putProductOnMenuState.loading ? (
                <img src={imgLoading} />
              ) : (
                <i className="icon icon-icon_check_circle"></i>
              )}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    putProductOnMenuRequest: (data) => {
      dispatch(DeliverySystemActions.putProductOnMenuRequest(data));
    },
    removeProductFromMenuRequest: (cardapioId, produtoId) => {
      dispatch(DeliverySystemActions.removeProductFromMenuRequest(cardapioId, produtoId));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    removeProductFromMenuState: state.deliverySystem.removeProductFromMenu,
    putProductOnMenuState: state.deliverySystem.putProductOnMenu,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
