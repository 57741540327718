import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as SimulatorActions from "../../../store/simulator/actions";

const BannerBeneficios = ({
  steps,
  navigation,
  index,
  banners
}) => {

  let banner = window.innerWidth > 500 ? banners[0].bannerDesk : banners[0].bannerMobile;

  const [counter, setCounter] = useState(1);
  useEffect(() => {
    setCounter(counter);
  }, [counter]);

  const [bannerBeneficio, setBannerBeneficio] = useState(banner);
  useEffect(() => {
    setBannerBeneficio(bannerBeneficio);
  }, [bannerBeneficio]);

  const handleStep = () => {
    setCounter(counter + 1);
    if(counter < banners.length){
      setBannerBeneficio(window.innerWidth > 500 ? banners[counter].bannerDesk : banners[counter].bannerMobile);
    }else{
      navigation.next();
    }
  };

  return (
    <section className="bx bx-section-bannerDescontos">
      <div className="content">
        <div className="banner-descontos">
          <img
            src={bannerBeneficio}
            className="fullWidthImg"
            title="Banner Descontos Exclusivos"
            alt="Banner Descontos Exclusivos"
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={() => handleStep()}
        className="btn btn-main ic-go"
      >
        Próximo
      </button>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    current_state: state.simulator.current_state,
    banners: state.simulator.data.banners
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simulator_state: (key, value) => {
      dispatch(SimulatorActions.simulatorState(key, value));
    },
    simulatorRequest: (state) => {
      dispatch(SimulatorActions.simulatorRequest(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerBeneficios);
