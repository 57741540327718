import {action} from 'typesafe-actions';
import {RetailChainTypes} from './types';

export const retailChainState = (node, key, value) => action(RetailChainTypes.RETAIL_CHAIN_STATE, {node, key, value});

export const retailChainListRequest = (matriz = true) => action(RetailChainTypes.RETAIL_CHAIN_LIST_REQUEST, {matriz});

export const retailChainListSuccess = (data) => action(RetailChainTypes.RETAIL_CHAIN_LIST_SUCCESS, {data});

export const retailChainListFailure = () => action(RetailChainTypes.RETAIL_CHAIN_LIST_FAILURE);

export const facilityIdentificationRequest = (document) => action(RetailChainTypes.FACILITY_IDENTIFICATION_REQUEST, {document});

export const facilityIdentificationSuccess = (data) => action(RetailChainTypes.FACILITY_IDENTIFICATION_SUCCESS, {data});

export const facilityIdentificationFailure = (error) => action(RetailChainTypes.FACILITY_IDENTIFICATION_FAILURE, {error});

export const retailChainSolicitationRequest = (data) => action(RetailChainTypes.RETAIL_CHAIN_SOLICITATION_REQUEST, {data});

export const retailChainSolicitationSuccess = (data) => action(RetailChainTypes.RETAIL_CHAIN_SOLICITATION_SUCCESS, {data});

export const retailChainSolicitationFailure = (error) => action(RetailChainTypes.RETAIL_CHAIN_SOLICITATION_FAILURE, {error});

export const retailChainReportRequest = () => action(RetailChainTypes.RETAIL_CHAIN_REPORT_REQUEST, {});

export const retailChainReportSuccess = (data) => action(RetailChainTypes.RETAIL_CHAIN_REPORT_SUCCESS, {data});

export const retailChainReportFailure = () => action(RetailChainTypes.RETAIL_CHAIN_REPORT_FAILURE, {});