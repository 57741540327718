import { action } from 'typesafe-actions';
import { FirstAccessTypes } from './types';

export const firstAccessState = (node, key, value) => action(FirstAccessTypes.FIRSTACCESS_STATE, {node, key, value});

export const loadFirstAccess = () => action(FirstAccessTypes.LOAD_FIRSTACCESS_REQUEST);

export const loadFirstAccessSuccess = data => action(FirstAccessTypes.LOAD_FIRSTACCESS_SUCCESS, { data });

export const loadFirstAccessFailure = () => action(FirstAccessTypes.LOAD_FIRSTACCESS_FAILURE);

export const updateFirstAccess = (data) => action(FirstAccessTypes.UPDATE_FIRSTACCESS_REQUEST, {data});

export const updateFirstAccessSuccess = data => action(FirstAccessTypes.UPDATE_FIRSTACCESS_SUCCESS, { data });

export const updateFirstAccessFailure = (error) => action(FirstAccessTypes.UPDATE_FIRSTACCESS_FAILURE, {error});

export const updateFirstAccessSuccessFlag = () => action(FirstAccessTypes.UPDATE_FIRSTACCESS_FLAGS);
