import Immutable from 'seamless-immutable';
import { DeliveryPayTypes } from './types';


const INITIAL_STATE = Immutable({
  termAccepted: false,
  loading: false,
  isSaveSuccess: false,
  conectaPayPortal: ""
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeliveryPayTypes.DELIVERY_PAY_REQUEST:
      return { ...state, loading: true };
    case DeliveryPayTypes.DELIVERY_PAY_SUCCESS:
      return {
        ...state,
        isSaveSuccess: action.payload.data,
        loading: false,
      }
    case DeliveryPayTypes.DELIVERY_PAY_FAILURE:
      return { ...state, loading: false }
    case DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_REQUEST:
      return { ...state, loading: true };
    case DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_SUCCESS:
      return {
        ...state,
        termAccepted: true,
        loading: false,
      }
    case DeliveryPayTypes.DELIVERY_PAY_ACCEPT_TERM_FAILURE:
      return { ...state, loading: false }
    case DeliveryPayTypes.GET_CONECTA_PAY_LINK_REQUEST:
      return { ...state, loading: true};
    case DeliveryPayTypes.GET_CONECTA_PAY_LINK_SUCCESS:
      return {
        ...state,
        conectaPayPortal: action.payload.data.data,
        loading: false,
      }
    case DeliveryPayTypes.GET_CONECTA_PAY_LINK_FAILURE:
      return { ...state, loading: false, conectaPayPortal: undefined}
    default:
      return state;
  }
};

export default reducer;
