import React from 'react'

const Tab = (props) => {

    const { className, label, isActive, onClick } = props;

    const tabClass = `tabs__tab ${className}`;
    const linkClass = isActive ? 'tabs__tab-link active' : 'tabs__tab-link';

    return (
        <li className={tabClass}>
            <a className={linkClass} onClick={onClick}>{label}</a>
        </li>
    );
};
export default Tab