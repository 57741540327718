import { action } from 'typesafe-actions';
import { PaymentTypes } from './types';

export const paymentState = (node, key, value) => action(PaymentTypes.PAYMENT_STATE, {node, key, value});

export const loadPaymentMethodsRequest = () => action(PaymentTypes.LOAD_PAYMENT_METHODS_REQUEST);

export const loadPaymentMethodsSuccess = data => action(PaymentTypes.LOAD_PAYMENT_METHODS_SUCCESS, { data });

export const loadPaymentMethodsFailure = () => action(PaymentTypes.LOAD_PAYMENT_METHODS_FAILURE);

export const participantPaymentHistoryRequest = () => action(PaymentTypes.PAYMENT_HISTORY_REQUEST);

export const participantPaymentHistorySuccess = (data) => action(PaymentTypes.PAYMENT_HISTORY_SUCCESS, {data});

export const participantPaymentHistoryFailure = () => action(PaymentTypes.PAYMENT_HISTORY_FAILURE);

export const participantPaymentMethodRequest = () => action(PaymentTypes.PARTICIPANT_PAYMENT_METHOD_REQUEST);

export const participantPaymentMethodSuccess = (data) => action(PaymentTypes.PARTICIPANT_PAYMENT_METHOD_SUCCESS, {data});

export const participantPaymentMethodFailure = () => action(PaymentTypes.PARTICIPANT_PAYMENT_METHOD_FAILURE);

export const participantPaymentChangeRequest = (data) => action(PaymentTypes.CHANGE_PAYMENT_METHOD_REQUEST, {data});

export const participantPaymentChangeSuccess = (data) => action(PaymentTypes.CHANGE_PAYMENT_METHOD_SUCCESS, {data});

export const participantPaymentChangeFailure = (error) => action(PaymentTypes.CHANGE_PAYMENT_METHOD_FAILURE, {error});

export const participantCopyOfTheBankSlipRequest = (data) => action(PaymentTypes.COPY_OF_THE_BANK_SLIP_REQUEST, {data});

export const participantCopyOfTheBankSlipSuccess = (data) => action(PaymentTypes.COPY_OF_THE_BANK_SLIP_SUCCESS, {data});

export const participantCopyOfTheBankSlipFailure = (error) => action(PaymentTypes.COPY_OF_THE_BANK_SLIP_FAILURE, {error});

export const changeFlagShowNotificationChangePaymentRequest = (data) => action(PaymentTypes.CHANGE_FLAG_PAYMENT_REQUEST, {data});

export const changeFlagShowNotificationChangePaymentSuccess = (data) => action(PaymentTypes.CHANGE_FLAG_PAYMENT_SUCCESS, {data});

export const changeFlagShowNotificationChangePaymentFailure = (error) => action(PaymentTypes.CHANGE_FLAG_PAYMENT_FAILURE, {error});

export const setCreditCardPayment = (data) => action(PaymentTypes.SET_CREDIT_CARD_PAYMENT_REQUEST, {data});

export const setCreditCardPaymentSuccess = (data) => action(PaymentTypes.SET_CREDIT_CARD_PAYMENT_SUCCESS, {data});

export const setCreditCardPaymentFailure = (error) => action(PaymentTypes.SET_CREDIT_CARD_PAYMENT_FAILURE, {error});

export const setChangeCreditCardPayment = (data) => action(PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_REQUEST, {data});

export const setChangeCreditCardPaymentSuccess = (data) => action(PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_SUCCESS, {data});

export const setChangeCreditCardPaymentFailure = (error) => action(PaymentTypes.SET_CHANGE_CREDIT_CARD_PAYMENT_FAILURE, {error});

export const sporadicBankSlipRequest = (data) => action(PaymentTypes.SPORADIC_BANK_SLIP_REQUEST, {data});

export const sporadicBankSlipSuccess = (data) => action(PaymentTypes.SPORADIC_BANK_SLIP_SUCCESS, {data});

export const sporadicBankSlipFailure = (error) => action(PaymentTypes.SPORADIC_BANK_SLIP_FAILURE, {error});

export const sporadicBankSlipResetFlag = () => action(PaymentTypes.SPORADIC_BANK_SLIP_RESET_FLAG);
