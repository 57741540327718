import React from 'react'
import './src/style.min.css'

const CustomLoading = (props) => {
    const classShow = props.show ? 'open' : ''
    return (
        <div className={`overlay ${classShow}`}>
            <div className='bx-loading'>
                <div className="sk-chase">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                </div>
                <span className='logo'></span>
            </div>
        </div>
    )
}

export default CustomLoading