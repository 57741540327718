import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { DataTableFilter } from "@/deliverySystem/components/dataTable";
import { SectionTitle } from "@/deliverySystem/components/sectionTitle";
import * as MyDataActions from "store/my-data/actions";
import * as DeliverySystemActions from "store/deliverySystem/actions";
import bannerHome from "@/deliverySystem/assets/images/home/home-delivery-system.png";
import { ModalLayout } from "@/deliverySystem/components/modais";
import { useStep } from "react-hooks-helper";
import { slugfy } from "@/deliverySystem/common/globalFunctions";
import {
  SelectProjectType,
  BusinessDataForm,
  ProjectCompleted,
} from "@/deliverySystem/components/projectCreationSteps";
import { Toast } from "primereact/toast";
import AuthHelper from "@/deliverySystem/common/AlertHelper";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  CartaoVisitaPdf,
  FolderPdf,
  ImaPdf,
} from "@/deliverySystem/components/downloadPdfKit";
import { RadioButton } from "@/deliverySystem/components/radioButton";

const Home = ({
  getMyDataRequest,
  loadProjectsListRequest,
  myData,
  deliverySystem,
  auth,
}) => {
  const toast = useRef(null);
  const initialFormValue = {
    nomeRevenda: "",
    whatsApp: "",
    endereco: "",
    formData: new FormData(),
  };
  const steps = ["SelectProjectType", "BusinessDataForm", "ProjectCompleted"];
  const { index, step, navigation } = useStep({ initialStep: 0, steps });
  const stepsProps = { steps, navigation, index };
  const [modalState, setModalState] = useState("close");
  const [downloadModalState, setDownloadModalState] = useState("close");
  const [downloadTargetProps, setDownloadTargetProps] = useState({});
  const [inputValue, setInputValue] = useState(initialFormValue);

  const toastShow = (icon, title, detailMessage, timer) => {
    // Prime React - Toast (see doc for more)
    toast.current.show({
      severity: `${icon}`,
      summary: `${title}`,
      detail: `${detailMessage}`,
      life: `${timer}`,
    });
  };

  const reset = () => {
    setInputValue(initialFormValue);
    navigation.go(0);
  };

  useEffect(() => {
    getMyDataRequest();

    if (auth.userInfo.readOnly === true) {
      const redirectToHome = true;
      AuthHelper.alertReadOnly(redirectToHome);
      return;
    }

    loadProjectsListRequest();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // GET after POST New Project or PATCH Edit Project
    if (
      deliverySystem?.newProject?.success ||
      deliverySystem?.updateProject?.success
    ) {
      loadProjectsListRequest();
    }
    // eslint-disable-next-line
  }, [deliverySystem?.newProject?.success, deliverySystem?.updateProject?.success]);

  const propsModal = {
    setModalState: setModalState,
    modalState: modalState,
    closeButton: true,
    callback: reset,
  };

  const propsModalDownload = {
    setModalState: setDownloadModalState,
    modalState: downloadModalState,
    setDownloadTargetProps: setDownloadTargetProps,
    downloadTargetProps: downloadTargetProps,
    closeButton: true,
  };

  return (
    <div className="pg pg-home">
      <SectionTitle
        title={`<span>${myData?.nome},</span><br/> Bem vindo`}
        customClass={"welcome-title"}
      />
      <div className="image-home">
        <span className="msg-minor">#deixacomoseudelivery</span>
        <span className="msg-major">
          Ponha o Seu <br />
          Delivery na rua
        </span>
        <span className="msg-bold">
          O jeito mais fácil de você começar um delivery próprio.
        </span>
        <img src={bannerHome} />
      </div>
      <DataTableFilter
        deliverySystem={deliverySystem}
        setModalState={setModalState}
        modalState={modalState}
        stepsProps={stepsProps}
        setInputValue={setInputValue}
        inputValue={inputValue}
        toastShow={toastShow}
        propsModalDownload={propsModalDownload}
      />

      <ModalLayout customClass="createProject-modal" {...propsModal}>
        {
          {
            SelectProjectType: <SelectProjectType {...stepsProps} />,
            BusinessDataForm: (
              <BusinessDataForm
                {...stepsProps}
                setInputValue={setInputValue}
                inputValue={inputValue}
                toastShow={toastShow}
              />
            ),
            ProjectCompleted: (
              <ProjectCompleted
                {...stepsProps}
                inputValue={inputValue}
                propsModalDownload={propsModalDownload}
              />
            ),
          }[step]
        }
      </ModalLayout>

      <ModalLayout customClass="download-modal" {...propsModalDownload}>
        <SectionTitle title="Escolha a peça que deseja baixar" />
        <div className="bx-buttons download">
          <div className="form-field">
            <RadioButton
              label="Cartão de Visita"
              checked={
                downloadTargetProps?.type === "cartaoVisita" ||
                downloadTargetProps?.type === undefined
                  ? true
                  : false
              }
              inputProps={{
                id: "cartaoVisita",
                name: "downloadOption",
                value: "cartaoVisita",
                onChange: (e) => {
                  setDownloadTargetProps({
                    ...downloadTargetProps,
                    type: e.target.value,
                  });
                },
              }}
            />
            <RadioButton
              label="Folder"
              checked={downloadTargetProps?.type === "folder"}
              inputProps={{
                id: "folder",
                name: "downloadOption",
                value: "folder",
                onChange: (e) => {
                  setDownloadTargetProps({
                    ...downloadTargetProps,
                    type: e.target.value,
                  });
                },
              }}
            />
            <RadioButton
              label="Imã de Geladeira"
              checked={downloadTargetProps?.type === "imaGeladeira"}
              inputProps={{
                id: "imaGeladeira",
                name: "downloadOption",
                value: "imaGeladeira",
                onChange: (e) => {
                  setDownloadTargetProps({
                    ...downloadTargetProps,
                    type: e.target.value,
                  });
                },
              }}
            />
          </div>

          {downloadTargetProps && (
            <PDFDownloadLink
              className="btn btn-download"
              document={
                downloadTargetProps.type === "imaGeladeira" ? (
                  <ImaPdf {...downloadTargetProps} />
                ) : downloadTargetProps.type === "folder" ? (
                  <FolderPdf {...downloadTargetProps} />
                ) : (
                  <CartaoVisitaPdf {...downloadTargetProps} />
                )
              }
              fileName={`${
                downloadTargetProps.type === "imaGeladeira"
                  ? "ima-de-geladeira"
                  : downloadTargetProps.type == "folder"
                  ? "folder"
                  : "cartao-de-visita"
              }-${slugfy(
                propsModalDownload.downloadTargetProps.nomeRevenda
              )}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Aguarde..." : "DOWNLOAD"
              }
            </PDFDownloadLink>
          )}
        </div>
      </ModalLayout>
      <Toast ref={toast} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMyDataRequest: () => {
      dispatch(MyDataActions.getMyDataRequest());
    },
    loadProjectsListRequest: () => {
      dispatch(DeliverySystemActions.loadProjectsListRequest());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    myData: state.myData.data,
    deliverySystem: state.deliverySystem,
    downloadPdf: state.deliverySystem.downloadPdf,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
