import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import * as BalanceActions from "store/balance/actions";
import * as MyDataActions from "store/my-data/actions"
import { UserMenu } from "@/components/menus";
import { IconEye } from "@/components/icons";
import AuthHelper from "./../../Utils/AlertHelper";

import "./src/loggedbar.min.css";

const Currency = ({ value }) => (
  <NumberFormat
    displayType={"text"}
    thousandSeparator={"."}
    decimalSeparator={","}
    value={value}
  />
);

const defaultState = {
  userLogged: true,
  userName: "",
  userPoints: 0,
  notifications: 0,
};

// AREA DE LOGGEDBAR DO HEADER
const LoggedBar = (props) => {
  const [userProfile, setUserProfile] = useState(defaultState);
  useEffect(() => {
    props.loadBalance();
    props.getMyDataRequest()
  }, []);

  useEffect(() => {
    setUserProfile({
      userLogged: true,
      userName: props.userName ? props.userName.split(" ") : "",
      userPoints: props.balance,
      notifications: 0,
    });
  }, [props.balance, props.userName]);

  const [viewBalance, setViewBalance] = useState(true);

  const showBalance = () => {
    setViewBalance((viewBalance) => !viewBalance);
  };

  if (userProfile.userLogged) {
    return (
      <Fragment>
        <section className="user-area">
          <div className="bx bx-welcome">
            <span className="user-name" onClick={showBalance}>
              Olá, {userProfile.userName[0]}!
              <IconEye className="ic ic-view" opened={viewBalance} />
            </span>

            <strong
              className={`user-points ${
                viewBalance ? "balance-show" : "balance-closed"
              }`}
            >
              {props.reativacaoAtual &&
              AuthHelper.validateUpdateDateReentry(
                props.reativacaoAtual.dataConclusao
              ) &&
              !props.reativacaoAtual.negociaDebito ? (
                "Em atualização"
              ) : (
                <>
                  <Currency value={userProfile.userPoints} /> pontos
                </>
              )}
            </strong>
          </div>
          <div className="bx bx-user-menu">
            <UserMenu
              userName={userProfile.userName[0]}
              userPoints={userProfile.userPoints}
            />
          </div>
        </section>
      </Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    balance: state.balance.pontuacao,
    userName: state.myData.data.nome,
    reativacaoAtual: state.auth.userInfo.reativacaoAtual,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadBalance: () => {
      dispatch(BalanceActions.loadBalance());
    },
    getMyDataRequest: () => {
      dispatch(MyDataActions.getMyDataRequest())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedBar);
