import React, { useState, useRef } from 'react'
import { Link } from "react-router-dom";
import './src/userMenu.min.css'

const UserMenu = () => {
    const [dropdownMenu, setDropdownMenu] = useState("")
    const [subMenuHeight, setSubMenuHeight] = useState("0px")
    const [subMenuWidth, setSubMenuWidth] = useState("0px")
    const content = useRef(null);

    const toggleDropdown = () => {
        setDropdownMenu(dropdownMenu === "open" ? "" : "open");
        setSubMenuHeight(
            dropdownMenu === "open" ? "0px" : `${content.current.scrollHeight}px`
        );
        setSubMenuWidth(
            dropdownMenu === "open" ? "0px" : `inherit`
        );
    };

    return (
        <div className='user-menu'>
            <a
                onClick={toggleDropdown}
                className={`welcome ${dropdownMenu}`}>
                <span>
                    <i className='icon icon-icon_profile'></i>
                </span>
                <i className='icon icon-icon_down'></i>
            </a>
            <div
                style={{ maxHeight: subMenuHeight, maxWidth: subMenuWidth}}
                className={`dropdown-menu`}
                ref={content}>
                <ul>
                    <li><Link to='/'>Voltar ao Conecta Prime</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default UserMenu;
